import React, { useContext, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useOutletContext } from 'react-router-dom';
import { SaveAs, Save } from '@mui/icons-material';
import {
  ReportReviewStatuses,
  ReportReviewStatusesLabels,
} from 'common/globalTypes';

import PolicyDataView from '@/components/PolicyDataView';
import API from '@/services/API';
import { LoadingContext } from '@/contexts/LoadingContext';
import { useAccountStore } from '@/store';
import CommissionsDataView from '../CommissionsDataView';
import ReconciliationsView from '../ReconciliationsView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2,
};
const editDataStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2,
};

type EditViewReportProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  name: string;
  notes: string;
  access: string;
  usersWhiteList: string[];
  approver?: string | null;
  status?: string | null;
  created_by: string;
  allData?: any;
  updateName: React.Dispatch<React.SetStateAction<string>>;
  updateNotes: React.Dispatch<React.SetStateAction<string>>;
  updateAccess: React.Dispatch<React.SetStateAction<string>>;
  updateUsersList: React.Dispatch<React.SetStateAction<string[]>>;
  updateApprover?: React.Dispatch<React.SetStateAction<string | null>>;
  updateStatus?: React.Dispatch<React.SetStateAction<string | null>>;
  isOwner: boolean;
  id: string;
};

const EditViewReport = ({
  open,
  setOpen,
  type,
  name,
  notes,
  access,
  usersWhiteList,
  approver = null,
  status = null,
  created_by,
  allData = null,
  updateName,
  updateNotes,
  updateAccess,
  updateUsersList,
  updateApprover = () => {},
  updateStatus = () => {},
  isOwner,
  id,
}: EditViewReportProps): JSX.Element => {
  const { selectedAccount } = useAccountStore();

  const { data: accountUsers } = API.getBasicQuery(
    // @ts-ignore
    `users/get_account_users?accId=${selectedAccount.accountId}&state=active`
  );

  const [newName, setNewName] = useState(name);
  const [newAccess, setNewAccess] = useState(access);
  const [newNotes, setNewNotes] = useState(notes);
  const [usersList, setUsersList] = useState(usersWhiteList);
  const [newApprover, setNewApprover] = useState(approver);
  const [newStatus, setNewStatus] = useState(status);

  const [openEditData, setOpenEditData] = useState(false);

  const patcher = API.getMutation('saved_reports', 'PATCH');

  const { openSnackbar } = useOutletContext<any>();

  const { setLoadingConfig } = useContext(LoadingContext);

  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    setOpen(false);
  };

  const handleCloseEditData = () => {
    setOpenEditData(false);
  };

  const handleAccessChange = (event: SelectChangeEvent) => {
    if (event.target.value !== 'user_list') setUsersList([]);
    setNewAccess(event.target.value);
  };

  const handleUserListChange = (event: SelectChangeEvent) => {
    setUsersList(event.target.value as unknown as string[]);
  };

  const handleApproverChange = (event: SelectChangeEvent) => {
    setNewApprover(event.target.value as string);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setNewStatus(event.target.value as string);
  };

  const onEditReportData = () => {
    setOpenEditData(true);
  };

  const handleUpdate = async (event, reason) => {
    setLoadingConfig({
      loading: true,
      message: 'Loading...',
    });
    const newData = {
      id,
      str_id: allData?.str_id,
      page: allData?.page,
      type: type.toLowerCase(),
      name: newName,
      notes: newNotes,
      access: newAccess,
      users_white_list: usersList,
      reviewed_by: newApprover,
      status: newStatus,
      created_by,
    };
    // @ts-ignore
    const response = await patcher.mutateAsync(newData);
    if (response.status === 200) {
      setLoadingConfig({
        loading: false,
        message: 'Updating...',
      });
      openSnackbar(<Alert severity="success">{type} updated!</Alert>);
      updateName(newName);
      updateNotes(newNotes);
      updateAccess(newAccess);
      updateUsersList(usersList);
      updateApprover(newApprover);
      updateStatus(newStatus);
    } else if (response.status === 400) {
      setLoadingConfig({
        loading: false,
        message: '',
      });
      setOpen(true);
      openSnackbar(<Alert severity="error">{response.error}</Alert>);
    } else {
      setLoadingConfig({
        loading: false,
        message: '',
      });
      openSnackbar(<Alert severity="error">{type} not updated!</Alert>);
    }
  };

  return (
    <>
      <div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              Saved report settings
            </Typography>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="name-textfield"
                label="Name"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="standard-multiline-static"
                label="Notes"
                multiline
                rows={5}
                defaultValue={newNotes}
                onChange={(e) => setNewNotes(e.target.value)}
                fullWidth
              />
            </Box>
            {isOwner ? (
              <>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="select-access">Access</InputLabel>
                    <Select
                      labelId="select-access"
                      value={newAccess}
                      label="Access"
                      onChange={handleAccessChange}
                    >
                      <MenuItem value={'user'}>User</MenuItem>
                      <MenuItem value={'account'}>Account</MenuItem>
                      <MenuItem value={'user_list'}>User list</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {newAccess === 'user_list' && accountUsers ? (
                  <Box sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="select-user-list">User list</InputLabel>
                      <Select
                        labelId="select-user-list"
                        multiple
                        // @ts-ignore
                        value={usersList}
                        label="Access"
                        onChange={handleUserListChange}
                      >
                        {accountUsers.map((user) => (
                          <MenuItem key={user.str_id} value={user.str_id}>
                            {user.email}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : null}
              </>
            ) : null}
            {accountUsers && type === 'Report' ? (
              <>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="select-approver">Approver</InputLabel>
                    <Select
                      labelId="select-approver"
                      // @ts-ignore
                      value={newApprover}
                      label="Approver"
                      onChange={handleApproverChange}
                    >
                      <MenuItem value={''}>None</MenuItem>
                      {accountUsers.map((user) => (
                        <MenuItem
                          key={`${user.str_id}-approver`}
                          value={user.uid}
                        >
                          {user.email}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="select-status">Status</InputLabel>
                    <Select
                      labelId="select-status"
                      // @ts-ignore
                      value={newStatus}
                      label="Status"
                      onChange={handleStatusChange}
                    >
                      <MenuItem value={ReportReviewStatuses.None}>
                        {ReportReviewStatusesLabels.None}
                      </MenuItem>
                      <MenuItem value={ReportReviewStatuses.Draft}>
                        {ReportReviewStatusesLabels.Draft}
                      </MenuItem>
                      <MenuItem value={ReportReviewStatuses.InReview}>
                        {ReportReviewStatusesLabels.InReview}
                      </MenuItem>
                      <MenuItem value={ReportReviewStatuses.Approved}>
                        {ReportReviewStatusesLabels.Approved}
                      </MenuItem>
                      <MenuItem value={ReportReviewStatuses.Rejected}>
                        {ReportReviewStatusesLabels.Rejected}
                      </MenuItem>
                      <MenuItem value={ReportReviewStatuses.RequestUpdate}>
                        {ReportReviewStatusesLabels.RequestUpdate}
                      </MenuItem>
                      <MenuItem value={ReportReviewStatuses.NonPayable}>
                        {ReportReviewStatusesLabels.NonPayable}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </>
            ) : null}
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={onEditReportData}
                startIcon={<SaveAs />}
                variant="outlined"
              >
                Edit report data
              </Button>
            </Box>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={(e) => handleClose(e, null)} sx={{ mr: 1 }}>
                Close
              </Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  handleUpdate(e, null);
                  handleClose(e, null);
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      <div>
        <Modal open={openEditData} onClose={handleCloseEditData}>
          <Box sx={editDataStyle}>
            <Typography variant="h6" component="h2">
              Edit report data
            </Typography>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-start' }}>
              <Typography sx={{ mr: 0.5 }}>
                Select the new report data and use the save report icon
                <Save color="primary" />
                to update the report with the new data.
              </Typography>
            </Box>
            <Box
              sx={{
                overflowX: 'auto',
                overflowY: 'auto',
                width: '100%',
                height: '70vh',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
              }}
            >
              {allData?.page === 'reconciliation' && (
                <ReconciliationsView reportId={allData?.str_id} />
              )}
              {allData?.page === 'commissions' && (
                <CommissionsDataView reportId={allData?.str_id} />
              )}
              {allData?.page === 'policies' && (
                <PolicyDataView reportId={allData?.str_id} />
              )}
            </Box>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={(e) => handleCloseEditData()} sx={{ mr: 1 }}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default EditViewReport;
