import { Tooltip, Checkbox } from '@mui/material';

const NullCheckbox = ({ readOnly, field, formattedData, setNewData }) => {
  return (
    <Tooltip title={`Clear data for ${field.label}`}>
      <Checkbox
        checked={formattedData?.[`${field.id}-null`] ?? false}
        onChange={() =>
          setNewData({
            ...formattedData,
            [`${field.id}-null`]: !formattedData?.[`${field.id}-null`],
          })
        }
        disabled={readOnly || field.readOnly}
      />
    </Tooltip>
  );
};

export default NullCheckbox;
