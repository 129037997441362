import log from 'loglevel';

import { auth, storage } from '@/firebase';

const Storage = {};

const checkUser = () => {
  if (!auth?.currentUser) {
    throw new Error('No current user');
  }
};

Storage.uploadFile = async (path, fileContent) => {
  checkUser();
  if (!path || !fileContent) {
    throw new Error(
      `Unable to upload ${path}. Path or file contents are empty.`
    );
  }
  const storageRef = storage.ref();
  const pdfRef = storageRef.child(path);
  const res = await pdfRef.put(fileContent);
  if (!res.state) {
    log.error(`Failed to upload file: ${path}`);
  }
};

Storage.uploadFiles = async (files) => {
  checkUser();
  const promises = files.map(async ({ path, contents }) => {
    if (!path || !contents) {
      throw new Error(
        `Unable to upload ${path}. Path or file contents are empty.`
      );
    }
    const storageRef = storage.ref();
    const pdfRef = storageRef.child(path);
    const res = await pdfRef.put(contents);
    if (!res.state) {
      log.error(`Failed to upload file: ${path}`);
    }
  });
  await Promise.all(promises);
};

Storage.getFileUrl = async (path) => {
  checkUser();
  if (!path) {
    throw new Error(
      `Unable to get download URL for ${path}. Specified path is invalid.`
    );
  }
  const ref = storage.ref(path);
  const url = await ref.getDownloadURL();
  return url;
};

Storage.getFileData = async (path) => {
  checkUser();
  if (!path) {
    throw new Error(
      `Unable to get download URL for ${path}. Specified path is invalid.`
    );
  }
  const ref = storage.ref(path);
  const datas = await ref.getMetadata();
  return datas;
};

export default Storage;
