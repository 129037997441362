import { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

interface PDFPreviewProps {
  file?: File;
  previewWidth?: number;
  rotate?: number;
}

const PDFPreview = ({ file, previewWidth, rotate = 0 }: PDFPreviewProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

  const [numPage, setNumPage] = useState(0);
  const wrapperRef = useRef(null);

  const onLoadSuccess = (res) => {
    setNumPage(res.numPages);
  };
  return (
    <div
      ref={wrapperRef}
      style={{
        padding: '20px',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {file && (
        <Document file={file} onLoadSuccess={onLoadSuccess} rotate={rotate}>
          {Array.from(new Array(numPage), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              devicePixelRatio={2}
              width={previewWidth}
            />
          ))}
        </Document>
      )}
    </div>
  );
};

export default PDFPreview;
