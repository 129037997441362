import { DeleteOutlined, Launch } from '@mui/icons-material';
import { Button, Chip } from '@mui/material';
import validator from 'validator';
import { Navigate } from 'react-router-dom';
import { ReportReviewStatuses } from 'common/globalTypes';

import DataView from '@/components/DataView';
import API from '@/services/API';
import { useAccountStore } from '@/store';
import Formatter from '@/services/Formatter';

interface UsersWithAccess {
  str_id: string;
  email: string;
}

interface ReportGroup {
  id: number;
  name: string;
}

const ReportsView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.reports;

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const { selectedAccount } = useAccountStore();

  const deleter = API.getMutation('saved_reports', 'DELETE');

  const dataDesc = {
    label: 'Saved reports',
    table: 'saved_reports?include_shared_groups=true',
    editable: false,
    validateData: (data) =>
      validator.isEmail(data?.email || '') ||
      validator.isURL(data?.website || ''),
    fields: [
      {
        id: 'name',
        label: 'Name',
        formatter: (row, collectionVals) => {
          return (
            <Button
              onClick={() => {
                window.open(`/reports/${collectionVals.str_id}`, '_blank');
              }}
              endIcon={<Launch />}
            >
              {row}
            </Button>
          );
        },
      },
      {
        id: 'page',
        label: 'Page',
      },
      {
        id: 'notes',
        label: 'Notes',
      },
      {
        id: 'access',
        label: 'Access',
      },
      {
        id: 'users_white_list',
        label: 'Users with access',
        type: 'dynamic-select',
        table: `users/get_account_users?state=active`,
        queryParamTable: selectedAccount?.accountId,
        queryParamNameTable: 'accId',
        queryParamValue: selectedAccount?.accountId,
        queryParamName: 'accId',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum: UsersWithAccess = collectionVals?.filter(
              (datum: UsersWithAccess) => datum.str_id === val
            )?.[0];
            return datum ? `${datum.email}` : 'Not found';
          }
          return val; // not formatting when collectionVals is not available
        },
      },
      {
        id: 'status',
        label: 'Status',
        formatter: Formatter.reportStatusFormatter,
      },
      {
        id: 'reviewed_by',
        label: 'Approver',
        formatter: (s, row) =>
          s ? `${row.users_reports_reviewed_byTousers?.email}` : '',
      },
      {
        id: 'saved_report_group_id',
        label: 'Report group',
        type: 'dynamic-select',
        table: `saved_reports/groups`,
        field: 'id',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum: ReportGroup = collectionVals?.filter(
              (datum: ReportGroup) => datum.id === val
            )?.[0];
            return datum ? `${datum.name}` : 'Not found';
          }
          return val; // not formatting when collectionVals is not available
        },
      },
      {
        id: 'created_at',
        label: 'Created at',
        formatter: (row) => new Date(row).toLocaleString(),
      },
    ],
    actions: [
      {
        label: <DeleteOutlined sx={{ color: '#777' }} />,
        type: 'button',
        onClick: async (data) => {
          if (data.isOwner) {
            if (confirm('Are you sure you want to delete this report?')) {
              // @ts-ignore
              await deleter.mutateAsync({ ids: [data.id] });
            }
          } else {
            confirm('Only the owner can delete this report.');
          }
        },
      },
    ],
    queryChips: {
      all: {
        id: 'all',
        label: 'All',
        query: {},
      },
      reconciliation: {
        id: 'reconciliation',
        label: 'Reconciliation',
        query: {
          pageFilter: 'reconciliation',
        },
      },
      policies: {
        id: 'policies',
        label: 'Policies',
        query: {
          pageFilter: 'policies',
        },
      },
      commissions: {
        id: 'commissions',
        label: 'Commissions',
        query: {
          pageFilter: 'commissions',
        },
      },
    },
  };

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  return (
    <>
      <DataView
        headingOffset={105}
        dataDesc={dataDesc}
        viewOnly
        hideExport
        enablePagination
      />
    </>
  );
};
export default ReportsView;
