import {
  ChevronRight,
  ExpandMore,
  Groups,
  HorizontalRule,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import LoadingCircle from '@/components/atoms/LoadingCircle';
import HierarchyDiagram from '@/components/contacts/ContactGroupsView/HierarchyDiagram';
import DataView from '@/components/DataView';
import LazyLoadDynamicSelect from '@/components/molecules/LazyLoadDynamicSelect';
import SearchableList from '@/components/molecules/SearchableList';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';

const formatter = (val, collectionVals = []) => {
  const hierarchy = <ContactHierarchy contact={val} />;
  return hierarchy;
};

const ContactHierarchy = ({ contact, depth = 0 }) => {
  const { selectedAccount } = useAccountStore();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Only show contacts with children (root contacts)
  if (contact?.parent_relationships?.length > 0) return null;

  const expandable = contact?.child_relationships?.length > 0;
  const expandFunction = expandable ? toggleExpanded : undefined;

  return contact && contact?.id ? (
    <Box key={`box-${contact.id}-${depth}`}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {[...Array(depth + 1)].map((e, i) =>
          i === depth ? (
            expanded ? (
              <ExpandMore
                key={i}
                sx={{
                  color: 'lightgray',
                  cursor: expandable ? 'pointer' : 'default',
                }}
                onClick={expandFunction}
              />
            ) : (
              <ChevronRight
                key={i}
                sx={{
                  color: 'lightgray',
                  cursor: expandable ? 'pointer' : 'default',
                }}
                onClick={expandFunction}
              />
            )
          ) : (
            <HorizontalRule key={i} sx={{ color: '#ddd' }} />
          )
        )}
        <Chip
          key={`chip-${contact.id}-${depth}`}
          label={`${Formatter.contact(contact, {
            account_id: selectedAccount?.accountId,
          })}${expandable ? ` (${contact?.child_relationships?.length})` : ''}`}
          sx={{
            m: 0.25,
            cursor: expandable ? 'pointer' : 'default',
          }}
          onClick={expandFunction}
        />
      </Box>
      <Collapse in={expanded}>
        {contact?.child_relationships?.map((contact) => (
          <ContactHierarchy
            contact={contact.contact}
            depth={depth + 1}
            key={contact.id}
          />
        ))}
      </Collapse>
    </Box>
  ) : null;
};

const ContactGroupsView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.agents_groups;

  const viewOnly = viewSettings?.read_only ?? false;

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const { selectedAccount } = useAccountStore();
  const location = useLocation();
  const navigate = useNavigate();

  // Parse the URL's query string
  const params = new URLSearchParams(location.search);
  const urlSearch = params.get('search') || '';
  const urlSort = params.get('sort') || 'asc';
  const urlView = params.get('view') || 'tree';
  const urlSelectedAgentState = params.get('selected_agent_id') || '';

  const [fetchContacts, setFetchContacts] = useState(true);
  const [fetchClickedContact, setFetchClickedContact] = useState(false);
  const [clickedContactId, setClickedContactId] = useState<any>(null);
  const [search, setSearch] = useState(urlSearch || '');
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(urlSort || 'asc');
  const [contactsItems, setContactsItems] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [view, setView] = useState(urlView);

  const limit = 100;
  const prevSort = useRef(sort);
  const prevSearch = useRef(search);
  const { data: contacts, isLoading } = API.getBasicQuery(
    'contacts',
    `page=${page}&limit=${limit}&sort=${sort}&q=${search}&orderBy=first_name&hierarchy_depth=0&is_detailed_view=true`,
    fetchContacts
  );
  const { data: clickedContact, isLoading: isClickedContactLoading } =
    API.getBasicQuery(
      'contacts',
      `id=${clickedContactId}&hierarchy_depth=9&is_detailed_view=true&get_parent_children=true`,
      fetchClickedContact
    );

  // Find the selected item when the component mounts or the URL's query string changes
  useEffect(() => {
    if (urlSelectedAgentState !== '') {
      setClickedContactId(urlSelectedAgentState);
      setFetchClickedContact(true);
    }
  }, []);

  // Update the URL's query string whenever the state changes
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('search', search);
    params.set('sort', sort);
    params.set('view', view);
    if (selectedItem) params.set('selected_agent_id', selectedItem.str_id);
    navigate({ search: params.toString() });
  }, [search, sort, view, selectedItem]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setFetchContacts(true);
    }
  };

  useEffect(() => {
    if (contacts) {
      if (sort !== prevSort.current) {
        setContactsItems(contacts.data);
      } else if (search !== prevSearch.current) {
        setContactsItems(contacts.data);
      } else {
        setContactsItems((prevContacts) => [...prevContacts, ...contacts.data]);
      }
      if (urlSelectedAgentState === '') handleItemClick(contacts.data[0]);
      setFetchContacts(false);
      prevSort.current = sort;
      prevSearch.current = search;
    }
  }, [contacts, sort]);

  const handleItemClick = (item) => {
    setClickedContactId(item?.str_id);
    setFetchClickedContact(true);
  };

  useEffect(() => {
    if (clickedContact?.data) {
      setSelectedItem(clickedContact.data[0]);
    }
  }, [clickedContact]);

  const listItemText = (item) => {
    if (item.child_relationships?.length > 0) {
      return (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          width="100%"
        >
          <Box component="span" flex="1" pr={2}>
            {Formatter.contact(item, {
              account_id: selectedAccount?.accountId,
            })}
          </Box>
          <Groups fontSize="small" sx={{ color: 'lightgray' }} />
        </Box>
      );
    } else {
      return Formatter.contact(item, {
        account_id: selectedAccount?.accountId,
      });
    }
  };

  const dataDesc = {
    label: '',
    table: 'contacts/groups',
    editable: true,
    extrtaActions: [],
    fields: [
      {
        id: 'id',
        visible: false,
      },
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'notes',
        label: 'Notes',
      },
      {
        id: 'contacts',
        label: 'Agents',
        type: 'dynamic-select',
        table: 'contacts',
        multiple: true,
        queryParamTable: 'true',
        queryParamNameTable: 'add_contact_children',
        queryParamName: 'contact_group_id_add',
        queryParamValue: 'id',
        formatter: (val, collectionVals = []) => {
          if (val === '') return '';
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const record: any = collectionVals?.filter(
              (datum: any) => datum.id === val
            )?.[0];
            return record
              ? Formatter.contact(record, {
                  account_id: selectedAccount?.accountId,
                })
              : '';
          }
          return val;
        },
        optionFormatter: (option) =>
          Formatter.contact(option, { account_id: selectedAccount?.accountId }),
        optionValuer: (option) => option.id,
        tableFormatter: (field, row, dynamicSelects, header) => (
          <LazyLoadDynamicSelect
            data={(field ?? []).map((o) => o?.id)}
            header={header}
            formatter={formatter}
          />
        ),
      },
    ],
  };

  return (
    <Box
      sx={{
        width: '100%',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            pt: 2,
            px: 2,
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5">
              {viewSettings?.page_label || 'Agent groups'}
            </Typography>
          </Box>
          <ToggleButtonGroup
            sx={{ height: 32 }}
            value={view}
            exclusive
            color="primary"
            onChange={(event, newView) => {
              setView(newView);
            }}
          >
            <ToggleButton
              sx={{ borderRadius: 16, lineHeight: 'unset' }}
              value="tree"
            >
              Tree view
            </ToggleButton>
            <ToggleButton
              sx={{ borderRadius: 16, lineHeight: 'unset' }}
              value="orgchart"
            >
              Org chart
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {view === 'orgchart' ? (
          <>
            {isLoading || isClickedContactLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  width: '100%',
                }}
              >
                <LoadingCircle />
              </Box>
            ) : (
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pt: 2,
                    px: 2,
                    pb: 2,
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Box
                      maxHeight="80vh"
                      sx={{
                        flex: 2,
                      }}
                    >
                      <SearchableList
                        items={contactsItems}
                        count={contacts?.count}
                        page={page}
                        setPage={setPage}
                        search={search}
                        setSearch={setSearch}
                        onKeyPress={handleKeyPress}
                        sort={sort}
                        setSort={setSort}
                        limit={limit}
                        handleItemClick={handleItemClick}
                        listItemText={listItemText}
                        selectedItem={selectedItem}
                        title={`My team (${contacts?.count || 0})`}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 10,
                      }}
                    >
                      {selectedItem && (
                        <HierarchyDiagram
                          contact={selectedItem}
                          handleItemClick={handleItemClick}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <DataView
            dataDesc={dataDesc}
            viewOnly={viewOnly}
            readOnly={viewOnly}
          />
        )}
      </Box>
    </Box>
  );
};

export default ContactGroupsView;
