import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import { isValidElement, useState } from 'react';
import { Link } from 'react-router-dom';

import LoadingCircle from '@/components/atoms/LoadingCircle';
import API from '@/services/API';

type LazyLoadDynamicSelectProps = {
  data: any;
  formatter?: (any) => any;
  header: any;
  link?: string;
};

const LazyLoadDynamicSelect: React.FC<LazyLoadDynamicSelectProps> = ({
  data,
  header,
  formatter = (val) => val,
  link,
}) => {
  const [expanded, setExpanded] = useState(false);

  let queryParams = `is_dynamic_select=true&ids=${encodeURIComponent(data.join(','))}`;
  if (header.queryParamNameTable && header.queryParamTable) {
    queryParams += `&${encodeURIComponent(header.queryParamNameTable)}=${encodeURIComponent(header.queryParamTable)}`;
  }

  const { data: _items, isLoading } = API.getBasicQuery(
    header.table,
    queryParams,
    expanded
  );

  // Some endpoints return data in data property, while others don't >_<
  const items = Array.isArray(_items?.data) ? _items.data : _items;
  return (
    <Box>
      {!isLoading ? (
        <>
          <Button
            endIcon={expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            onClick={() => setExpanded(!expanded)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              color: 'text.primary',
              fontWeight: 500,
            }}
          >
            {`${data.length} ${header.label.toLowerCase()}`}
          </Button>
          {expanded && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.25, mt: 0.5 }}>
              {Array.isArray(items) &&
                items?.map((item) => {
                  const formattedItem = formatter(item);
                  return isValidElement(formattedItem) ? (
                    formattedItem
                  ) : link ? (
                    <Chip
                      key={item.id}
                      label={formatter(item)}
                      component={Link}
                      to={`${link}?id=${item.str_id}`}
                      clickable
                    />
                  ) : (
                    <Chip key={item.id} label={formatter(item)} />
                  );
                })}
            </Box>
          )}
        </>
      ) : (
        <LoadingCircle />
      )}
    </Box>
  );
};

export default LazyLoadDynamicSelect;
