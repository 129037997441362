import { Autorenew, Delete, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Divider, Toolbar, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import DataForm from '@/components/DataForm';
import MultiSelect from '@/components/molecules/MultiSelect';

// TODO: Since bulk edit is being used in more places, we should find a way to separate fields based on the page where it is being used
const fieldIds = [
  'policy_id',
  'agent_name',
  'writing_carrier_name',
  'carrier_name',
  'customer_name',
  'product_type',
  'product_name',
  'effective_date',
  'contacts',
  'notes',
  'agent_commissions_status',
  'compensation_type',
  'transaction_type',
  'policy_status',
  'product_option_name',
  'processing_date',
  'payment_date',
  'period_date',
  'internal_id',
  'split_percentage',
  'premium_type',
  'agent_commissions',
  'report_notes',
  'status', // Mainly for saved_report status bulk edit
];

const FieldSelector = ({ fields, fieldIds, onFieldsChange }) => {
  const [selectedFieldsIds, setSelectedFieldsIds] = useState<any>([]);
  const foundFields = fieldIds
    .map((id) => fields.find((field) => field.id === id))
    .filter(Boolean);

  useEffect(() => {
    const selectedFields = selectedFieldsIds
      .map((id) => fields.find((field) => field.id === id))
      .filter(Boolean);
    onFieldsChange(selectedFields);
  }, [selectedFieldsIds]);

  return (
    <Box sx={{ mt: 2 }}>
      <MultiSelect<any, any>
        label="Fields to edit"
        values={foundFields}
        selectedValues={selectedFieldsIds}
        setSelectedValues={setSelectedFieldsIds}
        formatter={(field) => field.label}
        valuer={(field) => field.id}
        sx={{ width: '100%' }}
      />
    </Box>
  );
};

const EnhancedTableToolbar = ({
  title,
  selected,
  setSelected,
  onDelete,
  onSync,
  headers: fields,
  onEdit,
  customActions = false,
}): JSX.Element => {
  const [isWorkingToolbar, setIsWorkingToolbar] = useState(false);
  const [open, setOpen] = useState(false);
  const [newDatum, setNewDatum] = useState({});
  const [dataDesc, setDataDesc] = useState({ fields: [] });
  const numSelected = selected.length;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: 2,
    pt: 1,
    px: 2,
    maxHeight: '90vh',
    overflowY: 'auto',
  };

  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    setOpen(false);
    setIsWorkingToolbar(false);
    setNewDatum({});
  };

  const handleFieldsChange = (selectedFields) => {
    setDataDesc({
      fields: selectedFields.map((header) => ({
        ...header,
        enableNullCheckbox: true,
      })),
    });
  };

  return numSelected > 0 ? (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
        variant="dense"
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        )}
        {numSelected > 0 && !customActions && onEdit instanceof Function && (
          <Tooltip title="Bulk edit" arrow>
            <LoadingButton
              loading={isWorkingToolbar}
              onClick={async () => {
                setIsWorkingToolbar(true);
                setOpen(true);
              }}
              sx={{ minWidth: 48 }}
            >
              <Edit />
            </LoadingButton>
          </Tooltip>
        )}
        {numSelected > 0 && !customActions && onDelete instanceof Function && (
          <Tooltip title="Delete" arrow>
            <LoadingButton
              loading={isWorkingToolbar}
              onClick={async () => {
                if (
                  confirm('Are you sure you want to delete the selected items?')
                ) {
                  setIsWorkingToolbar(true);
                  await onDelete(selected);
                  setIsWorkingToolbar(false);
                }
              }}
              sx={{ minWidth: 48 }}
            >
              <Delete />
            </LoadingButton>
          </Tooltip>
        )}
        {numSelected > 0 && !customActions && onSync instanceof Function && (
          <Tooltip title="Sync" arrow>
            <LoadingButton
              loading={isWorkingToolbar}
              onClick={async () => {
                if (
                  confirm('Are you sure you want to sync the selected items?')
                ) {
                  setIsWorkingToolbar(true);
                  await onSync(selected);
                  setIsWorkingToolbar(false);
                }
              }}
              sx={{ minWidth: 48 }}
            >
              <Autorenew />
            </LoadingButton>
          </Tooltip>
        )}
      </Toolbar>

      <Modal open={open} onClose={handleClose} sx={{ overflowY: 'scroll' }}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Bulk editor
          </Typography>
          <FieldSelector
            fields={fields}
            fieldIds={fieldIds}
            onFieldsChange={handleFieldsChange}
          />
          <Divider sx={{ my: 1 }} />
          {/* @ts-ignore */}
          <DataForm
            dataDesc={{ saveOnly: true }}
            fields={dataDesc.fields}
            newData={newDatum}
            setNewData={setNewDatum}
            onCancel={() => {
              setIsWorkingToolbar(false);
              setOpen(false);
            }}
            onSave={async () => {
              await onEdit(selected, newDatum);
              setIsWorkingToolbar(false);
              setOpen(false);
              setSelected([]);
              setNewDatum({});
            }}
            formModeOnly={false}
            embed={true}
            fetchOnFieldChange={true}
          />
        </Box>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default EnhancedTableToolbar;
