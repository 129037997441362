export const arrayToMap = (arr, key: string | Function) =>
  (arr ?? []).reduce((acc, e) => {
    acc[typeof key === 'string' ? e[key] : key(e)] = e;
    return acc;
  }, {});

export const enumToSelectOptions = (enumObj: any) =>
  Object.entries(enumObj).map(([k, v]) => ({
    id: k,
    label: v,
  }));
