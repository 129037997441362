import * as Sentry from '@sentry/react';
import * as ReactDOM from 'react-dom/client';
import 'typeface-roboto';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './views/App';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({ maskAllText: false }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

// log.setLevel('trace');

// ReactDOM.render(<App />, document.getElementById('root'));
const el = document.getElementById('root') || document.createElement('div');
const root = ReactDOM.createRoot(el);
root.render(
  <QueryClientProvider client={new QueryClient()}>
    <App />
  </QueryClientProvider>
);

serviceWorker.register();
