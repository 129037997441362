import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { IFieldsProps } from './process';

interface RemoveFieldsDialogProp {
  open: boolean;
  onClose: (v) => void;
  onConfirm: (e: IFieldsProps[]) => void;
  fieldsSource: IFieldsProps[];
}

const RemoveFieldsDialog = ({
  open,
  onClose,
  onConfirm,
  fieldsSource,
}: RemoveFieldsDialogProp) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [selectedFields, setSelectedFields] = useState<IFieldsProps[]>([]);

  const handleChange = (key, e) => {
    if (e.target.checked) {
      setChecked((prev) => {
        return [...prev, key];
      });
    } else {
      setChecked((prev) => {
        return prev.filter((s) => s !== key);
      });
    }
  };

  const handleSubmit = () => {
    const list = fieldsSource.filter((item) => checked.includes(item.key));
    setSelectedFields(list);
    onConfirm(list);
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Remove field(s)</DialogTitle>
      <DialogContent>
        <Box>
          <FormGroup>
            {fieldsSource.map((item) => (
              <FormControlLabel
                control={<Checkbox />}
                label={item.label}
                key={item.key}
                value={item.key}
                onChange={(e) => handleChange(item.key, e)}
              />
            ))}
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={!checked.length}
          variant="contained"
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveFieldsDialog;
