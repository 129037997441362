import { useEffect, useState } from 'react';
const IMGPreview = ({ file }) => {
  const [fileContent, setFileContent] = useState('');
  useEffect(() => {
    if (file) {
      setFileContent(URL.createObjectURL(file));
    }
  }, [file]);
  return (
    <div className="p-2 box-border flex items-center rounded-xl overflow-scroll">
      {file && (
        <div style={{ margin: 'auto' }}>
          <img
            alt="preview"
            src={fileContent}
            style={{ maxWidth: '70vw', width: '100%' }}
          />
        </div>
      )}
    </div>
  );
};

export default IMGPreview;
