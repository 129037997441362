import {
  AttachMoney,
  CalendarTodayOutlined,
  CheckBoxOutlined,
  DataArray,
  DataObject,
  Numbers,
  Percent,
  PlusOne,
  ReceiptOutlined,
  RequestQuoteOutlined,
  TextFieldsOutlined,
} from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

import DataView from '@/components/DataView';

const types = {
  text: {
    label: 'Text',
    type: 'text',
    icon: <TextFieldsOutlined />,
  },
  integer: {
    label: 'Integer',
    type: 'integer',
    icon: <PlusOne />,
  },
  decimal: {
    label: 'Decimal',
    type: 'decimal',
    icon: <Numbers />,
  },
  currency: {
    label: 'Currency',
    type: 'currency',
    icon: <AttachMoney />,
  },
  date: {
    label: 'Date',
    type: 'date',
    icon: <CalendarTodayOutlined />,
  },
  percentage: {
    label: 'Percentage',
    type: 'percentage',
    icon: <Percent />,
  },
  boolean: {
    label: 'Boolean',
    type: 'boolean',
    icon: <CheckBoxOutlined />,
  },
  array: {
    label: 'Array',
    type: 'array',
    icon: <DataArray />,
  },
  json: {
    label: 'JSON',
    type: 'text',
    icon: <DataObject />,
  },
};

const normalizeJson = (val) => {
  if (val && typeof val === 'string') return JSON.parse(val);
  return val;
};

const formatJson = (s) => (typeof s === 'string' ? s : JSON.stringify(s));

const formatJsonAsKeyVal = (json) => {
  const _json = typeof json === 'string' ? JSON.parse(json) : json;
  return (
    <Box>
      {Object.entries(_json).map(([k, v]) => (
        <Box key={k} sx={{ whiteSpace: 'nowrap' }}>
          <span style={{ color: v === false ? 'darkgray' : 'inherit' }}>
            {k}: {String(v)}
          </span>
        </Box>
      ))}
    </Box>
  );
};

const formatModel = (val) =>
  val === 'Policy reports' ? (
    <Tooltip arrow title="Policy reports">
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <ReceiptOutlined /> Policy
      </span>
    </Tooltip>
  ) : val === 'Commission statements' ? (
    <Tooltip arrow title="Commission statements">
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <RequestQuoteOutlined /> Commission
      </span>
    </Tooltip>
  ) : (
    val
  );

const formatType = (val) =>
  types[val] ? (
    <Tooltip arrow title={types[val].label}>
      {types[val].icon}
    </Tooltip>
  ) : null;

const dataDesc = {
  label: 'Fields',
  table: 'fields',
  editable: true,
  fields: [
    {
      id: 'model',
      label: 'Model',
      type: 'select',
      options: [
        {
          label: 'Policies',
          id: 'reports',
        },
        {
          label: 'Commissions',
          id: 'statements',
        },
      ],
      // tableFormatter: formatModel,
    },
    { id: 'label', label: 'Label', tableFormatter: (val) => <b>{val}</b> },
    { id: 'key', label: 'Key' },
    {
      id: 'options',
      label: 'Options',
      type: 'text-multiline',
      normalizer: normalizeJson,
      formatter: formatJson,
    },
    {
      id: 'matches',
      label: 'Matches',
      type: 'text-multiline',
      normalizer: normalizeJson,
      formatter: formatJson,
    },
    {
      id: 'enabled_for',
      label: 'Enabled?',
      normalizer: normalizeJson,
      formatter: formatJson,
      tableFormatter: formatJsonAsKeyVal,
      default: { default: true, insurance: true },
    },
    {
      id: 'required_for',
      label: 'Required?',
      normalizer: normalizeJson,
      formatter: formatJson,
      tableFormatter: formatJsonAsKeyVal,
      default: { default: false, insurance: false },
    },
    {
      id: 'type',
      label: 'Type',
      type: 'select',
      options: Object.keys(types),
      tableFormatter: formatType,
    },
    {
      id: 'copyable',
      label: 'Copyable?',
      type: 'boolean',
    },
    { id: 'description', label: 'Description', type: 'text-multiline' },
    { id: 'notes', label: 'Notes', type: 'text-multiline' },
  ],
  queryChips: {
    all: {
      id: 'all',
      label: 'All',
      query: {},
    },
    commissions: {
      id: 'commissions',
      label: 'Commissions',
      query: {
        model: 'statements',
      },
    },
    policies: {
      id: 'policies',
      label: 'Policies',
      query: {
        model: 'reports',
      },
    },
  },
};

const FieldsView = () => <DataView dataDesc={dataDesc} hideExport />;

export default FieldsView;
