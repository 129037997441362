import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles.css';
import { dashboardWidgetOptions } from 'common/constants';

import GridItem from './GridItem';
import API from '@/services/API';

const ResponsiveGridLayout = WidthProvider(Responsive);
const ReactGridLayout = () => {
  const [layouts, setLayouts] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const {
    isLoading,
    data: insightsData,
    isFetched,
  } = API.getBasicQuery('insights');

  const [widgetArray, setWidgetArray] = useState([]);

  const cols = { lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 };
  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
  const margin = {
    lg: [10, 10],
    md: [10, 10],
    sm: [10, 10],
    xs: [10, 10],
    xxs: [10, 10],
  };
  useEffect(() => {
    if (!insightsData) {
      return;
    }
    buildWidgetArray(insightsData?.boxes);
  }, [insightsData]);

  const buildWidgetArray = (data) => {
    const currentBreakpoint = Object.keys(breakpoints).find((breakpoint) => {
      return window.innerWidth > breakpoints[breakpoint];
    });

    const totalCols = cols[currentBreakpoint];
    let tempArray = [];
    data.map((item, index) => {
      tempArray.push({
        ...item,
        i: 'widget' + (index + 1),
        x: (index * 2) % totalCols,
        y: Math.floor((index * 2) / totalCols),
        w: 2,
        h: 1,
      });
    });
    setWidgetArray(tempArray);
  };
  const handleModify = (layouts, layout) => {
    const tempArray = widgetArray ?? [];
    setLayouts(layout);
    layouts?.map((position) => {
      tempArray.map((data) => {
        if (data.i === position.i) {
          data.x = position.x;
          data.y = position.y;
          data.width = position.w;
          data.height = position.h;
        }
      });
    });
    setWidgetArray(tempArray);
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const handleDelete = (key) => {
    const tempArray = widgetArray.slice();
    const index = tempArray.indexOf(tempArray.find((data) => data.i === key));
    tempArray.splice(index, 1);
    setWidgetArray(tempArray);
  };
  if (!widgetArray) return null;
  return (
    <Box p={2} sx={{ width: '100%', overflowY: 'scroll' }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Dashboard</Typography>
        <Box sx={{ display: 'flex' }}></Box>
        <Button variant="contained" onClick={handleAdd}>
          Add
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{
          component: 'form',
          onSubmit: () => {
            handleClose();
          },
        }}
      >
        <DialogTitle>Add widget</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the widget you want to add
          </DialogContentText>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={'policiesInForce'}
            label="Widget"
            onChange={(e) => console.log(e.target.value)}
          >
            {Object.keys(dashboardWidgetOptions).map((key) => {
              return (
                <MenuItem value={key}>{dashboardWidgetOptions[key]}</MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <ResponsiveGridLayout
        onLayoutChange={handleModify}
        verticalCompact={true}
        layout={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        preventCollision={false}
        draggableHandle=".dragHandle"
        cols={cols}
        autoSize={true}
        margin={margin}
      >
        {widgetArray?.map((widget, index) => {
          return (
            <div
              className="reactGridItem"
              key={index}
              data-grid={{
                x: widget?.x,
                y: widget?.y,
                w: widget?.w,
                h: widget?.h,
                i: widget.i,
                minW: 2,
                maxW: Infinity,
                maxH: Infinity,
                isDraggable: true,
                isResizable: true,
              }}
            >
              <GridItem widget={widget} handleDelete={handleDelete}>
                <Card className="grid-item-card">
                  <CardContent
                    sx={{
                      pb: '16px !important',
                      minHeight: 120,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      sx={{ fontSize: 16, fontWeight: 500 }}
                    >
                      {widget.id}
                    </Typography>
                    {widget.type === 'h5' && (
                      <Typography variant="h5">{widget.value}</Typography>
                    )}
                  </CardContent>
                </Card>
              </GridItem>
            </div>
          );
        })}
      </ResponsiveGridLayout>
    </Box>
  );
};

export default ReactGridLayout;
