import { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import API from '@/services/API';

const AdminCard = (params: {
  children;
  isAdmin?: boolean;
  showIcon?: boolean;
  iconPosition?: 'left' | 'right';
}) => {
  const {
    children,
    isAdmin: externalIsAdmin,
    showIcon = false,
    iconPosition = 'left',
  } = params;
  const [isAdmin, setIsAdmin] = useState(false);
  const { data } = API.getBasicQuery(
    'admin/check',
    '',
    externalIsAdmin == undefined
  );

  useEffect(() => {
    if (externalIsAdmin !== undefined) {
      setIsAdmin(externalIsAdmin);
    } else {
      setIsAdmin(data?.data?.isAdmin);
    }
  }, [externalIsAdmin, data]);

  if (!isAdmin) {
    return null;
  }

  const icon = <span>🔒</span>;
  return (
    <Box>
      {showIcon && iconPosition === 'left' ? icon : null}
      {children}
      {showIcon && iconPosition === 'right' ? icon : null}
    </Box>
  );
};

export default AdminCard;
