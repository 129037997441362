export enum DocumentTypeCreatedType {
  automatically = 'automatically',
  manully = 'manully',
}

export interface DocumentTypeE {
  id: number;
  str_id: string;
  uid: string;
  account_id: string;
  state: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: any;
  description: any;
  carrier_name: string;
  paying_entity: string;
  file_link: string[];
  field_mapping: string;
  owner: string;
  status: string;
  notes: string;
  processor_str_ids: any[];
  mappings_str_ids: any[];
  document_str_ids: string[];
  create_type: DocumentTypeCreatedType;
  priority: DocumentTypesPriority;
}

export enum DocumentTypesPriority {
  urgent = 'urgent',
  high = 'high',
  medium = 'medium',
  low = 'low',
}

export const PriorityList = [
  {
    label: 'Urgent',
    value: 1,
    color: 'red',
  },
  {
    label: 'High',
    value: 2,
    color: 'yellow',
  },
  {
    label: 'Medium',
    value: 3,
    color: 'blue',
  },
  {
    label: 'Low',
    value: 4,
    color: 'green',
  },
];
