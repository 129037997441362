import { ContentCopy, Label, Login } from '@mui/icons-material';
import { Box, Chip, IconButton } from '@mui/material';
import copy from 'copy-to-clipboard';

import EnhancedDataView from '@/components/organisms/EnhancedDataView';

const dataSpec = {
  label: 'Users',
  table: 'admin/users',
  fields: {
    uid: {
      label: 'Uid',
      id: 'uid',
      copyable: true,
      enabled: true,
      visible: true,
    },
    email: {
      label: 'Email',
      enabled: true,
      visible: true,
    },
    first_name: {
      label: 'First name',
      enabled: true,
      visible: true,
    },
    last_name: {
      label: 'Last name',
      enabled: true,
      visible: true,
    },
    account_user_roles: {
      label: 'Log in as user/account',
      enabled: true,
      visible: true,
      formatter: (fieldData, rowData) => {
        return (
          <Chip
            key={fieldData.id}
            label={`${fieldData.account.name} (${fieldData.role.name})`}
            onClick={() => {
              localStorage.clear();
              localStorage.setItem('customLoginUser', JSON.stringify(rowData));
              localStorage.setItem(
                'selectedAccount',
                JSON.stringify({
                  accountId: fieldData.account.str_id,
                  accountName: fieldData.account.name,
                  accountMode: fieldData.account.mode,
                  state: fieldData.account.state,
                })
              );
              window.location.pathname = '/';
            }}
            clickable
            icon={<Login />}
            sx={{ m: 0.25 }}
          />
        );
      },
    },
  },
};

const LoginAsView = () => (
  <EnhancedDataView
    dataSpec={dataSpec}
    hideAdd
    hideSelectedCount
    enableMultiSelect={false}
    enableEdit={false}
  />
);

export default LoginAsView;
