import { PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useState } from 'react';

import API from '@/services/API';

const processReconciliation = async (options, documentId) => {
  const { deDupe, redo, testRun, flowId } = options || {};
  const res = await fetch(
    `${process.env.REACT_APP_API}/api/reconciliation/reconcile`,
    {
      method: 'POST',
      headers: await API.getHeaders(),
      body: JSON.stringify({
        deDupe,
        redo,
        testRun,
        flowId,
        documentId,
      }),
    }
  );
  console.log('processReconciliation: ', await res.json());
  return res;
};

const DocumentReconciler = ({ flowId }) => {
  const { data } = API.getBasicQuery('documents');
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const statementData = data?.data?.filter(
    (datum) => datum.type === 'statement'
  );
  return (
    statementData && (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Autocomplete
          disablePortal
          id="document"
          options={statementData}
          renderInput={(params) => <TextField {...params} label="Document" />}
          getOptionLabel={(option: any) =>
            `${option.filename} (${option?.str_id} - ${new Date(
              option?.created_at
            ).toLocaleString()})`
          }
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          sx={{ width: 300 }}
        />
        <LoadingButton
          disabled={!(value as any)?.str_id}
          loading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await processReconciliation(
              {
                deDupe: false,
                redo: true,
                testRun: true,
                flowId,
              },
              (value as any)?.str_id
            );
            setIsLoading(false);
          }}
        >
          <PlayArrow />
        </LoadingButton>
      </Box>
    )
  );
};

export default DocumentReconciler;
