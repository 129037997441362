import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import validator from 'validator';

import DataView from '@/components/DataView';
import { auth } from '@/firebase';
import useAccountStore from '@/store/accountStore';
import API from '@/services/API';

const userData = {
  label: 'User information',
  table: 'users/onboarding_update',
  editable: false,
  validateData: (data) => validator.isEmail(data?.email),
  fields: [
    {
      id: 'email',
      label: 'Email',
      default: auth?.currentUser?.email, // null on load, set below
      readOnly: true,
      validator: (val) => validator.isEmail(val || ''),
    },
    {
      id: 'first_name',
      label: 'First name',
      required: true,
    },
    {
      id: 'last_name',
      label: 'Last name',
      required: true,
    },
    {
      id: 'phone',
      label: 'Phone',
      validator: (val) =>
        validator.isMobilePhone(val || '', 'en-US') || val === '',
    },
  ],
};

const accountData = {
  label: 'Account information',
  table: 'accounts/onboarding_update',
  editable: false,
  fields: [
    {
      id: 'name',
      label: 'Account name',
      required: true,
    },
    {
      id: 'description',
      label: 'Account description',
    },
  ],
};

const Onboarding = ({ userState, openSnackbar }) => {
  const storedUser = localStorage.getItem('customLoginUser');
  const impersonatedUser = storedUser ? JSON.parse(storedUser) : null;
  userData.fields.filter((field) => field.id === 'email')[0].default =
    impersonatedUser?.email ?? auth?.currentUser?.email ?? userState?.userEmail;

  const [activeStep, setActiveStep] = useState(0);
  const [account, setAccount] = useState({ state: null });
  const [steps, setSteps] = useState(['User info', 'Account info']);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const { data } = API.getBasicQuery('accounts/get_onboarding_accounts', '');
  const { selectedAccount } = useAccountStore();

  useEffect(() => {
    if (data && !data.error) {
      // TODO: handle multiple accounts
      setAccount(data[0]);
      data[0]?.state === 'active'
        ? setSteps(['User info'])
        : setSteps(['User info', 'Account info']);
    }
  }, [data, selectedAccount]);

  const savedUser = (userResponse) => {
    if (userResponse && userResponse.state === 'active') {
      handleComplete();
    }
  };

  const savedAccount = (accountResponse) => {
    if (accountResponse && accountResponse.state === 'active') {
      handleComplete();
    }
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const welcomeMessage =
    account?.state === 'active'
      ? 'Tell us about yourself'
      : 'Tell us about yourself and your agency';

  return (
    <>
      <Box sx={{ width: '100%', mt: 4, px: 2 }}>
        <Typography variant="h5">Let's get started</Typography>
        <Typography sx={{ mt: 3 }}>{welcomeMessage}</Typography>
        {Array.isArray(steps) && steps.length > 1 && (
          <Stepper sx={{ mt: 3 }} nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
        <div>
          {allStepsCompleted() ? (
            <Box sx={{ mt: 5 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">
                  Congratulations! You're ready for start using Fintary.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 2,
                }}
              >
                <Button
                  onClick={() => {
                    window.location.href = '/';
                  }}
                  variant="outlined"
                  sx={{ mr: 1 }}
                >
                  Go to home
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              {activeStep === 0 ? (
                <Box
                  sx={{
                    height: '300px',
                    mt: 2,
                  }}
                >
                  <DataView
                    dataDesc={userData}
                    dataCallback={savedUser}
                    formModeOnly
                    embed
                  />
                </Box>
              ) : null}
              {activeStep === 1 ? (
                <Box
                  sx={{
                    height: '300px',
                    mt: 2,
                  }}
                >
                  <DataView
                    dataDesc={accountData}
                    dataCallback={savedAccount}
                    formModeOnly
                    embed
                  />
                </Box>
              ) : null}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                {activeStep > 0 && (
                  <Button
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant="outlined"
                  >
                    Back
                  </Button>
                )}
              </Box>
            </>
          )}
        </div>
      </Box>
    </>
  );
};

export default Onboarding;
