import { useOutletContext } from 'react-router-dom';

import TransactionsListView from './TransactionsListView';

const TransactionsPage = () => {
  const { openSnackbar } = useOutletContext();
  return <TransactionsListView openSnackbar={openSnackbar} />;
};

export default TransactionsPage;
