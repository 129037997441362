import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEqual, isNull, set } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { WidgetGroup } from 'common/constants';

import { useRoleStore } from '@/store';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import MultiSelect from '@/components/molecules/MultiSelect';
import API from '@/services/API';
import UILabels from '@/services/UILabels';
import { useAccountStore } from '@/store';
import WidgetCreator from './WidgetCreator';
import BoxWidget from './BoxWidget';
import ChartWidget from './ChartWidget';
import TableWidget from './TableWidget';
import WidgetWrapper from './WidgetWrapper';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { Roles } from '@/types';
import WidgetSelector from './WidgetSelector';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

const dateRanges = {
  thisWeek: () => ({
    startDate: dayjs().startOf('week').toDate(),
    endDate: dayjs().endOf('week').toDate(),
  }),
  lastWeek: () => ({
    startDate: dayjs().subtract(1, 'week').startOf('week').toDate(),
    endDate: dayjs().subtract(1, 'week').endOf('week').toDate(),
  }),
  thisMonth: () => ({
    startDate: dayjs().startOf('month').toDate(),
    endDate: dayjs().endOf('month').toDate(),
  }),
  lastMonth: () => ({
    startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
    endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
  }),
  thisQuarter: () => ({
    startDate: dayjs().startOf('quarter').toDate(),
    endDate: dayjs().endOf('quarter').toDate(),
  }),
  lastQuarter: () => ({
    startDate: dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
    endDate: dayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
  }),
  thisYear: () => ({
    startDate: dayjs().startOf('year').toDate(),
    endDate: dayjs().endOf('year').toDate(),
  }),
  lastYear: () => ({
    startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
    endDate: dayjs().subtract(1, 'year').endOf('year').toDate(),
  }),
  last7days: () => ({
    startDate: dayjs().subtract(7, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last30days: () => ({
    startDate: dayjs().subtract(30, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last60days: () => ({
    startDate: dayjs().subtract(60, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last90days: () => ({
    startDate: dayjs().subtract(90, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
};

const DrawerMode = {
  CREATE_WIDGET: 'CREATE_WIDGET',
  ADD_SHARED_WIDGET: 'ADD_SHARED_WIDGET',
};

const filters = {
  agentGroup: {
    label: 'Agent group',
    type: 'multi-select',
    field: 'agent_group',
    optionsKey: 'agentGroup',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  agent: {
    label: 'Agent',
    type: 'multi-select',
    field: 'agent',
    optionsKey: 'agent',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  policyStatus: {
    label: 'Policy status',
    type: 'multi-select',
    optionsKey: 'policyStatus',
    field: 'policy_status',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  productType: {
    label: 'Product type',
    type: 'multi-select',
    optionsKey: 'productType',
    field: 'product_type',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  compensationType: {
    label: 'Compensation type',
    type: 'multi-select',
    optionsKey: 'compensationType',
    field: 'compensation_type',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  dateRange: {
    label: 'Date range',
    type: 'select',
    options: [
      { label: 'This week', value: 'thisWeek' },
      { label: 'Last week', value: 'lastWeek' },
      { label: 'This month', value: 'thisMonth' },
      { label: 'Last month', value: 'lastMonth' },
      { label: 'This quarter', value: 'thisQuarter' },
      { label: 'Last quarter', value: 'lastQuarter' },
      { label: 'This year', value: 'thisYear' },
      { label: 'Last year', value: 'lastYear' },
      { label: 'Last 7 days', value: 'last7days' },
      { label: 'Last 30 days', value: 'last30days' },
      { label: 'Last 60 days', value: 'last60days' },
      { label: 'Last 90 days', value: 'last90days' },
      { label: 'Custom', value: 'custom' },
    ],
    initialValue: 'last60days',
    sx: { width: 140 },
  },
  startDate: {
    label: 'Start date',
    type: 'date',
    field: 'effectiveDate',
    initialValue: dayjs().subtract(60, 'days').toDate(),
    filterFunc: (val, filterVal) => val >= filterVal,
  },
  endDate: {
    label: 'End date',
    type: 'date',
    field: 'effectiveDate',
    initialValue: dayjs().toDate(),
    filterFunc: (val, filterVal) => val <= filterVal,
  },
  timePeriod: {
    label: 'Time period',
    type: 'select',
    options: [
      { label: 'Day', value: 'day' },
      { label: 'Week', value: 'week' },
      { label: 'Month', value: 'month' },
    ],
    initialValue: 'Month',
    sx: { width: 100 },
  },
};

const AgentsInsights = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const { data: widgetSetting } = API.getBasicQuery(
    `insights/accountWidgetsSettings`
  );
  const sharedWidgets = widgetSetting?.sharedWidgets;
  const adminWidgetsSettings = widgetSetting?.adminWidgetsSettings;
  const [sideDrawerMode, setSideDrawerMode] = useState(
    DrawerMode.CREATE_WIDGET
  );
  const producerWidgetsSettings = widgetSetting?.producerWidgetsSettings;
  const {
    role: [role],
  } = useContext(UIStateContext);
  const isFinatryAdmin = role === 'admin';
  const { value: enableCustomWidget } = useGate('customizedwidget');
  const { userRole } = useRoleStore();
  const isAccountAdmin = userRole === Roles.ACCOUNT_ADMIN;
  const [widgetOnEdit, setWidgetOnEdit] = useState(null);
  const viewSettings = accountSettings?.pages_settings?.insights;
  const createWidgetPoster = API.getMutation('insights/preview', 'POST');
  const saveWidgetPoster = API.getMutation('insights', 'POST');
  const deleter = API.getMutation('insights', 'DELETE');
  const updateWidgetPoster = API.getMutation('insights', 'PUT');
  let pageLabel = 'Agents insights';
  if (viewSettings?.page_label) {
    pageLabel = viewSettings?.page_label;
  }

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }
  const [widgetModel, setWidgetModel] = useState({
    name: '',
    spec: null,
    accessRoles: null,
  });
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [widgetIdTobeDeleted, setWidgetIdTobeDeleted] = useState(null);
  const [values, setValues] = useState({
    agentGroup: [],
    agent: [],
    compensationType: [],
    policyStatus: [],
    productType: [],
  });
  const [filteredValues, setFilteredValues] = useState({
    agentGroup: [],
    agent: [],
    compensationType: [],
    dateRange: 'last60days',
    endDate: dateRanges.last60days().endDate,
    policyStatus: [],
    productType: [],
    startDate: dateRanges.last60days().startDate,
    timePeriod: 'day',
  });

  // load the filtered values from the url query string
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const filteredValues = {};
    Object.keys(filters).forEach((key) => {
      if (params.has(key)) {
        if (filters[key].type === 'multi-select') {
          filteredValues[key] = params.getAll(key);
        } else {
          filteredValues[key] = params.get(key);
        }
      }
    });
    setFilteredValues((prev) => ({ ...prev, ...filteredValues }));
  }, []);

  const updateFilteredValues = (key, value) => {
    setFilteredValues({ ...filteredValues, [key]: value });
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, '', url);
  };

  const { data: settingsData } = API.getBasicQuery('accounts');

  const { selectedAccount } = useAccountStore();
  const mode = selectedAccount?.accountMode;
  const labels = new UILabels(mode);

  let query = `start_date=${new Date(filteredValues.startDate)
    .toISOString()
    .substring(0, 10)}&end_date=${new Date(filteredValues.endDate)
    .toISOString()
    .substring(0, 10)}`;
  if (values?.agentGroup?.length !== filteredValues?.agentGroup?.length) {
    filteredValues.agentGroup.forEach((val) => {
      query = query.concat(`&agent_group=${val}`);
    });
  }
  if (
    values?.agent?.length !== filteredValues?.agent?.length &&
    filteredValues.agent
  ) {
    filteredValues.agent.forEach((val) => {
      query = query.concat(`&agent=${val}`);
    });
  }
  if (
    values?.compensationType?.length !==
      filteredValues?.compensationType?.length ||
    values?.compensationType?.length !== 0
  ) {
    filteredValues.compensationType.forEach((val) => {
      query = query.concat(`&compensation_type=${val}`);
    });
  }
  if (values?.productType?.length !== filteredValues?.productType?.length) {
    filteredValues.productType.forEach((val) => {
      query = query.concat(`&product_type=${val}`);
    });
  }
  if (values?.policyStatus?.length !== filteredValues?.policyStatus?.length) {
    filteredValues.policyStatus.forEach((val) => {
      query = query.concat(`&policy_status=${val}`);
    });
  }
  query = query.concat(`&time_period=${filteredValues.timePeriod}`);

  const createWidget = async (data) => {
    let agent_group = [];
    filteredValues.agentGroup.forEach((val) => {
      agent_group.push(val);
    });
    let compensation_type = [];
    filteredValues.compensationType.forEach((val) => {
      compensation_type.push(val);
    });
    let product_type = [];
    filteredValues.productType.forEach((val) => {
      product_type.push(val);
    });
    let policy_status = [];
    filteredValues.policyStatus.forEach((val) => {
      policy_status.push(val);
    });
    const response = await createWidgetPoster.mutateAsync({
      // Build the below data object here using the query data
      agent: filteredValues.agent,
      agent_group: agent_group.length ? agent_group : undefined,
      compensation_type: compensation_type.length
        ? compensation_type
        : undefined,
      end_date: new Date(filteredValues.endDate).toISOString().substring(0, 10),
      policy_status: policy_status.length ? policy_status : undefined,
      product_type: product_type.length ? product_type : undefined,
      start_date: new Date(filteredValues.startDate)
        .toISOString()
        .substring(0, 10),
      time_period: filteredValues.timePeriod,
      widgetDefinition: data,
    });
    return response;
  };

  const saveWidget = async () => {
    if (isNull(widgetModel)) {
      return;
    }
    let response;
    if (widgetOnEdit) {
      response = await updateWidgetPoster.mutateAsync({
        id: widgetOnEdit.id,
        name: widgetModel.name,
        spec: widgetModel.spec,
        accessRoles: widgetModel.accessRoles,
      });
    } else {
      response = await saveWidgetPoster.mutateAsync({
        name: widgetModel.name,
        spec: widgetModel.spec,
        accessRoles: widgetModel.accessRoles,
      });
    }
    switch (response.widgetGroup) {
      case WidgetGroup.BOX:
        setBoxes((prev) => [...prev, response]);
        break;
      case WidgetGroup.CHART:
        setCharts((prev) => [...prev, response]);
        break;
      case WidgetGroup.TABLE:
        setTables((prev) => [...prev, response]);
        break;
    }
  };
  const [insightsData, setInsightsData] = useState({});

  const {
    isLoading: isLoadingInsights,
    data: result,
    isFetched: isFetchedInsights,
  } = API.getBasicQuery('insights', query);

  useEffect(() => {
    if (isFetchedInsights) {
      setInsightsData(result);
    }
  }, [result]);

  useEffect(() => {
    if (
      (isFetchedInsights &&
        !isEqual(values.agentGroup, insightsData?.agentGroup)) ||
      !isEqual(values.agent, insightsData?.filterValues?.agent) ||
      !isEqual(
        values.compensationType,
        insightsData?.filterValues?.compensationType
      ) ||
      !isEqual(values.policyStatus, insightsData?.filterValues?.policyStatus) ||
      !isEqual(values.productType, insightsData?.filterValues?.productType)
    ) {
      setValues((prev) => ({
        ...prev,
        agentGroup: insightsData?.filterValues?.agentGroup,
        agent: insightsData?.filterValues?.agent,
        compensationType: insightsData?.filterValues?.compensationType,
        policyStatus: insightsData?.filterValues?.policyStatus,
        productType: insightsData?.filterValues?.productType,
      }));
      if (
        filteredValues.agent === undefined ||
        filteredValues.agent.length === 0
      ) {
        setFilteredValues((prev) => ({
          ...prev,
          agent: insightsData?.filterValues?.agent?.map((e) => e.id) ?? '',
        }));
      }

      if (
        filteredValues.agentGroup === undefined ||
        filteredValues.agentGroup.length === 0
      ) {
        setFilteredValues((prev) => ({
          ...prev,
          agentGroup:
            insightsData?.filterValues?.agentGroup?.map((e) => e.id) ?? '',
        }));
      }
    }
  }, [isFetchedInsights, insightsData]);

  useEffect(() => {
    if (filteredValues.dateRange !== 'custom') {
      setFilteredValues({
        ...filteredValues,
        ...dateRanges[filteredValues.dateRange](),
      });
    }
  }, [filteredValues.dateRange]);

  const isLoading = isLoadingInsights;

  const [boxes, setBoxes] = useState([]);
  const [charts, setCharts] = useState([]);
  const [tables, setTables] = useState([]);

  useEffect(() => {
    if (isFetchedInsights) {
      setBoxes(
        insightsData?.boxes?.filter((x) => x.spec?.category == 'agents') ?? []
      );
      setCharts(
        insightsData?.charts?.filter((x) => x.spec?.category == 'agents') ?? []
      );
      setTables(
        insightsData?.tables?.filter((x) => x.spec?.category == 'agents') ?? []
      );
    }
  }, [isFetchedInsights, insightsData]);

  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(
      ([k, v]) =>
        !['startDate', 'endDate'].includes(k) ||
        filteredValues.dateRange === 'custom'
    )
  );

  const [sideDrawerOpen, setsideDrawerOpen] = useState(false);

  const openAddWidgetDialog = (mode) => {
    setSideDrawerMode(mode);
    setWidgetOnEdit(null);
    setsideDrawerOpen(true);
  };

  const editWidget = (id) => {
    const widget = [...boxes, ...charts, ...tables].find((e) => e.id === id);
    setWidgetOnEdit({ ...widget.spec, id: widget.id, spec: widget.spec });
    setSideDrawerMode(DrawerMode.CREATE_WIDGET);
    setsideDrawerOpen(true);
  };

  const renderDrawerContent = (mode) => {
    switch (mode) {
      case DrawerMode.CREATE_WIDGET:
        return (
          <WidgetCreator
            widgetOnEdit={widgetOnEdit}
            createWidget={createWidget}
            setWidgetModel={setWidgetModel}
          />
        );
      case DrawerMode.ADD_SHARED_WIDGET:
        return (
          <WidgetSelector
            sharedWidgets={sharedWidgets}
            adminSelectedWidget={adminWidgetsSettings}
            producerSelectedWidget={producerWidgetsSettings}
          />
        );
      default:
        return null;
    }
  };

  const deleteWidget = () => {
    deleter.mutateAsync({ id: widgetIdTobeDeleted });
    setBoxes((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
    setCharts((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
    setTables((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
    setDeleteConfirmDialogOpen(false);
  };

  const closeAddWidgetDialog = () => {
    setsideDrawerOpen(false);
  };

  return (
    <Box p={2} sx={{ width: '100%', overflowY: 'scroll' }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">
          {settingsData?.company
            ? `${settingsData?.company} ${pageLabel?.toLowerCase()}`
            : pageLabel}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            width: '100vw',
            padding: '10px',
            overflowX: 'scroll',
          }}
        >
          {Object.entries(filteredFilters)?.map(([k, v]) => {
            if (v.type === 'date') {
              return (
                <BasicDatePicker
                  label={v.label}
                  key={v.label}
                  value={filteredValues[k]}
                  setValue={
                    (e) => updateFilteredValues(k, e)
                    // setFilteredValues({ ...filteredValues, [k]: e })
                  }
                  sx={{ ml: 1, width: 140 }}
                />
              );
            } else if (v.type === 'multi-select') {
              if (
                k === 'agent' &&
                settingsData?.dashboard_filter_by_agent !== 'True'
              )
                return null;
              if (k === 'agent' || k === 'agentGroup')
                return (
                  <MultiSelect
                    label={v.label}
                    values={values?.[v.optionsKey] ?? []}
                    valuer={(val) => val.id}
                    selectedValues={
                      filteredValues[k]?.length
                        ? filteredValues[k]
                        : values?.[v.optionsKey]?.id
                    }
                    setSelectedValues={(values) => {
                      console.log(values);
                      setFilteredValues({
                        ...filteredValues,
                        [k]: values,
                      });
                    }}
                    sx={{ width: 135, ml: 1 }}
                    key={v.label}
                    formatter={(val) => val.name}
                  />
                );
              return (
                <MultiSelect
                  label={v.label}
                  values={values?.[v.optionsKey] ?? []}
                  selectedValues={
                    filteredValues[k]?.length
                      ? filteredValues[k]
                      : values?.[v.optionsKey]
                  }
                  setSelectedValues={(values) =>
                    setFilteredValues({
                      ...filteredValues,
                      [k]: values,
                    })
                  }
                  sx={{ width: 135, ml: 1 }}
                  key={v.label}
                />
              );
            } else if (v.type === 'select') {
              return (
                <div key={v.label}>
                  <FormControl
                    sx={{ ml: 1, width: 135, ...v.sx }}
                    key={v.label}
                  >
                    <InputLabel>{v.label}</InputLabel>
                    <Select
                      value={filteredValues[k]}
                      label={v.label}
                      onChange={(e) =>
                        // setFilteredValues({
                        //   ...filteredValues,
                        //   [k]: e.target.value,
                        // })
                        updateFilteredValues(k, e.target.value)
                      }
                      sx={{
                        '.MuiSelect-select': {
                          py: 0.75,
                          px: 1.5,
                        },
                      }}
                    >
                      {(v.options ?? []).map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              );
            }
            return null;
          })}
          <Dialog
            style={{ zIndex: 9999 }}
            open={deleteConfirmDialogOpen}
            onClose={() => setDeleteConfirmDialogOpen(false)}
          >
            <DialogTitle>{'Do you want to delete this widget?'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {'This action cannot be undone.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteConfirmDialogOpen(false)}>
                Disagree
              </Button>
              <Button onClick={deleteWidget} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          {(enableCustomWidget || isFinatryAdmin) && (
            <Button
              variant="contained"
              style={{ marginLeft: '10px', maxHeight: '45px' }}
              onClick={() => openAddWidgetDialog(DrawerMode.CREATE_WIDGET)}
            >
              Create
            </Button>
          )}
          {(isAccountAdmin || isFinatryAdmin) && (
            <Button
              variant="contained"
              style={{ marginLeft: '10px', maxHeight: '45px' }}
              onClick={() => openAddWidgetDialog(DrawerMode.ADD_SHARED_WIDGET)}
            >
              Add
            </Button>
          )}

          <Drawer
            anchor="right"
            open={sideDrawerOpen}
            onClose={closeAddWidgetDialog}
            sx={{
              '& .MuiDrawer-paper': {
                marginTop: '64px',
                zIndex: 9999,
                maxWidth: '500px',
              },
            }}
          >
            {/* action bar to close the drawer and save the widget */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              <Box>
                {sideDrawerMode === DrawerMode.CREATE_WIDGET && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      saveWidget();
                      closeAddWidgetDialog();
                    }}
                  >
                    Save
                  </Button>
                )}
                <Button
                  variant="outlined"
                  style={{ marginLeft: '10px' }}
                  onClick={() => closeAddWidgetDialog()}
                >
                  Close
                </Button>
              </Box>
            </Box>
            {renderDrawerContent(sideDrawerMode)}
          </Drawer>
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        {isLoading ? (
          <LoadingCircle isLoading={isLoading} />
        ) : (
          <>
            <Grid container spacing={2}>
              {boxes?.map((e) => (
                <Grid item xs={6} md={3} key={e.id}>
                  <WidgetWrapper
                    id={e.id}
                    displayName={
                      labels.getLabel('dashboard', e.labelId) ?? e.displayName
                    }
                    sharedWidget={e.spec?.shared}
                    enableCustomWidget={enableCustomWidget}
                    isCustomWidget={e.spec}
                    onDelete={() => {
                      setDeleteConfirmDialogOpen(true);
                      setWidgetIdTobeDeleted(e.id);
                    }}
                    onEdit={() => editWidget(e.id)}
                  >
                    <BoxWidget
                      id={e.id}
                      displayName={
                        labels.getLabel('dashboard', e.labelId) ?? e.displayName
                      }
                      value={e.value}
                    />
                  </WidgetWrapper>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {charts?.map((e) => (
                <Grid item xs={12} md={6} key={e.id}>
                  <WidgetWrapper
                    id={e.id}
                    sharedWidget={e.spec?.shared}
                    enableCustomWidget={enableCustomWidget}
                    isCustomWidget={e.spec}
                    displayName={
                      labels.getLabel('dashboard', e.labelId) ?? e.displayName
                    }
                    onDelete={() => {
                      setDeleteConfirmDialogOpen(true);
                      setWidgetIdTobeDeleted(e.id);
                    }}
                    onEdit={() => editWidget(e.id)}
                    data={e.value}
                    type={e.type}
                  >
                    <ChartWidget
                      id={e.id}
                      displayName={
                        labels.getLabel('dashboard', e.labelId) ?? e.displayName
                      }
                      data={e.value}
                    />
                  </WidgetWrapper>
                </Grid>
              ))}
              {tables?.map((e) => (
                <Grid item xs={12} md={6} key={e.id}>
                  <WidgetWrapper
                    id={e.id}
                    sharedWidget={e.spec?.shared}
                    enableCustomWidget={enableCustomWidget}
                    isCustomWidget={e.spec}
                    displayName={
                      labels.getLabel('dashboard', e.labelId) ?? e.displayName
                    }
                    onDelete={() => {
                      setDeleteConfirmDialogOpen(true);
                      setWidgetIdTobeDeleted(e.id);
                    }}
                    onEdit={() => editWidget(e.id)}
                  >
                    <TableWidget
                      data={e.data}
                      formatters={e.formatters}
                      id={e.id}
                      displayName={
                        labels.getLabel('dashboard', e.labelId) ?? e.displayName
                      }
                    />
                  </WidgetWrapper>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AgentsInsights;
