import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from 'nanoid';
import { Launch } from '@mui/icons-material';
import { ReportReviewStatuses } from 'common/globalTypes';

import API from '@/services/API';
import ReportData from '../molecules/ReportData';

interface HistoryReport {
  str_id: string;
  created_at: string;
  rep_created_byTousers: { email: string };
  name: string;
  notes: string;
  access: string;
  users_white_list: JSX.Element;
  status: JSX.Element;
  rep_reviewed_byTousers: { email: string };
  snapshot_data: JSX.Element;
}

type DataRow = HistoryReport[];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2,
};
const viewDataStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 2,
};

const ReportHistory = ({ open, setOpen, reportId }): JSX.Element => {
  const { data: reportHistoryData } = API.getBasicQuery(
    // @ts-ignore
    `saved_reports/history?report_id=${reportId}`,
    '',
    open
  );

  const [showData, setShowData] = useState<DataRow>([]);
  const [openData, setOpenData] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const openDataModal = (snapshotData) => {
    setSelectedData(snapshotData);
    setOpenData(true);
  };

  useEffect(() => {
    if (reportHistoryData && reportHistoryData.statusText === 'ok') {
      const _showData = reportHistoryData.data.map((row) => {
        let color;
        if (row.status === ReportReviewStatuses.Approved) {
          color = '#a5d6a7';
        } else if (row.status === ReportReviewStatuses.InReview) {
          color = '#fff59d';
        } else if (row.status === ReportReviewStatuses.RequestUpdate) {
          color = '#ffcc80';
        } else if (row.status === ReportReviewStatuses.Rejected) {
          color = '#f52525';
        } else if (row.status === ReportReviewStatuses.NonPayable) {
          color = '#f52525';
        } else {
          color = '#ddd';
        }

        if (row.users_white_list.length > 0) {
          row.users_white_list = row.users_white_list.map((user) => (
            <p key={`${user?.email}-${nanoid()}`}>{user?.email}</p>
          ));
        } else {
          row.users_white_list = [<p key="no-users">No users</p>];
        }

        if (row.snapshot_data) {
          const data = row.snapshot_data;
          row.snapshot_data = (
            <Button onClick={() => openDataModal(data)} endIcon={<Launch />}>
              Check Data
            </Button>
          );
        }

        return {
          ...row,
          status: (
            <Chip
              sx={{ backgroundColor: color, my: 0.25, mr: 0.5 }}
              label={row.status}
            />
          ),
          users_white_list: <div>{row.users_white_list}</div>,
        };
      });
      setShowData(_showData);
    }
  }, [reportHistoryData]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseData = () => {
    setOpenData(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Report history
          </Typography>
          <Box
            sx={{
              overflowX: 'auto',
              overflowY: 'auto',
              width: '100%',
              height: '70vh',
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'white',
            }}
          >
            {!showData && <p>loading...</p>}
            {showData.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Performed by</TableCell>
                      <TableCell align="center">Report name</TableCell>
                      <TableCell align="center">Report notes</TableCell>
                      <TableCell align="center">Access</TableCell>
                      <TableCell align="center">Users with access</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Approver</TableCell>
                      <TableCell align="center">Check report data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {showData.map((row) => (
                      <TableRow
                        key={row.str_id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {new Date(row.created_at).toLocaleString()}
                        </TableCell>
                        <TableCell align="center">
                          {row.rep_created_byTousers?.email}
                        </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.notes}</TableCell>
                        <TableCell align="center">{row.access}</TableCell>
                        <TableCell align="center">
                          {row.users_white_list}
                        </TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell align="center">
                          {row.rep_reviewed_byTousers?.email}
                        </TableCell>
                        <TableCell align="center">
                          {row.snapshot_data}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>No historical data found</p>
            )}
          </Box>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={(e) => handleClose()} sx={{ mr: 1, mt: 2 }}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openData} onClose={handleCloseData}>
        <Box sx={viewDataStyle}>
          <ReportData
            isLoading={false}
            selectedSnapshotReport={{ snapshot_data: selectedData }}
            filteredData={selectedData?.data?.data}
            setOpenHistory={setOpen}
            showActions={false}
            isHistoryView={true}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={(e) => handleCloseData()} sx={{ mr: 1, mt: 2 }}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ReportHistory;
