import { Navigate } from 'react-router-dom';

import DataView from '@/components/DataView';
import API from '@/services/API';
import useSyncedFields from '@/contexts/useSyncedFields';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';

const View = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.products;

  const viewOnly = viewSettings?.read_only ?? false;

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const { data: queryChipData } = API.getBasicQuery('companies');

  const dataDesc = {
    label: 'Products',
    table: 'companies/products',
    editable: true,
    copyable: true,
    bulkAdd: true,
    fields: [
      {
        id: 'company_id',
        label: 'Company',
        type: 'dynamic-select',
        table: 'companies',
        field: 'id',
        formatter: (val, collectionVals = []) =>
          (Array.isArray(collectionVals) &&
            collectionVals?.find((company) => company.id === val)
              ?.company_name) ||
          '',
        optionFormatter: (option) => option.company_name,
        optionValuer: (option) => option.id,
        required: true,
        bulkAddSelect: true,
      },
      {
        id: 'product_type',
        label: 'Product type',
        type: 'select',
        options: [
          'Life',
          'IUL',
          'UL',
          'Term',
          'Annuity',
          'Indexed Annuity',
          'Fixed Annuity',
          'Health',
          'Dental',
          'Vision',
          'Disability',
          'Long Term Care',
          'Other',
        ],
      },
      {
        id: 'product_name',
        label: 'Product name',
        required: true,
      },
      {
        id: 'notes',
        label: 'Notes',
      },
    ],
    queryChips: {},
  };
  const { syncedFields, isSyncedField } = useSyncedFields('company_products');
  dataDesc.fields.forEach((field) => {
    if (syncedFields?.includes(field.id)) {
      field.readOnly = (data) => {
        return isSyncedField(data, field.id, data.config);
      };
      field.endAdornment = (data, field, setNewData) => (
        <SyncEndAdornment
          syncedFields={syncedFields}
          syncId={data?.sync_id}
          fieldId={field?.id}
          data={data}
          onChange={(newOverrideFields) => {
            setNewData({
              ...data,
              config: {
                ...(data.config || {}),
                overrideFields: newOverrideFields,
              },
            });
          }}
        />
      );
    }
  });

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  if (Array.isArray(queryChipData) && queryChipData.length > 1) {
    const queryChips = [
      {
        id: '0',
        company_name: 'All',
        query: {},
      },
      ...(queryChipData ?? []),
    ];

    const FK = 'company_id';

    queryChips.forEach((item) => {
      dataDesc.queryChips[item.id] = {
        id: String(item.id),
        label: item.company_name,
        query: { [FK]: item.id },
      };
    });
  }

  return (
    <DataView
      dataDesc={dataDesc}
      viewOnly={viewOnly}
      readOnly={viewOnly}
      enablePagination
    />
  );
};

export default View;
