import { Clear, Search } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const SearchBox = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get('q'));
  const [queryDebounced] = useDebounce(query ?? '', 500);
  const queryRef = useRef<HTMLInputElement>(null);

  const updateSearchParams = (kvMap: object) =>
    setSearchParams((prev) => {
      Object.entries(kvMap).forEach(([k, v]) => {
        if ([undefined, null, ''].includes(v)) {
          prev.delete(k);
        } else {
          prev.set(k, v);
        }
      });
      return prev;
    });

  useEffect(() => {
    updateSearchParams({ q: queryDebounced });
  }, [queryDebounced]);

  return (
    <TextField
      name="search-query"
      variant="outlined"
      value={query ?? ''}
      inputRef={queryRef}
      onChange={(e) => setQuery(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          queryRef?.current?.blur();
        }
      }}
      autoFocus
      InputProps={{
        startAdornment: <Search sx={{ opacity: 0.5 }} />,
        endAdornment: (
          <IconButton
            onClick={() => {
              setQuery('');
            }}
          >
            <Clear sx={{ opacity: 0.5 }} />
          </IconButton>
        ),
        style: { height: '34px' },
      }}
    />
  );
};

export default SearchBox;
