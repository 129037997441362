import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import { isValidElement, useState } from 'react';
import { Link } from 'react-router-dom';

type ExpandableDataProps = {
  data: any;
  formatter?: (any) => any;
  header: any;
  link?: string;
};

const ExpandableData: React.FC<ExpandableDataProps> = ({
  data,
  header,
  formatter = (val) => val,
  link,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Button
        endIcon={expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          color: 'text.primary',
          fontWeight: 500,
        }}
      >
        {`${data.length} ${header.label.toLowerCase()}`}
      </Button>
      {expanded && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.25, mt: 0.5 }}>
          {Array.isArray(data) &&
            data?.map((datum) => {
              const formattedItem = formatter(datum);
              return isValidElement(formattedItem) ? (
                formattedItem
              ) : link ? (
                <Chip
                  key={datum.id}
                  label={formatter(datum)}
                  component={Link}
                  to={`${link}?id=${datum.str_id}`}
                  clickable
                />
              ) : (
                <Chip key={datum.id} label={formatter(datum)} />
              );
            })}
        </Box>
      )}
    </>
  );
};

export default ExpandableData;
