import { useEffect, useState, ReactNode } from 'react';
import {
  Autocomplete,
  TextField,
  Checkbox,
  ListItemText,
  Box,
  Button,
} from '@mui/material';

interface SelectItem {
  [key: string]: any;
}

interface FilterSelectItem {
  value: string;
  label: string;
  [key: string]: any;
  data: SelectItem;
}

interface FilterSelectProps {
  options: SelectItem[];
  getOptionLabel?: (option: any, options?: any[]) => string;
  onChange: any;
  label?: string;
  multiple?: boolean;
  labelKey?: string;
  valueKey?: string;
  renderOptionItem?: (option: SelectItem) => ReactNode;
  renderCustomOption?: () => ReactNode;
  enablePagination?: boolean;
  itemsPerPage?: number;
  [key: string]: any;
}

const FilterSelect = ({
  options,
  getOptionLabel,
  onChange,
  label,
  multiple,
  renderOptionItem,
  renderCustomOption,
  enablePagination = false,
  itemsPerPage = 10,
  labelKey = 'label',
  valueKey = 'value',
  placeholder = 'Select options',
  loading = false,
  ...props
}: FilterSelectProps) => {
  const [selectOptions, setSelectOptions] = useState<FilterSelectItem[]>([]);
  const [displayedOptions, setDisplayedOptions] = useState<FilterSelectItem[]>(
    []
  );
  const [page, setPage] = useState(1);

  useEffect(() => {
    const list = options.map((option) => {
      const value = option[valueKey];
      const label = option[labelKey];
      return {
        value,
        label,
        data: option,
      };
    });
    setSelectOptions(list);
    setDisplayedOptions(enablePagination ? list.slice(0, itemsPerPage) : list);
  }, [options, enablePagination, itemsPerPage]);

  const handleShowMore = () => {
    const nextPage = page + 1;
    const startIndex = nextPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDisplayedOptions((prevOptions) => [
      ...prevOptions,
      ...selectOptions.slice(startIndex, endIndex),
    ]);
    setPage(nextPage);
  };

  const filterOptions = (options: FilterSelectItem[], state: any) => {
    const filtered = options.filter((option) =>
      getOptionLabel
        ? getOptionLabel(option.data, options)
            .toLowerCase()
            .includes(state.inputValue.toLowerCase())
        : option.label.toLowerCase().includes(state.inputValue.toLowerCase())
    );
    return enablePagination ? filtered.slice(0, page * itemsPerPage) : filtered;
  };

  return (
    <Autocomplete
      multiple={multiple}
      filterSelectedOptions={multiple}
      options={selectOptions}
      filterOptions={filterOptions}
      onChange={(event, value) => onChange(value as any)}
      getOptionLabel={(option) =>
        typeof getOptionLabel === 'function'
          ? getOptionLabel(option, options)
          : ''
      }
      loading={loading}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.value}>
            {multiple && <Checkbox checked={selected} />}
            <ListItemText
              key={option.value}
              primary={
                renderOptionItem
                  ? renderOptionItem(option.data)
                  : getOptionLabel
                    ? getOptionLabel(option.data, selectOptions)
                    : option.label
              }
            />
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      ListboxComponent={(listboxProps) => (
        <Box {...listboxProps}>
          {renderCustomOption && renderCustomOption()}
          {listboxProps.children}
          {enablePagination &&
            displayedOptions.length < selectOptions.length && (
              <Box display="flex" justifyContent="center" p={2}>
                <Button onClick={handleShowMore}>Show More</Button>
              </Box>
            )}
        </Box>
      )}
      {...props}
    />
  );
};

export default FilterSelect;
