import { create } from 'zustand';

type Account = {
  accountId: string | null;
  accountName: string | null;
  accountMode: string | null;
  compGridsEnabled: boolean | null;
  accountingTransactionsEnabled: boolean | null;
  whiteLabelMode: boolean | null;
  logoUrl: string | null;
  uid: string | null;
};
interface AccountStore {
  selectedAccount: Account | null;
  setSelectedAccount: (account: Account | null) => void;
}

const useAccountStore = create<AccountStore>((set) => ({
  selectedAccount: JSON.parse(localStorage.getItem('selectedAccount') ?? '{}'),
  setSelectedAccount: (account) => set({ selectedAccount: account }),
}));

export default useAccountStore;
