import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';

import API from '@/services/API';

const PlanSettings = () => {
  const [loadingPortal, setLoadingPortal] = useState(false);
  const { data } = API.getBasicQuery('plans');
  const stripeSession = API.getMutation('stripe/create_session', 'POST');

  const getStripePortal = async () => {
    const returnUrl = 'https://app.fintary.com/settings/plan';
    setLoadingPortal(true);

    if (data && data.stripe_id) {
      const { stripe_id: customerId } = data;
      const response = await stripeSession.mutateAsync({
        customerId,
        returnUrl,
      });

      if (response.message === 'OK' && response.payload?.url) {
        window.location.href = response.payload.url;
      } else {
        console.error('Could not retrieve payment link.');
      }
    } else {
      const paymentLink = 'https://buy.stripe.com/dR63dk2hR5AMc8M9AA';
      window.open(paymentLink, '_blank');
    }
    setLoadingPortal(false);
  };

  return (
    <Box sx={{ mx: 2 }}>
      {data?.plan_name ? (
        <Box>
          <Typography variant="h5">Plan: {data.plan_name}</Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={getStripePortal}
              disabled={loadingPortal}
            >
              Manage Subscription
              {loadingPortal && <CircularProgress size={20} sx={{ ml: 1 }} />}
            </Button>
          </Box>
        </Box>
      ) : (
        <Button
          onClick={getStripePortal}
          disabled={loadingPortal}
          variant="contained"
        >
          Get Fintary Premium
        </Button>
      )}
    </Box>
  );
};

export default PlanSettings;
