import { Box, BoxProps, styled } from '@mui/material';

import ContactCard from '@/components/contacts/ContactGroupsView/ContactCard';

interface LineBoxProps extends BoxProps {
  'data-hasparents'?: boolean;
  'data-haschildren'?: boolean;
}

const LineBox = styled(Box)<LineBoxProps>(
  ({
    theme,
    'data-hasparents': hasParents,
    'data-haschildren': hasChildren,
  }) => ({
    position: 'relative',
    border:
      hasParents || hasChildren ? `1px solid ${theme.palette.divider}` : 'none',
    padding: '15px 15px 15px 15px',
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'center',
    // TODO: Tweak the line styles to show up as expected
    // '&::before': hasParents
    //   ? {
    //       content: '""',
    //       position: 'absolute',
    //       top: '0',
    //       left: '0',
    //       right: '0',
    //       height: '1px',
    //       borderTop: `1px solid ${theme.palette.divider}`,
    //       transform: 'translateY(-50%)',
    //     }
    //   : {},
    // '&::after': hasChildren
    //   ? {
    //       content: '""',
    //       position: 'absolute',
    //       bottom: '0',
    //       left: '0',
    //       right: '0',
    //       height: '1px',
    //       borderBottom: `1px solid ${theme.palette.divider}`,
    //       transform: 'translateY(50%)',
    //     }
    //   : {},
  })
);

const HierarchyDiagram = ({ contact, handleItemClick }) => {
  const { id, child_relationships } = contact;
  let { parent_relationships } = contact;

  if (
    parent_relationships &&
    !parent_relationships.some((rel) => rel.parent !== null)
  ) {
    parent_relationships = undefined;
  }

  return (
    <Box
      overflow="auto"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="85vh"
      width="100%"
      gap={1}
      mb={2}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
        overflow="auto"
        height="auto"
        gap={1}
        mb={2}
      >
        <LineBox data-hasparents={parent_relationships?.length > 0}>
          {parent_relationships?.map(
            (parentRel) =>
              parentRel.parent && (
                <Box key={parentRel.id}>
                  <ContactCard
                    contact={parentRel.parent}
                    onClick={handleItemClick}
                  />
                </Box>
              )
          )}
        </LineBox>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
        height="auto"
        gap={1}
        mb={2}
      >
        <ContactCard
          key={id}
          contact={contact}
          main={true}
          onClick={handleItemClick}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
        overflow="auto"
        height="auto"
        gap={1}
        mb={2}
      >
        <LineBox data-haschildren={child_relationships?.length > 0}>
          {child_relationships?.map((childRel) => {
            return (
              childRel.contact.status !== 'Inactive' && (
                <Box key={childRel.id}>
                  <ContactCard
                    contact={childRel.contact}
                    onClick={handleItemClick}
                  />
                </Box>
              )
            );
          })}
        </LineBox>
      </Box>
    </Box>
  );
};

export default HierarchyDiagram;
