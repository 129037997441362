import { Box, Grid, TextField } from '@mui/material';
import { useState } from 'react';

const GenericTool = ({
  inputLabel = 'Input',
  outputLabel = 'Output',
  outFn,
}) => {
  const [input, setInput] = useState('');

  return (
    <Box display="flex">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={inputLabel}
            multiline
            minRows={16}
            value={input}
            onChange={(event) => {
              setInput(event.target.value);
            }}
            sx={{ width: '100%' }}
            maxRows={32}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={outputLabel}
            multiline
            minRows={16}
            value={outFn(input)}
            sx={{ width: '100%' }}
            maxRows={32}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenericTool;
