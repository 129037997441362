import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from 'contexts/LoadingContext';

import Spreadsheet from '@/services/Spreadsheet';
import { getFileData } from '@/services/helpers';
import Storage from '@/services/Storage';
import FilePreview from '@/common/preview';
import { SpresdsheetProps, XLS_CSV_TYPES } from '@/common/preview/model';

const DocPreview = ({ path, previewWidth, previewFile, setPreviewFile }) => {
  const [spreadsheet, setSpreadsheet] = useState<SpresdsheetProps>();

  const { setLoadingConfig } = useContext(LoadingContext);

  useEffect(() => {
    // get the file based on rowdata
    const readFile = async () => {
      if (!path) return null;
      setLoadingConfig({
        loading: true,
      });
      const fileName = path.split('/').pop();
      const url = await Storage.getFileUrl(path);
      const file = await getFileData(url, fileName);
      setPreviewFile(file);
      // TODO need got the page render time and show the loading
      setLoadingConfig({ loading: false });
    };
    readFile();
  }, [path]);

  useEffect(() => {
    // excel, csv need to load the raw data
    const setExcelData = async () => {
      if (previewFile && XLS_CSV_TYPES.includes(previewFile.type)) {
        const res = await Spreadsheet.loadSpreadsheet(previewFile);
        setSpreadsheet(res as any);
      }
    };
    setExcelData();
  }, [previewFile]);

  return (
    <FilePreview
      previewFile={previewFile}
      previewWith={previewWidth}
      // @ts-ignore
      spreadsheet={spreadsheet}
      // @ts-ignore
      setSpreadsheet={setSpreadsheet}
    />
  );
};
export default DocPreview;
