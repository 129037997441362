import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { nanoid } from 'nanoid';
import { enumToSelectOptions } from 'common/utils';
import { TRANSACTION_STATUSES } from 'common/constants';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';

type ContactsTransactionsAddProps = {
  data: any;
  setter: any;
  field: any;
};

const defaultTransaction = {
  amount: '',
  status: 'draft',
  date: null,
  notes: '',
};

const defaultTransactionDetails = {
  amount: '',
  date: null,
  notes: '',
};

const ContactsTransactionsAdd: React.FC<ContactsTransactionsAddProps> = ({
  data,
  field,
  setter,
}) => {
  const contactTransactions = data?.accounting_transactions ?? [];

  return (
    <Box>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            px: 1,
            pt: 0.5,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {contactTransactions?.map((contactTransaction, index) => (
              <Box
                key={`${contactTransaction.id}-${index}`}
                sx={{
                  m: 0.5,
                  px: 1,
                  py: 0.5,
                  borderStyle: 'solid',
                  borderColor: 'silver',
                  borderWidth: 1,
                  borderRadius: 4,
                  display: 'inline-block',
                  width: 250,
                  backgroundColor: '#2196f30a',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  sx={{ ml: 0.5 }}
                >
                  <Typography variant="body2">Transaction</Typography>
                  <IconButton
                    onClick={() => {
                      setter({
                        ...data,
                        accounting_transactions: contactTransactions.filter(
                          (cl) => cl.id !== contactTransaction.id
                        ),
                      });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box>
                  <BasicDatePicker
                    label="Date"
                    value={contactTransaction.date}
                    setValue={(e) => {
                      setter({
                        ...data,
                        accounting_transactions:
                          data.accounting_transactions.map((item) =>
                            item.id === contactTransaction.id
                              ? { ...item, date: e }
                              : item
                          ),
                      });
                    }}
                    sx={{ my: 0.5, width: 230 }}
                  />
                  <TextField
                    label="Amount"
                    variant="outlined"
                    value={contactTransaction.amount ?? ''}
                    onChange={(e) => {
                      setter({
                        ...data,
                        accounting_transactions:
                          data.accounting_transactions.map((item) =>
                            item.id === contactTransaction.id
                              ? { ...item, amount: e.target.value }
                              : item
                          ),
                      });
                    }}
                    sx={{ my: 0.5, width: 230 }}
                  />
                  <FormControl fullWidth sx={{ my: 0.5, width: 230 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={contactTransaction.status ?? ''}
                      label="Status"
                      onChange={(e) => {
                        setter({
                          ...data,
                          accounting_transactions:
                            data.accounting_transactions.map((item) =>
                              item.id === contactTransaction.id
                                ? {
                                    ...item,
                                    status: e.target.value,
                                  }
                                : item
                            ),
                        });
                      }}
                    >
                      {enumToSelectOptions(TRANSACTION_STATUSES).map(
                        (option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label as string}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Notes"
                    variant="outlined"
                    value={contactTransaction.notes ?? ''}
                    onChange={(e) => {
                      setter({
                        ...data,
                        accounting_transactions:
                          data.accounting_transactions.map((item) =>
                            item.id === contactTransaction.id
                              ? { ...item, notes: e.target.value }
                              : item
                          ),
                      });
                    }}
                    sx={{ my: 0.5, width: 230 }}
                    multiline
                    rows={2}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="left"
                  position="relative"
                  sx={{ ml: 0.5 }}
                >
                  <Typography variant="body2">Details</Typography>
                  {contactTransaction?.accounting_transaction_details?.map(
                    (transactionDetail, index) => (
                      <Box
                        key={`${transactionDetail.id}-${index}`}
                        sx={{
                          m: 0.5,
                          px: 1,
                          py: 0.5,
                          borderStyle: 'solid',
                          borderColor: 'silver',
                          borderWidth: 1,
                          borderRadius: 4,
                          display: 'inline-block',
                          width: 220,
                          backgroundColor: '#2196f30a',
                        }}
                      >
                        <Box sx={{ mt: 0.5 }}>
                          <BasicDatePicker
                            label="Date"
                            value={transactionDetail.date}
                            setValue={(e) => {
                              setter({
                                ...data,
                                accounting_transactions:
                                  data.accounting_transactions.map((item) =>
                                    item.id === contactTransaction.id
                                      ? {
                                          ...item,
                                          accounting_transaction_details:
                                            item.accounting_transaction_details?.map(
                                              (detailItem) =>
                                                detailItem.id ===
                                                transactionDetail.id
                                                  ? { ...detailItem, date: e }
                                                  : detailItem
                                            ),
                                        }
                                      : item
                                  ),
                              });
                            }}
                            sx={{ my: 0.5, width: 200 }}
                          />
                          <TextField
                            label="Amount"
                            variant="outlined"
                            value={transactionDetail.amount ?? ''}
                            onChange={(e) => {
                              setter({
                                ...data,
                                accounting_transactions:
                                  data.accounting_transactions.map((item) =>
                                    item.id === contactTransaction.id
                                      ? {
                                          ...item,
                                          accounting_transaction_details:
                                            item.accounting_transaction_details?.map(
                                              (detailItem) =>
                                                detailItem.id ===
                                                transactionDetail.id
                                                  ? {
                                                      ...detailItem,
                                                      amount: e.target.value,
                                                    }
                                                  : detailItem
                                            ),
                                        }
                                      : item
                                  ),
                              });
                            }}
                            sx={{ my: 0.5, width: 200 }}
                          />
                          <TextField
                            label="Notes"
                            variant="outlined"
                            value={transactionDetail.notes ?? ''}
                            onChange={(e) => {
                              setter({
                                ...data,
                                accounting_transactions:
                                  data.accounting_transactions.map((item) =>
                                    item.id === contactTransaction.id
                                      ? {
                                          ...item,
                                          accounting_transaction_details:
                                            item.accounting_transaction_details?.map(
                                              (detailItem) =>
                                                detailItem.id ===
                                                transactionDetail.id
                                                  ? {
                                                      ...detailItem,
                                                      notes: e.target.value,
                                                    }
                                                  : detailItem
                                            ),
                                        }
                                      : item
                                  ),
                              });
                            }}
                            sx={{ my: 0.5, width: 200 }}
                            multiline
                            rows={2}
                          />
                        </Box>
                      </Box>
                    )
                  )}
                  <FormControl key={`${field.id}-2`} sx={{ m: 0.5, width: 30 }}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setter({
                          ...data,
                          accounting_transactions: [
                            ...data.accounting_transactions.map((item) =>
                              item.id === contactTransaction.id
                                ? {
                                    ...item,
                                    accounting_transaction_details: [
                                      ...(item.accounting_transaction_details ??
                                        []),
                                      {
                                        id: nanoid(),
                                        ...defaultTransactionDetails,
                                      },
                                    ],
                                  }
                                : item
                            ),
                          ],
                        });
                      }}
                    >
                      Add
                    </Button>
                  </FormControl>
                </Box>
              </Box>
            ))}
            <FormControl key={field.id} sx={{ m: 0.5, width: 30 }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setter({
                    ...data,
                    accounting_transactions: [
                      ...(data.accounting_transactions ?? []),
                      { id: nanoid(), ...defaultTransaction },
                    ],
                  });
                }}
              >
                Add
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactsTransactionsAdd;
