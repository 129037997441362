import { create } from 'zustand';

const useExcelStore = create((set) => ({
  overrideFile: null,
  // the file uploaded by user
  originFile: null,

  uploadedFile: null,
  // the raw data from the file
  rawData: [],
  rawDataSheets: [],
  // the processed data from the raw data
  processedData: [],

  setOriginFile: (file) => set({ originFile: file }),

  setOverrideFile: (file) => set({ overrideFile: file }),

  setUploadedFile: (file) => set({ uploadedFile: file }),

  setRawData: (data) => set({ rawData: data }),
  setRawDataSheets: (sheets) => set({ rawDataSheets: sheets }),
  setProcessedData: (data) => set({ processedData: data }),
  updateValueInRawData: (index, attr, newValue) =>
    set((state) => {
      const newData = [...state.rawData];
      newData[index][attr] = newValue;
      return { rawData: newData };
    }),
}));

export const useOriginalFile = () => useExcelStore((state) => state.originFile);
export const useOverrideFile = () =>
  useExcelStore((state) => state.overrideFile);

export const useUploadedFile = () =>
  useExcelStore((state) => state.uploadedFile);

export const useRawData = () => useExcelStore((state) => state.rawData);
export const useRawDataSheets = () =>
  useExcelStore((state) => state.rawDataSheets);
export const useProcessedData = () =>
  useExcelStore((state) => state.processedData);

export const useSetOriginFile = () =>
  useExcelStore((state) => state.setOriginFile);

export const useSetOverrideFile = () =>
  useExcelStore((state) => state.setOverrideFile);

export const useSetUploadedFile = () =>
  useExcelStore((state) => state.setUploadedFile);

export default useExcelStore;
