import { Alert } from '@mui/material';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import DataBulkAdd from '@/components/DataBulkAdd';
import API from '@/services/API';

const policyAgentSplitFields = [
  { id: 'key_column', label: 'Key column', required: true },
  { id: 'key_data', label: 'Key data', required: true },
  { id: 'agent_id', label: 'Agent ID', required: true },
  { id: 'split', label: 'Split', required: true },
];

const defaultParams = '{ "updatePolicyContacts": true }';

const BulkEditPolicyAgentSplit = () => {
  const [params, setParams] = useState(defaultParams);
  // @ts-ignore
  const { openSnackbar } = useOutletContext();
  const posterBulk = API.getMutation(`report_data/bulk_update`, 'PATCH');

  return (
    <DataBulkAdd
      fields={policyAgentSplitFields}
      params={params}
      handleUpdateParams={(e) => {
        setParams(e.target.value);
      }}
      onCancel={() => {}}
      onSave={async (payload) => {
        const res = await posterBulk.mutateAsync(payload);
        if (res.statusText === 'ok') {
          openSnackbar(
            <Alert severity="success">{`Updated ${res?.stats?.current_length} records`}</Alert>
          );
        } else {
          openSnackbar(
            <Alert severity="error">
              Error updating records
              <br />
              {res.error.split('\n').map((line, i) => (
                <span key={i}>
                  {line}
                  <br />
                </span>
              ))}
            </Alert>
          );
        }
      }}
      btnLabel="Update"
    />
  );
};

export default BulkEditPolicyAgentSplit;
