export const IMG_TYPES = ['image/png', 'image/jpeg'];
export const PDF_TYPES = ['application/pdf'];
export const PDF_IMG_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];
export const XLS_CSV_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

export type PreviewType = 'excel' | 'pdf' | 'img';
export interface SpresdsheetMo {
  getSheets: () => string[];
  getJson: (sheet: string) => any[];
}

export interface SpresdsheetProps {
  spreadsheet?: SpresdsheetMo;
  setSpreadsheet?: React.Dispatch<
    React.SetStateAction<SpresdsheetMo | undefined>
  >;
}
