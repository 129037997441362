import Box from '@mui/material/Box';
import { AgGridReact } from 'ag-grid-react';

const ResultTable = ({ headers, rows }) => {
  const columnDefs = (headers || []).map((header) => ({
    field: header,
    // sortable: true,
    // filter: true,
    // editable: true,
  }));

  const rowDataDef = (rows || []).map((row) => {
    const obj = {};
    headers.forEach((header, i) => {
      obj[header] = row[i];
    });
    return obj;
  });
  return (
    <Box
      sx={{ overflowX: 'auto', width: '100%', height: '100%' }}
      className="ag-theme-material"
    >
      <AgGridReact
        headerHeight={40}
        rowData={rowDataDef}
        columnDefs={columnDefs}
      />
    </Box>
  );
};

export default ResultTable;
