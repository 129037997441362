import {
  CheckCircle,
  Info,
  Error,
  RadioButtonUnchecked,
  TaskAlt,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

const icons = {
  info: <Info />,
  error: <Error />,
  complete: <CheckCircle />,
  complete2: <TaskAlt />,
  incomplete: <RadioButtonUnchecked />,
};

const Icon = ({ color, icon }) =>
  React.cloneElement(icons[icon], { style: { color } });

const StatusIcon = ({ icon, color, message }) => (
  <Tooltip title={message} placement="left" arrow>
    <div style={{ height: '24px' }}>
      <Icon icon={icon} color={color} />
    </div>
  </Tooltip>
);

export default StatusIcon;
