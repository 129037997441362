import { LoadingButton } from '@mui/lab';
import { Alert, Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import API from '@/services/API';

const UserTools: React.FC = () => {
  const [userUid, setUserUid] = useState('');
  const [passUserUid, setPassUserUid] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [passLoading, setPassLoading] = useState(false);

  const verifyUserPoster = API.getMutation(
    'admin/users/verify-user-account',
    'POST'
  );

  const resetPasswordPoster = API.getMutation(
    'admin/users/reset-user-password',
    'POST'
  );

  const { openSnackbar } = useOutletContext() as any;

  return (
    <Box>
      <Box>
        <Typography sx={{ m: 0.5 }}>Verify user email</Typography>
        <TextField
          label="User uid"
          value={userUid}
          onChange={({ target: { value } }) => setUserUid(value)}
          sx={{ width: 400, mr: 1 }}
        />
        <LoadingButton
          loading={verifyLoading}
          variant="outlined"
          onClick={async () => {
            setVerifyLoading(true);
            const response = await verifyUserPoster.mutateAsync({
              userUid: userUid,
            });
            if (response.error) {
              openSnackbar(response.error);
            } else {
              openSnackbar('User email verified');
            }
            setVerifyLoading(false);
          }}
          sx={{ mr: 1 }}
        >
          Verify email
        </LoadingButton>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography sx={{ m: 0.5 }}>Reset user password</Typography>
        <TextField
          label="User uid"
          value={passUserUid}
          onChange={({ target: { value } }) => setPassUserUid(value)}
          sx={{ width: 400, mr: 1 }}
        />
        <TextField
          label="New password"
          value={newUserPassword}
          onChange={({ target: { value } }) => setNewUserPassword(value)}
          sx={{ width: 400, mr: 1 }}
          type="password"
        />
        <LoadingButton
          loading={passLoading}
          variant="outlined"
          onClick={async () => {
            setPassLoading(true);
            const response = await resetPasswordPoster.mutateAsync({
              userUid: passUserUid,
              newPassword: newUserPassword,
            });
            if (response.data) {
              openSnackbar(
                <Alert severity="success">User password reset.</Alert>
              );
            } else {
              openSnackbar(<Alert severity="error">An error occurred.</Alert>);
            }
            setPassLoading(false);
          }}
          sx={{ mr: 1 }}
        >
          Reset password
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default UserTools;
