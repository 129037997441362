import DataView from '@/components/DataView';
import LazyLoadDynamicSelect from '@/components/molecules/LazyLoadDynamicSelect';
import CompProfilesAdd from '@/components/schedules/CompProfilesView/CompProfilesAdd';
import Formatter from '@/services/Formatter';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';

const CompProfileSetsView = () => {
  const { userRole } = useRoleStore();
  const { selectedAccount } = useAccountStore();

  const dataDesc = {
    label: 'Compensation profile sets',
    table: 'schedules/comp-profile-sets',
    editable: userRole === Roles.ACCOUNT_ADMIN,
    fields: [
      { id: 'name', label: 'Name' },
      { id: 'notes', label: 'Notes' },
      {
        id: 'commission_profiles',
        label: 'Comp profiles',
        table: 'schedules/comp-profiles',
        field: 'id',
        multiple: true,
        formatter: (val, collectionVals = []) => {
          if (val === '') return '';
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const record: any = collectionVals?.filter(
              (datum: any) => datum.id === val
            )?.[0];
            return record?.name ?? '';
          }
          return val;
        },
        optionValuer: (option) => option?.id,
        optionFormatter: (option) => option?.name,
        type: 'dynamic-select',
        tableFormatter: (field, row, dynamicSelects, header) => (
          <LazyLoadDynamicSelect
            data={(field ?? []).map((o) => o?.id)}
            header={header}
            formatter={(val) => val?.name}
            link="/schedules/comp-profiles"
          />
        ),
      },
      {
        id: 'contacts_agent_commission_schedule_profiles_sets',
        label: 'Agents',
        type: 'custom',
        table: 'contacts',
        tableFormatter: (field, row, dynamicSelects, header) => (
          <LazyLoadDynamicSelect
            data={(field ?? []).map((o) => o?.contact?.id)}
            header={header}
            formatter={(o) =>
              Formatter.contact(o, { account_id: selectedAccount?.accountId })
            }
            link="/contacts"
          />
        ),
        optionFormatter: (option) => `${option.email}`,
        optionValuer: (option) => option?.id,
        render: (field, row, setter, dynamicSelects) => (
          <CompProfilesAdd
            key="contacts_agent_commission_schedule_profiles_sets"
            table="contacts_agent_commission_schedule_profiles_sets"
            data={row}
            setter={setter}
            field={field}
            dynamicSelects={dynamicSelects}
            readOnly={userRole !== Roles.ACCOUNT_ADMIN}
          />
        ),
      },
      { id: 'divider', type: 'divider' },
      {
        id: 'created_at',
        label: 'Created at',
        condition: (data) => !!data.created_at,
        formatter: Formatter.dateTime,
        readOnly: true,
        visible: ['form'],
      },
      {
        id: 'updated_at',
        label: 'Updated at',
        condition: (data) => !!data.updated_at,
        formatter: Formatter.dateTime,
        readOnly: true,
        visible: ['form'],
      },
    ],
  };

  return (
    <DataView
      dataDesc={dataDesc}
      hideExport
      readOnly={userRole !== Roles.ACCOUNT_ADMIN}
      // TODO: add this new view to fields and views settings
    />
  );
};

export default CompProfileSetsView;
