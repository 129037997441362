import { Roles } from '@/types';
import EnhancedDataView from '../organisms/EnhancedDataView';

const AgentSettings = () => {
  const dataSpec = {
    label: 'Agent settings',
    table: 'accounts/settings/agents',
    fields: {
      key: {
        label: 'Setting',
        enabled: true,
        type: 'select',
        sx: { width: 200 },
        readOnly: true,
        options: [
          {
            label: 'Upline hierarchy access level',
            id: 'uplineHierarchyAccessLevel',
          },
          {
            label: 'Downline hierarchy access level',
            id: 'downlineHierarchyAccessLevel',
          },
          {
            label: 'Direct upline data access',
            id: 'directUplineDataAccess',
          },
          {
            label: 'Extended upline data access',
            id: 'extendedUplineDataAccess',
          },
          {
            label: 'Direct downline data access',
            id: 'directDownlineDataAccess',
          },
          {
            label: 'Extended downline data access',
            id: 'extendedDownlineDataAccess',
          },
        ],
      },
      role: {
        label: 'Role',
        enabled: true,
        type: 'select',
        options: [
          {
            label: 'Account admin',
            id: Roles.ACCOUNT_ADMIN,
          },
          {
            label: 'Producer',
            id: Roles.PRODUCER,
          },
          {
            label: 'Data specialist',
            id: Roles.DATA_SPECIALIST,
          },
        ],
        readOnly: true,
      },
      value: {
        label: 'Value',
        enabled: true,
      },
    },
  };

  return (
    <EnhancedDataView
      dataSpec={dataSpec}
      hideSelectedCount
      hideExport={true}
      hideAdd={true}
      enableMultiSelect={false}
    />
  );
};

export default AgentSettings;
