import { create } from 'zustand';

import { Roles } from '@/types';

interface RoleStore {
  userRole: Roles | null;
  setUserRole: (userRole: number | null) => void;
}

const useRoleStore = create<RoleStore>((set) => ({
  userRole: JSON.parse(localStorage.getItem('userRole') ?? '{}'),
  setUserRole: (userRole) => set({ userRole: userRole }),
}));

export default useRoleStore;
