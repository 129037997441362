import DashboardView from '.';

const BusinessInsights = () => {
  return (
    <DashboardView
      dashboardName={'Business Insights'}
      widgetsFilter={'businessInsights'}
    />
  );
};
export default BusinessInsights;
