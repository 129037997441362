import { Groups, Person } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Chip,
  IconButton,
  Typography,
} from '@mui/material';

import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';

const ContactCard = ({ contact, main = false, onClick }) => {
  const { selectedAccount } = useAccountStore();

  const countAllChildren = (contact) => {
    let count = contact?.child_relationships?.length;

    contact?.child_relationships?.forEach((childRel) => {
      if (childRel.contact) count += countAllChildren(childRel.contact);
    });

    return count;
  };

  const totalReports = countAllChildren(contact);

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: main ? '#2096f322' : 'inherit',
        width: '300px',
        height: '100px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      onClick={() => onClick(contact)}
    >
      <CardHeader
        avatar={
          <Avatar>
            {contact ? Formatter.contactInitials(contact) : <Person />}
          </Avatar>
        }
        action={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              ml: 1,
            }}
          >
            {contact?.contact_level?.length > 0 &&
              contact.contact_level.map((level) => (
                <Chip
                  key={level.id}
                  label={`${level.level_label}`}
                  sx={{ mb: 0.5, backgroundColor: '#2096f322' }}
                  size="small"
                />
              ))}
            {contact?.child_relationships?.length > 0 && (
              <Chip
                label="Manager"
                sx={{ backgroundColor: '#2096f322' }}
                size="small"
              />
            )}
            {contact?.child_relationships?.length > 0 && (
              <IconButton>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {contact?.child_relationships?.length || 0}
                  </Typography>
                  <Groups fontSize="small" />
                </Box>
              </IconButton>
            )}
          </Box>
        }
        title={
          contact
            ? Formatter.contact(contact, {
                account_id: selectedAccount?.accountId,
              })
            : 'No contact selected'
        }
        subheader={totalReports > 0 && `Total reports: ${totalReports}`}
      />
    </Card>
  );
};

export default ContactCard;
