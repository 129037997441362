import { DeleteOutlined, Launch } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import validator from 'validator';
import { Navigate } from 'react-router-dom';

import API from '@/services/API';
import DataView from '@/components/DataView';
import { useAccountStore } from '@/store';

const ViewsView: React.FunctionComponent = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.views;

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const { selectedAccount } = useAccountStore();

  const deleter = API.getMutation('saved_reports/views', 'DELETE');

  const dataDesc = {
    label: 'Saved views',
    table: 'saved_reports/views',
    editable: false,
    validateData: (data) =>
      validator.isEmail(data?.email || '') ||
      validator.isURL(data?.website || ''),
    fields: [
      {
        id: 'name',
        label: 'Name',
        formatter: (row, collectionVals) => {
          return (
            <Button
              onClick={() => {
                window.open(
                  `/views/${collectionVals.str_id}${collectionVals.params}`,
                  '_blank'
                );
              }}
              endIcon={<Launch />}
            >
              {row}
            </Button>
          );
        },
      },
      {
        id: 'page',
        label: 'Page',
      },
      {
        id: 'notes',
        label: 'Notes',
      },
      {
        id: 'access',
        label: 'Access',
      },
      {
        id: 'users_white_list',
        label: 'Users with access',
        type: 'dynamic-select',
        table: `users/get_account_users?state=active`,
        queryParamTable: selectedAccount?.accountId,
        queryParamNameTable: 'accId',
        queryParamValue: selectedAccount?.accountId,
        queryParamName: 'accId',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum = collectionVals?.filter(
              // @ts-ignore
              (datum) => datum.str_id === val
            )?.[0];
            // @ts-ignore
            return datum ? `${datum.email}` : 'Not found';
          }
          return val; // not formatting when collectionVals is not available
        },
      },
      {
        id: 'created_at',
        label: 'Created at',
        formatter: (row) => new Date(row).toLocaleString(),
      },
    ],
    actions: [
      {
        // label: 'Delete',
        label: <DeleteOutlined sx={{ color: '#777' }} />,
        type: 'button',
        onClick: async (data) => {
          if (data.isOwner) {
            if (confirm('Are you sure you want to delete this view?')) {
              // @ts-ignore
              await deleter.mutateAsync({ ids: [data.id] });
            }
          } else {
            confirm('Only the owner can delete this view.');
          }
        },
      },
    ],
    queryChips: {
      all: {
        id: 'all',
        label: 'All',
        query: {},
      },
      reconciliation: {
        id: 'reconciliation',
        label: 'Reconciliation',
        query: {
          page: 'reconciliation',
        },
      },
      policies: {
        id: 'policies',
        label: 'Policies',
        query: {
          page: 'policies',
        },
      },
      commissions: {
        id: 'commissions',
        label: 'Commissions',
        query: {
          page: 'commissions',
        },
      },
    },
  };

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  return (
    <>
      <DataView dataDesc={dataDesc} viewOnly hideExport />
    </>
  );
};

export default ViewsView;
