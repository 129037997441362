import { Card, CardContent, Grid, Typography } from '@mui/material';
import { e } from 'mathjs';

import BasicTable from '../molecules/BasicTable';

const TableWidget = (widget) => {
  return (
    <BasicTable
      headers={widget.data ? widget.data[0] : []}
      rows={widget.data?.slice(1)}
      formatters={widget.formatters}
    />
  );
};

export default TableWidget;
