import { MoreVert } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputLabel,
  FormControl,
  Select,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useState, useEffect } from 'react';

import BasicTable from '@/components/molecules/BasicTable';
import { exportCsv } from '@/services/helpers';
import Formatter from '@/services/Formatter';
import ChartWidget from '@/components/DashboardView/ChartWidget';

dayjs.extend(utc);

interface ChartWrapperProps {
  title?: string;
  data?: any;
  dataSet?: any[];
}

const ChartWrapper = ({
  title,
  data: _data,
  dataSet: _dataSet,
}: ChartWrapperProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [view, setView] = useState('chart');
  const [selectVal, setSelectVal] = useState<any>({});
  const [currTitle, setCurrTitle] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (_dataSet) {
      const newOptions: string[] = [];
      _dataSet.forEach((eachData) => {
        const _title = eachData?.title;
        if (_title) {
          newOptions.push(_title);
        }
      });

      setOptions(newOptions);

      if (newOptions.length > 0) {
        const curr = !currTitle ? newOptions[0] : currTitle;
        setSelectVal(
          _dataSet.find((eachData) => eachData.title === curr)?.value || []
        );
        setCurrTitle(curr);
      }
    } else if (_data && title) {
      setSelectVal(_data);
      setCurrTitle(title);
    }
  }, [_data, _dataSet, title]);

  const handleDataSet = (e) => {
    const selectedTitle = e.target.value as string;
    const selectedData = _dataSet?.find(
      (eachData) => eachData.title === selectedTitle
    );

    if (selectedData) {
      setSelectVal(selectedData.value);
      setCurrTitle(selectedTitle);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Get data
  const headers = Object.keys(selectVal?.series?.[0]?.data?.[0] || {});
  let data = selectVal?.series?.[0]?.data?.map((row) => {
    return Object.values(row);
  });
  let exportData: [string, number | string][] = data;

  console.log(selectVal?.series);

  if (selectVal?.xAxis?.type === 'time') {
    exportData = [];
    headers[0] = selectVal?.xAxis?.type || headers[0];
    headers[1] = selectVal?.series?.[0]?.name || headers[1];

    data = data.map(([k, v]) => {
      let date = dayjs.utc(k).format('YYYY-MM-DD');
      let value;
      date = dayjs(date).format('YYYY-MM-DD');
      exportData.push([date, v]);

      switch (currTitle) {
        case 'Time to import':
          value = Formatter.highlightChip(v, 5, 600);
          break;
        case 'Processing time':
          value = Formatter.highlightChip(v, 1, 15);
          break;
        case 'Records':
          value = Formatter.highlightChip(v, 6, 600);
          break;
        case 'Records per minutes':
          value = Formatter.highlightChip(v, 0, 1);
          break;
        case 'Adjustments':
          value = Formatter.highlightChip(v, 6, 40);
          break;
        case 'Adjustments w/o mapping':
          value = Formatter.highlightChip(v, 6, 40);
          break;

        default:
          break;
      }
      return [date, value];
    });
  }

  return (
    <Card>
      <CardContent sx={{ pb: 0 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item style={{ flex: 1 }}>
            <Typography gutterBottom variant="body1">
              {currTitle}
            </Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            {options.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id="select-data-label">Select Data</InputLabel>
                <Select
                  value={currTitle}
                  onChange={handleDataSet}
                  label="Select Data"
                  variant="outlined"
                  sx={{
                    minWidth: 100,
                    height: 32,
                    fontSize: '0.9rem',
                    '& .MuiSelect-icon': {
                      color: 'primary.main',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                      borderRadius: 0.5,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.dark',
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <IconButton onClick={handleMenuClick} sx={{ ml: 2 }}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                key="view-toggle"
                onClick={() => {
                  setView(view === 'table' ? 'chart' : 'table');
                }}
              >
                {view === 'table' ? 'Chart view' : 'Table view'}
              </MenuItem>
              <MenuItem
                key="export"
                onClick={() => {
                  exportCsv(headers, exportData, 'Fintary-Export.csv');
                }}
              >
                Export
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        {view === 'chart' && <ChartWidget data={selectVal} />}
        {view === 'table' && (
          <BasicTable headers={headers} rows={data} formatters={{}} />
        )}
      </CardContent>
    </Card>
  );
};

export default ChartWrapper;
