import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { cloneDeep, isEqual, remove } from 'lodash-es';
import currency from 'currency.js';
import { AgGridReact } from 'ag-grid-react';
import { Clear, FileDownload, InfoOutlined } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import {
  CellValueChangedEvent,
  ColDef,
  FilterChangedEvent,
} from 'ag-grid-community';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { tool } from '@/common/tools';
import { exportCsv } from '@/services/helpers';
import AddFieldsDialog from './addFieldDialog';
import { normalizeCurrency } from '@/services/DataTransformation/normalizer';
import {
  IFieldsProps,
  ProcessDataModel,
  ProcessMethodE,
  ProcessResultE,
  RowMappingModel,
  ShareDataModel,
} from './process.d';
import RemoveFieldsDialog from './removeFieldDialog';
import VertexResult from './VertexResult';
import { DocumentProcessActionTypes, DocumentProcessTypes } from '@/types';

interface ICommissionPreviewProps {
  rowMapping: RowMappingModel;
  setRowMapping: (val: any) => void;
  processedData: ProcessDataModel;
  setProcessedData: (data: any) => void;
  shareData: ShareDataModel;
  missingRequiredFields: string[];
  setProcessFormatData: (data: any) => void;
  handleProcessFormChange: (k, v) => void;
  setErrors: (data: any) => void;
  addActionCount: (type?: DocumentProcessTypes) => void;
  manualMapping: RowMappingModel;
}

dayjs.extend(utc);

const CommissionPreview: FC<ICommissionPreviewProps> = ({
  rowMapping,
  setRowMapping,
  processedData,
  setProcessedData,
  shareData,
  setProcessFormatData,
  handleProcessFormChange,
  setErrors,
  missingRequiredFields,
  addActionCount,
  manualMapping,
}) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [customData, setCustomData] = useState<string[][]>([]);
  const [editCustomData, setEditCustomData] = useState<string[][]>([]);
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState<any[]>([]);
  const [removeNode, setRemoveNode] = useState<any>();

  const [rowDataDef, setRowDataDef] = useState<RowMappingModel[]>([]);

  const [open, setOpen] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [canAddFields, setCanAddFields] = useState<IFieldsProps[]>([]);
  const [canRemoveFields, setCanRemoveFields] = useState<IFieldsProps[]>([]);

  const [showVertexRes, setShowVertexRes] = useState(false);
  const [invalidVertexRes, setInvalidVertexRes] = useState('');
  const [parsedVertexJson, setParsedVertexJson] = useState<any[]>([]);

  const gridRef = useRef<AgGridReact>(null);

  const onConfirmVertexRes = (jsonStr: string) => {
    if (!jsonStr) return;
    try {
      const res = JSON.parse(jsonStr);
      setParsedVertexJson(res);
      addActionCount(DocumentProcessActionTypes.FIX_EXTRACTION);
      // Update the extractions data
    } catch (err) {
      console.log(err);
    } finally {
      setShowVertexRes(false);
    }
  };

  useEffect(() => {
    if (shareData.fileData?.type === ProcessMethodE.InvalidJSON) {
      setInvalidVertexRes(shareData.fileData?.data);
      setShowVertexRes(true);
    }
  }, [shareData.fileData]);

  useEffect(() => {
    if (parsedVertexJson && parsedVertexJson.length) {
      const { keys, values } = tool.convertMapToArray(parsedVertexJson);
      setProcessedData({
        data: values,
        fields: keys,
        version: ProcessResultE.Gemini,
      });
    }
  }, [parsedVertexJson]);

  useEffect(() => {
    let data: string[][] = [];
    if (processedData && processedData.version && processedData.data?.length) {
      data = [...processedData.data];
      if (processedData.sheet) {
        handleProcessFormChange('selectedSheet', processedData.sheet);
      }
    }
    if (data.length) {
      setCustomData(data);
    }
  }, [processedData]);

  useEffect(() => {
    setEditCustomData(customData);
  }, [customData]);

  /**
   * Get processed data by mapping and custom data (closely like the data in the table)
   */
  useEffect(() => {
    const _keys = Object.keys(rowMapping);
    if (_keys.length && customData.length && shareData.fields) {
      const mapingEntries = Object.entries(rowMapping);
      const rowList = customData.map((item) => {
        const row = mapingEntries.reduce((acc, [k, v]) => {
          // Format value
          let formattedValue: string;
          const targetField = shareData.fields?.[k] || {};
          if (typeof v === 'string') {
            formattedValue = v;
          } else if (targetField.formatter) {
            formattedValue = targetField.formatter(item[v]);
          } else {
            formattedValue = item[v];
          }
          acc[k] = formattedValue;
          return acc;
        }, {});
        return row;
      });
      setRowDataDef(rowList);
    } else if (!customData.length || !_keys.length) {
      setRowDataDef([]);
    }
  }, [customData, rowMapping, shareData.fields]);

  useEffect(() => {
    const key = Object.keys(manualMapping)[0];
    const index = manualMapping[key];

    const copyMapping = { ...rowMapping };
    if (typeof index === 'number') {
      copyMapping[key] = index;
    } else if (typeof index === 'string') {
      if (index === 'MANUAL_ENTRY') {
        copyMapping[key] = '';
      } else if (index === 'NULL') {
        Reflect.deleteProperty(copyMapping, key);
      } else {
        copyMapping[key] = index;
      }
    }
    setRowMapping(copyMapping);
    setCustomData(editCustomData);
  }, [manualMapping]);

  /**
   * Check if missing required fields
   */
  useEffect(() => {
    const tips = missingRequiredFields.length
      ? `Missing required fields: ${missingRequiredFields.join(', ')}`
      : undefined;

    setErrors((error) => {
      return {
        ...error,
        missingField: tips,
      };
    });
  }, [missingRequiredFields]);

  const getTotalRowItem = () => {
    if (!rowDataDef.length || !shareData?.fields) return;
    const mappingKeys = Object.keys(rowMapping);
    const amtKeys = Object.entries(shareData.fields)
      .map(([k, v]) => {
        return v.type === 'currency' && mappingKeys.includes(k) ? k : null;
      })
      .filter(Boolean) as string[];

    const totalRowItem = {};
    amtKeys.forEach((k) => {
      const total = (rowDataDef as any[]).reduce((acc, cur) => {
        if (!cur[k] || cur[k]?.toString().includes('❌')) return acc;
        const calcVal = currency(acc).add(cur[k!]).format();
        return calcVal;
      }, 0);
      totalRowItem[k!] = total;
    });

    return totalRowItem;
  };

  useEffect(() => {
    const totalRowItem = getTotalRowItem();
    setPinnedBottomRowData([totalRowItem]);
  }, [rowDataDef, shareData?.fields, rowMapping]);

  useEffect(() => {
    if (!rowDataDef.length) return;
    setProcessFormatData((prev: any) => {
      return {
        ...prev,
        rowData: rowDataDef,
        mappingOptions: rowMapping,
      };
    });
  }, [rowDataDef]);

  useEffect(() => {
    if (pinnedBottomRowData.length) {
      setProcessFormatData((prev: any) => {
        return {
          ...prev,
          cmsTotal: normalizeCurrency(
            pinnedBottomRowData[0]?.commission_amount || ''
          ),
        };
      });
    }
  }, [pinnedBottomRowData]);

  const dataTypeDefinitions: any = useMemo(() => {
    return {
      dateString: {
        baseDataType: 'dateString',
        extendsDataType: 'dateString',
        valueParser: (params) =>
          params.newValue != null &&
          params.newValue.match('\\d{2}/\\d{2}/\\d{4}')
            ? params.newValue
            : null,
        valueFormatter: (params) => (params.value == null ? '' : params.value),
        dataTypeMatcher: (value) =>
          typeof value === 'string' && !!value.match('\\d{2}/\\d{2}/\\d{4}'),
        dateParser: (value) => {
          if (value == null || value === '') {
            return undefined;
          }
          const dateParts = value.split('/');
          return dateParts.length === 3
            ? dayjs.utc(new Date(value)).toDate()
            : undefined;
        },
        dateFormatter: (value) => {
          if (value == null) {
            return undefined;
          }
          const date = String(value.getDate());
          const month = String(value.getMonth() + 1);
          return `${month.length === 1 ? '0' + month : month}/${date.length === 1 ? '0' + date : date}/${value.getFullYear()}`;
        },
      },
    };
  }, []);

  // Load table column by mapping
  useEffect(() => {
    let tempMapping = { ...rowMapping };
    if (shareData.fileData?.type === 'GPT') {
      tempMapping = shareData.fileData?.data[0];
    }
    const _keys = Object.keys(tempMapping);
    if (_keys.length) {
      const _columnDefs = _keys.map((k) => {
        const targetField = shareData.fields?.[k] || {};
        const baseConf: ColDef = {
          headerName: targetField.label || k,
          field: k,
          minWidth: 160,
          editable: (params) => {
            return !params.node.rowPinned;
          },
          filter: true,
          resizable: true,
          valueParser: (params) => {
            if (targetField.formatter && targetField.type !== 'date') {
              return targetField.formatter(params.newValue);
            }
            return params.newValue;
          },
        };
        if (
          (targetField.type && targetField.type === 'date') ||
          k.includes('_date')
        ) {
          return {
            ...baseConf,
            cellEditor: 'agDateStringCellEditor',
            cellEditorParams: {
              min: '1900-01-01',
              max: '2100-01-01',
            },
          } as ColDef;
        } else {
          return baseConf;
        }
      });
      const actionColumn: ColDef = {
        headerName: '',
        field: '',
        pinned: 'left',
        width: 40,
        cellStyle: {
          padding: 0,
          display: 'flex',
          alignItems: 'center',
        },
        cellRenderer: (params) => {
          if (!params.node.rowPinned) {
            return (
              <IconButton
                sx={{
                  ':hover': {
                    opacity: 1,
                    color: 'error.main',
                  },
                  opacity: 0.55,
                }}
                onClick={() => {
                  setRemoveNode(params);
                }}
              >
                <Clear />
              </IconButton>
            );
          } else {
            return (
              <Tooltip
                title="Totals calculated and provided for review only. This row will not be imported."
                arrow
              >
                <IconButton sx={{ opacity: 0.55 }}>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            );
          }
        },
      };
      setColumnDefs([actionColumn, ..._columnDefs]);
    } else {
      setColumnDefs([]);
    }
  }, [rowMapping, shareData.fields, shareData.fileData]);

  useEffect(() => {
    if (removeNode) {
      gridRef.current!.api.applyTransaction({
        remove: [removeNode.data],
      });

      const rowIndex = removeNode.node.childIndex;
      const _editCustomData = editCustomData.filter(
        (_, index) => index !== rowIndex
      );
      setEditCustomData(_editCustomData);

      const fileteredRowData = rowDataDef.filter(
        (_, index) => index !== rowIndex
      );
      setRowDataDef(fileteredRowData);

      addActionCount(DocumentProcessActionTypes.DELETE_DATA);
      setRemoveNode(undefined);
    }
  }, [removeNode, editCustomData]);

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    addActionCount(DocumentProcessActionTypes.EDIT_DATA);
    const totalRowItem: any = getTotalRowItem();
    if (!isEqual(pinnedBottomRowData[0], totalRowItem)) {
      setPinnedBottomRowData([totalRowItem]);
    }

    const {
      value,
      colDef: { field },
      rowIndex,
    } = params;
    if (field === 'action') return;
    if (rowIndex !== null && field) {
      const newData = [...editCustomData];
      newData[rowIndex][rowMapping[field]] = value;
      setEditCustomData(newData);
    }

    setProcessFormatData((prev: any) => {
      return {
        ...prev,
        rowData: rowDataDef,
      };
    });
  };

  const onFilterChanged = (params: FilterChangedEvent) => {
    const filteredRows = getAllRows();
    setProcessFormatData((prev: any) => {
      return {
        ...prev,
        rowData: filteredRows,
      };
    });
    addActionCount(DocumentProcessActionTypes.FILTER_DATA);
  };

  const gridOptions = useMemo(() => {
    return {
      defaultColDef: {
        sortable: true,
        flex: 1,
      },
      animateRows: true,
      suppressMenuHide: true,
      alwaysShowHorizontalScroll: true,
    };
  }, []);

  const addField = () => {
    const currentFields = columnDefs.map((item) => item.field).filter(Boolean);
    const _canAddFields = shareData.allFieldKeys
      .map((item) => {
        return !currentFields.includes(item.key) ? item : null;
      })
      .filter(Boolean) as IFieldsProps[];
    setCanAddFields(_canAddFields);
    setOpen(true);
  };

  const removeField = () => {
    const currentFields = columnDefs.map((item) => item.field).filter(Boolean);
    const formattedFields = currentFields
      .filter((s) => s && !s.includes('action'))
      .map((k) => {
        const target = shareData.allFieldKeys.find((item) => item.key === k);
        if (target) {
          return target;
        } else {
          return {
            key: k,
            label: k,
            type: 'text',
          };
        }
      });
    setCanRemoveFields(formattedFields as any);
    setShowRemove(true);
  };

  const generateNewCustomedRowData = () => {
    const processedDataLength = processedData.fields.length;
    const newRow = Array.from({ length: processedDataLength }, () => '');
    return newRow;
  };

  const getAllRows = () => {
    if (!gridRef.current) return [];
    const rows = gridRef.current.api.getRenderedNodes();
    return rows.map((row) => row.data);
  };

  const addRow = () => {
    const newRowData = generateNewCustomedRowData();
    const allRows = [newRowData, ...editCustomData];
    setCustomData(allRows);
    addActionCount(DocumentProcessActionTypes.ADD_DATA);
  };

  const onAddFields = (data: IFieldsProps | undefined) => {
    addActionCount(DocumentProcessActionTypes.ADD_DATA);
    if (!data) return;
    const baseConf: ColDef = {
      headerName: data.label,
      field: data.key,
      minWidth: 160,
      editable: true,
      filter: true,
      resizable: true,
    };
    let option = baseConf;
    if (data.key.includes('_date') || data.type === 'date') {
      option = {
        ...baseConf,
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '01/01/2020',
          max: '01/01/2050',
        },
      } as ColDef;
    }
    setColumnDefs(columnDefs.concat(option));

    setRowMapping((prev) => {
      return {
        ...prev,
        [data.key]: processedData.fields.length,
      };
    });

    setOpen(false);
  };

  const onRemoveFields = (data: IFieldsProps[]) => {
    addActionCount(DocumentProcessActionTypes.DELETE_DATA);
    if (!data.length) return;
    const delList = data.map((item) => item.key);
    const newColumns = columnDefs.filter((item) => {
      return !delList.includes(item.field!);
    }) as any;

    setColumnDefs(newColumns);

    const newMapping = { ...rowMapping };
    delList.forEach((k) => {
      Reflect.deleteProperty(newMapping, k);
    });
    setRowMapping(newMapping);
    setShowRemove(false);
  };

  const getRowStyle = (params) => {
    if (params.node.rowPinned) {
      return { background: '#F0F0F0' };
    }
  };

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {rowDataDef.length ? (
            <Chip
              label={`Rows: ${gridRef?.current?.api.getDisplayedRowCount()}`}
            />
          ) : (
            ''
          )}
          <Button variant="outlined" onClick={addField}>
            Add field
          </Button>
          <Button variant="outlined" onClick={addRow}>
            Add row
          </Button>
          <Button variant="outlined" onClick={removeField}>
            Remove field
          </Button>
          <Tooltip title="Double-click cell to edit" placement="left" arrow>
            <InfoOutlined sx={{ color: '#aaa', mr: 1 }} />
          </Tooltip>
          <Tooltip title="Export as csv" placement="bottom" arrow>
            <FileDownload
              sx={{ color: '#aaa', pt: 0.25 }}
              onClick={() => {
                exportCsv(
                  Object.keys(rowDataDef[0]).filter((k) => k !== 'action'),
                  rowDataDef,
                  `Fintary-Preview-Export.csv`
                );
              }}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{ width: '100%', flex: 1, overflow: 'auto' }}
          className="ag-theme-material"
        >
          <AgGridReact
            ref={gridRef}
            headerHeight={40}
            rowData={rowDataDef}
            columnDefs={columnDefs}
            onCellValueChanged={onCellValueChanged}
            onFilterChanged={onFilterChanged}
            dataTypeDefinitions={dataTypeDefinitions}
            getRowStyle={getRowStyle}
            pinnedBottomRowData={pinnedBottomRowData}
            {...gridOptions}
          />
        </Box>
      </Box>
      <RemoveFieldsDialog
        open={showRemove}
        fieldsSource={canRemoveFields}
        onClose={() => setShowRemove(false)}
        onConfirm={onRemoveFields}
      />
      <VertexResult
        open={showVertexRes}
        onClose={() => setShowVertexRes(false)}
        onConfirm={onConfirmVertexRes}
        json={invalidVertexRes}
        extraction={shareData.fileData?.extraction}
      />
      <AddFieldsDialog
        open={open}
        fieldsSource={canAddFields}
        onClose={() => setOpen(false)}
        onConfirm={onAddFields}
      />
    </>
  );
};

export default CommissionPreview;
