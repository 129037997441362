import { useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress, Backdrop, Typography } from '@mui/material';
import { LoadingContext } from 'contexts/LoadingContext';
import { Close } from '@mui/icons-material';

const LoadingMask = () => {
  const { loadingConfig, setLoadingConfig } = useContext(LoadingContext);
  const [open, setOpen] = useState(false);
  const timerRef = useRef(null);

  // add delay to close
  useEffect(() => {
    if (loadingConfig.loading) {
      setOpen(true);
    } else {
      // @ts-ignore
      timerRef.current = setTimeout(() => {
        setOpen(false);
      }, loadingConfig.delayToClose || 0);
    }

    return () => {
      // @ts-ignore
      clearTimeout(timerRef.current);
    };
  }, [loadingConfig.loading]);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={open}
    >
      {loadingConfig.allowClose && (
        <Close
          color="inherit"
          onClick={() => {
            setLoadingConfig({ loading: false });
          }}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            cursor: 'pointer',
          }}
        />
      )}
      <CircularProgress color="inherit" />
      <Typography variant="subtitle2" component="span" sx={{ paddingLeft: 2 }}>
        {loadingConfig.message || 'Loading...'}
      </Typography>
    </Backdrop>
  );
};

export default LoadingMask;
