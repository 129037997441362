import { create } from 'zustand';

interface MenuStore {
  menuOpen: boolean;
  setMenuOpen: (flag: boolean) => void;
}

const useMenuStore = create<MenuStore>((set) => ({
  menuOpen: true,
  setMenuOpen: (flag) => set({ menuOpen: flag }),
}));

export default useMenuStore;
