import {
  AccountCircle,
  Business,
  CampaignOutlined,
  DeveloperBoard,
  ExpandMore,
  HelpOutline,
  LogoutOutlined,
  NotificationsNone,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { feedbackIntegration } from '@sentry/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { auth } from '@/firebase';
import API from '@/services/API';
import Storage from '@/services/Storage';
import { useAccountStore, useMenuStore } from '@/store';
import { States } from '@/types';

const Bar = ({
  onHelpClick,
  onSignOutClick,
  onSignUpClick,
  onSignInClick,
  performingAction,
  user,
  userState,
}) => {
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [data, setData] = useState({});
  const [fetchNotifications, setFetchNotifications] = useState(false);
  const isDesktop = useMediaQuery('(min-width:600px)');
  const { selectedAccount, setSelectedAccount } = useAccountStore();
  const { menuOpen, setMenuOpen } = useMenuStore();

  const { data: notifications } = API.getBasicQuery(
    'notifications',
    '',
    fetchNotifications
  );

  const navigate = useNavigate();

  useEffect(() => {
    const data = userState?.userAccounts.map((acc) => ({
      accountId: acc.account.str_id,
      accountMode: acc.account.mode,
      accountName: acc.account.name,
      compGridsEnabled: acc.account.comp_grids_enabled,
      accountingTransactionsEnabled:
        acc.account.accounting_transactions_enabled,
      logoUrl: acc.account.logo_url,
      whiteLabelMode: acc.account.white_label_mode,
      uid: acc.account.uid,
    }));
    setData(data);

    if (!auth.currentUser) return;
    if (Array.isArray(data) && data.length === 1) {
      const newSelectedAccount = data[0];
      setSelectedAccount(newSelectedAccount);
    } else if (data?.length === 0) {
      // No active accounts
      setSelectedAccount(null);
    }
    // TODO: Hack to update account name in bar. Probably better to just store the accountId in the accountStore
    // and rely on reactQuery data for everything else (e.g. account name to show in bar)
    if (Array.isArray(data) && data.length > 1) {
      const account = data.find(
        (account) => account.accountId === selectedAccount?.accountId
      );
      if (account?.accountName !== selectedAccount?.accountName) {
        setSelectedAccount(account);
      }
    }
  }, [userState]);

  let jsonSelectedAccount = selectedAccount;

  useEffect(() => {
    if (user && userState?.userOverallState === States.ACTIVE) {
      setFetchNotifications(true);

      if (!selectedAccount) {
        setLogoUrl(null);
      }

      const getSetUrl = async (filePath) => {
        try {
          const url = await Storage.getFileUrl(filePath);
          setLogoUrl(url);
        } catch (e) {
          console.error('Error getting logo url', e);
        }
      };
      if (selectedAccount?.whiteLabelMode && selectedAccount?.logoUrl) {
        getSetUrl(selectedAccount.logoUrl);
      }
    }
  }, [selectedAccount, user]);

  const openMenu = (event) => {
    const accountAnchorEl = event.currentTarget;
    setAccountAnchorEl(accountAnchorEl);
  };

  const closeMenu = () => {
    setAccountAnchorEl(null);
  };

  const openNotifications = (event) => {
    const notificationsAnchorEl = event.currentTarget;
    setNotificationsAnchorEl(notificationsAnchorEl);
  };

  const closeNotifications = () => {
    setNotificationsAnchorEl(null);
  };

  const selectAccount = (account) => {
    setSelectedAccount(account);
    closeMenu();
    // TODO: Reset React Query client cache
    // Refresh other tabs if account changes
    localStorage.setItem('loggedOut', Date.now().toString());
    window.location.reload();
  };

  const [feedbackWidget, setFeedbackWidget] = useState(null);
  const feedback = feedbackIntegration({
    autoInject: false,
    triggerLabel: 'Send feedback',
    colorScheme: 'light',
    formTitle: 'Send feedback',
    isEmailRequired: true,
    showBranding: false,
    showName: false,
    submitButtonLabel: 'Submit',
    themeLight: {
      submitBorder: '#2196f3',
      submitBackground: '#2196f3',
      submitBackgroundHover: '#2196f3',
      submitOutlineFocus: '#2196f3',
      inputOutlineFocus: '#2196f3',
    },
  });

  const menuItems = [
    {
      icon: <HelpOutline />,
      name: 'Help',
      onClick: onHelpClick,
    },
    {
      icon: <CampaignOutlined />,
      name: feedbackWidget ? 'Close feedback' : 'Open feedback',
      onClick: () => {
        if (feedbackWidget) {
          feedbackWidget?.removeFromDom();
          setFeedbackWidget(null);
        } else {
          setFeedbackWidget(feedback.createWidget());
        }
      },
    },
    {
      icon: <LogoutOutlined />,
      name: 'Sign out',
      onClick: onSignOutClick,
    },
  ];

  const getNotificationIcon = (notification) => {
    switch (notification.type) {
      case 'processor':
        return <DeveloperBoard />;
      default:
        return null;
    }
  };

  return (
    <AppBar color="primary" position="fixed" sx={{ zIndex: 1300 }}>
      <Toolbar disableGutters sx={{ px: 2 }}>
        <Box display="flex" flexGrow={1}>
          <Typography color="inherit" variant="h5" display={'flex'}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setMenuOpen(!menuOpen)}
              edge="start"
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
            <Link
              color="inherit"
              component={RouterLink}
              to="/"
              underline="none"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {logoUrl && selectedAccount ? (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}
                >
                  <Avatar alt="Logo" src={logoUrl} />
                  <Box ml={1}>{selectedAccount?.accountName}</Box>
                </Box>
              ) : (
                process.env.REACT_APP_TITLE
              )}
            </Link>
          </Typography>
        </Box>
        {user ? (
          <>
            {notifications && (
              <>
                <Tooltip
                  title={
                    !notifications.length
                      ? 'No new notifications'
                      : `${notifications.length} notifications`
                  }
                  arrow
                >
                  <IconButton onClick={openNotifications}>
                    <NotificationsNone
                      sx={{
                        color: notifications.length > 0 ? '#fff' : '#eee',
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={notificationsAnchorEl}
                  open={Boolean(notificationsAnchorEl)}
                  onClose={closeNotifications}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box>
                    <MenuList sx={{ py: 0 }}>
                      {!notifications.error && notifications.length > 0 ? (
                        notifications.map((notification) => (
                          <MenuItem
                            key={notification.id}
                            onClick={() => {
                              navigate(notification.link);
                            }}
                          >
                            <ListItemIcon>
                              {getNotificationIcon(notification)}
                            </ListItemIcon>
                            <ListItemText>{notification.text}</ListItemText>
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No notifications</MenuItem>
                      )}
                    </MenuList>
                  </Box>
                </Menu>
              </>
            )}
            <Button
              data-testid="log_out_button"
              endIcon={<ExpandMore />}
              onClick={openMenu}
              variant="outlined"
              sx={{ color: 'white', mr: -2 }}
            >
              {isDesktop ? (
                <Typography variant="h6">
                  {selectedAccount?.whiteLabelMode
                    ? user.email
                    : (jsonSelectedAccount?.accountName ?? user.email)}
                </Typography>
              ) : (
                <PersonIcon />
              )}
            </Button>
            <Menu
              anchorEl={accountAnchorEl}
              open={Boolean(accountAnchorEl)}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              sx={{ py: 0 }}
            >
              <MenuItem disabled style={{ opacity: 1 }}>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText>{user.email}</ListItemText>
              </MenuItem>
              {data &&
                Array.isArray(data) && [
                  <Divider variant="middle" key="divider" />,
                  <Box key="accountList">
                    <Box
                      display="flex"
                      justifyContent="center"
                      sx={{ my: -0.5 }}
                    >
                      <Typography variant="caption">
                        {data.length > 1 ? 'Accounts' : 'Account'}
                      </Typography>
                    </Box>
                    {data.map((account) => (
                      <MenuItem
                        key={account.accountId}
                        style={{
                          opacity: 1,
                        }}
                        disabled={data.length === 1}
                        onClick={() => selectAccount(account)}
                      >
                        <ListItemIcon>
                          {account.logoUrl && logoUrl ? (
                            <Avatar
                              alt="Account logo"
                              src={logoUrl}
                              sx={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <Business />
                          )}
                        </ListItemIcon>
                        <ListItemText>
                          {account.accountName ?? '(unspecified)'}
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </Box>,
                ]}
              <Divider variant="middle" sx={{ mb: 1 }} />
              {menuItems.map((menuItem, index) => {
                if (
                  // eslint-disable-next-line no-prototype-builtins
                  menuItem.hasOwnProperty('condition') &&
                  !menuItem.condition
                ) {
                  return null;
                }

                let component = null;

                if (menuItem.to) {
                  component = (
                    <MenuItem
                      key={index}
                      component={RouterLink}
                      to={menuItem.to}
                      onClick={closeMenu}
                    >
                      {menuItem.name}
                    </MenuItem>
                  );
                } else {
                  component = (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        closeMenu();
                        menuItem.onClick();
                      }}
                    >
                      {menuItem.icon && (
                        <ListItemIcon>{menuItem.icon}</ListItemIcon>
                      )}
                      <ListItemText>{menuItem.name}</ListItemText>
                    </MenuItem>
                  );
                }

                if (menuItem.divide) {
                  return (
                    <span key={index}>
                      <Divider />
                      {component}
                    </span>
                  );
                }

                return component;
              })}
            </Menu>
          </>
        ) : null}

        {!user && (
          <>
            <Button
              color="inherit"
              variant="outlined"
              onClick={onSignUpClick}
              disabled={performingAction}
              sx={{ mr: 1 }}
            >
              Sign up
            </Button>
            <Button
              color="inherit"
              onClick={onSignInClick}
              disabled={performingAction}
            >
              Sign in
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

Bar.propTypes = {
  performingAction: PropTypes.bool.isRequired,
  roles: PropTypes.array,
  user: PropTypes.object,
  userState: PropTypes.object,
  onHelpClick: PropTypes.func,
  onSignOutClick: PropTypes.func.isRequired,
  onSignInClick: PropTypes.func,
  onSignUpClick: PropTypes.func,
  setSelectedAccount: PropTypes.func,
  openSnackbar: PropTypes.func.isRequired,
};

export default Bar;
