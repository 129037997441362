import * as React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
} from '@mui/material';
import { Tune } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

const SearchSettings = ({ settings }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton color="primary" onClick={handleClick}>
        <Tune />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {settings.map((setting) => {
          if (typeof setting === 'object') {
            return (
              <Box key={setting.id}>
                {setting.type === 'toggle' && (
                  <FormControlLabel
                    label={setting.label}
                    control={
                      <Checkbox
                        checked={searchParams.get(setting.id) === 'true'}
                        onChange={() => {
                          setSearchParams((prev) => {
                            if (searchParams.get(setting.id)) {
                              prev.delete(setting.id);
                            } else {
                              prev.set(setting.id, 'true');
                            }
                            return prev;
                          });
                        }}
                      />
                    }
                    sx={{ ml: 0 }}
                  />
                )}
              </Box>
            );
          }
          return null;
        })}
      </Popover>
    </div>
  );
};

export default SearchSettings;
