import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import React from 'react';

import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';

type AgentCommissionsEditProps = {
  data: {
    agent_commissions: Record<string, number>;
    agent_commissions_v2?: Record<string, number>;
    contacts: string[];
  };
  setter: (fn: (prevData: any) => any) => void;
  field: any;
  dynamicSelects: any;
  isV2?: boolean;
};

const AgentCommissionsEdit: React.FC<AgentCommissionsEditProps> = ({
  data,
  field,
  setter,
  dynamicSelects,
  isV2 = false,
}) => {
  let { agent_commissions: agentCommissions = {} } = data || {};
  const { agent_commissions_v2 } = data || {};
  if (isV2) {
    agentCommissions = agent_commissions_v2 || {};
  }
  const { selectedAccount } = useAccountStore();
  const [selectedAgents, setSelectedAgents] = React.useState<string[]>([]);
  const [query, setQuery] = React.useState('');
  if (!agentCommissions) {
    agentCommissions = {};
  }

  const handleAddSelected = () => {
    selectedAgents.forEach((agentId) => {
      if (!agentCommissions[agentId]) {
        updateAgentCommissions(agentId, '0');
      }
    });
    setSelectedAgents([]);
  };

  const filterOptions = (options: any[], query: string) => {
    if (!query) return options;
    const lowerQuery = query.toLowerCase();
    return options.filter(
      (option) =>
        option.first_name.toLowerCase().includes(lowerQuery) ||
        option.last_name.toLowerCase().includes(lowerQuery)
    );
  };

  const updateAgentCommissions = (agentId: string, value: string) => {
    setter((prevData) => {
      const newAgentCommissions = { ...prevData.agent_commissions };
      newAgentCommissions[agentId] = value;

      const { total, ...commissionsWithoutTotal } = newAgentCommissions;

      const newTotal = (
        Object.values(commissionsWithoutTotal) as string[]
      ).reduce(
        (sum: number, current: string) =>
          sum + (Number.isNaN(+current) ? 0 : +current),
        0
      );

      newAgentCommissions['total'] = newTotal;

      return {
        ...prevData,
        agent_commissions: newAgentCommissions,
      };
    });
  };

  const removeAgentCommission = (agentId: string) => {
    setter((prevData) => {
      const newAgentCommissions = { ...prevData.agent_commissions };
      delete newAgentCommissions[agentId];

      const { total, ...commissionsWithoutTotal } = newAgentCommissions;

      const newTotal = (
        Object.values(commissionsWithoutTotal) as number[]
      ).reduce((sum: number, current: number) => sum + current, 0);

      newAgentCommissions['total'] = newTotal;

      return {
        ...prevData,
        agent_commissions: newAgentCommissions,
      };
    });
  };

  return (
    <Box>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            pl: 1,
            pt: 0.5,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {agentCommissions &&
              Object.entries(agentCommissions)
                .filter(([k, v]) => k !== 'total')
                .map(([k, v]) => {
                  const contact = dynamicSelects?.find(
                    (e) => e?.str_id === k
                  ) ?? { id: undefined };
                  return contact.id ? (
                    <Box
                      key={`${contact.id}`}
                      sx={{
                        m: 0.5,
                        p: 1,
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        display: 'inline-block',
                        width: 180,
                        backgroundColor: '#2196f30a',
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        position="relative"
                        sx={{ ml: 0.5 }}
                      >
                        <Typography variant="body2">
                          {Formatter.contact(contact, {
                            account_id: selectedAccount?.accountId,
                          })}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            removeAgentCommission(contact.str_id);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <TextField
                          label="Commission value"
                          variant="outlined"
                          value={v}
                          onChange={(e) => {
                            console.log('e', e);
                            const newValue = e.target.value;
                            updateAgentCommissions(contact.str_id, newValue);
                          }}
                          onBlur={(e) => {
                            setter((prevData) => {
                              Object.entries(
                                prevData.agent_commissions
                              ).forEach(([k, v]) => {
                                const amount: string = v as any;
                                prevData.agent_commissions[k] = Number.isNaN(
                                  +amount
                                )
                                  ? 0
                                  : +amount;
                              });
                              return prevData;
                            });
                          }}
                          sx={{ my: 0.5 }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton key={k} />
                  );
                })}
            <Box
              display="flex"
              justifyContent="space-between"
              position="relative"
              sx={{ ml: 0.5 }}
            >
              <FormControl key={field.id} sx={{ m: 0.5, width: 145 }}>
                <InputLabel id={`${field.id}-label`}>Add</InputLabel>
                <Select
                  labelId={`${field.id}-label`}
                  id={field.id}
                  label="Add"
                  multiple
                  value={selectedAgents}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onClose={handleAddSelected}
                  onChange={(e) => {
                    setSelectedAgents(
                      Array.isArray(e.target.value)
                        ? e.target.value.filter(Boolean)
                        : [e.target.value]
                    );
                  }}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#333',
                    },
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {Array.isArray(selected) &&
                        (selected as string[]).map((value) => {
                          const item = dynamicSelects.find(
                            (option) => option.id === value
                          );
                          return (
                            <Chip
                              key={item ? item.str_id : value}
                              label={item ? item.name : value}
                            />
                          );
                        })}
                    </Box>
                  )}
                >
                  <Box
                    sx={{
                      mb: 0.5,
                      mx: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <TextField
                      sx={{ flex: 1, mr: 1 }}
                      label="Search"
                      value={query}
                      onChange={(e) => {
                        setQuery(e.target.value);
                      }}
                    />
                  </Box>
                  <Button
                    sx={{ ml: 1 }}
                    variant="outlined"
                    onClick={handleAddSelected}
                  >
                    Add selected
                  </Button>
                  {field.nullable && (
                    <MenuItem value={''} key="null">
                      &nbsp;
                    </MenuItem>
                  )}
                  {filterOptions(dynamicSelects, query).map((option: any) => (
                    <MenuItem value={option.str_id} key={option.id}>
                      {Formatter.contact(option, {
                        account_id: selectedAccount?.accountId,
                      })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            sx={{ ml: 0.5 }}
          >
            <TextField
              label="Total"
              variant="outlined"
              value={Formatter.currency(
                agentCommissions?.total ? agentCommissions.total : 0
              )}
              disabled
              sx={{ my: 0.5 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AgentCommissionsEdit;
