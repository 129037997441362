import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

export interface BasicDialogProps {
  id?: string;
  keepMounted?: boolean;
  value?: string;
  positiveLabel?: string;
  negativeLabel?: string;
  loading?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  open: boolean;
  title: string;
  bodyComponent: React.ReactNode;
  onClose: (value?: string | boolean) => void;
}

function BasicDialog(props: BasicDialogProps) {
  const {
    onClose,
    open,
    title,
    bodyComponent,
    negativeLabel = 'Cancel',
    positiveLabel = 'Ok',
    loading,
    ...other
  } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{bodyComponent}</DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button autoFocus onClick={handleCancel}>
          {negativeLabel}
        </Button>
        <Button onClick={handleOk} variant="contained">
          {loading ? 'Loading...' : positiveLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BasicDialog;
