import { useParams, useOutletContext, useSearchParams } from 'react-router-dom';
import { DateRangeOutlined, Launch, Share } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { capitalize } from 'lodash-es';
import { useState } from 'react';
import CommonFormatter from 'common/Formatter';
import {
  ContactPayableStatuses,
  ReportReviewStatuses,
  ReportReviewStatusesLabels,
} from 'common/globalTypes';

import DataView from '@/components/DataView';
import API from '@/services/API';
import DataTransformation from '@/services/DataTransformation';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { savedReportsGroupsTemplates } from '@/types';
import CommissionPayoutExportConfig from './CommissionPayoutExportConfig';

const Normalizer = DataTransformation;

const ReportsGroupDetailsView = (): JSX.Element => {
  const { id } = useParams();
  // @ts-ignore
  const { openSnackbar } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedData, setSelectedData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [exportOptions, setExportOptions] = useState<{
    export_type: string;
    fileName: any;
  } | null>(null);

  const bulkSharePoster = API.getMutation(
    'saved_reports/groups/bulk_share',
    'POST'
  );
  const { data: contactOptions, isFetched: isFetchedContactOptions } =
    API.getBasicQuery(`contacts/options?saved_report_group=${id}`);
  const { data: savedReportResponse } = API.getBasicQuery(
    // @ts-ignore
    `saved_reports/groups?id=${id}`
  );
  const reportGroupPatcher = API.getMutation(
    'saved_reports/groups/details',
    'PATCH'
  );

  const savedReport = savedReportResponse?.data;

  const { selectedAccount } = useAccountStore();

  // TODO: Currently getting dates from report name. These should be saved as params in the saved report for this type.
  let processing_date_start = '';
  let processing_date_end = '';
  if (Array.isArray(savedReport) && savedReport.length === 1) {
    const report = savedReport[0];
    const processingDates = report?.name?.match(/\d\d?\/\d\d?\/\d{4}/g);
    if (processingDates) {
      [processing_date_start, processing_date_end] = processingDates;
    }
  }

  const extraActions = [
    ...(processing_date_start && processing_date_end
      ? [
          <FormControlLabel
            control={
              <Checkbox checked={searchParams.get('hide_empty') === 'true'} />
            }
            label="Hide empty"
            sx={{ mr: 1 }}
            onChange={(e: any) => {
              setSearchParams((prev: any) => {
                if (e.target.checked) {
                  prev.set('hide_empty', 'true');
                } else {
                  prev.delete('hide_empty');
                }
                return prev;
              });
            }}
            key="emptyFilter"
          />,
          <Button
            key={`${processing_date_end}-${processing_date_start}-key`}
            href={`/commissions/?processing_date_start=${processing_date_start}&processing_date_end=${processing_date_end}`}
            variant="outlined"
            startIcon={<DateRangeOutlined />}
          >
            View current data
          </Button>,
        ]
      : []),
    <Tooltip
      key={`shareReportsButtonKey`}
      title="Share report with producer"
      arrow
    >
      <span>
        <Button
          onClick={async () => {
            const response = await bulkSharePoster.mutateAsync(selectedData);
            if (response && response.statusText === 'ok') {
              openSnackbar('Reports shared successfully');
            } else if (response && response.error) {
              openSnackbar(response.error);
            } else {
              openSnackbar('Error sharing reports');
            }
          }}
          variant="outlined"
          startIcon={<Share />}
          disabled={selectedData.length === 0}
          sx={{ ml: 1 }}
        >
          Share reports
        </Button>
      </span>
    </Tooltip>,
  ];

  const dataDesc = {
    label: 'Report group summary',
    table: `saved_reports/groups/details?id=${id}`,
    fields: [
      {
        id: 'name',
        label: 'Report name',
        tableFormatter: (field, row) => {
          return (
            <Button
              onClick={() => {
                window.open(`/reports/${row.str_id}`, '_blank');
              }}
              endIcon={<Launch />}
            >
              {field}
            </Button>
          );
        },
      },
      {
        id: 'processing_date_start',
        label: 'Start date',
        formatter: (val) => {
          if (val) {
            return CommonFormatter.date(val);
          }
          return '';
        },
      },
      {
        id: 'processing_date_end',
        label: 'End date',
        formatter: (val) => {
          if (val) {
            return CommonFormatter.date(val);
          }
          return '';
        },
      },
      {
        id: 'totals',
        label: 'Amount due',
        formatter: (val, row) => {
          if (val.agent_commissions)
            return Formatter.currency(
              Normalizer.normalizeCurrency(
                JSON.stringify(Object.values(val.agent_commissions)?.[0] ?? 0)
              )
            );
        },
      },
      {
        id: 'report_notes',
        label: 'Notes',
      },
      {
        id: 'status',
        label: 'Report status',
        type: 'select',
        options: [
          {
            id: ReportReviewStatuses.None,
            label: ReportReviewStatusesLabels.None,
          },
          {
            id: ReportReviewStatuses.Draft,
            label: ReportReviewStatusesLabels.Draft,
          },
          {
            id: ReportReviewStatuses.InReview,
            label: ReportReviewStatusesLabels.InReview,
          },
          {
            id: ReportReviewStatuses.Approved,
            label: ReportReviewStatusesLabels.Approved,
          },
          {
            id: ReportReviewStatuses.RequestUpdate,
            label: ReportReviewStatusesLabels.RequestUpdate,
          },
          {
            id: ReportReviewStatuses.Rejected,
            label: ReportReviewStatusesLabels.Rejected,
          },
          {
            id: ReportReviewStatuses.NonPayable,
            label: ReportReviewStatusesLabels.NonPayable,
          },
        ],
        formatter: Formatter.reportStatusFormatter,
      },
      {
        id: 'agent_name',
        label: 'Agent',
      },
      {
        id: 'agent_email',
        label: 'Agent email',
      },
      {
        id: 'agent_status',
        label: 'Agent status',
        queryable: true,
        condition: () => isFetchedContactOptions,
        formatter: (val) => capitalize(val),
      },
      {
        id: 'agent_payable_status',
        label: 'Payable status',
        queryable: true,
        type: 'select',
        options: [
          ...new Set([
            '',
            ContactPayableStatuses.PAYABLE,
            ContactPayableStatuses.NON_PAYABLE,
            ...(contactOptions?.agent_payable_status ?? []),
          ]),
        ],
        condition: () => isFetchedContactOptions,
        formatter: (val) => capitalize(val),
      },
      {
        id: 'agent_bank_info',
        label: 'Agent bank info',
      },
      {
        id: 'agent',
        readOnly: true,
        visible: false,
      },
    ],
    filterConfigs: {
      agent_status: { type: 'select', label: 'Agent status', options: {} },
      agent_payable_status: {
        type: 'select',
        label: 'Agent payable status',
        options: {},
      },
    },
  };

  if (contactOptions) {
    contactOptions.status.forEach((option) => {
      dataDesc.filterConfigs.agent_status.options[option] = {
        id: option,
        label: capitalize(option),
        query: { agent_status: option },
      };
    });
    contactOptions.payable_status.forEach((option) => {
      dataDesc.filterConfigs.agent_payable_status.options[option] = {
        id: option,
        label: capitalize(option),
        query: { agent_payable_status: option },
      };
    });
  }

  const defaultExportOptions = [
    {
      id: 'export',
      label: 'Export',
      options: {
        export_type: 'zip',
        fileName:
          savedReport && Array.isArray(savedReport) && savedReport.length > 0
            ? savedReport[0]?.template ===
              savedReportsGroupsTemplates.COMMISSION_PAYOUT
              ? `${selectedAccount?.accountName} - ${Formatter.date(savedReport[0]?.created_at)}`
              : savedReport[0]?.name
            : '',
      },
    },
    {
      id: 'export-zip',
      label: 'Export reports as zip',
      options: {
        export_type: 'zip',
        fileName:
          savedReport && Array.isArray(savedReport) && savedReport.length > 0
            ? savedReport[0]?.template ===
              savedReportsGroupsTemplates.COMMISSION_PAYOUT
              ? `${selectedAccount?.accountName} - ${Formatter.date(savedReport[0]?.created_at)}`
              : savedReport[0]?.name
            : '',
      },
    },
    {
      id: 'export-xlsx',
      label: 'Export reports as xlsx',
      options: {
        export_type: 'xlsx',
        fileName:
          savedReport && Array.isArray(savedReport) && savedReport.length > 0
            ? savedReport[0]?.template ===
              savedReportsGroupsTemplates.COMMISSION_PAYOUT
              ? `${selectedAccount?.accountName} - ${Formatter.date(savedReport[0]?.created_at)}`
              : savedReport[0]?.name
            : '',
      },
    },
  ];

  const handleCustomExportOptions = (onExport) => {
    if (exportOptions && Object.keys(exportOptions).length > 0) {
      onExport({ ...exportOptions });
      setExportOptions(null);
    }
  };

  const handleBulkEdit = async (selected, updateData) => {
    const body = {
      saved_reports_ids: selected,
      ...updateData,
    };
    const response = await reportGroupPatcher.mutateAsync(body);
    if (response && response.statusText === 'ok') {
      openSnackbar('Reports updated successfully');
      setRefresh(refresh + 1);
    } else if (response && response.error) {
      openSnackbar(response.error);
    } else {
      openSnackbar('Error updating reports');
    }
  };

  return (
    <>
      <DataView
        refresh={refresh}
        enableAutoRefetch={true}
        dataDesc={dataDesc}
        setSelectedData={setSelectedData}
        // @ts-ignore
        onDelete={(v) => false}
        // @ts-ignore
        onBulkEdit={async (selected, updateData) => {
          await handleBulkEdit(selected, updateData);
        }}
        exportOptions={defaultExportOptions}
        viewOnly
        extraActions={extraActions}
        handleCustomExportOptions={handleCustomExportOptions}
        {...(savedReport &&
        Array.isArray(savedReport) &&
        savedReport.length > 0 &&
        savedReport[0]?.template ===
          savedReportsGroupsTemplates.COMMISSION_PAYOUT
          ? {
              customExport: true,
              customExportCallback: () => setShowModal(true),
            }
          : {})}
        headingOffset={122}
        enablePagination
      />
      <CommissionPayoutExportConfig
        open={showModal}
        setOpen={setShowModal}
        setExportOptions={setExportOptions}
        savedReport={savedReport}
        selectedAccount={selectedAccount}
        selectedData={selectedData}
      />
    </>
  );
};

export default ReportsGroupDetailsView;
