import { Box, Chip, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  AccountCircleOutlined,
  Image,
  PlayArrow,
  SmartToyOutlined,
} from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import remarkGfm from 'remark-gfm';
import { styled } from '@mui/material/styles';
import { materialLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { LoadingButton } from '@mui/lab';
import { useOutletContext } from 'react-router-dom';

import API from '@/services/API';
import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import EmptyState from '@/components/EmptyState';
import NoDataImg from '@/illustrations/gpt-empty.png';

interface AIPesponse {
  role: string;
  parts: {
    text: string;
  }[];
}

interface AIReqestModle {
  text: string;
  inlineData?: {
    mimeType: string;
    data: string;
  };
  type?: string;
  dataUrl?: string;
  name?: string;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const VertexLayout = () => {
  const [req, setReq] = useState<AIReqestModle[]>([]);
  const [question, setQuestion] = useState<string>('');
  const [anwsers, setAnwsers] = useState<string[]>([]);

  const [curFileUrl, setCurFileUrl] = useState<{
    type: string;
    dataUrl: string;
    name: string;
  } | null>();

  const [fileParam, setFileParam] = useState({});

  const [loading, setLoading] = useState<boolean>(false);

  const gptPoster = API.getMutation('gpt', 'POST');

  const { openSnackbar } = useOutletContext<{ openSnackbar: any }>();

  const submit = async () => {
    if (!question) {
      return;
    }
    setLoading(true);

    const params: AIReqestModle = {
      text: question,
      ...fileParam,
    };
    const allReq = [...req, params];
    setReq(allReq);

    const reqBody = allReq.map((item) => {
      const { text, inlineData } = item;
      return {
        text,
        inlineData,
      };
    });
    try {
      const res = await gptPoster.mutateAsync(reqBody);
      if (res.error) {
        openSnackbar(res.error);
        setLoading(false);
        return;
      }
      setAnwsers(res);
      setQuestion('');
      setCurFileUrl(null);
      setFileParam({});
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const onkeyup = async (event) => {
    if (event.keyCode === 13) {
      // Perform your action when Enter is pressed
      await submit();
    }
  };

  const convertFileToBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (files: File[]) => {
    const file = files[0];
    const { type, name } = file;

    const base64Data = (await convertFileToBase64(file)) as string;
    const pureBase64 = base64Data.split(',')[1];
    const dataUrl = (await getUrlOrName(file)) as string;
    const params = {
      inlineData: {
        mimeType: type,
        data: pureBase64,
      },
      type: file.type,
      dataUrl,
      name,
    };
    setFileParam(params);

    setCurFileUrl({
      type: file.type,
      dataUrl,
      name,
    });
  };

  const getUrlOrName = async (file: File) => {
    return new Promise((resolve, reject) => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result);
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
      } else {
        resolve(file.name);
      }
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '95vh',
        display: 'flex',
        flexDirection: 'column',
        padding: '18px',
      }}
    >
      <Box
        sx={{
          height: '50px',
          fontSize: '18px',
          color: '#5f6368',
          lineHeight: 1.75,
        }}
      >
        Fintary AI
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          width: '100%',
          overflow: 'auto',
        }}
      >
        {anwsers.length ? (
          <Box
            sx={{
              flexDirection: 'column',
              width: '1240px',
            }}
          >
            {(anwsers || []).map((res, index) => (
              <Box
                sx={{
                  marginBottom: '40px',
                  // background: '#f6f6f6',
                }}
              >
                <Box
                  sx={{ display: 'flex', gap: '20px', paddingBottom: '20px' }}
                >
                  <Box>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <AccountCircleOutlined />
                    </StyledBadge>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6">{req[index].text}</Typography>
                  </Box>
                </Box>

                {req[index]?.type?.includes('image') && (
                  <Box sx={{ height: '80px' }}>
                    <img src={req[index]?.dataUrl} className="h-20" />
                  </Box>
                )}
                {req[index]?.type && !req[index]?.type?.includes('image') && (
                  <Box>
                    <Chip label={req[index]?.name} />
                  </Box>
                )}

                <Box sx={{ display: 'flex', gap: '20px' }}>
                  <Box sx={{ paddingTop: '20px' }}>
                    <SmartToyOutlined />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    {(JSON.parse(res) as AIPesponse).parts.map((an) => {
                      return (
                        <Markdown
                          children={an.text}
                          rehypePlugins={[remarkGfm]}
                          components={{
                            code({ className, children, ...props }) {
                              const match = /language-(\w+)/.exec(
                                className || ''
                              );
                              return match ? (
                                <SyntaxHighlighter
                                  children={String(children).replace(/\n$/, '')}
                                  language={match[1]}
                                  style={materialLight}
                                  {...props}
                                />
                              ) : (
                                <code className={className} {...props}>
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            <EmptyState
              type="page"
              size="large"
              image={<img src={NoDataImg} />}
              title="Start a conversation"
              description="Model responses will appear here. To start chatting, enter a prompt below."
            />
          </Box>
        )}
      </Box>

      {curFileUrl?.type?.includes('image') && (
        <Box sx={{ height: '80px' }}>
          <img src={curFileUrl?.dataUrl} className="h-20" />
        </Box>
      )}
      {curFileUrl?.type && !curFileUrl?.type?.includes('image') && (
        <Box>
          <Chip label={curFileUrl?.name} />
        </Box>
      )}
      <Box
        sx={{
          height: '80px',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          display: 'flex',
          gap: '8px',
        }}
      >
        <FactoryFileUpload
          buttonStyle
          multiple={false}
          buttonComp={
            <IconButton>
              <Image color="primary" />
            </IconButton>
          }
          onChange={handleFileChange}
        />
        <TextField
          id="prompt"
          label=""
          variant="outlined"
          fullWidth
          value={question}
          onKeyUp={onkeyup}
          // autoComplete="off"
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          placeholder="Enter a prompt here"
        />
        <LoadingButton onClick={submit} loading={loading} variant="outlined">
          <PlayArrow />
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default VertexLayout;
