import { useEffect, useRef, useState } from 'react';

const useStopWatch = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef<any>(null);

  const start = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (!isRunning) {
      setIsRunning(true);
    }
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const pause = () => {
    if (isRunning) {
      setIsRunning(false);
    }
    clearInterval(timerRef?.current);
  };

  const reset = () => {
    clearInterval(timerRef?.current);
    setIsRunning(false);
    setTime(0);
  };

  useEffect(() => {
    return () => clearInterval(timerRef?.current);
  }, []);

  return { totalSeconds: time, start, pause, reset };
};

export default useStopWatch;
