import CloseIcon from '@mui/icons-material/Close';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSetOriginFile, useSetOverrideFile } from 'store/excelStore';

import ProcessUploads from '@/components/UploadModal/processFlow/ProcessUploads';
import API from '@/services/API';
import TimerCount from '@/components/UploadModal/processFlow/TimerCount';

const UpdateProcessData = ({
  open,
  handleClose,
  openSnackbar,
  rowData,
  setRowData,
}) => {
  const [uploading, setUploading] = useState(false);
  const [baseQueryLoading, setBaseQueryLoading] = useState(false); // [mappings, processors
  const processFileRef = useRef();
  const [mappingsAndProcessors, setMappingsAndProcessors] = useState({
    mappings: [],
    processors: [],
  });

  const [actionCount, setActionCount] = useState(0);
  const timerCountRef = useRef(null);

  const queryClient = useQueryClient();

  const setOriginFile = useSetOriginFile();
  const setOverrideFile = useSetOverrideFile();

  const handleCancel = () => {
    handleClose(false);
    setOriginFile(null);
    setOverrideFile(null);
  };

  const { data: mappingsList, isLoading: mappingLoading } = API.getBasicQuery(
    'mappings',
    `company_id=${rowData.company_str_id}&type=${rowData.type}`,
    !!rowData.company_str_id
  );

  const { data: processorsList, isLoading: processorsLoading } =
    API.getBasicQuery('processors');

  useEffect(() => {
    setBaseQueryLoading(processorsLoading || mappingLoading);
    if (mappingsList && processorsList) {
      setMappingsAndProcessors({
        mappings: mappingsList,
        processors: processorsList,
      });
    }
  }, [processorsLoading, mappingLoading]);

  const showErrorMsg = (err = '') => {
    const alert = <Alert severity="error">{err}</Alert>;
    openSnackbar(alert);
  };

  const showSuccessMsg = (msg = '') => {
    const alert = <Alert severity="success">{msg}</Alert>;
    openSnackbar(alert);
  };

  const submit = async () => {
    setUploading(true);
    const res = await processFileRef.current?.submit();
    setUploading(false);
    if (res && !res.error && !res.upload) {
      showSuccessMsg('Import successful');
      queryClient.invalidateQueries();
    } else {
      showErrorMsg(res?.error || res?.upload || 'Import failed');
      setUploading(false);
      return;
    }
    handleCancel();
  };

  return (
    <div>
      <Dialog
        open={open}
        fullScreen
        maxWidth="100%"
        sx={{ background: 'transparent', p: 2 }}
        PaperProps={{ sx: { borderRadius: 2 } }}
      >
        <DialogTitle sx={{ p: 2 }}>
          <header className="flex justify-between items-center">
            <Box className="flex justify-between items-center">
              <CloudSyncIcon />
              <Box className="pl-2">
                <span>Process file</span>
                {baseQueryLoading && (
                  <span className="ml-2 text-sm text-gray-700">
                    Loading companies and processors...
                  </span>
                )}
              </Box>
            </Box>
            <Box
              className="flex group cursor-pointer hover:text-blue-600"
              onClick={handleCancel}
            >
              <CloseIcon className="group-hover:rotate-180 transition-all origin-center" />
            </Box>
          </header>
        </DialogTitle>
        <Divider />

        <DialogContent
          sx={{
            padding: 1,
            backgroundColor: '#fff',
          }}
        >
          <ProcessUploads
            ref={processFileRef}
            rowData={rowData}
            setRowData={setRowData}
            mpData={mappingsAndProcessors}
            actionCount={actionCount}
            setActionCount={setActionCount}
            timerCountRef={timerCountRef}
          />
        </DialogContent>

        <DialogActions sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ flex: 1 }}>
            <TimerCount count={actionCount} ref={timerCountRef} />
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <LoadingButton
              loading={uploading}
              sx={{ width: '100px' }}
              onClick={submit}
              variant="contained"
            >
              Import
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UpdateProcessData.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  openSnackbar: PropTypes.func,
  rowData: PropTypes.object,
  mpData: PropTypes.object,
};

export default UpdateProcessData;
