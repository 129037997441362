import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RotateRight } from '@mui/icons-material';

import ExcelPreview from '@/common/preview/ExcelPreview';
import PDFPreview from '@/common/preview/PdfPreview';
import IMGPreview from '@/common/preview/imgPreview';
import {
  IMG_TYPES,
  PDF_TYPES,
  XLS_CSV_TYPES,
  PreviewType,
  SpresdsheetProps,
} from '@/common/preview/model';

interface FilePreviewProps {
  previewFile?: File;
  previewWith?: number;
  sheet?: string;
}

const FilePreview: FC<FilePreviewProps & SpresdsheetProps> = ({
  spreadsheet,
  setSpreadsheet,
  previewFile,
  previewWith,
  sheet,
}) => {
  const [preview, setPreview] = useState<PreviewType>();
  const [rotate, setRotate] = useState(0);

  const handleRotate = () => {
    setRotate((prev) => (prev + 90) % 360);
  };

  useEffect(() => {
    if (previewFile) {
      let preview = '';
      if (XLS_CSV_TYPES.includes(previewFile.type)) {
        preview = 'excel';
      } else if (PDF_TYPES.includes(previewFile.type)) {
        preview = 'pdf';
      } else if (IMG_TYPES.includes(previewFile.type)) {
        preview = 'img';
      }
      setPreview(preview as PreviewType);
    }
  }, [previewFile]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      {preview === 'excel' && (
        <ExcelPreview
          spreadsheet={spreadsheet}
          setSpreadsheet={setSpreadsheet}
          sheet={sheet}
        />
      )}
      {preview === 'pdf' && (
        <>
          <RotateRight
            onClick={handleRotate}
            color="action"
            sx={{
              position: 'absolute',
              right: 40,
              top: 5,
              zIndex: 100,
              cursor: 'pointer',
              '&:hover': {
                transform: 'rotate(90deg)',
                color: '#2196f3',
                transition: 'all 0.1s ease-in-out',
              },
            }}
          />
          <PDFPreview
            file={previewFile}
            previewWidth={previewWith}
            rotate={rotate}
          />
        </>
      )}
      {preview === 'img' && <IMGPreview file={previewFile} />}
    </Box>
  );
};

export default FilePreview;
