import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FC } from 'react';

type EmptyValuesSelectProps = {
  data: any;
  setter: any;
  field: any;
};

const EmptyValuesSelect: FC<EmptyValuesSelectProps> = ({
  data,
  field,
  setter,
}) => {
  const fieldKeys = data[`${field.id.replace('_unique_empty', '')}`] ?? [];
  const fieldUniqueEmptyKeys = data[field.id] ?? [];

  return (
    <Box sx={{ mt: -1, mb: 1 }}>
      <Typography variant="caption" sx={{ ml: 1.5 }}>
        {field.label}
      </Typography>
      <Box
        sx={{
          p: 0.25,
          borderStyle: 'solid',
          borderColor: 'silver',
          borderWidth: 1,
          borderRadius: 8,
          width: '100%',
          backgroundColor: '#2196f308',
        }}
      >
        {fieldKeys.map((fieldKey) => (
          <Box
            key={`${field.id}-${fieldKey}`}
            sx={{
              m: 0.5,
              pl: 1,
              borderStyle: 'solid',
              borderColor: 'silver',
              borderWidth: 1,
              borderRadius: 4,
              display: 'inline-block',
              backgroundColor: '#2196f30a',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={fieldUniqueEmptyKeys.includes(fieldKey) ?? false}
                  onChange={(e) => {
                    console.log('e', e);
                    const newUniqueEmpties = data[field.id].filter(
                      (item) => item !== fieldKey
                    );
                    if (e.target.checked) newUniqueEmpties.push(fieldKey);
                    setter({
                      ...data,
                      [field.id]: newUniqueEmpties,
                    });
                  }}
                  sx={{ py: 0.5 }}
                />
              }
              label={fieldKey}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EmptyValuesSelect;
