import { create } from 'zustand';

type FEVersioningObj = {
  stateCode: string | null;
  message: string | null;
};
interface FEVersioningStore {
  feVersion: FEVersioningObj | null;
  setFeVersion: (feVersion: FEVersioningObj | null) => void;
}

const useFeVersionStore = create<FEVersioningStore>((set) => ({
  feVersion: null,
  setFeVersion: (feVersion) => set({ feVersion: feVersion }),
}));

export default useFeVersionStore;
