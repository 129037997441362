import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useContext, useEffect, useState } from 'react';
import { LoadingContext } from 'contexts/LoadingContext';

import Spreadsheet from '@/services/Spreadsheet';
import { getFileData } from '@/services/helpers';
import Storage from '@/services/Storage';
import FilePreview from '@/common/preview';
import { XLS_CSV_TYPES, SpresdsheetMo } from '@/common/preview/model';
import API from '@/services/API';
import usePreviewParams from '@/contexts/usePreviewParams';
import { getFilenameFromPath } from '../tools';
import { DocumentPreviewKeys } from '@/types';

interface FileDialogPreviewProps {
  showPreview: boolean;
  setShowPreview: (val: boolean) => void;
  fileId?: string;
  fileUrl?: string;
}

const FileDialogPreview: FC<FileDialogPreviewProps> = ({
  fileId,
  showPreview,
  setShowPreview,
  fileUrl,
}) => {
  const [previewFile, setPreviewFile] = useState<any>();

  const [spreadsheet, setSpreadsheet] = useState<SpresdsheetMo>();

  const { setLoadingConfig } = useContext(LoadingContext);
  const { previewType } = usePreviewParams();

  const { data: documentData, isLoading } = API.getBasicQuery(
    'documents',
    `id=${fileId}`,
    !!fileId
  ) as { data: { data: any[]; count: number }; isLoading: boolean };

  // get the file based on rowdata
  const readFile = async (url) => {
    if (!url) return null;
    setLoadingConfig({
      loading: true,
      message: 'Loading document...',
    });
    const fileName = url.split('/').pop();
    const fullUrl = await Storage.getFileUrl(url);
    const file = await getFileData(fullUrl, fileName);
    // set preview type
    setPreviewFile(file);
    setLoadingConfig({ loading: false });
  };

  useEffect(() => {
    setLoadingConfig({
      loading: isLoading,
      message: 'Loading document...',
    });
  }, [isLoading]);

  useEffect(() => {
    let _url = fileUrl;
    if (documentData) {
      const doc = documentData.data[0];
      _url =
        previewType === DocumentPreviewKeys.ORIGINAL
          ? doc?.file_path
          : doc?.override_file_path || doc?.file_path;
    }
    if (!_url || isLoading) return;
    try {
      readFile(_url);
    } catch (error) {
      console.error(error);
    }
  }, [documentData, isLoading, fileUrl]);

  useEffect(() => {
    // excel, csv need to load the raw data
    const setExcelData = async () => {
      if (previewFile && XLS_CSV_TYPES.includes(previewFile?.type)) {
        const res = (await Spreadsheet.loadSpreadsheet(
          previewFile
        )) as unknown as SpresdsheetMo;
        setSpreadsheet(res);
      }
    };
    setExcelData();
  }, [previewFile]);

  /**
   * Download file: use the previewFile
   */
  const downloadFile = () => {
    if (!previewFile) return;
    const url = window.URL.createObjectURL(previewFile);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = getFilenameFromPath(previewFile.name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Dialog
      open={showPreview}
      onClose={() => {
        setShowPreview(false);
      }}
      fullScreen
      sx={{ p: 2 }}
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Box className="flex justify-between items-center">
          <Box>Document preview</Box>
          <Box
            className="group cursor-pointer hover:text-blue-600"
            onClick={() => {
              setShowPreview(false);
            }}
          >
            <CloseIcon className="group-hover:rotate-180 transition-all origin-center" />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <FilePreview
          previewFile={previewFile}
          previewWith={window.innerWidth * 0.8}
          spreadsheet={spreadsheet}
          setSpreadsheet={setSpreadsheet}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={downloadFile}>Download</Button>
        <Button
          onClick={() => {
            setShowPreview(false);
          }}
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileDialogPreview;
