import { useState } from 'react';
import { Chip, Tooltip } from '@mui/material';
import { Chat, Login } from '@mui/icons-material';
import dayjs from 'dayjs';

import API from '@/services/API';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { CommentDrawer } from '@/common';

const dataSpec = {
  label: 'Accounts',
  table: 'admin/accounts',
  fields: {
    str_id: {
      label: 'ID',
      copyable: true,
      id: 'str_id',
      enabled: true,
    },
    name: {
      label: 'Name',
      enabled: true,
      visible: true,
    },
    created_at: {
      label: 'Last reconciliation',
      enabled: true,
      visible: true,
      formatter: (o, row) =>
        row?.reconciliation_result && (
          <Tooltip
            title={dayjs(row?.reconciliation_result?.created_at)
              .toDate()
              .toLocaleDateString()}
            arrow
            placement="right"
          >
            <span>
              {`${Math.round(dayjs().diff(dayjs(row?.reconciliation_result?.created_at), 'days'))} day(s) ago`}
            </span>
          </Tooltip>
        ),
      disableSort: true,
    },
    reconciliation_result: {
      label: 'Matched',
      enabled: true,
      visible: true,
      formatter: (o) =>
        `${Math.round((o?.statementsReconciled / o?.statementsTotal) * 100)}%`,
      description:
        '% of commission statement records matched to a policy record',
      disableSort: true,
    },
    account_user_roles: {
      label: 'Log in as user',
      enabled: true,
      visible: true,
      formatter: (fieldData, rowData) => {
        return (
          <Chip
            key={fieldData.id}
            label={`${fieldData.user.email} (${fieldData.role.name})`}
            onClick={() => {
              localStorage.clear();
              localStorage.setItem(
                'customLoginUser',
                JSON.stringify(fieldData.user)
              );
              localStorage.setItem(
                'selectedAccount',
                JSON.stringify({
                  accountId: rowData.str_id,
                  accountName: rowData.name,
                  accountMode: rowData.mode,
                  whiteLabelMode: rowData.white_label_mode,
                  logoUrl: rowData.logo_url,
                  state: rowData.state,
                })
              );
              // Force to refresh all active tabs
              localStorage.setItem('loggedOut', Date.now().toString());
              window.location.pathname = '/';
            }}
            clickable
            icon={<Login />}
            sx={{ m: 0.25 }}
          />
        );
      },
      disableSort: true,
    },
    type: {
      label: 'Type',
      enabled: true,
      visible: true,
      formatter: (o) => {
        const mapping = {
          fintary: 'Test',
          life: 'Life',
          health: 'Health',
          'p&c': 'P&C',
        };
        return mapping[o] ?? o;
      },
    },
  },
};

const LoginAsView = () => {
  const [showComments, setShowComments] = useState(false);
  const [current, setCurrent] = useState(null);

  const actions = [
    {
      label: 'Chat',
      type: 'iconButton',
      icon: <Chat />,
      onClick: (row) => {
        setShowComments(true);
        setCurrent(row);
      },
    },
  ];

  return (
    <>
      <EnhancedDataView
        dataSpec={dataSpec}
        hideAdd
        hideSelectedCount
        enableMultiSelect={false}
        enableEdit={false}
        actions={actions}
        actionsEnabled={() => true}
      />

      {showComments && (
        <CommentDrawer
          open={showComments}
          setOpen={setShowComments}
          rowData={current}
          type="account"
        />
      )}
    </>
  );
};

export default LoginAsView;
