import { Box, InputAdornment, TextField, Typography } from '@mui/material';

import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';

const AgentCommissionSplitConfig = ({
  title,
  data,
  contacts,
  split,
  setter,
  setterKey,
  dynamicSelects,
}) => {
  const { selectedAccount } = useAccountStore();

  const totalPct = Object.values(split ?? {}).reduce(
    (acc, cur) =>
      +acc +
      (Number.isNaN(Number.parseFloat(cur)) ? 0 : Number.parseFloat(cur)),
    0
  );
  return (
    <Box sx={{ mb: 1 }}>
      <Typography>{title}</Typography>
      {Array.isArray(contacts) && contacts.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {contacts.map((contact, i) => (
              <TextField
                key={contact}
                value={split?.[contact] ?? 0}
                onChange={(e) => {
                  setter({
                    ...data,
                    [setterKey]: {
                      ...(split ?? {}),
                      [contact]: e.target.value,
                    },
                  });
                }}
                label={Formatter.contact(
                  dynamicSelects?.find((val) => val.str_id === contact) ?? {},
                  { account_id: selectedAccount?.accountId }
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                sx={{ mt: 1, mr: 0.5 }}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Typography
              sx={totalPct === 100 ? {} : { color: 'red' }}
            >{`Total: ${totalPct}%`}</Typography>
            {totalPct !== 100 && (
              <Typography variant="caption">
                Agents split should add up to 100
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Typography variant="body2" sx={{ ml: 1 }}>
          No agents attached to this policy
        </Typography>
      )}
    </Box>
  );
};

export default AgentCommissionSplitConfig;
