import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Typography,
  Chip,
  DialogActions,
  Button,
  Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Formatter from 'common/Formatter';

import DateRangeManager from '@/components/molecules/DateRangeManager';
import { BasicDialog } from '@/common';
import { DateRangesTypes } from '@/types';

interface SelectedCriteriaProps {
  selectedCriteria: any[];
}

const SelectedCriteria: React.FC<SelectedCriteriaProps> = ({
  selectedCriteria,
}) => {
  return (
    <>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        Selected criteria
      </Typography>
      {selectedCriteria.map((criteria, index) => (
        <Chip
          key={`${index}-criterion`}
          label={Formatter.compGridCriterion(criteria)}
          sx={{ mr: 0.5, mb: 0.5 }}
        />
      ))}
    </>
  );
};

interface CompGridBulkActionsProps {
  openBulkActionsDialog: boolean;
  setOpenBulkActionsDialog: (boolean) => void;
  selectedCriteria: any[];
  selectedLevels: string[];
  selectedDateRanges: any;
  setSelectedDateRanges: (value: any) => void;
  loading: boolean;
  bulkEditRates: () => Promise<void>;
  bulkCopyRates: () => Promise<void>;
  bulkDeleteRates: () => Promise<void>;
  showConfirmDeleteDialog: boolean;
  setShowConfirmDeleteDialog: (value: boolean) => void;
}

const CompGridBulkActions: React.FC<CompGridBulkActionsProps> = ({
  openBulkActionsDialog,
  setOpenBulkActionsDialog,
  selectedCriteria,
  selectedLevels,
  selectedDateRanges,
  setSelectedDateRanges,
  loading,
  bulkEditRates,
  bulkCopyRates,
  bulkDeleteRates,
  showConfirmDeleteDialog,
  setShowConfirmDeleteDialog,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [activeDialogTab, setActiveDialogTab] = useState(0);

  return (
    <Dialog
      open={openBulkActionsDialog}
      onClose={() => {
        setOpenBulkActionsDialog(false);
        setSelectedDateRanges([]);
      }}
      fullWidth
    >
      <DialogTitle>Bulk actions</DialogTitle>
      <DialogContent>
        <Tabs
          value={activeDialogTab}
          onChange={(e, v) => setActiveDialogTab(v)}
        >
          <Tab label="Edit" />
          <Tab label="Copy" />
          <Tab label="Delete" />
        </Tabs>
        {activeDialogTab === 0 && (
          <Box sx={{ mt: 1 }}>
            <Box sx={{ mt: 1, mb: 1 }}>
              {Array.isArray(selectedCriteria) &&
                selectedCriteria.length > 0 && (
                  <SelectedCriteria selectedCriteria={selectedCriteria} />
                )}
              {selectedLevels.length > 0 ? (
                <>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Editing rates for levels
                  </Typography>
                  {selectedLevels.map((level) => (
                    <Chip sx={{ mr: 0.5 }} key={level} label={level} />
                  ))}
                </>
              ) : (
                <Typography variant="body2">No rates selected</Typography>
              )}
            </Box>
            <DateRangeManager
              key="comp_grid_criteria_date_ranges"
              row={selectedDateRanges}
              setter={setSelectedDateRanges}
              field={{ id: 'date_ranges', label: 'Rates date ranges' }}
              type={DateRangesTypes.ANY}
            />
            <DialogActions sx={{ p: 0 }}>
              <Button
                sx={{ ml: 1 }}
                onClick={() => {
                  setOpenBulkActionsDialog(false);
                  setSelectedDateRanges([]);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ ml: 1 }}
                variant="contained"
                onClick={async () => {
                  await bulkEditRates();
                }}
                loading={loading}
                disabled={
                  selectedLevels?.length === 0 ||
                  selectedDateRanges?.date_ranges?.length === 0
                }
              >
                Bulk edit
              </LoadingButton>
            </DialogActions>
          </Box>
        )}
        {activeDialogTab === 1 && (
          <Box sx={{ mt: 1 }}>
            <Box sx={{ mt: 1, mb: 1 }}>
              {Array.isArray(selectedCriteria) &&
                selectedCriteria.length > 0 && (
                  <SelectedCriteria selectedCriteria={selectedCriteria} />
                )}
              {selectedLevels.length > 0 ? (
                <>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Copying rates for levels
                  </Typography>
                  {selectedLevels.map((level) => (
                    <Chip sx={{ mr: 0.5 }} key={level} label={level} />
                  ))}
                </>
              ) : (
                <Typography variant="body2">No rates selected</Typography>
              )}
            </Box>
            <DateRangeManager
              key="comp_grid_criteria_date_ranges"
              row={selectedDateRanges}
              setter={setSelectedDateRanges}
              field={{ id: 'date_ranges', label: 'New rates date ranges' }}
              type={DateRangesTypes.ANY}
            />
            <DialogActions sx={{ p: 0 }}>
              <Button
                sx={{ ml: 1 }}
                onClick={() => {
                  setOpenBulkActionsDialog(false);
                  setSelectedDateRanges([]);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ ml: 1 }}
                variant="contained"
                onClick={() => {
                  setShowConfirmDialog(true);
                }}
                loading={loading}
                disabled={
                  selectedLevels?.length === 0 ||
                  selectedDateRanges?.date_ranges?.length === 0
                }
              >
                Bulk copy
              </LoadingButton>
              {showConfirmDialog && (
                <BasicDialog
                  open={showConfirmDialog}
                  title="Bulk copy rates"
                  bodyComponent={
                    <Alert severity="warning">
                      Are you sure you want to bulk copy these rates?
                    </Alert>
                  }
                  onClose={async (isOk) => {
                    setShowConfirmDialog(false);

                    if (isOk) {
                      await bulkCopyRates();
                    }
                  }}
                />
              )}
            </DialogActions>
          </Box>
        )}
        {activeDialogTab === 2 && (
          <Box sx={{ mt: 1 }}>
            <Box sx={{ mt: 1, mb: 1 }}>
              {Array.isArray(selectedCriteria) &&
                selectedCriteria.length > 0 && (
                  <>
                    <SelectedCriteria selectedCriteria={selectedCriteria} />
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Selected date range
                    </Typography>
                    {selectedCriteria.map((criteria: any, index) => (
                      <Chip
                        key={`${index}-criterion`}
                        label={Formatter.compGridCriterion(criteria)}
                        sx={{ mr: 0.5, mb: 0.5 }}
                      />
                    ))}
                  </>
                )}
              {selectedLevels.length > 0 ? (
                <>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Deleting rates for levels
                  </Typography>
                  {selectedLevels.map((level) => (
                    <Chip sx={{ mr: 0.5 }} key={level} label={level} />
                  ))}
                </>
              ) : (
                <Typography variant="body2">No rates selected</Typography>
              )}
            </Box>
            <DialogActions sx={{ p: 0 }}>
              <Button
                sx={{ ml: 1 }}
                onClick={() => {
                  setOpenBulkActionsDialog(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ ml: 1 }}
                variant="contained"
                onClick={() => {
                  setShowConfirmDeleteDialog(true);
                }}
                loading={loading}
                disabled={selectedLevels?.length === 0}
              >
                Bulk delete
              </LoadingButton>
              {showConfirmDeleteDialog && (
                <BasicDialog
                  open={showConfirmDeleteDialog}
                  title="Bulk delete rates"
                  bodyComponent={
                    <Alert severity="warning">
                      Are you sure you want to delete these rates?
                    </Alert>
                  }
                  onClose={async (isOk) => {
                    setShowConfirmDeleteDialog(false);
                    if (isOk) {
                      await bulkDeleteRates();
                    }
                  }}
                />
              )}
            </DialogActions>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CompGridBulkActions;
