import { Google } from '@mui/icons-material';

const authProviders = [
  // {
  //   id: "apple.com",
  //   color: "#000000",
  //   icon: <AppleIcon />,
  //   name: "Apple",
  // },
  // {
  //   id: "facebook.com",
  //   color: "#3c5a99",
  //   icon: <FacebookIcon />,
  //   name: "Facebook",
  // },
  {
    id: 'google.com',
    color: '#4285F4',
    icon: <Google />,
    name: 'Google',
  },
  // {
  //   id: "microsoft.com",
  //   color: "#0078d7",
  //   icon: <MicrosoftIcon />,
  //   name: "Microsoft",
  // },
];

export default authProviders;
