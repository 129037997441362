import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

const Field = ({ name, val }) => (
  <Box>
    <Typography variant="caption" color="text.secondary">
      {name}
    </Typography>
    <Typography sx={{ mt: '-4px' }}>{val || 'ㅤ'}</Typography>
  </Box>
);

const RowCard = ({ data, fields = [], colSize = 4, style = {} }) => {
  return data ? (
    <Card
      variant="outlined"
      sx={{ mb: 1, bgcolor: style.bgcolor ? style.bgcolor : 'inherit' }}
      key={data.id}
    >
      <CardContent sx={{ pt: 1, pb: '8px !important' }}>
        <Grid container>
          {fields.map((f) => (
            <Grid item xs={colSize} key={f.label}>
              <Field
                name={f.label}
                val={
                  f.field3 ? data?.[f.id]?.[f.field2]?.[f.field3] : data?.[f.id]
                }
              />
            </Grid>
          ))}
        </Grid>
        {/* Commissions Months... Actions */}
      </CardContent>
    </Card>
  ) : (
    <Box sx={{ color: 'darkgray' }}>No data selected</Box>
  );
};

export default RowCard;
