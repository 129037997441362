import { CompGridRateFields } from 'common/globalTypes';
import CommonFormatter from 'common/Formatter';

import DataView from '@/components/DataView';
import Formatter from '@/services/Formatter';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';

const View = () => {
  const { userRole } = useRoleStore();

  const dataDesc = {
    label: 'Comp grids',
    table: 'comp-grids',
    editable: true,
    copyable: true,
    bulkAdd: true,
    fields: [
      {
        id: 'company_id',
        label: 'Carriers',
        type: 'dynamic-select',
        table: 'companies',
        field: 'id',
        formatter: Formatter.getDynamicSelectFormatter(
          (o) => o?.company_name ?? '(Not found)'
        ),
        optionFormatter: (o) => o.company_name,
        optionValuer: (o) => o.id,
        required: true,
        bulkAddSelect: true,
      },
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'rate_fields',
        label: 'Rate fields',
        type: 'select',
        multiple: true,
        options: [
          {
            label: 'Carrier rate',
            id: CompGridRateFields.CARRIER_RATE,
          },
          {
            label: 'House rate',
            id: CompGridRateFields.HOUSE_RATE,
          },
          {
            label: 'Total rate',
            id: CompGridRateFields.TOTAL_RATE,
          },
        ],
        formatter: CommonFormatter.compGridRatesFields,
      },
      {
        id: 'notes',
        label: 'Notes',
      },
      {
        id: 'created_at',
        label: 'Created',
        formatter: Formatter.dateTime,
        condition: (val) => val.id,
      },
      {
        id: 'updated_at',
        label: 'Last updated',
        formatter: Formatter.dateTime,
        condition: (val) => val.id,
      },
    ],
  };

  const rolesWithEditAccess = [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST];

  return (
    <DataView
      dataDesc={dataDesc}
      readOnly={!userRole || !rolesWithEditAccess.includes(userRole)}
      viewOnly={!userRole || !rolesWithEditAccess.includes(userRole)}
    />
  );
};

export default View;
