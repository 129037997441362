import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from '@mui/material';

const METHOD_LABELS = {
  extractTable: 'Extract Table',
  documentAI: 'Google Document AI',
  adobeExtract: 'Adobe Extract',
  GPT: 'Vertex AI',
  gemini: 'Gemini',
};

const NEW_EXTRACTION_OPTIONS = [
  {
    value: 'documentAI',
    label: 'Google Document AI',
  },
  {
    value: 'extractTable',
    label: 'Extract Table',
  },
  {
    value: 'gemini',
    label: 'Gemini',
  },
  {
    value: 'adobeExtract',
    label: 'Adobe PDF Extract (temporarily unavailable)',
    disabled: true,
  },
];

const ExtractMethod = ({ showExtract, onClose, onConfirm, uploadedRow }) => {
  const [selectedMethod, setSelectedMethod] = useState('extractTable');

  // Prepend existing extractions to list of options.
  // Hack: Using ::str_id::id in value to denote that we want to load an existing extraction with str_id
  const previousExtractions = useMemo(() => {
    const prevExtractions = (uploadedRow.extractions ?? [])
      .filter((item) => {
        if (item.method === 'extractTable') {
          return item.result;
        } else {
          return true;
        }
      })
      .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))
      .map((e) => ({
        label: (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box>{METHOD_LABELS[e.method]}</Box>
            <Box>{new Date(e.created_at).toLocaleString()}</Box>
          </Box>
        ),
        value: `${e.method}::${e.str_id}::${e.id}`,
      }));
    return prevExtractions.length
      ? [
          {
            label: (
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                Use existing extraction
              </Box>
            ),
            value: 'divider1',
            disabled: true,
            sx: { textAlign: 'center' },
          },
          ...prevExtractions,
          {
            label: (
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                Create new extraction
              </Box>
            ),
            value: 'divider2',
            disabled: true,
          },
          ...NEW_EXTRACTION_OPTIONS,
        ]
      : NEW_EXTRACTION_OPTIONS;
  }, [uploadedRow]);

  useEffect(() => {
    if (uploadedRow.extractions?.length > 0) {
      const mostRecent =
        uploadedRow.extractions.find((item) => item.status === 'extracted') ||
        uploadedRow.extractions[0];
      setSelectedMethod(
        `${mostRecent.method}::${mostRecent.str_id}::${mostRecent.id}`
      );
    }
  }, [uploadedRow.extractions]);

  const handleSelectionChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleSubmit = () => {
    onConfirm(selectedMethod);
  };

  return (
    <Dialog open={showExtract} onClose={onClose} fullWidth>
      <DialogTitle>Document data extraction method</DialogTitle>
      <DialogContent>
        <Box>
          <Select
            fullWidth
            value={selectedMethod}
            onChange={handleSelectionChange}
          >
            {previousExtractions.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                disabled={item.disabled}
                sx={item.sx}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={!selectedMethod || !previousExtractions.length}
          variant="contained"
        >
          {NEW_EXTRACTION_OPTIONS.map((e) => e.value).includes(selectedMethod)
            ? 'Extract data'
            : 'View data'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExtractMethod.propTypes = {
  showExtract: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  uploadedRow: PropTypes.object,
};

export default ExtractMethod;
