import { useContext, useEffect, useState } from 'react';
import { useOriginalFile, useOverrideFile } from 'store/excelStore';

import Spreadsheet from '@/services/Spreadsheet';
import { arrayBufferToBase64, readFile } from '@/services/helpers';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import { FileDataModel, ProcessMethodE } from '../process.d';
import { PDF_IMG_TYPES, XLS_CSV_TYPES } from '@/common/preview/model';

const useFileToData = (rowData, processForm?) => {
  const originalFile = useOriginalFile();
  const overrideFile = useOverrideFile();
  const [file, setFile] = useState<File | null>(null);

  const [fileData, setFileData] = useState<FileDataModel>();
  const [curExtractionId, setCurExtractionId] = useState();
  const { setLoadingConfig, loadingConfig } = useContext(LoadingContext);

  const documentAIPoster = API.getMutation('documents/docAI', 'POST');
  const documentsExtractDataPoster = API.getMutation(
    'documents/extractData',
    'POST'
  );
  const adobeExtractDataPoster = API.getMutation(
    'documents/adobeExtract',
    'POST'
  );

  const vertexPoster = API.getMutation('gpt/doc', 'POST');
  const { data: curExtractionData, isLoading: curExtractionLoading } =
    API.getBasicQuery(`extractions/${curExtractionId}`, '', !!curExtractionId);

  useEffect(() => {
    setFile(overrideFile || originalFile);
  }, [overrideFile, originalFile]);

  useEffect(() => {
    if (rowData.method) {
      const latestGeminiExtract = rowData.extractions.find(
        (item) => item.method === ProcessMethodE.Gemini
      );
      // Check rowdata method is gemini and prompt text is exist
      const [method, str_id] = rowData.method.split('::');

      if (file && processForm.method === ProcessMethodE.Gemini) {
        if (method === ProcessMethodE.Gemini) {
          handleFileWithGPT(file, str_id);
        } else if (latestGeminiExtract) {
          handleFileWithGPT(file, latestGeminiExtract.str_id);
        } else {
          handleFileWithGPT(file, '');
        }
      }
    }
  }, [processForm.promptText, file, processForm.method]);

  useEffect(() => {
    if (file && XLS_CSV_TYPES.includes(file.type)) {
      saveSpreadsheet(file);
    }
  }, [file]);

  useEffect(() => {
    if (rowData.method) {
      const [_method, _extractionStrId, extractionId] =
        rowData.method.split('::');
      if (extractionId) {
        setCurExtractionId(extractionId);
      }
    }
  }, [rowData.method]);

  useEffect(() => {
    if (curExtractionLoading) return;
    const [method, extractionStrId] = rowData.method.split('::');
    if (file) {
      switch (method) {
        case 'documentAI':
          saveImgOrPdfByAI(file, extractionStrId);
          break;
        case 'extractTable':
          saveImgOrPdfByExtractTable(file, extractionStrId);
          break;
        case 'adobeExtract':
          savePdfByAdobe(file, extractionStrId);
          break;
        default:
          break;
      }
    }
  }, [curExtractionData, curExtractionLoading, file]);

  const handleFileWithGPT = async (file: File, extractionStrId) => {
    if (loadingConfig.loading) return;
    setLoadingConfig({
      loading: true,
      message: 'Gemini is processing...',
    });
    const resp = await vertexPoster.mutateAsync({
      url: rowData.url,
      type: file.type,
      document_id: rowData.id,
      prompt: processForm.promptText,
      force_run: !extractionStrId,
      extract_str_id: extractionStrId,
    });
    try {
      let res: any[] = [];
      resp.data.forEach((objStr) => {
        const target = JSON.parse(objStr);
        const list = target.parts.map((item) => {
          return JSON.parse(item.text);
        }) as any[];
        res = res.concat(...list);
      });
      setLoadingConfig({
        loading: false,
      });
      setFileData({
        type: 'gemini',
        data: res,
        extraction: resp.extraction,
        error: '',
      });
    } catch (error) {
      setLoadingConfig({
        loading: false,
      });
      const target = JSON.parse(resp?.data[0]);
      if (target) {
        setFileData({
          type: 'InvalidJSON',
          data: target.parts[0].text,
          extraction: resp?.extraction,
          error: '',
        });
      }
    }
  };

  const saveSpreadsheet = async (file) => {
    const res = await Spreadsheet.loadSpreadsheet(file);
    if (res) {
      setFileData({
        type: 'spreadsheet',
        data: res,
        error: '',
      });
    }
  };

  const saveImgOrPdfByAI = async (_file, extractionStrId) => {
    let tableData;
    if (!curExtractionData && !extractionStrId) {
      const fileContent = arrayBufferToBase64(await readFile(_file));
      const data = {
        document_id: rowData.id, // if exist. docAI will use this id to update the extraction data, if not exist, docAI will create a new extraction data
        fileName: _file.name,
        fileType: _file.type,
        fileContent,
        extract_str_id: extractionStrId,
      } as any;
      setLoadingConfig({
        loading: true,
        message: 'Processing document...',
        allowClose: true,
      });

      const resJson = await documentAIPoster.mutateAsync(data);
      setLoadingConfig({
        loading: false,
        message: '',
      });
      if (resJson.error) {
        setFileData({
          type: 'documentAI',
          data: null,
          error: resJson.error,
        });
        return;
      }
      tableData = resJson.documentProcessed.table;
    } else if (curExtractionData) {
      const outputData = JSON.parse(curExtractionData.output);
      tableData = outputData;
    }

    if (tableData) {
      setFileData({
        type: 'documentAI',
        data: tableData,
        error: '',
      });
    }
  };

  const savePdfByAdobe = async (_file, extractionStrId) => {
    const reextract = !extractionStrId;
    try {
      const extractionOption = rowData.method.split('::');
      const extractionId =
        extractionOption.length > 1 ? extractionOption.pop() : '';

      if (extractionId) {
        const res = [JSON.parse(curExtractionData.output)];
        setFileData({
          type: 'adobeExtract',
          data: res,
          error: '',
        });
        return;
      } else {
        const param = {
          url: rowData.url,
          filename: _file.name,
          document_id: rowData.id,
          update: reextract,
          extractionId,
        } as any;
        setLoadingConfig({
          loading: true,
          message: 'Processing document...',
          allowClose: true,
        });
        const resJson = await adobeExtractDataPoster.mutateAsync(param);
        setLoadingConfig({
          loading: false,
        });
        if (resJson.error) {
          setFileData({
            type: 'adobeExtract',
            data: null,
            error: resJson.error,
          });
          return;
        }
        const res = resJson;
        setFileData({
          type: 'adobeExtract',
          data: [res.data],
          error: '',
        });
      }
    } catch (error) {
      setLoadingConfig({
        loading: false,
      });
      setFileData({
        type: 'adobeExtract',
        data: null,
        error: `Error: ${(error as any).message}`,
      });
    }
  };

  const saveImgOrPdfByExtractTable = async (_file, extractionStrId) => {
    const reextract = !extractionStrId;
    try {
      let res;
      if (rowData.extractions.length > 0 && !reextract) {
        res = curExtractionData.output;
      } else {
        const extractionOption = rowData.method.split('::');
        const extractionId =
          extractionOption.length > 1 ? extractionOption.pop() : '';
        const param = {
          url: rowData.url,
          filename: _file.name,
          document_id: rowData.id, // str_id?
          extract_job_id: rowData.extract_job_id,
          update: reextract,
          extractionId,
        } as any;
        setLoadingConfig({
          loading: true,
          message: 'Processing document...',
          allowClose: true,
        });
        const resJson = await documentsExtractDataPoster.mutateAsync(param);
        setLoadingConfig({
          loading: false,
        });
        if (resJson.error) {
          setFileData({
            type: 'extractTable',
            data: null,
            error: resJson.error,
          });
          return;
        }
        res = JSON.stringify(resJson);
      }
      setFileData({
        type: 'extractTable',
        data: res,
        error: '',
      });
    } catch (error) {
      setLoadingConfig({
        loading: false,
      });
      setFileData({
        type: 'extractTable',
        data: null,
        error: `Error: ${(error as any).message}`,
      });
    }
  };

  return {
    setFileData,
    fileData,
    file,
    setFile,
  };
};

export default useFileToData;
