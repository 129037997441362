import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Formatter from 'common/Formatter';
import { nanoid } from 'nanoid';
import React from 'react';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import { useAccountStore } from '@/store';

type ParentsHierarchyAddProps = {
  data: any;
  setter: any;
  field: any;
  dynamicSelects: any;
};

interface dynamicSelectContacts {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  str_id: string;
}

const applyFunc = (func, arg) => (typeof func === 'function' ? func(arg) : arg);

const ParentsHierarchyAdd: React.FC<ParentsHierarchyAddProps> = ({
  data,
  field,
  setter,
  dynamicSelects,
}) => {
  const [query, setQuery] = React.useState('');
  const { selectedAccount } = useAccountStore();

  const parentsHierarchy = data?.parent_relationships ?? [];

  return (
    <Box>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            pl: 1,
            pt: 0.5,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {parentsHierarchy.map((parent, index) => (
              <Box
                key={`${parent.id}-${index}`}
                sx={{
                  m: 0.5,
                  p: 1,
                  borderStyle: 'solid',
                  borderColor: 'silver',
                  borderWidth: 1,
                  borderRadius: 4,
                  display: 'inline-block',
                  width: 180,
                  backgroundColor: '#2196f30a',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  sx={{ ml: 0.5 }}
                >
                  <Typography variant="body2">
                    {Formatter.contact(parent.parent, {
                      account_id: selectedAccount?.accountId,
                    })}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setter({
                        ...data,
                        parent_relationships: data.parent_relationships.filter(
                          (item) => item.id !== parent.id
                        ),
                      });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box>
                  <BasicDatePicker
                    label="Start date"
                    value={parent.start_date}
                    setValue={(e) => {
                      setter({
                        ...data,
                        parent_relationships: data.parent_relationships.map(
                          (item) =>
                            item.id === parent.id
                              ? { ...item, start_date: e }
                              : item
                        ),
                      });
                    }}
                    sx={{ my: 0.5, width: 160 }}
                  />
                </Box>
                <Box>
                  <BasicDatePicker
                    label="End date"
                    value={parent.end_date}
                    setValue={(e) => {
                      setter({
                        ...data,
                        parent_relationships: data.parent_relationships.map(
                          (item) =>
                            item.id === parent.id
                              ? { ...item, end_date: e }
                              : item
                        ),
                      });
                    }}
                    sx={{ my: 0.5, width: 160 }}
                  />
                </Box>
                <Box>
                  <TextField
                    label="Split percentage"
                    value={parent.split_percentage ?? ''}
                    onChange={(e) => {
                      setter({
                        ...data,
                        parent_relationships: data.parent_relationships.map(
                          (item) =>
                            item.id === parent.id
                              ? { ...item, split_percentage: e.target.value }
                              : item
                        ),
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ ml: 0 }}>
                          %
                        </InputAdornment>
                      ),
                    }}
                    sx={{ my: 0.5, width: 160 }}
                  />
                </Box>
              </Box>
            ))}
            <FormControl key={field.id} sx={{ m: 0.5, width: 145 }}>
              <InputLabel id={`${field.id}-labelAdd`}>Add</InputLabel>
              <Select
                labelId={`${field.id}-label`}
                id={field.id}
                label="Add"
                value={''}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const newContact = e.target
                    .value as unknown as dynamicSelectContacts;
                  const newId = newContact?.id;
                  if (newId) {
                    setter({
                      ...data,
                      parent_relationships: [
                        ...(data.parent_relationships ?? []),
                        {
                          parent: dynamicSelects.find(
                            (item) => item.id === newId
                          ),
                          id: nanoid(),
                          start_date: null,
                          end_date: null,
                        },
                      ],
                    });
                  }
                }}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#333',
                  },
                  width: '50%',
                }}
              >
                <Box
                  sx={{
                    mb: 0.5,
                    mx: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <TextField
                    sx={{ flex: 1 }}
                    label="Search"
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                </Box>
                {field.nullable && (
                  <MenuItem value={''} key="null">
                    &nbsp;
                  </MenuItem>
                )}
                {dynamicSelects
                  ?.filter((item) =>
                    (
                      (item?.first_name?.toLowerCase() ?? '') +
                      (item?.last_name?.toLowerCase() ?? '')
                    ).includes(query.toLowerCase())
                  )
                  ?.map((option, index) => (
                    <MenuItem
                      value={option}
                      key={applyFunc(field.optionValuer, option) + index}
                    >
                      {Formatter.contact(option, {
                        account_id: selectedAccount?.accountId,
                      })}
                    </MenuItem>
                  )) ?? null}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ParentsHierarchyAdd;
