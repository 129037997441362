// processed data --> formatter --> normalizer
import { formatDate, formatPercentage, formatCurrency } from './formatter';
import {
  normalizeDate,
  normalizeCurrency,
  normalizePercentage,
  normalizeBoolean,
  normalizeInt,
} from './normalizer';

class DataTransformation {
  static formatDate = formatDate;
  static formatDateWithoutUTC = (val) => formatDate(val, false); // for process flow, time is already convert to utc type in datepicker
  static formatPercentage = formatPercentage;
  static formatCurrency = formatCurrency;
  static normalizeDate = normalizeDate;
  static normalizeDateWithoutUtc = (val) => normalizeDate(val, false); // // for process flow, time is already convert to utc type in datepicker
  static normalizeCurrency = normalizeCurrency;
  static normalizePercentage = normalizePercentage;
  static normalizeBoolean = normalizeBoolean;
  static normalizeInt = normalizeInt;
}

export default DataTransformation;
