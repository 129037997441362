import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import validate from 'validate.js';
import { Theme } from '@mui/material/styles';

import AuthProviderList from '@/components/AuthProviderList';
import constraints from '@/data/constraints';
import authentication from '@/services/authentication';
import { removeURLQueryParameters } from '@/services/helpers';

interface UserRegistryParams {
  email: string;
  mode: string;
  uid: string;
}

type Provider = 'google'; // Add more providers as needed

interface User {
  email: string;
  uid: string;
}

interface State {
  performingAction: boolean;
  emailAddress: string;
  emailAddressConfirmation: string;
  password: string;
  passwordConfirmation: string;
  errors: {
    emailAddress?: string;
    emailAddressConfirmation?: string;
    password?: string;
    passwordConfirmation?: string;
    inviteCodeValidation?: string;
  } | null;
}

interface SignUpDialogProps {
  dialogProps: {
    onClose: (callback?: () => void) => void;
    open: boolean;
    email?: string;
  };
  openSnackbar: (message: string) => void;
  theme: Theme;
}

type InputEvent = React.ChangeEvent<HTMLInputElement>;
type KeyPressEvent = React.KeyboardEvent<HTMLDivElement>;

const SignUpDialog = ({
  dialogProps,
  openSnackbar,
  theme,
}: SignUpDialogProps) => {
  const initialState: State = {
    performingAction: false,
    emailAddress: dialogProps.email || '',
    emailAddressConfirmation: dialogProps.email || '',
    password: '',
    passwordConfirmation: '',
    errors: null,
  };

  const [state, setState] = useState<State>(initialState);
  const [inviteCodeValidated, setInviteCodeValidated] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const urlInviteCode = urlParams.get('invite-code');
  const [inviteCode, setInviteCode] = useState(
    urlInviteCode ? urlInviteCode : ''
  );

  const userLoginEmail = dialogProps.email || '';

  const StyledIconButton = styled(IconButton)({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  });

  const StyledDivider = styled(Divider)({
    margin: 'auto',
  });

  const validateInviteCode = () => {
    const PUBLIC_INVITE_CODE = 'TRYFINTARY2024';
    const INTERNAL_INVITE_CODE = 'ADMININVITE123!';
    if (
      inviteCode.toUpperCase() === PUBLIC_INVITE_CODE ||
      inviteCode.toUpperCase() === INTERNAL_INVITE_CODE
    ) {
      setState({ ...state, errors: null });
      setInviteCodeValidated(true);
    } else {
      const errors = {
        inviteCodeValidation: 'Wrong invite code, please try again.',
      };
      setState({ ...state, errors: errors });
      setInviteCodeValidated(false);
    }
  };

  const validateEmailAndPassword = (
    emailAddress: string,
    emailAddressConfirmation: string,
    password: string,
    passwordConfirmation: string
  ) => {
    return validate(
      {
        emailAddress,
        emailAddressConfirmation,
        password,
        passwordConfirmation,
      },
      {
        emailAddress: constraints.emailAddress,
        emailAddressConfirmation: constraints.emailAddressConfirmation,
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation,
      }
    );
  };

  const signUp = (): void => {
    const {
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation,
    } = state;

    const errors = validateEmailAndPassword(
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation
    );

    if (errors) {
      setState({ ...state, errors });
    } else {
      setState({ ...state, performingAction: true, errors: null });

      authentication
        .signUpWithEmailAddressAndPassword(emailAddress, password)
        .then((user: User) => {
          removeURLQueryParameters(['login-type', 'email']);

          dialogProps.onClose(() => {
            const emailAddress = user.email;
            openSnackbar(`Signed in as ${emailAddress}`);
          });
        })
        .catch((reason) => {
          const { message } = reason;
          openSnackbar(message);
        })
        .finally(() => {
          setState({ ...state, performingAction: false });
        });
    }
  };

  const signInWithAuthProvider = (provider: Provider) => {
    setState({ ...state, performingAction: true });

    authentication
      .signInWithAuthProvider(provider)
      .then((user: User) => {
        dialogProps.onClose(() => {
          const emailAddress = user.email;
          openSnackbar(`Signed in as ${emailAddress}`);
        });
      })
      .catch((reason) => {
        const { message } = reason;
        openSnackbar(message);
      })
      .finally(() => {
        setState({ ...state, performingAction: false });
      });
  };

  const handleKeyPress = (event: KeyPressEvent) => {
    const {
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation,
    } = state;

    if (
      !emailAddress ||
      !emailAddressConfirmation ||
      !password ||
      !passwordConfirmation
    ) {
      return;
    }

    const { key } = event;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      signUp();
    }
  };

  const handleExited = () => {
    setState(initialState);
  };

  const handleEmailAddressChange = (event: InputEvent) => {
    const emailAddress = event.target.value;
    setState({ ...state, emailAddress });
  };

  const handleEmailAddressConfirmationChange = (event: InputEvent) => {
    const emailAddressConfirmation = event.target.value;
    setState({ ...state, emailAddressConfirmation });
  };

  const handlePasswordChange = (event: InputEvent) => {
    const password = event.target.value;
    setState({ ...state, password });
  };

  const handlePasswordConfirmationChange = (event: InputEvent) => {
    const passwordConfirmation = event.target.value;
    setState({ ...state, passwordConfirmation });
  };

  // Dialog Properties
  const {
    performingAction,
    emailAddress,
    emailAddressConfirmation,
    password,
    passwordConfirmation,
    errors,
  } = state;

  return (
    <Dialog
      open={dialogProps.open}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown={performingAction}
      onKeyPress={handleKeyPress}
      TransitionProps={{
        onExited: handleExited,
      }}
      onClose={() => {
        dialogProps.onClose();
      }}
      sx={{
        [theme.breakpoints.down('sm')]: {
          '& .MuiDialog-container .MuiDialog-paper': {
            margin: 2,
            width: '100%',
          },
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6" component="div">
          Sign up for an account
        </Typography>

        <Tooltip title="Close" arrow>
          <StyledIconButton
            disabled={performingAction}
            onClick={(event) => {
              event.preventDefault();
              dialogProps.onClose();
            }}
            size="large"
          >
            <CloseIcon />
          </StyledIconButton>
        </Tooltip>
      </DialogTitle>

      {inviteCodeValidated ? (
        <>
          <DialogContent>
            <Grid container direction="row" sx={{ mt: 1 }}>
              <Grid item xs={4}>
                <AuthProviderList
                  performingAction={performingAction}
                  onAuthProviderClick={signInWithAuthProvider}
                />
              </Grid>

              <Grid item xs={1} sx={{ display: 'flex' }}>
                <StyledDivider orientation="vertical" />
              </Grid>

              <Grid item xs={7}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs>
                    <TextField
                      autoComplete="email"
                      disabled={!!(performingAction || userLoginEmail)}
                      error={!!(errors && errors.emailAddress)}
                      fullWidth
                      helperText={
                        errors && errors.emailAddress
                          ? errors.emailAddress[0]
                          : ''
                      }
                      label="E-mail address"
                      placeholder="john@doe.com"
                      required
                      type="email"
                      value={emailAddress}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={handleEmailAddressChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="email"
                      disabled={!!(performingAction || userLoginEmail)}
                      error={!!(errors && errors.emailAddressConfirmation)}
                      fullWidth
                      helperText={
                        errors && errors.emailAddressConfirmation
                          ? errors.emailAddressConfirmation[0]
                          : ''
                      }
                      label="E-mail address confirmation"
                      placeholder="john@doe.com"
                      required
                      type="email"
                      value={emailAddressConfirmation}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={handleEmailAddressConfirmationChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="new-password"
                      disabled={performingAction}
                      error={!!(errors && errors.password)}
                      fullWidth
                      helperText={
                        errors && errors.password ? errors.password[0] : ''
                      }
                      label="Password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      type="password"
                      value={password}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={handlePasswordChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="password"
                      disabled={performingAction}
                      error={!!(errors && errors.passwordConfirmation)}
                      fullWidth
                      helperText={
                        errors && errors.passwordConfirmation
                          ? errors.passwordConfirmation[0]
                          : ''
                      }
                      label="Password confirmation"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      type="password"
                      value={passwordConfirmation}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={handlePasswordConfirmationChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              disabled={
                !emailAddress ||
                !emailAddressConfirmation ||
                !password ||
                !passwordConfirmation ||
                performingAction
              }
              variant="contained"
              onClick={signUp}
            >
              Sign up
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <Grid container direction="row" sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs>
                    <TextField
                      autoComplete="Invite code"
                      // disabled={!!performingAction}
                      error={!!(errors && errors.inviteCodeValidation)}
                      fullWidth
                      helperText={
                        errors && errors.inviteCodeValidation
                          ? errors.inviteCodeValidation
                          : ''
                      }
                      label="Invite code"
                      placeholder="Invite code"
                      required
                      type="text"
                      value={inviteCode}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={(e) => {
                        setInviteCode(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={!inviteCode || performingAction}
              variant="contained"
              onClick={validateInviteCode}
            >
              Next
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

SignUpDialog.propTypes = {
  dialogProps: PropTypes.shape({
    onClose: PropTypes.func.isRequired,
  }).isRequired,
  openSnackbar: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired as React.Validator<Theme>,
};

export default SignUpDialog;
