import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Close as CloseIcon, DeleteOutline } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Formatter from 'common/Formatter';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CommonFormatter from 'common/Formatter';

import API from '@/services/API';
import { DateRange, DateRangesTypes } from '@/types';
import LoadingCircle from '../atoms/LoadingCircle';
import BasicDatePicker from './BasicDatePicker';
import MultiSelect from './MultiSelect';
import DataView from '@/components/DataView';

dayjs.extend(utc);
dayjs.extend(timezone);

type DateRangeManagerProps = {
  row: any;
  setter: any;
  field: any;
  type: DateRangesTypes;
};

const DateRangeManager: React.FC<DateRangeManagerProps> = ({
  row,
  field,
  setter,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [newStartDate, setNewStartDate] = useState<Date | null>(null);
  const [newEndDate, setNewEndDate] = useState<Date | null>(null);
  const [newNotes, setNewNotes] = useState<string>('');
  const [newName, setNewName] = useState<string>('');
  const [selectedDateRange, setSelectedDateRange] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [dialogWidth, setDialogWidth] = useState<DialogProps['maxWidth']>('xs');

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) setDialogWidth('xs');
    else setDialogWidth('md');
    setActiveTab(newValue);
  };

  const { openSnackbar } = useOutletContext<any>();
  const {
    data: dateRangesData,
    isFetched: isFetchedDateRangesData,
    refetch: refetchDateRanges,
  } = API.getBasicQuery('date-ranges', `type=${type}`);

  const dateRangesPoster = API.getMutation('date-ranges', 'POST');
  const dateRangesDeleter = API.getMutation('date-ranges', 'DELETE');

  useEffect(() => {
    if (isFetchedDateRangesData) setIsLoading(false);
  }, [isFetchedDateRangesData]);

  useEffect(() => {
    if (dateRangesData) {
      const selectedDateRangeObjects = selectedDateRange
        .map((id) =>
          dateRangesData.find((dateRange: DateRange) => dateRange.id === id)
        )
        .filter(Boolean);

      const uniqueDateRanges = row.date_ranges
        ? row.date_ranges.filter((dateRange: DateRange) =>
            selectedDateRange.includes(dateRange.id)
          )
        : [];

      const updatedDateRanges = [
        ...uniqueDateRanges,
        ...selectedDateRangeObjects,
      ].filter(
        (dateRange, index, self) =>
          index === self.findIndex((dr) => dr.id === dateRange.id)
      );

      setter({
        ...row,
        date_ranges: updatedDateRanges,
      });
    }
  }, [selectedDateRange, dateRangesData]);

  const dataDesc = {
    label: '',
    table: 'date-ranges',
    editable: true,
    copyable: false,
    bulkAdd: false,
    fields: [
      {
        id: 'id',
        label: 'ID',
        visible: false,
        readOnly: true,
      },
      { id: 'name', label: 'Name', editable: true },
      {
        id: 'start_date',
        label: 'Start date',
        type: 'date',
        formatter: (value) => (value ? Formatter.date(value) : ''),
      },
      {
        id: 'end_date',
        label: 'End date',
        type: 'date',
        formatter: (value) => (value ? Formatter.date(value) : ''),
      },
      {
        id: 'notes',
        label: 'Notes',
        editable: true,
      },
    ],
    actions: [
      {
        label: 'Delete',
        type: 'iconButton',
        icon: <DeleteOutline />,
        onClick: async (row) => {
          const confirmDelete = window.confirm(
            'Are you sure you want to delete this date range?'
          );
          if (confirmDelete) {
            dateRangesDeleter.mutate(
              { id: row.id },
              {
                onSuccess: (res: any) => {
                  if (res.statusText && res.statusText === 'error') {
                    openSnackbar(
                      <Alert severity="error">
                        An error occurred when deleting date range
                      </Alert>
                    );
                  } else {
                    openSnackbar('Date range deleted');
                    refetchDateRanges();
                  }
                },
              }
            );
          }
        },
      },
    ],
  };

  const addNewDateRange = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const params: DateRange = {
      start_date: newStartDate,
      end_date: newEndDate,
      type: type,
      notes: newNotes,
      name: newName,
    };
    dateRangesPoster.mutate(params as any, {
      onSuccess: (res: any) => {
        if (res.statusText && res.statusText === 'error') {
          openSnackbar(
            <Alert severity="error">
              An error occurred when creating new date range.
              {res.error ? ` ${res.error}` : ''}
            </Alert>
          );
        } else {
          openSnackbar('Date range created');
          setNewEndDate(null);
          setNewStartDate(null);
          setNewNotes('');
          setNewName('');
          setOpenAddDialog(false);
          refetchDateRanges();
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <Box>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <LoadingCircle />
          </Box>
        ) : (
          <Box
            sx={{
              mb: 0.5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', mt: 0.5 }}>
              <MultiSelect<any, any>
                label="Add"
                values={dateRangesData || []}
                selectedValues={selectedDateRange}
                setSelectedValues={setSelectedDateRange}
                formatter={(dateRange) =>
                  dateRange.name
                    ? `${dateRange.name}: ${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`
                    : `${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`
                }
                valuer={(dateRange) => dateRange.id}
                sx={{ width: 145 }}
              />
              <Button
                variant="outlined"
                onClick={() => setOpenAddDialog(true)}
                sx={{ ml: 1 }}
              >
                Manage date ranges
              </Button>
            </Box>
            <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
              {row.date_ranges?.map((selectedDateRange) => (
                <Box
                  key={`${selectedDateRange.id}-selected`}
                  sx={{
                    mr: 0.5,
                    mb: 0.5,
                    pl: 1,
                    borderStyle: 'solid',
                    borderColor: 'silver',
                    borderWidth: 1,
                    borderRadius: 6,
                    display: 'inline-block',
                    backgroundColor: '#2196f30a',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    position="relative"
                    sx={{ ml: 0.5 }}
                  >
                    <Typography variant="body2">
                      {selectedDateRange.name
                        ? `${selectedDateRange.name}: ${CommonFormatter.dateRange(selectedDateRange.start_date, selectedDateRange.end_date)}`
                        : `${CommonFormatter.dateRange(selectedDateRange.start_date, selectedDateRange.end_date)}`}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setter({
                          ...row,
                          date_ranges: row.date_ranges.filter(
                            (item) => item.id !== selectedDateRange.id
                          ),
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        fullWidth
        maxWidth={dialogWidth}
      >
        <DialogTitle>Manage date ranges</DialogTitle>
        <DialogContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Create" />
            <Tab label="View" />
          </Tabs>
          {activeTab === 0 && (
            <>
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <BasicDatePicker
                  label="Start date"
                  value={newStartDate}
                  setValue={setNewStartDate}
                  sx={{ mr: 0.5 }}
                />
                <BasicDatePicker
                  label="End date"
                  value={newEndDate}
                  setValue={setNewEndDate}
                  sx={{ mr: 0.5 }}
                />
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <TextField
                  label="Name"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <TextField
                  label="Notes"
                  value={newNotes}
                  onChange={(e) => setNewNotes(e.target.value)}
                  sx={{ width: '100%' }}
                  size="small"
                />
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  width: '100%',
                }}
              >
                <Button
                  onClick={() => {
                    setNewStartDate(null);
                    setNewEndDate(null);
                    setNewNotes('');
                    setNewName('');
                    setOpenAddDialog(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => addNewDateRange(e)}
                  sx={{ ml: 1 }}
                >
                  Create
                </Button>
              </Box>
            </>
          )}
          {activeTab === 1 && (
            <Box
              sx={{
                maxHeight: 600,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  mb: 2,
                  overflowY: 'scroll',
                }}
              >
                <DataView
                  dataDesc={dataDesc}
                  hideExport
                  embed
                  viewOnly
                  headingOffset={392}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    setNewStartDate(null);
                    setNewEndDate(null);
                    setNewNotes('');
                    setNewName('');
                    setOpenAddDialog(false);
                  }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DateRangeManager;
