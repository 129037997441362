import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';

import LoadingCircle from '@/components/atoms/LoadingCircle';
import API from '@/services/API';
import ChartWrapper from '@/components/admin/AdminMetricsView/ChartWrapper';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

type FilterOption = {
  label: string;
  value: string;
};

type SelectFilter = {
  label: string;
  type: 'select';
  options: FilterOption[];
  initialValue: string;
  sx?: { width: number };
};

type DateFilter = {
  label: string;
  type: 'date';
  field: string;
  initialValue: Date;
  filterFunc: (val: any, filterVal: any) => boolean;
};

type Filter = SelectFilter | DateFilter;

const dateRanges = {
  thisWeek: () => ({
    startDate: dayjs().startOf('week').toDate(),
    endDate: dayjs().endOf('week').toDate(),
  }),
  lastWeek: () => ({
    startDate: dayjs().subtract(1, 'week').startOf('week').toDate(),
    endDate: dayjs().subtract(1, 'week').endOf('week').toDate(),
  }),
  thisMonth: () => ({
    startDate: dayjs().startOf('month').toDate(),
    endDate: dayjs().endOf('month').toDate(),
  }),
  lastMonth: () => ({
    startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
    endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
  }),
  thisQuarter: () => ({
    startDate: dayjs().startOf('quarter').toDate(),
    endDate: dayjs().endOf('quarter').toDate(),
  }),
  lastQuarter: () => ({
    startDate: dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
    endDate: dayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
  }),
  thisYear: () => ({
    startDate: dayjs().startOf('year').toDate(),
    endDate: dayjs().endOf('year').toDate(),
  }),
  lastYear: () => ({
    startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
    endDate: dayjs().subtract(1, 'year').endOf('year').toDate(),
  }),
  last7days: () => ({
    startDate: dayjs().subtract(7, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last30days: () => ({
    startDate: dayjs().subtract(30, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last60days: () => ({
    startDate: dayjs().subtract(60, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last90days: () => ({
    startDate: dayjs().subtract(90, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
};

const filters: Record<string, Filter> = {
  dateRange: {
    label: 'Date range',
    type: 'select',
    options: [
      { label: 'This week', value: 'thisWeek' },
      { label: 'Last week', value: 'lastWeek' },
      { label: 'This month', value: 'thisMonth' },
      { label: 'Last month', value: 'lastMonth' },
      { label: 'This quarter', value: 'thisQuarter' },
      { label: 'Last quarter', value: 'lastQuarter' },
      { label: 'This year', value: 'thisYear' },
      { label: 'Last year', value: 'lastYear' },
      { label: 'Last 7 days', value: 'last7days' },
      { label: 'Last 30 days', value: 'last30days' },
      { label: 'Last 60 days', value: 'last60days' },
      { label: 'Last 90 days', value: 'last90days' },
    ],
    initialValue: 'last60days',
    sx: { width: 140 },
  },
  startDate: {
    label: 'Start date',
    type: 'date',
    field: 'effectiveDate',
    initialValue: dayjs().subtract(60, 'days').toDate(),
    filterFunc: (val, filterVal) => val >= filterVal,
  },
  endDate: {
    label: 'End date',
    type: 'date',
    field: 'effectiveDate',
    initialValue: dayjs().toDate(),
    filterFunc: (val, filterVal) => val <= filterVal,
  },
};

const Metrics = () => {
  const pageLabel = 'Metrics';
  const [filteredValues, setFilteredValues] = useState({
    startDate: dateRanges.last60days().startDate,
    endDate: dateRanges.last60days().endDate,
    dateRange: 'last60days',
  });
  const [metricsData, setMetricsData] = useState<any[]>([]);

  const updateFilteredValues = (key: string, value: any) => {
    const newFilteredValues = { ...filteredValues, [key]: value };
    setFilteredValues(newFilteredValues);

    if (key === 'dateRange') {
      const { startDate, endDate } = dateRanges[value]();
      setFilteredValues({ ...newFilteredValues, startDate, endDate });
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const newFilteredValues: any = {};

    Object.keys(filters).forEach((key) => {
      if (params.has(key)) {
        newFilteredValues[key] = params.get(key) || filters[key].initialValue;
      }
    });

    if (newFilteredValues.dateRange) {
      const { startDate, endDate } = dateRanges[newFilteredValues.dateRange]();
      setFilteredValues({
        ...newFilteredValues,
        startDate,
        endDate,
      });
    } else {
      setFilteredValues({
        ...newFilteredValues,
        startDate: dateRanges.last60days().startDate,
        endDate: dateRanges.last60days().endDate,
      });
    }
  }, []);

  // Ensure query string is properly formatted
  const query = `start_date=${new Date(filteredValues.startDate).toISOString().substring(0, 10)}&end_date=${new Date(filteredValues.endDate).toISOString()}`;

  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(
      ([k]) => !['startDate', 'endDate'].includes(k)
    )
  );

  const {
    isLoading: isLoadingMetrics,
    data: result,
    isFetched: isFetchedMetrics,
  } = API.getBasicQuery('metrics', query);

  useEffect(() => {
    if (isFetchedMetrics) {
      setMetricsData(result || []);
    }
  }, [result, isFetchedMetrics]);

  return (
    <Box p={2} sx={{ width: '100%', overflowY: 'scroll' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ mt: 1, p: '0 1%' }}
      >
        <Typography variant="h5">{pageLabel}</Typography>
        {Object.entries(filteredFilters).map(
          ([key, filter]) =>
            filter.type === 'select' && (
              <FormControl
                key={filter.label}
                sx={{ ml: 1, width: 135, ...filter.sx }}
              >
                <InputLabel>{filter.label}</InputLabel>
                <Select
                  value={filteredValues[key] || ''}
                  label={filter.label}
                  onChange={(e) => updateFilteredValues(key, e.target.value)}
                  sx={{ '.MuiSelect-select': { py: 0.75, px: 1.5 } }}
                >
                  {filter.options.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
        )}
      </Box>
      <Box sx={{ mt: 1, p: '3% 2%' }}>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {isLoadingMetrics && <LoadingCircle />}
          {metricsData.map((e, key) => (
            <Grid
              item
              xs={12}
              md={6}
              key={key}
              sx={{ display: isLoadingMetrics ? 'none' : 'block' }}
            >
              {Array.isArray(e) ? (
                <ChartWrapper dataSet={e}></ChartWrapper>
              ) : (
                <ChartWrapper title={e.title} data={e.value}></ChartWrapper>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Metrics;
