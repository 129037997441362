import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
const GridItem = ({ widget, handleDelete, children }) => {
  return (
    <>
      <div className="grid-item__title">
        <div className="dragHandle"></div>
        <RemoveCircleRoundedIcon
          className="deleteButton"
          onClick={() => handleDelete(widget.i)}
        />
      </div>
      {children}
    </>
  );
};

export default GridItem;
