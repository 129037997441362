import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const BasicTable = ({
  headers,
  rows,
  format = 'array',
  formatters,
  defaultShow = undefined,
}) => {
  const [expanded, setExpanded] = useState(false);

  const rowsToShow = rows.slice(0, expanded ? undefined : defaultShow);

  useEffect(() => {
    if (defaultShow === undefined) {
      setExpanded(true);
    }
  }, [defaultShow]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers &&
              headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {format === 'array'
            ? rowsToShow.map((row, i) => (
                <TableRow key={`row-${i}`}>
                  {row.map((cell, j) =>
                    j === 0 ? (
                      <TableCell component="th" scope="row" key={j}>
                        {typeof formatters?.[j] === 'function'
                          ? formatters[j](cell)
                          : cell}
                      </TableCell>
                    ) : (
                      <TableCell key={j}>
                        {typeof formatters?.[j] === 'function'
                          ? formatters[j](cell)
                          : cell}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))
            : rowsToShow.map((row, i) => (
                <TableRow key={row.name ?? row.id ?? `row-${i}`}>
                  {headers.map((col, j) =>
                    j === 0 ? (
                      <TableCell component="th" scope="row" key={col}>
                        {row[col]}
                      </TableCell>
                    ) : (
                      <TableCell key={col}>{row[col]}</TableCell>
                    )
                  )}
                </TableRow>
              ))}
          {defaultShow !== undefined && (
            <TableRow>
              <TableCell
                colSpan={headers?.length}
                sx={{ textAlign: 'center' }}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? (
                  <ExpandLess sx={{ height: 16 }} />
                ) : (
                  <ExpandMore sx={{ height: 16 }} />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
