import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  Stack,
} from '@mui/material';
import { createContext, useState } from 'react';
import { ExpandMoreOutlined } from '@mui/icons-material';

import DataForm from '@/components/DataForm';
import Statements from '@/services/Statements';
import Reports from '@/services/Reports';

export const ComparisonContext = createContext<{
  setShow: (show: boolean) => void;
  setData: (data) => void;
}>({} as any);

export const ReconciliationConfirmProvider = ({ mode, children }) => {
  const [show, setShow] = useState(false);
  const statementList = new Statements(mode);
  const [data, setData] = useState({
    policy: {},
    statements: [],
    defaultOpenStatement: '',
  });
  const report = new Reports(mode);
  const context = {
    setShow,
    setData,
  };

  const dataDesc = {
    label: statementList.label,
    table: statementList.table,
    editable: false,
    fields: Object.entries(statementList.fields)
      .filter(([k, v]) => v.enabled)
      .reduce((acc: any, [k, v]) => [...acc, { ...v, id: k }], []),
  };
  const policyDesc = {
    label: report.label,
    table: report.table,
    editable: false,
    fields: Object.entries(report.fields)
      .filter(([k, v]) => v.enabled)
      .reduce((acc: any, [k, v]) => [...acc, { ...v, id: k }], []),
  };
  return (
    <ComparisonContext.Provider value={context}>
      <>
        {children}
        <Drawer anchor="right" open={show} onClose={() => setShow(false)}>
          <Stack
            spacing={2}
            direction="row"
            sx={{ width: '80vw', padding: 2, paddingTop: '80px' }}
          >
            <Box sx={{ width: '50%', height: '100vh', overflow: 'scroll' }}>
              <Accordion expanded>
                <AccordionSummary>Policy</AccordionSummary>
                <AccordionDetails>
                  <DataForm
                    dataDesc={policyDesc}
                    fields={Object.values(policyDesc.fields)}
                    newData={data.policy}
                    readOnly={true}
                    formModeOnly={true}
                    oldData={{}}
                    setNewData={null}
                    onCancel={null}
                    onDelete={null}
                    onSave={null}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ width: '50%', height: '100vh', overflow: 'scroll' }}>
              {data.statements.map((statement: any, index) => (
                <Accordion
                  defaultExpanded={
                    data.defaultOpenStatement === statement.str_id
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    Statement: {statement.str_id}
                  </AccordionSummary>
                  <AccordionDetails>
                    <DataForm
                      dataDesc={dataDesc}
                      fields={Object.values(dataDesc.fields)}
                      newData={statement}
                      readOnly={true}
                      formModeOnly={true}
                      oldData={{}}
                      setNewData={null}
                      onCancel={null}
                      onDelete={null}
                      onSave={null}
                    />
                  </AccordionDetails>
                  <AccordionActions>
                    <Button>Confirm</Button>
                    <Button>Reject</Button>
                  </AccordionActions>
                </Accordion>
              ))}
            </Box>
          </Stack>
        </Drawer>
      </>
    </ComparisonContext.Provider>
  );
};
