import DataView from '@/components/DataView';
import DocumentReconciler from '@/components/molecules/DocumentReconciler';
import API from '@/services/API';

const deDupeData = async (p) => {
  const res = await fetch(
    `${process.env.REACT_APP_API}/api/data_processing/grouping`,
    {
      method: 'POST',
      headers: await API.getHeaders(),
    }
  );
  console.log('deDupe: ', await res.json());
  return res;
};

const processReconciliation = async (options) => {
  const { redo, testRun, flowId } = options || {};
  const res = await fetch(
    `${process.env.REACT_APP_API}/api/reconciliation/reconcile`,
    {
      method: 'POST',
      headers: await API.getHeaders(),
      body: JSON.stringify({
        redo,
        testRun,
        flowId,
      }),
    }
  );
  console.log('processReconciliation: ', await res.json());
  return res;
};

const dataDesc = {
  label: 'Reconciler Flows',
  table: 'reconciler_flows',
  editable: true,
  fields: [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'flow',
      label: 'Flow',
      type: 'dynamic-select',
      multiple: true,
      table: 'reconcilers',
      field: 'id',
      formatter: (val, collectionVals = []) => {
        if (Array.isArray(collectionVals) && collectionVals.length > 0) {
          return (
            collectionVals?.filter((company) => company.str_id === val)?.[0]
              ?.name || 'Not found'
          );
        }
        return val; // not formatting when collectionVals is not available
      },
      optionFormatter: (option) => option.name,
      optionValuer: (option) => option.str_id,
      itemOnClick: (item) => {
        window.open(`/reconciliation/reconcilers?q=${item}`, '_blank');
      },
      delimiter: ' → ',
    },
    { id: 'notes', label: 'Notes', type: 'text-multiline' },
    {
      id: 'access',
      label: 'Access',
      type: 'select',
      default: 'account',
      options: ['account', 'global'],
    },
    {
      id: 'state',
      label: 'State',
      type: 'select',
      default: 'active',
      options: ['active', 'disabled'],
    },
    {
      id: 'account_default',
      label: 'Default reconciler',
      type: 'boolean',
    },
    { id: 'divider', type: 'divider', label: 'Metadata' },
    {
      id: 'updated_at',
      label: 'Last updated',
      formatter: (s) => new Date(s).toLocaleString(),
      readOnly: true,
    },
    // TODO: Move to actions
    // Hack since actions don't support formatters rn
    {
      id: 'id',
      label: 'Test doc',
      tableFormatter: (id) => <DocumentReconciler flowId={id} />,
      formatter: (a) => null,
    },
  ],
  actions: [
    {
      label: 'Test run',
      onClick: async (data) => {
        await deDupeData();
        await processReconciliation({
          deDupe: true,
          redo: true,
          testRun: true,
          flowId: data.id,
        });
      },
      type: 'button',
    },
    {
      label: 'Run',
      onClick: async (data) => {
        await deDupeData();
        await processReconciliation({
          deDupe: true,
          redo: true,
          testRun: false,
          flowId: data.id,
        });
      },
      type: 'button',
    },
  ],
};

const ReconcilersFlowsView = () => <DataView dataDesc={dataDesc} />;

export default ReconcilersFlowsView;
