import {
  Box,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

const getStats = (reconciliationResult) => {
  const res = {};
  res['Policy data'] = {
    // Unique: reconciliationResult?.reportsUnique,
    Total: reconciliationResult?.reportsTotal,
    // Duplicates: reconciliationResult?.reportsDupes,
  };
  res['Commission data'] = {
    // Unique: reconciliationResult?.statementsUnique,
    Total: reconciliationResult?.statementsTotal,
    // Duplicates: reconciliationResult?.statementsDupes,
  };
  res['Reconciliation data'] = {
    Total: reconciliationResult?.reconciliationsTotal,
    'Missing Policy Data': reconciliationResult?.missingPolicyData,
    'Missing Commission Data': reconciliationResult?.missingCommissionData,
    'Commissions Shortfall': reconciliationResult?.commissionsShortfall,
    'Policies Matched': `${
      reconciliationResult?.reportsReconciled &&
      reconciliationResult?.reportsTotal
        ? (
            (reconciliationResult.reportsReconciled /
              reconciliationResult.reportsTotal) *
            100
          ).toFixed(0)
        : '0'
    }% (${reconciliationResult?.reportsReconciled} / ${
      reconciliationResult?.reportsTotal
    })`,
    'Commissions Matched': `${
      reconciliationResult?.statementsReconciled &&
      reconciliationResult?.statementsTotal
        ? (
            (reconciliationResult.statementsReconciled /
              reconciliationResult.statementsTotal) *
            100
          ).toFixed(0)
        : '0'
    }% (${reconciliationResult?.statementsReconciled} / ${
      reconciliationResult?.statementsTotal
    })`,
  };
  res['Reconciler stats'] = {};
  reconciliationResult?.reconcilerStats?.forEach((stat) => {
    res['Reconciler stats'][`${stat.id}: ${stat.name}`] = (
      <div>
        {Object.entries(stat)
          .filter(([k, v]) => !['id', 'name'].includes(k))
          .map(([k, v]) => (
            <div key={k}>
              {k}: {v}
            </div>
          ))}
      </div>
    );
  });
  return res;
};

const ResultsSection = ({ title, value }) => (
  <Box sx={{ mt: 1 }}>
    <Typography variant="subtitle1">{title}</Typography>
    {value &&
      Object.entries(value).map(([k2, v2]) => (
        <Table key={k2}>
          <TableBody>
            <TableRow>
              <TableCell>{k2}</TableCell>
              <TableCell align="right">{v2}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ))}
  </Box>
);

const ResultsCard = ({ title, data, variant }) => {
  const results = getStats(data);
  const date = new Date(data?.created_at);

  return (
    <Box>
      {title && <CardHeader title={title} />}
      {variant === 'wide' ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {Object.entries(results).map(([key, value]) => (
            <ResultsSection key={key} title={key} value={value} />
          ))}
        </Box>
      ) : (
        Object.entries(results).map(([key, value]) => (
          <Box key={key} sx={{ mt: 1 }}>
            <ResultsSection title={key} value={value} />
          </Box>
        ))
      )}{' '}
      <Typography variant="body2" sx={{ mt: 2, textAlign: 'center ' }}>
        Date: {date?.toLocaleString()}
      </Typography>
    </Box>
  );
};

export default ResultsCard;
