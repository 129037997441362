import DashboardView from '.';

const PoliciesInsights = () => {
  return (
    <DashboardView
      dashboardName={'Policies Insights'}
      widgetsFilter={'policies'}
    />
  );
};
export default PoliciesInsights;
