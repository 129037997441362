import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Chip, Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import API from '@/services/API';
import ReconciliationsView from '@/components/ReconciliationsView';
import CommissionsDataView from '@/components/CommissionsDataView';
import PolicyDataView from '@/components/PolicyDataView';
import EditViewReport from '../molecules/EditViewReport';
import { useAccountStore } from '@/store';

const CheckView = (): JSX.Element => {
  const { id } = useParams();
  const { selectedAccount } = useAccountStore();
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [newNotes, setNewNotes] = useState('');
  const [newAccess, setNewAccess] = useState('');
  const [usersList, setUsersList] = useState<string[]>([]);
  const [fetchAccountUsers, setFetchAccountUsers] = useState(false);

  const { data: selectedView } = API.getBasicQuery(
    // @ts-ignore
    `saved_reports/views?view_id=${id}`
  );

  const { data: accountUsers, isLoading: accountUsersLoading } =
    API.getBasicQuery(
      // @ts-ignore
      `users/get_account_users?accId=${selectedAccount.accountId}`,
      '',
      fetchAccountUsers
    );

  useEffect(() => {
    if (selectedView) {
      setNewName(selectedView.name);
      setNewNotes(selectedView.notes);
      setNewAccess(selectedView.access);
      setUsersList(selectedView.users_white_list);
    }
    if (
      selectedView?.access === 'user_list' &&
      selectedView?.users_white_list.length > 0
    )
      setFetchAccountUsers(true);
  }, [selectedView]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: 2,
          pb: 1,
        }}
      >
        <Box sx={{ px: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid container>
              <Grid item xs={8}>
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  color="inherit"
                  variant="h5"
                  component="div"
                >
                  {newName}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right', pr: 2 }}>
                <Typography
                  sx={{
                    flex: '1 1 100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  component="div"
                >
                  <strong>Created at:</strong>
                  <div style={{ textAlign: 'right' }}>
                    {' '}
                    {new Date(selectedView?.created_at).toLocaleString()}
                  </div>
                </Typography>
                <Typography
                  sx={{
                    flex: '1 1 100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  component="div"
                >
                  <strong>Access:</strong>{' '}
                  <div style={{ textAlign: 'right' }}>{newAccess}</div>
                </Typography>
                {accountUsers && !accountUsersLoading && (
                  <Typography
                    sx={{
                      flex: '1 1 100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    component="div"
                  >
                    <strong>Users:</strong>
                    <div style={{ textAlign: 'right' }}>
                      {usersList.map((user) => {
                        const userObj = accountUsers?.find(
                          (u) => u.str_id === user
                        );
                        return (
                          <div key={userObj?.str_id}>{userObj?.email}</div>
                        );
                      })}
                    </div>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 1, mt: 2 }}>
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                <strong>Notes:</strong> {newNotes}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              label="Edit"
              sx={{ mr: 1, mt: 1 }}
              icon={<EditIcon />}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
              variant="outlined"
              color="primary"
            />
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: 'scroll',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {selectedView?.page === 'reconciliation' && <ReconciliationsView />}
          {selectedView?.page === 'commissions' && <CommissionsDataView />}
          {selectedView?.page === 'policies' && <PolicyDataView />}
        </Box>
      </Box>
      {selectedView ? (
        <EditViewReport
          open={open}
          setOpen={setOpen}
          type="View"
          name={selectedView.name}
          notes={selectedView.notes}
          access={selectedView.access}
          usersWhiteList={selectedView.users_white_list}
          created_by={selectedView.created_by}
          updateName={setNewName}
          updateNotes={setNewNotes}
          updateAccess={setNewAccess}
          updateUsersList={setUsersList}
          isOwner={selectedView.isOwner ? true : false}
          allData={selectedView}
          id={selectedView?.id}
        />
      ) : null}
    </>
  );
};

export default CheckView;
