export const ProcessSuccessStatus = ['SUCCEEDED', 'Success'];

export const ProcessMethodList = [
  {
    label: 'Mapping',
    value: 'mapping',
  },
  {
    label: 'Processor',
    value: 'processor',
  },
  {
    label: 'Gemini',
    value: 'gemini',
  },
];
