import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import prettyMilliseconds from 'pretty-ms';

import { AccountIds, CALC_METHODS } from './constants';
import { CompGridRateFields } from './globalTypes';
import { isNill } from './helpers';

dayjs.extend(utc);

class Formatter {
  static calcMethod = (s: keyof typeof CALC_METHODS) => CALC_METHODS[s] ?? s;

  static percentage = (val: string | number) => {
    if (typeof val === 'string' || isNill(val)) {
      return val;
    }
    return val * 100 < 100
      ? `${(val * 100).toPrecision(3)}%`
      : `${Math.round(val * 100)}%`;
  };

  static numberRange = (start: string | null | undefined, end: string) => {
    if (![null, undefined, ''].includes(start) && start === end) {
      return start;
    } else if (start && end) {
      return `${start}-${end}`;
    } else if (start) {
      return `${start}+`;
    } else if (end) {
      return `< ${end}`;
    } else {
      return 'any';
    }
  };

  static duration = (
    val,
    opts: { truncate: string } = { truncate: undefined }
  ) => {
    let res = prettyMilliseconds(+val, { secondsDecimalDigits: 0 });
    if (opts?.truncate === 'seconds') {
      res = res.replace(/ \d+s/, '');
    }
    return res;
  };

  static dateRange = (
    start: Date | null | undefined,
    end: Date | null | undefined,
    useUTC = true
  ) => {
    const _dayjs = useUTC ? dayjs.utc : dayjs;
    const _startStr = _dayjs(start).format('MM/DD/YYYY');
    const _endStr = _dayjs(end).format('MM/DD/YYYY');
    const startStr = _startStr === 'Invalid Date' ? null : _startStr;
    const endStr = _endStr === 'Invalid Date' ? null : _endStr;
    if (!startStr && !endStr) {
      return 'Full date range';
    } else if (startStr && startStr === endStr) {
      return startStr;
    } else if (startStr && endStr) {
      return `${startStr}-${endStr}`;
    } else if (startStr) {
      return `${startStr} or later`;
    } else if (endStr) {
      return `${endStr} or earlier`;
    } else {
      return '';
    }
  };

  static compGridCriterion = (val: any) =>
    val
      ? `${val.comp_grid_product?.type} • ${
          val.comp_grid_product?.name
        } • Years (${Formatter.numberRange(
          val.policy_year_start,
          val.policy_year_end
        )}) • Ages (${Formatter.numberRange(
          val.issue_age_start,
          val.issue_age_end
        )})${val.compensation_type ? ` • ${val.compensation_type}` : ''}${
          val.transaction_type ? ` • ${val.transaction_type}` : ''
        }`
      : '';

  static date = (val: Date | string, useUTC = true, format = 'MM/DD/YYYY') => {
    const _dayjs = useUTC ? dayjs.utc : dayjs;
    return _dayjs(val).format(format);
  };

  static contact = (
    contact: { first_name: string; last_name: string; email?: string },
    opts: {
      account_id?: string | null;
      incl_email?: boolean;
      last_first?: boolean;
    } = {
      incl_email: false,
      last_first: false,
    }
  ) => {
    const isTransGlobal = opts?.account_id === AccountIds.TRANSGLOBAL;
    const _lastFirst = (isTransGlobal || opts?.last_first) ?? false;

    if (!contact) {
      return '';
    }
    const arr = [];
    const nameArr = [];
    if (contact.first_name) nameArr.push(contact.first_name.trim());
    if (contact.last_name) nameArr.push(contact.last_name.trim());
    if (_lastFirst) {
      nameArr.reverse();
      arr.push(nameArr.join(', '));
    } else {
      arr.push(nameArr.join(' '));
    }
    if (opts?.incl_email && contact.email)
      arr.push(`(${contact.email.trim()})`);
    const str = arr.join(' ');
    return str;
  };

  static compGridRatesFields = (val: string) => {
    if (val === CompGridRateFields.CARRIER_RATE) {
      return 'Carrier Rate';
    } else if (val === CompGridRateFields.HOUSE_RATE) {
      return 'House Rate';
    } else if (val === CompGridRateFields.TOTAL_RATE) {
      return 'Total Rate';
    } else {
      return 'All';
    }
  };
}

export default Formatter;
