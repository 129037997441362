import DataView from '@/components/DataView';
import EmptyValuesSelect from '@/components/SettingsView/EmptyValuesSelect';

const POLICY_FIELDS = [
  'agent_name',
  'aggregation_id',
  'compensation_type',
  'customer_name',
  'document_id',
  'effective_date',
  'group_id',
  'id',
  'internal_id',
  'policy_id',
  'product_name',
  'product_type',
  'product_sub_type',
  'transaction_type',
  'writing_carrier_name',
];

const STATEMENT_FIELDS = [
  'agent_name',
  'carrier_name',
  'carrier_rate',
  'commission_amount',
  'commission_rate',
  'compensation_type',
  'customer_name',
  'document_id',
  'effective_date',
  'group_id',
  'id',
  'internal_id',
  'invoice_date',
  'payment_date',
  'period_date',
  'policy_id',
  'premium_amount',
  'processing_date',
  'product_name',
  'product_type',
  'statement_number',
  'transaction_type',
  'writing_carrier_name',
];

const dataDesc = {
  label: 'Data settings',
  table: 'accounts',
  editable: false,
  fields: [
    {
      id: 'reconciliation',
      label: 'Reconciliation',
      type: 'heading',
      access: 'admin',
    },
    {
      id: 'reconciliation_threshold',
      label: 'Reconciliation threshold',
      type: 'number',
      access: 'admin',
      tip: 'Balances under this amount will be considered reconciled',
    },
    { id: 'h1', type: 'divider' },
    {
      id: 'policy_data',
      label: 'Policy data',
      type: 'heading',
      access: 'admin',
    },
    {
      id: 'policy_deduping',
      label: 'Deduping',
      type: 'sub-heading',
      access: 'admin',
    },
    {
      id: 'report_de_dupe',
      label: 'Policy report duplicates detection',
      type: 'select',
      multiple: true,
      options: POLICY_FIELDS,
      access: 'admin',
    },
    {
      id: 'report_de_dupe_unique_empty',
      label: 'Consider empty values unique',
      type: 'custom',
      access: 'admin',
      render: (field, row, setter, dynamicSelects) => (
        <EmptyValuesSelect
          key="report_de_dupe_unique_empty"
          data={row}
          setter={setter}
          field={field}
        />
      ),
      condition: (row) => row?.report_de_dupe?.length > 0,
    },
    {
      id: 'policy_grouping',
      label: 'Grouping 1',
      type: 'sub-heading',
      access: 'admin',
    },
    {
      id: 'report_grouping',
      label: 'Policy report grouping',
      type: 'select',
      multiple: true,
      options: POLICY_FIELDS,
      access: 'admin',
    },
    {
      id: 'report_grouping_unique_empty',
      label: 'Consider empty values unique',
      type: 'custom',
      render: (field, row, setter, dynamicSelects) => (
        <EmptyValuesSelect
          key="report_grouping_unique_empty"
          data={row}
          setter={setter}
          field={field}
        />
      ),
      condition: (row) => row?.report_grouping?.length > 0,
    },
    {
      id: 'report_grouping_priority_field',
      label: 'Priority field',
      type: 'select',
      options: [
        'aggregation_primary',
        'created_at',
        'effective_date',
        'product_name',
      ],
    },
    {
      id: 'policy_grouping2',
      label: 'Grouping 2',
      type: 'sub-heading',
      access: 'admin',
    },
    {
      id: 'report_grouping2',
      label: 'Policy report grouping',
      type: 'select',
      multiple: true,
      options: POLICY_FIELDS,
      access: 'admin',
    },
    {
      id: 'report_grouping_unique_empty2',
      label: 'Consider empty values unique',
      type: 'custom',
      render: (field, row, setter, dynamicSelects) => (
        <EmptyValuesSelect
          key="report_grouping_unique_empty2"
          data={row}
          setter={setter}
          field={field}
        />
      ),
      condition: (row) => row?.report_grouping2?.length > 0,
    },
    {
      id: 'report_grouping_priority_field2',
      label: 'Priority field',
      type: 'select',
      options: [
        'aggregation_primary',
        'created_at',
        'effective_date',
        'product_name',
      ],
    },
    {
      id: 'policies_show_grouped',
      label: 'Show grouped policies in primary policy',
      type: 'boolean',
    },
    { id: 'h2', type: 'divider' },
    {
      id: 'commission_data',
      label: 'Commission data',
      type: 'heading',
      access: 'admin',
    },
    {
      id: 'commission_de_duping',
      label: 'Commission data deduping',
      type: 'sub-heading',
      access: 'admin',
    },
    {
      id: 'statement_de_dupe',
      label: 'Commission statement duplicate detection',
      type: 'select',
      multiple: true,
      options: STATEMENT_FIELDS,
      access: 'admin',
    },
    {
      id: 'statement_de_dupe_unique_empty',
      label: 'Consider empty values unique',
      type: 'custom',
      render: (field, row, setter, dynamicSelects) => (
        <EmptyValuesSelect
          key="statement_de_dupe_unique_empty"
          data={row}
          setter={setter}
          field={field}
        />
      ),
      condition: (row) => row?.statement_de_dupe?.length > 0,
    },
    {
      id: 'commission_grouping',
      label: 'Commission data grouping',
      type: 'sub-heading',
      access: 'admin',
    },
    {
      id: 'statement_grouping',
      label: 'Commission data grouping',
      type: 'select',
      multiple: true,
      options: STATEMENT_FIELDS,
      access: 'admin',
    },
    {
      id: 'statement_grouping_diff_fields',
      label: 'Field must be different to match',
      type: 'select',
      multiple: true,
      options: STATEMENT_FIELDS,
      access: 'admin',
    },
    {
      id: 'statement_grouping_unique_empty',
      label: 'Consider empty values unique',
      type: 'custom',
      render: (field, row, setter, dynamicSelects) => (
        <EmptyValuesSelect
          key="statement_grouping_unique_empty"
          data={row}
          setter={setter}
          field={field}
        />
      ),
      condition: (row) => row?.statement_grouping?.length > 0,
    },
    {
      id: 'statement_grouping_priority_field',
      label: 'Priority field',
      type: 'select',
      options: ['compensation_type'],
    },
    { id: 'h3', type: 'divider' },
    {
      id: 'other',
      label: 'Other',
      type: 'heading',
      access: 'admin',
    },
    {
      id: 'commissions_expected_source',
      label: 'Commission expected source',
      type: 'select',
      options: ['Schedule', 'Policy report'],
      access: 'admin',
    },
  ],
};

const ReconciliationSettings = () => {
  return <DataView dataDesc={dataDesc} formModeOnly embed />;
};

export default ReconciliationSettings;
