import { Chat, DeleteOutlineOutlined, Edit } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { CommentDrawer } from '@/common';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import ActionModal from './ActionModal';
import { PriorityList } from './enum';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';

const DocumentProfileView = () => {
  const [current, setCurrent] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const statusList = useMemo(() => {
    return [
      {
        value: 'draft',
        label: 'Draft',
        color: '',
      },
      {
        value: 'in_review',
        label: 'In review',
        color: 'blue',
      },
      {
        value: 'need_update',
        label: 'Need update',
        color: 'yellow',
      },
      {
        value: 'approved',
        label: 'Approved',
        color: 'green',
      },
    ];
  }, []);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: processors = [] } = API.getBasicQuery('processors');
  const { data: companies = [] } = API.getBasicQuery('companies/all');
  const { data: owners = [] } = API.getBasicQuery('users/get_fintary_admins');
  const { data } = API.getBasicQuery('admin/users');
  const accounts = data?.data;
  const deleter = API.getMutation('document_profiles', 'DELETE');

  const dataDesc = useMemo(
    () => ({
      lable: 'Document profiles',
      table: 'document_profiles',
      fields: {
        carrier_name: {
          label: 'Carrier/Paying entity',
          enabled: true,
          formatter: (s) => {
            const target = companies?.find((c) => c.str_id === s);
            return target?.company_name;
          },
        },
        field_mapping: {
          label: 'Field mappings',
          enabled: true,
          formatter: (text: string) =>
            text.split('\n').map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            )),
        },
        document_str_ids: {
          label: 'Documents',
          enabled: true,
          tableFormatter: (strIds: string[], row) => {
            return (
              <Box>
                <Chip
                  component={Link}
                  to={`/admin/documents?profile=${row.str_id}`}
                  label={strIds.length}
                  clickable
                />
              </Box>
            );
          },
        },
        owner: {
          label: 'Owner',
          enabled: true,
          formatter: (s) => {
            const target = owners?.find((c) => c.uid === s);
            return Formatter.contact(target);
          },
        },
        account_id: {
          label: 'Uploader',
          enabled: true,
          formatter: (s) => {
            const target = accounts?.find(
              (c) => c.account_user_roles[0]?.account_id === s
            );
            return Formatter.contact(target);
          },
        },
        priority: {
          label: 'Priority',
          enabled: true,
          formatter: (s, row) => {
            const tag = PriorityList.find((item) => item.value === s);
            if (!tag) return '';
            return Formatter.statusChip(tag?.label, {
              mapping: {
                [tag?.label]: tag?.color,
              },
            });
          },
        },
        status: {
          label: 'Status',
          enabled: true,
          formatter: (s) => {
            const tag = statusList.find((item) => item.value === s);
            if (!tag) return '';
            return Formatter.statusChip(tag?.label, {
              mapping: {
                [tag?.label]: tag?.color,
              },
            });
          },
        },
        processor_str_ids: {
          label: 'Processors',
          enabled: true,
          tableFormatter: (strIds, row) => {
            return (
              <Box>
                {strIds.length ? (
                  <Chip
                    component={Link}
                    to={`/processors?profile=${row.str_id}`}
                    label={strIds.length}
                    clickable
                  />
                ) : (
                  ''
                )}
              </Box>
            );
          },
        },
        notes: {
          label: 'Notes',
          enabled: true,
          formatter: (text: string) =>
            text.split('\n').map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            )),
        },
        created_at: {
          label: 'Created at',
          enabled: true,
          formatter: (s) =>
            Formatter.date(s, {
              format: 'MM/DD/YYYY hh:mm:A',
            }),
          readOnly: true,
        },
      },
      actions: [
        {
          label: 'Edit',
          type: 'iconButton',
          icon: <Edit />,
          onClick: (row) => {
            setCurrent(row);
            setShowAddModal(true);
          },
        },
        {
          label: 'Delete',
          type: 'iconButton',
          icon: <DeleteOutlineOutlined />,
          onClick: async (row) => {
            setCurrent(row);
            const res = await deleter.mutateAsync({ ids: [row.id] });
            if (res.error) {
              alert(res.error);
            } else {
              setRefresh((prev) => prev + 1);
            }
          },
        },
        {
          label: 'Chat',
          type: 'iconButton',
          icon: <Chat />,
          onClick: (row) => {
            setCurrent(row);
            setShowComment(true);
          },
        },
      ],
    }),
    [companies, owners, accounts]
  );

  const closeModal = (evt, reason) => {
    if (reason && reason === 'backdropClick') return;
    setShowAddModal(false);
    setRefresh((prev) => prev + 1);
    setCurrent(null);
  };

  useEffect(() => {
    if (refresh === 0) return;
    queryClient.invalidateQueries();
  }, [refresh]);

  return (
    <>
      <EnhancedDataView
        dataSpec={dataDesc}
        hideAdd
        hideSelectedCount
        enableMultiSelect={false}
        enableEdit={false}
        actionsEnabled={() => true}
        actions={dataDesc.actions}
      />
      {showAddModal && (
        <ActionModal
          open={showAddModal}
          handleCancel={closeModal}
          rowData={current}
          companies={companies}
          owners={owners}
          processors={processors}
        />
      )}
      {showComment && (
        <CommentDrawer
          open={showComment}
          setOpen={setShowComment}
          rowData={current}
          type="processor"
        />
      )}
    </>
  );
};

export default DocumentProfileView;
