import { useEffect } from 'react';

import API from '@/services/API';

const useSyncedFields = (table: string) => {
  const { data, abort } = API.getBasicQuery(
    'data_processing/sync/synced-fields'
  );

  useEffect(() => {
    return () => abort();
  }, []);

  const syncedFields = data?.[table];

  return {
    syncedFields,
    isSyncedField: (
      data: { [key: string]: any; sync_id?: string },
      key: string,
      config?: { overrideFields?: string[] }
    ) =>
      data.sync_id &&
      syncedFields?.includes(key) &&
      (!config?.overrideFields || !config?.overrideFields?.includes(key)),
  };
};

export default useSyncedFields;
