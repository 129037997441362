import { Navigate } from 'react-router-dom';

import DataView from '@/components/DataView';
import API from '@/services/API';

const View = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.options;

  const viewOnly = viewSettings?.read_only ?? false;

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const queryChipData =
    API.getBasicQuery('companies/products')?.data?.data ?? [];

  const dataDesc = {
    label: 'Product options',
    table: 'companies/products/options',
    editable: true,
    copyable: true,
    bulkAdd: true,
    fields: [
      {
        id: 'product_id',
        label: 'Product',
        type: 'dynamic-select',
        table: 'companies/products',
        field: 'id',
        formatter: (val, collectionVals = []) =>
          (Array.isArray(collectionVals) &&
            collectionVals?.find((item) => item.id === val)?.product_name) ||
          '',
        optionFormatter: (option) => option.product_name,
        optionValuer: (option) => option.id,
        required: true,
      },
      {
        id: 'name',
        label: 'Option',
        required: true,
      },
      {
        id: 'notes',
        label: 'Notes',
      },
    ],
    queryChips: {},
  };

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  if (Array.isArray(queryChipData) && queryChipData.length > 1) {
    const queryChips = [
      {
        id: '0',
        product_name: 'All',
        query: {},
      },
      ...(queryChipData ?? []),
    ];

    const FK = 'product_id';

    queryChips.forEach((item) => {
      dataDesc.queryChips[item.id] = {
        id: String(item.id),
        label: item.product_name,
        query: { [FK]: item.id },
      };
    });
  }

  return (
    <DataView dataDesc={dataDesc} viewOnly={viewOnly} readOnly={viewOnly} />
  );
};

export default View;
