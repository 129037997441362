import { useContext, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
  Grid,
  Switch,
} from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import { WidgetGroup } from 'common/constants';
import { LoadingContext } from 'contexts/LoadingContext';

import { UIStateContext } from '@/contexts/UIStateProvider';
import BoxWidget from './BoxWidget';
import ChartWidget from './ChartWidget';
import TableWidget from './TableWidget';
import WidgetWrapper from './WidgetWrapper';
import { Roles } from '@/types';
import FieldMatcher from '../molecules/FieldMatcher';
import useAccountStore from '@/store/accountStore';

// Dummy data for dropdowns
const accessRoles = [
  Roles.ACCOUNT_ADMIN,
  Roles.PRODUCER,
  Roles.DATA_SPECIALIST,
];
const dataSources = [
  'statement',
  'report',
  'reconciliation',
  'reportDataCommissionProcessingDate',
];
const dataSourceDisplayNames = {
  statement: 'Commissions',
  report: 'Policies',
  reconciliation: 'Reconciliations',
  reportDataCommissionProcessingDate: 'Policies Processing Date',
};
const filterFieldsByDataSource = {
  statement: {
    groupFields: [
      {
        name: 'writing_carrier_name',
        type: 'string',
        displayName: 'Writing carrier name',
      },
      {
        name: 'processing_date',
        type: 'date',
        displayName: 'Processing date',
      },
      {
        name: 'carrier_name',
        type: 'string',
        displayName: 'Carrier name',
      },
    ],
    dataFields: [
      {
        name: 'commission_amount',
        type: 'number',
      },
      {
        name: 'agent_commissions.total',
        type: 'obj',
        lookupKey: 'total',
      },
      {
        name: 'agent_commissions',
        type: 'obj',
      },
      {
        name: 'premium_amount',
        type: 'number',
      },
    ],
  },
  report: {
    groupFields: [
      {
        name: 'effective_date',
        type: 'date',
        displayName: 'Effective date',
      },
      {
        name: 'writing_carrier_name',
        type: 'string',
        displayName: 'Writing carrier name',
      },
      {
        name: 'policy_status',
        type: 'string',
        displayName: 'Policy status',
        availableValues: [
          'Active',
          'Inactive',
          'Active Client',
          'Inactive Client',
          'In Force',
          'Prospect/Lead',
          'duplicate',
        ],
      },
      {
        name: 'state',
        type: 'string',
        displayName: 'State',
        availableValues: ['duplicate', 'active', 'deleted'],
      },
    ],
    dataFields: [
      {
        name: 'commissionable_premium_amount',
        type: 'number',
      },
      {
        name: 'premium_amount',
        type: 'number',
      },
      {
        name: 'commissions_expected',
        type: 'number',
      },
      {
        name: 'customer_paid_premium_amount',
        type: 'number',
      },
    ],
  },
  reconciliation: {
    groupFields: [
      {
        name: 'carrier_name',
        type: 'string',
        displayName: 'Carrier name',
      },
      {
        name: 'writing_carrier_name',
        type: 'string',
        displayName: 'Writing carrier name',
      },
    ],
    dataFields: [
      {
        name: 'amount_paid',
        type: 'obj',
        lookupKey: 'amount_paid.amount_paid',
      },
      {
        name: 'aggregate_premiums',
        type: 'obj',
        lookupKey: 'aggregatePremiums.aggregatePremiums',
      },
    ],
  },
};
const widgetGroups = ['box', 'chart-donut', 'chart-line', 'chart-bar', 'table'];
const calculationValues = ['Sum', 'Count', 'Aggregate', 'Average'];

const WidgetCreator = ({ widgetOnEdit, createWidget, setWidgetModel }) => {
  const [selectedDataSource, setSelectedDataSource] = useState(
    widgetOnEdit?.dataSource || ''
  );
  const [selectedCustomCode, setSelectedCustomCode] = useState(
    widgetOnEdit?.customCode || ''
  );
  const [selectedWidgetType, setSelectedWidgetType] = useState(
    widgetOnEdit?.type || ''
  );
  const [selectedGroupByValue, setSelectedGroupByValue] = useState(
    widgetOnEdit?.groupBy || ''
  );
  const [selectedDataFieldValue, setSelectedDataFieldValue] = useState(
    widgetOnEdit?.dataField || []
  );
  const [dataFieldFilters, setDataFieldFilters] = useState<any>(
    widgetOnEdit?.dataFieldFilters || []
  );
  const [groupByFilters, setGroupByFilters] = useState<any>(
    widgetOnEdit?.groupByFilters || []
  );
  const [selectedCalculationValue, setSelectedCalculationValue] = useState(
    widgetOnEdit?.calculation || ''
  );
  const [selectedResultFormatter, setSelectedResultFormatter] = useState(
    widgetOnEdit?.resultFormatter || ''
  );
  const [selectedCategory, setSelectedCategory] = useState(
    widgetOnEdit?.category || ''
  );
  const [selectedAccessRoles, setSelectedAccessRoles] = useState(
    widgetOnEdit?.accessRoles || []
  );
  const [selectedShared, setSelectedShared] = useState(
    widgetOnEdit?.shared || false
  );

  const [applyAlevoExcludedAgents, setApplyAlevoExcludedAgents] = useState(
    widgetOnEdit?.applyAlevoExcludedAgents || false
  );
  const { selectedAccount } = useAccountStore();

  const [widgetName, setWidgetName] = useState(widgetOnEdit?.name || '');
  const [previewData, setPreviewData] = useState<any>(null);
  const { setLoadingConfig } = useContext(LoadingContext);
  const {
    role: [role],
  } = useContext(UIStateContext);
  const isFintaryAdmin = role === 'admin';
  const isAlevoAccount = selectedAccount?.accountName === 'Alevo';
  const handleDataFieldChange = (e) => {
    const { value } = e.target;
    setSelectedDataFieldValue(
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleCreateWidget = async () => {
    setLoadingConfig({
      loading: true,
      message: 'Creating widget...',
    });
    const result = await createWidget({
      name: widgetName,
      dataSource: selectedDataSource,
      type: selectedWidgetType,
      groupBy: selectedGroupByValue,
      groupByFilters: groupByFilters,
      dataField: selectedDataFieldValue,
      dataFieldFilters: dataFieldFilters,
      applyAlevoExcludedAgents: applyAlevoExcludedAgents,
      calculation: selectedCalculationValue,
      shared: selectedShared,
      category: selectedCategory,
      resultFormatter: selectedResultFormatter,
      customCode: selectedCustomCode,
      accessRoles: selectedAccessRoles,
    });
    setLoadingConfig({
      loading: false,
    });
    setPreviewData(result);
    setWidgetModel({
      name: widgetName,
      accessRoles: selectedAccessRoles,
      spec: {
        dataSource: selectedDataSource,
        name: widgetName,
        type: selectedWidgetType,
        groupBy: selectedGroupByValue,
        groupByFilters: groupByFilters,
        dataFieldFilters: dataFieldFilters,
        applyAlevoExcludedAgents: applyAlevoExcludedAgents,
        dataField: selectedDataFieldValue,
        calculation: selectedCalculationValue,
        resultFormatter: selectedResultFormatter,
        customCode: selectedCustomCode,
        category: selectedCategory,
        shared: selectedShared,
        accessRoles: selectedAccessRoles,
      },
    });
  };

  const handleAccessRolesChange = (e) => {
    const { value } = e.target;
    setSelectedAccessRoles(value);
  };

  const selectWidget = (data) => {
    switch (data.widgetGroup) {
      case WidgetGroup.BOX:
        return BoxWidget(data);
      case WidgetGroup.TABLE:
        return TableWidget(data);
      case WidgetGroup.CHART:
        return <ChartWidget data={data.value} />;
      default:
        return null;
    }
  };

  const renderWidget = (data) => (
    <WidgetWrapper displayName={data.displayName} enableCustomWidget={false}>
      {selectWidget(data)}
    </WidgetWrapper>
  );
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Create a new widget</Typography>

      <FormControl fullWidth margin="normal">
        <TextField
          sx={{ flex: 1 }}
          value={widgetName}
          label="Widget name"
          onChange={(e) => setWidgetName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Data source</InputLabel>
        <Select
          value={selectedDataSource}
          label="Data source"
          onChange={(e) => setSelectedDataSource(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select data source
          </MenuItem>
          {dataSources.map((source) => (
            <MenuItem key={source} value={source}>
              {dataSourceDisplayNames[source]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Widget type</InputLabel>
        <Select
          value={selectedWidgetType}
          label="Widget type"
          onChange={(e) => setSelectedWidgetType(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select widget type
          </MenuItem>
          {widgetGroups.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isFintaryAdmin && (
        <FormControl fullWidth margin="normal">
          <label>Custom code</label>
          <CodeMirror
            title="Custom code"
            height="350px"
            value={selectedCustomCode}
            width="100%"
            onChange={(code) => {
              setSelectedCustomCode(code);
            }}
          />
        </FormControl>
      )}

      <FormControl fullWidth margin="normal">
        <InputLabel>Group by field</InputLabel>
        <Select
          value={selectedGroupByValue}
          label="Group by field"
          onChange={(e) => setSelectedGroupByValue(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select group by field
          </MenuItem>
          {filterFieldsByDataSource[selectedDataSource]?.groupFields.map(
            (field) => (
              <MenuItem key={field.name} value={field.name}>
                {field.displayName}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      {filterFieldsByDataSource[selectedDataSource]?.groupFields && (
        <Box>
          <label>Group by filter</label>
          <FieldMatcher
            fields={filterFieldsByDataSource[
              selectedDataSource
            ]?.groupFields.map((field) => {
              return {
                id: field.name,
                label: field.displayName,
              };
            })}
            hideUsePolicyData={true}
            value={groupByFilters}
            setValue={setGroupByFilters}
          />
        </Box>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel>Data field</InputLabel>
        <Select
          value={selectedDataFieldValue}
          label="Data Field"
          multiple
          onChange={handleDataFieldChange}
        >
          <MenuItem value="" disabled>
            Select data field
          </MenuItem>
          {filterFieldsByDataSource[selectedDataSource]?.dataFields.map(
            (field) => (
              <MenuItem
                key={field.name}
                value={`${field.name}${field.lookupKey ? `?.${field.lookupKey}` : ''}`}
              >
                {field.name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      {filterFieldsByDataSource[selectedDataSource]?.dataFields && (
        <Box>
          <label>Data filters</label>
          <FieldMatcher
            fields={filterFieldsByDataSource[
              selectedDataSource
            ]?.dataFields.map((field) => {
              return {
                id: field.name,
                label: field.name,
              };
            })}
            hideUsePolicyData={true}
            value={dataFieldFilters}
            setValue={setDataFieldFilters}
          />
        </Box>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel>Aggregation method</InputLabel>
        <Select
          value={selectedCalculationValue}
          label="Aggregation method"
          onChange={(e) => setSelectedCalculationValue(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select aggregation method
          </MenuItem>
          {calculationValues.map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Result formatter</InputLabel>
        <Select
          value={selectedResultFormatter}
          label="Result formatter"
          onChange={(e) => setSelectedResultFormatter(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select result formatter
          </MenuItem>
          <MenuItem value="">Default</MenuItem>
          <MenuItem value="currency">Currency</MenuItem>
          <MenuItem value="percentage">Percentage</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        <Select
          value={selectedCategory}
          label="Result formatter"
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select result formatter
          </MenuItem>
          <MenuItem value="businessInsights">Business Insights</MenuItem>
          <MenuItem value="policies">Policies</MenuItem>
          <MenuItem value="agents">Agents</MenuItem>
          <MenuItem value="agentGroup">Agent Group</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Access roles</InputLabel>
        <Select
          value={selectedAccessRoles}
          label="Access roles"
          multiple
          onChange={handleAccessRolesChange}
        >
          <MenuItem value="" disabled>
            Select roles can access this widget
          </MenuItem>
          {accessRoles.map((role) => (
            <MenuItem key={role} value={role}>
              {Roles[role]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isAlevoAccount && (
        <FormControl fullWidth margin="normal">
          <Grid container spacing={2}>
            <Grid item>
              <Typography>Apply excluded agents</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={applyAlevoExcludedAgents}
                onChange={() =>
                  setApplyAlevoExcludedAgents(!applyAlevoExcludedAgents)
                }
              />
            </Grid>
          </Grid>
        </FormControl>
      )}
      {isFintaryAdmin && (
        <FormControl fullWidth margin="normal">
          <Grid container spacing={2}>
            <Grid item>
              <Typography>Global</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={selectedShared}
                onChange={() => setSelectedShared(!selectedShared)}
              />
            </Grid>
          </Grid>
        </FormControl>
      )}
      <hr />
      <Box mt={2} display={'flex'}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateWidget}
        >
          Preview
        </Button>
      </Box>
      <Box mt={4} mb={4} p={2} borderColor="grey.400" height={380}>
        {previewData && renderWidget(previewData)}
      </Box>
    </Box>
  );
};

export default WidgetCreator;
