import { Box, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

const transformObjectVal = (json, keys, fn) =>
  json.map((item) =>
    Object.fromEntries(
      Object.entries(item).map(([k, v]) =>
        keys.includes(k) ? [k, fn(v)] : [k, v]
      )
    )
  );

const CsvToJson = () => {
  const [csv, setCSV] = useState(
    'product_id	product_option_id	issue_age_min	issue_age_max	transaction_type	compensation_type	calculation_method	commission_rate	receivable_rate	carrier_grid_level	carrier_grid_override_rate	payout_rate	keep_rate	split'
  );
  const [json, setJSON] = useState('');

  useEffect(() => {
    const csvSheet = XLSX.read(csv, {
      type: 'string',
      raw: true,
    });
    const jsonRes = XLSX.utils.sheet_to_json(
      csvSheet.Sheets[csvSheet.SheetNames[0]],
      { raw: true }
    );
    setJSON(
      JSON.stringify(
        transformObjectVal(
          jsonRes,
          ['product_id', 'product_option_id'],
          (vals) => vals.split(',').map((val) => Number.parseInt(val))
        )
      )
    );
  }, [csv]);

  return (
    <Box display="flex">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="CSV/TSV"
            multiline
            minRows={16}
            value={csv}
            onChange={(event) => {
              setCSV(event.target.value);
            }}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="JSON"
            multiline
            minRows={16}
            value={json}
            onChange={(event) => {
              setJSON(event.target.value);
            }}
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CsvToJson;
