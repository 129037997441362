import { Box, Button, ButtonGroup } from '@mui/material';
import styled from '@mui/system/styled';
import authProviders from 'data/auth-providers';
import PropTypes from 'prop-types';

const AuthProviderList = ({
  gutterBottom = false,
  onAuthProviderClick,
  performingAction = false,
}) => {
  return (
    <Box mb={gutterBottom ? 3 : 0}>
      <ButtonGroup
        disabled={performingAction}
        fullWidth
        orientation="vertical"
        variant="outlined"
      >
        {authProviders.map((authProvider) => {
          const AuthProviderButton = styled(Button)({
            color: authProvider.color,
          });

          return (
            <AuthProviderButton
              key={authProvider.id}
              startIcon={authProvider.icon}
              onClick={() => onAuthProviderClick(authProvider)}
            >
              {authProvider.name}
            </AuthProviderButton>
          );
        })}
      </ButtonGroup>
    </Box>
  );
};

AuthProviderList.propTypes = {
  gutterBottom: PropTypes.bool,
  onAuthProviderClick: PropTypes.func.isRequired,
  performingAction: PropTypes.bool,
};

export default AuthProviderList;
