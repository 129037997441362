import DashboardView from '.';

const AgentGroupInsights = () => {
  return (
    <DashboardView
      dashboardName={'Agent group Insights'}
      widgetsFilter={'agentGroup'}
    />
  );
};
export default AgentGroupInsights;
