import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useOutletContext } from 'react-router-dom';

import API from '@/services/API';
import { IMappingModel } from './config';

const ActionModal = ({ open, setOpen, rowData, companies }) => {
  const { openSnackbar } = useOutletContext<any>();
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState<Partial<IMappingModel>>({
    name: '',
    mapping: '',
    carrier_id: '',
    type: '',
    access: '',
  });

  const accessList = useMemo(
    () => [
      {
        label: 'Account',
        value: 'account',
      },
      {
        label: 'Global',
        value: 'global',
      },
    ],
    []
  );
  const typeList = useMemo(
    () => [
      {
        label: 'Statement',
        value: 'statement',
      },
      {
        label: 'Report',
        value: 'report',
      },
    ],
    []
  );

  const mappingPoster = API.getMutation('mappings', 'POST');
  const mappingPutter = API.getMutation('mappings', 'PATCH');

  useEffect(() => {
    if (rowData) {
      const { type, name, carrier_id, mapping, access, carrier } = rowData;
      setFormData({
        type,
        name,
        carrier_id: carrier_id || carrier.str_id,
        mapping: JSON.stringify(mapping, null, 4),
        access,
      });
    }
  }, [rowData]);

  const onSubmit = async () => {
    setUploading(true);
    const { id, str_id, ...poster } = rowData;
    const body = {
      ...formData,
      str_id: rowData.str_id,
      id: id,
      modify_status: 'modified',
      carrier_id: formData.carrier_id,
      mapping: JSON.parse(formData.mapping || '{}'),
    };
    try {
      // save origin mappings
      const postRes = await mappingPoster.mutateAsync({
        ...poster,
        modify_status: `${str_id}::${new Date().toISOString()}`,
      });
      if (postRes.error) {
        openSnackbar(<Alert severity="error">{postRes.error}</Alert>);
        return;
      }
      // modify
      const result = await mappingPutter.mutateAsync(body);
      setUploading(false);
      if (result.error) {
        openSnackbar(<Alert severity="error">{result.error}</Alert>);
        return;
      }
      if (result) {
        setOpen(false);
      }
    } catch (error) {
      openSnackbar(<Alert severity="error">{error as any}</Alert>);
      setUploading(false);
    }
  };

  const onFormChange = (key, value: any) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{ background: 'transparent', p: 1 }}
      onClose={() => setOpen(false)}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Box className="flex items-center">Mappings edit</Box>
      </DialogTitle>
      <IconButton
        className="group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600"
        onClick={() => setOpen(false)}
      >
        <Close className="group-hover:rotate-180 transition-all origin-center" />
      </IconButton>
      <Divider />

      <DialogContent
        sx={{
          p: 0,
          backgroundColor: '#fff',
          borderRadius: '4px',
        }}
      >
        <Box sx={{ width: '100%', maxHeight: '700px', p: 4 }}>
          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <TextField
              label="Name"
              variant="outlined"
              value={formData.name}
              onChange={(e) => {
                onFormChange('name', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <TextField
              label="Mappings"
              variant="outlined"
              multiline
              minRows={4}
              maxRows={18}
              value={formData.mapping}
              onChange={(e) => {
                onFormChange('mapping', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <InputLabel>Access</InputLabel>
            <Select
              id="select-access"
              value={formData.access}
              label="Priority"
              onChange={(e) => {
                onFormChange('priority', e.target.value);
              }}
            >
              {accessList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <InputLabel>Type</InputLabel>
            <Select
              id="select-type"
              value={formData.type}
              label="Type"
              onChange={(e) => {
                onFormChange('type', e.target.value);
              }}
            >
              {typeList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <InputLabel>Carrier</InputLabel>
            <Select
              id="select-carrier"
              value={formData.carrier_id}
              label="Carrier"
              onChange={(e) => {
                onFormChange('carrier_id', e.target.value);
              }}
            >
              {(companies || []).map((c) => (
                <MenuItem value={c.str_id} key={c.str_id}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <Box sx={{ flex: 1 }}>{c.company_name}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions sx={{ pt: 0, pb: 2, px: 2 }}>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton
          onClick={onSubmit}
          loading={uploading}
          variant="contained"
          sx={{ width: '100px' }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ActionModal;
