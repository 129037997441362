import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { UIStateContext } from '@/contexts/UIStateProvider';
import API from '@/services/API';
import Reconciliations from '@/services/Reconciliations';
import { useAccountStore } from '@/store';

const exportOptions = [
  {
    id: 'export',
    label: 'Export',
    options: {},
  },
  {
    id: 'export-received',
    label: 'Commission received',
    options: {},
  },
  {
    id: 'export-received-due',
    label: 'Commission received + due',
    options: { include_commissions_due: true },
  },
  // If we want to enable the export producer view, we can add the following
  // {
  //   id: 'export-producer-view',
  //   label: 'Export producer view',
  //   options: { producer_view: true },
  // },
];

const ReconciliationsView = ({ reportId = null }) => {
  const {
    role: [role],
  } = useContext(UIStateContext);
  const { selectedAccount } = useAccountStore();
  const isMobile = useMediaQuery('(max-width:600px)');

  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const mode = selectedAccount?.accountMode;

  const reconciliations = new Reconciliations(mode, role, {
    account_id: selectedAccount?.accountId,
  });
  const pageSettingFields = isMobile
    ? accountSettings?.pages_settings?.reconciliation?.outstandingMobileFields
    : accountSettings?.pages_settings?.reconciliation?.fields;
  const newFields = (pageSettingFields ?? []).reduce((acc, cur) => {
    acc[cur] = {
      ...reconciliations.fields[cur],
    };
    return acc;
  }, {});
  if (Object.keys(newFields).length > 0) reconciliations.fields = newFields;

  if (accountSettings?.pages_settings?.reconciliation?.page_label) {
    reconciliations.label =
      accountSettings?.pages_settings?.reconciliation?.page_label;
  }

  if (
    isFetchedAccountSettings &&
    accountSettings?.pages_settings?.reconciliation?.show_page === false
  ) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/commissions" />
      // <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
      //   <Typography variant="h5">No results</Typography>
      // </Box>
    );
  }

  return selectedAccount && isFetchedAccountSettings ? (
    <EnhancedDataView
      reportId={reportId}
      dataSpec={reconciliations}
      outstandingMobileFields={
        accountSettings?.pages_settings?.reconciliation?.outstandingMobileFields
      }
      hideAdd
      enableSaves
      exportOptions={exportOptions}
      readOnly={true}
      nonSelectableOnMobile={true}
    />
  ) : null;
};

export default ReconciliationsView;
