import { Launch, RequestQuoteOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Typography } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  ReportReviewStatuses,
  ReportReviewStatusesLabels,
} from 'common/globalTypes';

import DataView from '@/components/DataView';
import CommissionPayoutReportConfig from '@/components/ReportsGroupView/CommissionPayoutReportConfig';
import { useAccountStore } from '@/store';
import API from '@/services/API';
import Formatter from '@/services/Formatter';

const ReportsSummaryView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.summaries;

  const viewOnly = viewSettings?.read_only ?? false;

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }
  const { selectedAccount } = useAccountStore();
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const extraActions = (
    <Button
      variant="contained"
      disableElevation
      startIcon={<RequestQuoteOutlined />}
      onClick={() => {
        setShowModal(true);
      }}
      sx={{ mr: 0.5 }}
    >
      Commissions payout
    </Button>
  );

  const dataDesc = {
    label: 'Saved report groups',
    table: 'saved_reports/groups',
    onDeleteText: 'All reports in this group will be deleted.',
    editable: true,
    fields: [
      {
        id: 'name',
        label: 'Name',
        tableFormatter: (row, collectionVals) => {
          return (
            <Button
              onClick={() => {
                window.open(
                  `/reports/summary/${collectionVals.str_id}`,
                  '_blank'
                );
              }}
              endIcon={<Launch />}
            >
              {row}
            </Button>
          );
        },
      },
      {
        id: 'template',
        label: 'Type',
        type: 'select',
        options: [
          { id: '', label: '' },
          { id: 'commission_payout', label: 'Commission payout' },
        ],
        readOnly: true,
      },
      {
        id: 'saved_reports',
        label: 'Saved reports',
        type: 'dynamic-select',
        table: 'saved_reports?include_grouped=true',
        field: 'id',
        multiple: true,
        tableFormatter: (field, row) => {
          return (
            <Box>
              {(field ?? []).slice(0, 6).map((item) => (
                <Chip label={item.name} sx={{ m: 0.25 }} key={item.id} />
              ))}
              <Typography variant="body2" sx={{ ml: 0.5, mt: 0.5 }}>
                {field.length > 6 && `+${field.length - 6} more`}
              </Typography>
            </Box>
          );
        },
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const _val = typeof val === 'object' ? val.id : val;
            return (
              collectionVals?.find((item) => item.id === _val)?.name ||
              'Not found'
            );
          }
          return typeof val === 'string' ? val : ''; // not formatting when collectionVals is not available
        },
        optionFormatter: (option) => option.name,
        optionValuer: (option) => option.id,
      },
      {
        id: 'notes',
        label: 'Notes',
      },
      {
        id: 'status',
        label: 'Status',
        type: 'select',
        default: 'draft',
        options: [
          {
            id: ReportReviewStatuses.None,
            label: ReportReviewStatusesLabels.None,
          },
          {
            id: ReportReviewStatuses.Draft,
            label: ReportReviewStatusesLabels.Draft,
          },
          {
            id: ReportReviewStatuses.InReview,
            label: ReportReviewStatusesLabels.InReview,
          },
          {
            id: ReportReviewStatuses.Approved,
            label: ReportReviewStatusesLabels.Approved,
          },
          {
            id: ReportReviewStatuses.RequestUpdate,
            label: ReportReviewStatusesLabels.RequestUpdate,
          },
          {
            id: ReportReviewStatuses.Rejected,
            label: ReportReviewStatusesLabels.Rejected,
          },
          {
            id: ReportReviewStatuses.NonPayable,
            label: ReportReviewStatusesLabels.NonPayable,
          },
        ],
        formatter: Formatter.reportStatusFormatter,
      },
      {
        id: 'reviewed_by',
        label: 'Approver',
        type: 'dynamic-select',
        table: `users/get_account_users?state=active`,
        queryParamTable: selectedAccount?.accountId,
        queryParamNameTable: 'accId',
        queryParamValue: selectedAccount?.accountId,
        queryParamName: 'accId',
        field: 'id',
        multiple: false,
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const _val = typeof val === 'object' ? val.uid : val;
            return (
              collectionVals?.find((item) => item.uid === _val)?.email ||
              'Not found'
            );
          }
          return val; // not formatting when collectionVals is not available
        },
        optionFormatter: (option) => option.email,
        optionValuer: (option) => option.uid,
      },
      {
        id: 'access',
        label: 'Access',
        type: 'select',
        default: 'user',
        options: [
          { id: 'user', label: 'User' },
          { id: 'account', label: 'Account' },
          { id: 'user_list', label: 'User list' },
        ],
      },
      {
        id: 'users_white_list',
        label: 'Users with access',
        type: 'dynamic-select',
        table: `users/get_account_users?state=active`,
        queryParamValue: selectedAccount?.accountId,
        queryParamName: 'accId',
        field: 'id',
        multiple: true,
        readOnly: (val) => {
          return val.access !== 'user_list';
        },
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const _val = typeof val === 'object' ? val.str_id : val;
            return (
              collectionVals?.find((item) => item.str_id === _val)?.email ||
              'Not found'
            );
          }
          return val; // not formatting when collectionVals is not available
        },
        optionFormatter: (option) => option.email,
        optionValuer: (option) => option.str_id,
      },
      {
        id: 'created_at',
        label: 'Created at',
        formatter: (row) => new Date(row).toLocaleString(),
        condition: (row) => row.created_at,
        readOnly: true,
      },
    ],
    actions: [],
    queryChips: {
      all: {
        id: 'all',
        label: 'All',
        query: {},
      },
      draft: {
        id: 'draft',
        label: 'Draft',
        query: {
          status: 'draft',
        },
      },
      in_review: {
        id: 'in_review',
        label: 'In review',
        query: {
          status: 'in_review',
        },
      },
      approved: {
        id: 'approved',
        label: 'Approved',
        query: {
          status: 'approved',
        },
      },
    },
  };

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  return (
    <>
      <DataView
        headingOffset={105}
        dataDesc={dataDesc}
        extraActions={extraActions}
        hideExport
        refresh={refresh}
        viewOnly={viewOnly}
        readOnly={viewOnly}
        enablePagination
      />
      {showModal && (
        <CommissionPayoutReportConfig
          open={showModal}
          setOpen={setShowModal}
          refreshData={() => setRefresh(refresh + 1)}
        />
      )}
    </>
  );
};
export default ReportsSummaryView;
