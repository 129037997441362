import { Close, Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Formatter from 'common/Formatter';
import { nanoid } from 'nanoid';
import { useMemo, useState } from 'react';
import validator from 'validator';

import { FilterSelect } from '@/common';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import API from '@/services/API';
import { useAccountStore } from '@/store';

type CompProfilesAddProps = {
  data: any;
  setter: any;
  field: any;
  dynamicSelects: any;
  type?: 'set' | '';
};

const applyFunc = (func, arg) => (typeof func === 'function' ? func(arg) : arg);

const CompProfilesAdd: React.FC<CompProfilesAddProps> = ({
  data,
  field,
  setter,
  dynamicSelects,
  type = '',
}) => {
  const { selectedAccount } = useAccountStore();
  const [query, setQuery] = useState('');
  const [selectedProfiles, setSelectedProfiles] = useState<any>([]);
  const { data: _contacts, isLoading: isLoadingContacts } = API.getBasicQuery(
    'contacts',
    `is_dynamic_select=true`
  );

  const contacts = useMemo(
    () =>
      (_contacts?.data ?? [])
        .map((contact) => ({
          value: contact.id,
          label: Formatter.contact(contact, {
            incl_email: true,
            account_id: selectedAccount?.accountId,
          }),
        }))
        .sort((a, b) => a.label?.localeCompare(b.label)),
    [_contacts]
  );

  let profilesWithDates;
  let keyToUse;
  let idToUse;
  if (type === 'set') {
    profilesWithDates =
      data?.contacts_agent_commission_schedule_profiles_sets ?? [];
    keyToUse = 'contacts_agent_commission_schedule_profiles_sets';
    idToUse = 'agent_commission_schedule_profile_set_id';
  } else {
    profilesWithDates = data?.contacts_agent_commission_schedule_profiles ?? [];
    keyToUse = 'contacts_agent_commission_schedule_profiles';
    idToUse = 'agent_commission_schedule_profile_id';
  }
  profilesWithDates.forEach((profileWithDates) => {
    if (!profileWithDates[idToUse].toString().includes('::'))
      profileWithDates[idToUse] =
        profileWithDates[idToUse] + '::' + profileWithDates.str_id;
    if (!profileWithDates.config) {
      profileWithDates.config = [
        {
          method: profileWithDates.method,
          rate: profileWithDates.rate,
          multipler: profileWithDates.multiplier,
          payee_id: profileWithDates.payee_id,
        },
      ];
    }
  });

  const handleSelectAll = (event) => {
    event.stopPropagation();
    const filteredOptions = filterOptions(dynamicSelects, query);
    setSelectedProfiles(filteredOptions.map((option: any) => option.id));
  };

  const handleAddSelected = () => {
    const newProfiles = selectedProfiles.map((newId) => ({
      agent_commission_schedule_profile: dynamicSelects.find(
        (item) => item.id === newId
      ),
      [idToUse]: newId + '::' + nanoid(),
      start_date: null,
      end_date: null,
      multiplier: '100',
      method: '',
      rate: '',
    }));

    setter({
      ...data,
      [keyToUse]: [...(data[keyToUse] ?? []), ...newProfiles],
    });

    setSelectedProfiles([]);
  };

  const filterOptions = (options = [], query) => {
    const trimmedQuery = query.trim().toLowerCase();

    return options.filter((item: any) => {
      const itemName = item?.name?.toLowerCase() ?? '';

      if (trimmedQuery.startsWith('"') && trimmedQuery.endsWith('"')) {
        const exactMatchRegex = new RegExp(
          `\\b${trimmedQuery.slice(1, -1)}\\b`
        );
        return exactMatchRegex.test(itemName);
      }

      return itemName.includes(trimmedQuery);
    });
  };

  return (
    <Box>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            pl: 1,
            pt: 0.5,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {profilesWithDates.map((profileWithDates) => (
              <Box
                key={profileWithDates.str_id}
                sx={{
                  mx: 0.5,
                  p: 1,
                  borderStyle: 'solid',
                  borderColor: 'silver',
                  borderWidth: 1,
                  borderRadius: 4,
                  display: 'inline-block',
                  width: 270,
                  boxSizing: 'border-box',
                  backgroundColor: '#2196f30a',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  sx={{ ml: 0.5 }}
                >
                  <Typography variant="body2">
                    {profileWithDates.agent_commission_schedule_profile?.name ??
                      profileWithDates.agent_commission_schedule_profiles_sets
                        ?.name}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setter({
                        ...data,
                        [keyToUse]: data[keyToUse].filter(
                          (item) => item[idToUse] !== profileWithDates[idToUse]
                        ),
                      });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box>
                  <BasicDatePicker
                    label="Start date"
                    value={profileWithDates.start_date}
                    setValue={(e) => {
                      setter({
                        ...data,
                        [keyToUse]: data[keyToUse].map((item) =>
                          item[idToUse] === profileWithDates[idToUse]
                            ? { ...item, start_date: e }
                            : item
                        ),
                      });
                    }}
                    sx={{ mt: 1, width: 250 }}
                  />
                </Box>
                <Box>
                  <BasicDatePicker
                    label="End date"
                    value={profileWithDates.end_date}
                    setValue={(e) => {
                      setter({
                        ...data,
                        [keyToUse]: data[keyToUse].map((item) =>
                          item[idToUse] === profileWithDates[idToUse]
                            ? { ...item, end_date: e }
                            : item
                        ),
                      });
                    }}
                    sx={{ mt: 1, width: 250 }}
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  {profileWithDates?.config?.length > 0 && <Divider />}
                  {profileWithDates?.config?.map((config, index) => (
                    <Box
                      sx={{
                        mb: 1,
                        display: 'flex',
                        boxSizing: 'border-box',
                        flexDirection: 'column',
                        width: '100%',
                        // backgroundColor: '#2196f308',
                      }}
                      key={index}
                    >
                      <Box>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>Custom method</InputLabel>
                            <Select
                              value={config.method ?? ''}
                              label="Custom method"
                              onChange={(e) => {
                                const items = profileWithDates.config;
                                items.map((item, idx) => {
                                  if (idx === index) {
                                    item.method = e.target.value;
                                  }
                                });
                                setter({
                                  ...data,
                                  [keyToUse]: data[keyToUse].map((item) =>
                                    item[idToUse] === profileWithDates[idToUse]
                                      ? {
                                          ...item,
                                          config: items,
                                        }
                                      : item
                                  ),
                                });
                              }}
                            >
                              <MenuItem value="">&nbsp;</MenuItem>
                              <MenuItem value="add">
                                Add % bonus to house rate
                              </MenuItem>
                              <MenuItem value="set">
                                Set % fixed total rate
                              </MenuItem>
                              <MenuItem value="fixed">Fixed override</MenuItem>
                              <MenuItem value="bonus">Override bonus</MenuItem>
                            </Select>
                          </FormControl>
                          <Box sx={{ ml: 0.5 }}>
                            <IconButton
                              onClick={() => {
                                const items = profileWithDates.config;
                                setter({
                                  ...data,
                                  [keyToUse]: data[keyToUse].map((item) =>
                                    item[idToUse] === profileWithDates[idToUse]
                                      ? {
                                          ...item,
                                          config: items.filter(
                                            (item, idx) => idx !== index
                                          ),
                                        }
                                      : item
                                  ),
                                });
                              }}
                            >
                              <Close />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box>
                          {!['set', 'bonus'].includes(config.method) && (
                            <TextField
                              label="Multiplier"
                              value={config.multiplier ?? '100'}
                              sx={{ mt: 1, width: 250 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" sx={{ ml: 0 }}>
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                const items = profileWithDates.config || [];
                                items.map((item, idx) => {
                                  if (idx === index) {
                                    item.multiplier = e.target.value;
                                  }
                                });
                                setter({
                                  ...data,
                                  [keyToUse]: data[keyToUse].map((item) =>
                                    item[idToUse] === profileWithDates[idToUse]
                                      ? {
                                          ...item,
                                          config: items,
                                        }
                                      : item
                                  ),
                                });
                              }}
                              error={
                                profileWithDates.multiplier &&
                                !validator.isFloat(
                                  profileWithDates.multiplier.trim(),
                                  {
                                    min: 0,
                                    max: 1000,
                                  }
                                )
                              }
                              helperText={
                                profileWithDates.multiplier &&
                                !validator.isFloat(
                                  profileWithDates.multiplier.trim(),
                                  {
                                    min: 0,
                                    max: 1000,
                                  }
                                )
                                  ? 'Must be a number between 0 and 1000'
                                  : ''
                              }
                            />
                          )}
                        </Box>
                        {config.method && (
                          <>
                            <Box>
                              <TextField
                                label="Rate"
                                value={config.rate ?? ''}
                                sx={{ mt: 1, width: 250 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ ml: 0 }}
                                    >
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  const items = profileWithDates.config;
                                  items.map((item, idx) => {
                                    if (idx === index) {
                                      item.rate = e.target.value;
                                    }
                                  });
                                  setter({
                                    ...data,
                                    [keyToUse]: data[keyToUse].map((item) =>
                                      item[idToUse] ===
                                      profileWithDates[idToUse]
                                        ? {
                                            ...item,
                                            config: items,
                                          }
                                        : item
                                    ),
                                  });
                                }}
                                error={
                                  profileWithDates.rate &&
                                  !validator.isFloat(
                                    profileWithDates.rate.trim(),
                                    {
                                      min: 0,
                                      max: 1000,
                                    }
                                  )
                                }
                                helperText={
                                  profileWithDates.rate &&
                                  !validator.isFloat(
                                    profileWithDates.rate.trim(),
                                    {
                                      min: 0,
                                      max: 1000,
                                    }
                                  )
                                    ? 'Must be a number between 0 and 1000'
                                    : ''
                                }
                              />
                            </Box>
                            {['fixed', 'bonus'].includes(config.method) && (
                              <Box>
                                <FormControl
                                  fullWidth
                                  margin="normal"
                                  sx={{ mt: 1, mb: 0 }}
                                >
                                  <FilterSelect
                                    value={config.payee_id ?? ''}
                                    options={contacts}
                                    label="Payee"
                                    onChange={(e) => {
                                      const items = profileWithDates.config;
                                      items.map((item, idx) => {
                                        if (idx === index) {
                                          item.payee_id = e?.data?.value;
                                        }
                                      });
                                      setter({
                                        ...data,
                                        [keyToUse]: data[keyToUse].map(
                                          (item) =>
                                            item[idToUse] ===
                                            profileWithDates[idToUse]
                                              ? {
                                                  ...item,
                                                  config: items,
                                                }
                                              : item
                                        ),
                                      });
                                    }}
                                    getOptionLabel={(
                                      option: any,
                                      options?: any[]
                                    ) =>
                                      typeof option === 'number' &&
                                      Array.isArray(options)
                                        ? (options.find(
                                            (o) => o.value === option
                                          )?.label ?? '')
                                        : typeof option === 'object'
                                          ? option.label
                                          : option
                                    }
                                  ></FilterSelect>
                                </FormControl>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  ))}
                  <Button
                    onClick={() =>
                      setter({
                        ...data,
                        [keyToUse]: data[keyToUse].map((item) =>
                          item[idToUse] === profileWithDates[idToUse]
                            ? {
                                ...item,
                                config: [
                                  ...(item.config || []),
                                  {
                                    method: null,
                                    rate: null,
                                    payee_id: null,
                                    multiplier: null,
                                  },
                                ],
                              }
                            : item
                        ),
                      })
                    }
                  >
                    Add custom method
                  </Button>
                </Box>
              </Box>
            ))}
            <FormControl key={field.id} sx={{ m: 0.5, width: 145 }}>
              <InputLabel id={`${field.id}-label`}>Add</InputLabel>
              <Select
                labelId={`${field.id}-label`}
                id={field.id}
                label="Add"
                multiple
                value={selectedProfiles}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onClose={handleAddSelected}
                onChange={(e) => {
                  setSelectedProfiles(
                    Array.isArray(e.target.value)
                      ? e.target.value.filter(Boolean)
                      : e.target.value
                  );
                }}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#333',
                  },
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {Array.isArray(selected) &&
                      (selected as string[]).map((value) => {
                        const item = dynamicSelects.find(
                          (option) => option.id === value
                        );
                        return (
                          <Chip
                            key={item ? item.str_id : value}
                            label={item ? item.name : value}
                          />
                        );
                      })}
                  </Box>
                )}
              >
                <Box
                  sx={{
                    mb: 0.5,
                    mx: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <TextField
                    sx={{ flex: 1, mr: 1 }}
                    label="Search"
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                  <Button
                    variant="outlined"
                    onClick={(e) => handleSelectAll(e)}
                    sx={{ mr: 1 }}
                  >
                    Select all
                  </Button>
                  <Button variant="outlined" onClick={handleAddSelected}>
                    Add selected
                  </Button>
                </Box>
                {field.nullable && (
                  <MenuItem value={''} key="null">
                    &nbsp;
                  </MenuItem>
                )}
                {filterOptions(dynamicSelects, query).map((option: any) => (
                  <MenuItem
                    value={option.id}
                    key={applyFunc(field.optionValuer, option)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompProfilesAdd;
