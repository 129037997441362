import {
  AttachFileOutlined,
  ReceiptOutlined,
  RequestQuoteOutlined,
  TableChartOutlined,
} from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { UIStateContext } from 'contexts/UIStateProvider';
import useBeforeUnloadPage from 'contexts/useBeforeunloadPage';
import { cloneDeep, isEqual } from 'lodash-es';
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useBeforeUnload } from 'react-use';
import {
  useOriginalFile,
  useOverrideFile,
  useSetOriginFile,
  useSetOverrideFile,
} from 'store/excelStore';
import validator from 'validator';

import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import API from '@/services/API';
import { DocumentTypes } from '@/types';

interface FromItemValueModel {
  value: any;
  error: boolean;
  required?: boolean;
}
interface FormStateModel {
  companies: FromItemValueModel;
  documentType: FromItemValueModel;
  originalFile: FromItemValueModel;
  overrideFile: FromItemValueModel;
  uploadOverride: FromItemValueModel;
  tag: FromItemValueModel;
}

const initialFormState: FormStateModel = {
  companies: { value: '', error: false, required: true },
  documentType: { value: '', error: false, required: true },
  originalFile: { value: [], error: false, required: true },
  overrideFile: { value: '', error: false },
  uploadOverride: { value: false, error: false },
  tag: { value: null, error: false },
};

const FileUploads = (
  { setCurrent, setFormModel, openSnackbar, formModel },
  ref
) => {
  const [loading, setLoading] = useState(false);
  const {
    role: [role],
  } = useContext(UIStateContext);

  const [formState, setFormState] = useState(initialFormState);
  const [newCompany, setNewCompany] = useState({
    value: '',
    error: false,
  });
  const [error, setError] = useState<boolean>(false);
  const [bankTotalAmounts, setBankTotalAmounts] = useState({});
  const [currentCompany, setCurrentCompany] = useState({
    label: '',
    value: '',
  });

  const [isAddingCompany, setIsAddingCompany] = useState(false);

  const { data: companies, refetch } = API.getBasicQuery('companies');
  const companiesPoster = API.getMutation('companies', 'POST');
  const companiesOptions = useMemo(() => {
    if (!companies) {
      return [];
    }
    return companies
      .map((company) => ({
        label: company.company_name,
        value: company.str_id,
      }))
      .sort((a, b) => (b.label > a.label ? -1 : 1));
  }, [companies]);

  const [formDirty, setFormDirty] = useState(false);
  const dirtyFn = useCallback(() => {
    const isChange = !isEqual(formState, initialFormState);
    setFormDirty(isChange);
    return isChange;
  }, [formState]);

  useBeforeUnload(dirtyFn, 'You have unsaved changes, are you sure?');
  useBeforeUnloadPage(formDirty, 'You have unsaved changes, are you sure?');

  const originFile = useOriginalFile();
  const overrideFile = useOverrideFile();
  const setOriginFile = useSetOriginFile();
  const setOverrideFile = useSetOverrideFile();

  /**
   * Handle form Item change
   */
  const handleChange = (field) => (event) => {
    let val = event.target.value;
    if (['uploadOverride'].includes(field)) {
      val = event.target.checked;
    } else if (['tag'].includes(field)) {
      val = event.target.checked ? 'test' : null;
    }
    setFormState((pre) => {
      const preState = cloneDeep(pre);
      preState[field].value = val;
      preState[field].error = false;
      return preState;
    });
  };

  /**
   * Handle file change
   */
  const onFileChange = (field) => (files) => {
    setFormState((pre) => {
      const preState = cloneDeep(pre);
      preState[field].value = files;
      preState[field].error = false;
      return preState;
    });
    const amountsMap = {};
    files.forEach((f) => {
      amountsMap[f.path] = '';
    });
    setBankTotalAmounts(amountsMap);
  };

  /**
   * Handle form submit
   */
  const handleSubmit = () => {
    let isValid = true;
    const entries = Object.entries(formState);
    entries.forEach(([field, value]) => {
      if (!value.value && (value as any).required) {
        isValid = false;
        setFormState((pre) => {
          const preState = cloneDeep(pre);
          preState[field].error = true;
          return preState;
        });
      }
    });
    if (!isValid) {
      console.error('Form validation error');
    } else {
      // store the file to global store
      const _originalFiles = formState.originalFile.value;
      const _overrideFiles = formState.overrideFile.value;
      if (_originalFiles.length) {
        setOriginFile(_originalFiles);
      }
      if (_overrideFiles) {
        setOverrideFile(_overrideFiles);
      }
      // Store current data to formModel
      setFormModel({
        company_str_id: formState.companies.value,
        document_type: formState.documentType.value,
        tag: formState.tag.value,
        form: cloneDeep(formState),
        bank_total_amounts: bankTotalAmounts,
      });
      // go to next step
      setCurrent((pre) => pre + 1);
    }
  };

  useEffect(() => {
    if (formModel && formModel.form) {
      setFormState(formModel.form);
    }

    if (formModel && formModel.bank_total_amounts) {
      setBankTotalAmounts(formModel.bank_total_amounts);
    }

    if (companiesOptions.length) {
      const company = companiesOptions.find(
        (company) => company.value === formModel.company_str_id
      );
      setCurrentCompany(company);
    }
  }, [formModel, companiesOptions]);

  const onAddCompany = (evt) => {
    evt.preventDefault();
    const companyName = newCompany.value;
    if (!companyName) {
      setNewCompany((pre) => ({ ...pre, error: true }));
      return;
    }
    const companyItem = {
      company_name: companyName,
    };
    setLoading(true);
    companiesPoster.mutate(companyItem, {
      onSuccess: (res) => {
        refetch(); // refresh companies list
        setLoading(false);
        setNewCompany({ value: '', error: false });
        openSnackbar(
          <Alert severity="success">Added new company {companyName}</Alert>
        );
        // Auto select the new company
        setFormState((pre) => {
          const preState = cloneDeep(pre);
          preState.companies.value = res.str_id;
          preState.companies.error = false;
          return preState;
        });
        setIsAddingCompany(false);
      },
      onError: (err) => {
        openSnackbar(<Alert severity="error">{err.message}</Alert>);
        setLoading(false);
      },
    });
  };

  const clearFile = () => {
    setOriginFile(null);
    setOverrideFile(null);
    setFormState(initialFormState);
  };

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
    clearFile,
  }));

  return (
    <div style={{ minWidth: 600, maxWidth: 1200 }}>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        {!isAddingCompany && (
          <FormControl
            required
            fullWidth
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'row',
            }}
            error={formState.companies.error}
          >
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Autocomplete
                id="company-search"
                options={companiesOptions}
                getOptionLabel={(option) => option && option.label}
                value={currentCompany}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    return;
                  }
                  setCurrentCompany(newValue);
                  handleChange('companies')({
                    target: { value: newValue.value },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company"
                    required
                    variant="outlined"
                    error={formState.companies.error}
                    helperText={formState.companies.error && 'Required'}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                fullWidth
              />
              <Button
                variant="outlined"
                sx={{ ml: 1, minWidth: 155, height: 40 }}
                onClick={() => {
                  setIsAddingCompany(true);
                }}
              >
                Add new company
              </Button>
            </Box>
          </FormControl>
        )}

        {isAddingCompany && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <TextField
              sx={{ flex: 1 }}
              label="New company"
              error={newCompany.error}
              value={newCompany.value}
              onChange={(evt) => {
                setNewCompany({ value: evt.target.value, error: false });
              }}
            />
            <Button
              sx={{ ml: 1 }}
              onClick={onAddCompany}
              variant={newCompany.value ? 'contained' : 'outlined'}
              disabled={!newCompany.value}
            >
              {loading ? 'Adding...' : 'Add'}
            </Button>
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => {
                setIsAddingCompany(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
        <FormControl
          required
          fullWidth
          sx={{ my: 1 }}
          error={formState.documentType.error}
        >
          <InputLabel>Document type</InputLabel>
          <Select
            value={formState.documentType.value}
            onChange={handleChange('documentType')}
            label="Document type"
          >
            <MenuItem value={DocumentTypes.STATEMENT}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <RequestQuoteOutlined sx={{ color: '#666' }} />
                &nbsp;&nbsp;Commissions
              </Box>
            </MenuItem>
            <MenuItem value={DocumentTypes.REPORT}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ReceiptOutlined sx={{ color: '#666' }} />
                &nbsp;&nbsp;Policies
              </Box>
            </MenuItem>
            <MenuItem value={DocumentTypes.COMPGRID}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TableChartOutlined sx={{ color: '#666' }} />
                &nbsp;&nbsp;Comp grid
              </Box>
            </MenuItem>
          </Select>
          {formState.documentType.error && (
            <FormHelperText error>Required</FormHelperText>
          )}
        </FormControl>

        <FactoryFileUpload
          label={formState.uploadOverride.value ? 'Original file' : ''}
          required
          error={formState.originalFile.error}
          curFile={originFile}
          onChange={onFileChange('originalFile')}
          multiple={true}
          showFileInfo={false}
        />
        <Box>
          {!!formState.originalFile.value.length &&
            formState.originalFile.value.map((f, index) => (
              <Box key={index} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '6px 0',
                    p: 1,
                    border: '1px dashed #ccc',
                    gap: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', alignContent: 'center' }}>
                    <AttachFileOutlined sx={{ pr: 1 }} />
                    {f.path} - {(f.size / 1024).toFixed(2)} KB
                  </Box>
                  <Box>
                    <TextField
                      label="Bank total"
                      sx={{ width: '100%' }}
                      value={bankTotalAmounts[f.path] || ''}
                      error={error}
                      helperText={error ? 'Invalid currency format' : ''}
                      onChange={(evt) => {
                        const val = evt.target.value;
                        const isValidCurrency = validator.isCurrency(val, {
                          allow_negatives: true,
                          thousands_separator: ',',
                          decimal_separator: '.',
                          allow_decimal: true,
                          digits_after_decimal: [1, 2],
                        });
                        if (!isValidCurrency) {
                          setError(true);
                        } else {
                          setError(false);
                        }
                        setBankTotalAmounts((pre) => {
                          const preState = cloneDeep(pre);
                          preState[f.path] = val;
                          return preState;
                        });
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
        {role === 'admin' && (
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              🔒
              <Box sx={{ width: '100%', ml: 1 }}>
                <Divider />
              </Box>
            </Box>
            {formState.uploadOverride.value && (
              <FactoryFileUpload
                label="Override file"
                curFile={overrideFile}
                onChange={onFileChange('overrideFile')}
              />
            )}
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch
                  checked={formState.uploadOverride.value}
                  onChange={handleChange('uploadOverride')}
                />
              }
              label="Upload override"
            />
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch
                  checked={formState.tag.value === 'test'}
                  onChange={handleChange('tag')}
                />
              }
              label="Test document"
            />
          </Box>
        )}
      </form>
    </div>
  );
};

export default forwardRef(FileUploads);
