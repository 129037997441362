import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

import { UIStateContext } from '@/contexts/UIStateProvider';

const ExpandButton = ({ expanded, setExpanded }) => (
  <IconButton
    sx={{ height: 24, width: 24 }}
    onClick={() => {
      setExpanded((prev) => !prev);
    }}
  >
    {expanded ? <ExpandLess /> : <ExpandMore />}
  </IconButton>
);

const AdminBox = ({
  title = '',
  bypass = false,
  expandable = false,
  expandableDefault = true,
  sx = {},
  children,
}) => {
  const [expanded, setExpanded] = useState(expandableDefault);
  const {
    role: [role],
  } = useContext(UIStateContext);

  if (role !== 'admin' && !bypass) return null;

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mt: 2,
        }}
      >
        {!title && <span>🔒</span>}
        <Box sx={{ width: '100%', ml: 1 }}>
          <Box>
            <Divider />
          </Box>
        </Box>
        {expandable && (
          <>
            {title ? (
              <>
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  onClick={() => {
                    setExpanded((prev) => !prev);
                  }}
                >
                  🔒 {title}
                </Typography>{' '}
                <ExpandButton expanded={expanded} setExpanded={setExpanded} />
              </>
            ) : (
              <ExpandButton expanded={expanded} setExpanded={setExpanded} />
            )}
            <Box sx={{ width: '100%' }}>
              <Box>
                <Divider />
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
};

export default AdminBox;
