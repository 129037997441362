export const dashboardWidgetOptions = {
  policiesInForce: 'policiesInForce',
  premiumsInForce: 'premiumsInForce',
  premiumsIssuePay: 'premiumsIssuePay',
  commissionsReceived: 'commissionsReceived',
  commissionsPaid: 'commissionsPaid',
  numPoliciesWithCommissions: 'numPoliciesWithCommissions',
  policiesWithReversedPayments: 'policiesWithReversedPayments',
  commissionsByCarrierMap: 'commissionsByCarrierMap',
  commissionsByWritingCarrierMap: 'commissionsByWritingCarrierMap',
  premiumsByCarrierMap: 'premiumsByCarrierMap',
  agentCommissions: 'agentCommissions',
  commissionsByTimeMap: 'commissionsByTimeMap',
  commissionsAccByTimeMap: 'commissionsAccByTimeMap',
  commissionsOutByTimeMap: 'commissionsOutByTimeMap',
  commissionsOutAccByTimeMap: 'commissionsOutAccByTimeMap',
  commissionsNetByTimeMap: 'commissionsNetByTimeMap',
  commissionsNetAccByTimeMap: 'commissionsNetAccByTimeMap',
  policiesByTimeMap: 'policiesByTimeMap',
  effectiveCommissionRateByCarrierArray:
    'effectiveCommissionRateByCarrierArray',
  effectiveCommissionRateByWritingCarrierArray:
    'effectiveCommissionRateByWritingCarrierArray',
};

export const TRANSACTION_STATUSES = {
  draft: 'Draft',
  pending: 'Pending',
  completed: 'Completed',
  failed: 'Failed',
  cancelled: 'Cancelled',
};

export const CALC_METHODS = {
  compGrid: 'Pay commission to grid level',
  compGridLevel: 'Share override to grid level',
  payoutRate: 'Pay a set rate',
  payHouseRate: 'Pay house rate',
  payOverrideUpToTotalRate: 'Pay override up to total rate',
  keepRate: 'Keep a set rate',
  overrideSplit: 'Split percentage',
  carrierGridSplitlRemainder: 'Pay to a grid level then split percentage',
  payoutRateIncentiveTiers: 'Pay based on incentive tier',
  referral: 'Referral',
};

export enum WidgetGroup {
  BOX = 'BOX',
  CHART = 'CHART',
  TABLE = 'TABLE',
}

export enum AccountIds {
  TRANSGLOBAL = 'XN9U5UtGrD5aovyEwNlHj',
  EDISON_RISK = 'tY4K6TGT8NH6yMREQf2XQ',
}