import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

const BreakdownByMonth = ({ schedule, setSchedule }) => {
  const perMonth = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
  return (
    <Box sx={{ display: 'flex', mr: 1 }}>
      {(schedule.breakdown ?? perMonth).map((month, i) => (
        <TextField
          key={`${schedule.year}-${i}`}
          label={`Mo ${i + 1}`}
          value={month}
          onChange={(e) => {
            const newSchedule = { ...schedule };
            if (!newSchedule.breakdown) {
              newSchedule.breakdown = perMonth;
            }
            newSchedule.breakdown[i] = e.target.value;
            setSchedule(newSchedule);
          }}
          sx={{ minWidth: 70, mr: '-1px' }}
          InputProps={{
            sx:
              i === 0
                ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                : i === 11
                  ? {
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }
                  : { borderRadius: 0 },
          }}
          inputProps={{
            style: { paddingLeft: 8, paddingRight: 8, textAlign: 'center' },
          }}
        />
      ))}
    </Box>
  );
};

const RateScheduleAnnual = ({ value: schedule, setValue }) => {
  return (
    <Box sx={{ mb: 2 }}>
      {schedule?.map((scheduleYear, i) => (
        <Box key={i} sx={{ mb: 1, display: 'flex' }}>
          <TextField
            label="Year"
            value={scheduleYear.year}
            onChange={(e) => {
              const newSchedule = [...schedule];
              newSchedule[i].year = e.target.value;
              setValue(newSchedule);
            }}
            sx={{ mr: 1, minWidth: 56, width: 56 }}
          />
          <TextField
            label="Rate"
            value={scheduleYear.rate}
            onChange={(e) => {
              const newSchedule = [...schedule];
              newSchedule[i].rate = e.target.value;
              setValue(newSchedule);
            }}
            sx={{ mr: 1, minWidth: 72, width: 72 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ ml: 0 }}>
                  %
                </InputAdornment>
              ),
            }}
          />
          {scheduleYear.rate > 0 && (
            <BreakdownByMonth
              schedule={scheduleYear}
              setSchedule={(newScheduleYear) =>
                setValue(
                  schedule.map((s, j) => (j === i ? newScheduleYear : s))
                )
              }
            />
          )}
          <TextField
            label="Max comm"
            value={scheduleYear.max_commission}
            onChange={(e) => {
              const newSchedule = [...schedule];
              newSchedule[i].max_commission = e.target.value;
              setValue(newSchedule);
            }}
            sx={{ mr: 1, minWidth: 88, width: 88 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end" sx={{ ml: 0, mr: 0.5 }}>
                  $
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            onClick={() => {
              const newSchedule = [...schedule];
              newSchedule.splice(i, 1);
              setValue(newSchedule);
            }}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      {schedule.length > 0 && schedule.some((s) => s?.rate > 0) > 0 && (
        <Box sx={{ ml: 24 }}>
          <Typography variant="caption">
            Monthly values for each year must add up to 100. Fraction may be
            used (e.g. 100 / 12 for 8.33...%).
          </Typography>
        </Box>
      )}
      <Button
        onClick={() =>
          setValue([
            ...schedule,
            {
              year: '',
              rate: '',
              breakdown: [
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
                '0',
              ],
            },
          ])
        }
      >
        Add year
      </Button>
    </Box>
  );
};

export default RateScheduleAnnual;
