import EnhancedDataView from '@/components/organisms/EnhancedDataView';

const CompanyView = () => {
  // can you help me conver the the above dataDesc to dataSpec, similar to AccountsView.js
  const dataSpec = {
    label: 'Companies',
    table: 'admin/companies',
    fields: {
      company_name: {
        label: 'Company Name',
        enabled: true,
      },
      id: {
        label: 'Id',
        enabled: true,
      },
      str_id: {
        label: 'Str Id',
        enabled: true,
      },
      account_id: {
        label: 'Account Id',
        enabled: true,
      },
      access: {
        label: 'Access',
        enabled: true,
      },
      company_products: {
        label: 'Products',
        enabled: true,
        formatter: (v) =>
          `${v.product_name} ${
            v.company_product_options.length
              ? `(${v.company_product_options.length} Options)`
              : ''
          }`,
      },
      documents: {
        label: 'Documents',
        enabled: true,
        formatter: (v) => v.filename,
      },
      created_at: {
        label: 'Created at',
        enabled: true,
        formatter: (s) => new Date(s).toLocaleString(),
        readOnly: true,
      },
    },
    queryChips: {
      all: {
        id: 'all',
        label: 'All',
        query: {},
      },
      global: {
        id: 'global',
        label: 'Global',
        query: {
          access: 'global',
        },
      },
      account: {
        id: 'account',
        label: 'Account',
        query: {
          access: 'account',
        },
      },
    },
  };
  return (
    <EnhancedDataView
      dataSpec={dataSpec}
      hideAdd
      hideSelectedCount
      enableMultiSelect={false}
      enableEdit={false}
    />
  );
};

export default CompanyView;
