import { javascript } from '@codemirror/lang-javascript';
import { ContentCopy } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import useBeforeUnloadPage from 'contexts/useBeforeunloadPage';
import copy from 'copy-to-clipboard';
import { clone, isEqual } from 'lodash-es';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { useBeforeUnload } from 'react-use';

import { BasicDialog } from '@/common';
import DynamicSelect from '@/components/DataForm/DynamicSelect';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import FieldMatcher from '@/components/molecules/FieldMatcher';
import RateScheduleAnnual from '@/components/molecules/RateScheduleAnnual';
import { UIStateContext } from '@/contexts/UIStateProvider';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { hasAccess } from '@/services/helpers';
import { useRoleStore } from '@/store';
import { FieldTypes, Roles } from '@/types';
import DragableSelect from './DragableSelect';
import FieldComponent from './FieldComponent';
import FieldRow from './FieldRow';
import NullCheckbox from './NullCheckbox';

const DataForm = ({
  dataDesc = {},
  fields,
  newData,
  setNewData,
  oldData,
  validateData = () => true,
  onCancel,
  onSave,
  onDelete,
  formModeOnly,
  embed = false,
  readOnly = false,
  fetchOnFieldChange = false,
}) => {
  const [formState, setFormState] = useState({});
  const [dynamicSelects, setDynamicSelects] = useState({});
  const [showDelConfirm, setShowDelConfirm] = useState(false);
  const [_searchParams, setSearchParams] = useSearchParams({});
  const [loading, setLoading] = useState(false);
  // @ts-ignore
  const { openSnackbar } = useOutletContext() ?? {};

  const navigate = useNavigate();
  const [dirty, setDirty] = useState(false);
  const { userRole } = useRoleStore();

  const dirtyFn = useCallback(() => {
    const isChange = !isEqual(newData, oldData);
    setDirty(isChange);
    return isChange;
  }, [newData, oldData]);

  useBeforeUnload(dirtyFn, 'You have unsaved changes, are you sure?');
  useBeforeUnloadPage(dirty, 'You have unsaved changes, are you sure?');

  const {
    role: [role],
  } = useContext(UIStateContext);

  const dynamicSelectsPoster = API.getMutation('dynamic_selects', 'POST', {
    gcTime: 1,
  });

  const formattedData = newData;

  const fieldIds = fields.flat().map((field) => field.id);

  const dynamicSelectVals = useMemo(
    () =>
      fields
        .flat()
        .filter((field) => field.type === 'dynamic-select' || field.table)
        .map((field) => ({
          table: field.table,
          queryParamValue: fieldIds.includes(field.queryParamValue)
            ? formattedData[field.queryParamValue]
            : field.queryParamValue,
          queryParamName: field.queryParamName,
        })),
    [formattedData, JSON.stringify(fieldIds)]
  );

  useEffect(
    () => {
      if (dynamicSelectVals.length > 0) getDynamicSelects(dynamicSelectVals);
    },
    fetchOnFieldChange
      ? [fields, JSON.stringify(dynamicSelectVals)]
      : [JSON.stringify(dynamicSelectVals)]
  );

  useEffect(() => {
    fields.flat().forEach((field) => {
      if (
        field.type === FieldTypes.DYNAMIC_SELECT &&
        Array.isArray(formattedData[field.id]) &&
        formattedData[field.id]?.[0]?.id &&
        field.id !== 'parent_relationships'
      ) {
        setNewData({
          ...formattedData,
          [field.id]: formattedData[field.id].map((e) => e.id),
        });
      }
    });
  }, [dynamicSelects]);

  useEffect(() => {
    return () => {
      dynamicSelectsPoster.abort();
    };
  }, []);

  const getDynamicSelects = async (dynamicSelectVals) => {
    const data = await dynamicSelectsPoster
      .mutateAsync(dynamicSelectVals)
      .catch((_err) => {});

    if (Array.isArray(data) && data.length > 0) {
      data.forEach((field) => {
        setDynamicSelects((prev) => ({
          ...prev,
          ...field,
        }));
      });
    }
  };

  const getFieldElement = useCallback(
    (field) => {
      if (field.type === FieldTypes.DIVIDER) {
        return field.access === 'admin' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mb: 1,
            }}
            key={field.id}
          >
            🔒
            <Box sx={{ width: '100%', ml: 1 }}>
              <Divider />
            </Box>
          </Box>
        ) : (
          <Divider key={field.id} sx={{ mt: 1, mb: 1 }} />
        );
      }
      if (field.type === FieldTypes.HEADING) {
        return (
          <Box key={field.id ?? field.label} sx={{ mb: 0.5 }}>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: 18 }}>
              {field.label}
            </Typography>
          </Box>
        );
      }
      if (field.type === FieldTypes.SUB_HEADING) {
        return (
          <Box key={field.id ?? field.label} sx={{ mb: 1 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 400, fontSize: 14, whiteSpace: 'nowrap' }}
            >
              {field.label}
            </Typography>
          </Box>
        );
      }
      if (field.type === FieldTypes.BOOLEAN) {
        return (
          <FieldRow key={field.id}>
            <Box sx={{ mb: 0, ml: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      readOnly ||
                      (typeof field.readOnly === 'function'
                        ? field.readOnly(newData)
                        : field.readOnly) ||
                      formattedData?.[`${field.id}-null`]
                    }
                    checked={formattedData?.[field.id] ?? false}
                    onChange={() =>
                      setNewData({
                        ...formattedData,
                        [field.id]: !formattedData?.[field.id],
                      })
                    }
                  />
                }
                label={field.label}
              />
            </Box>
            {field.enableNullCheckbox && (
              <NullCheckbox
                readOnly={readOnly}
                field={field}
                formattedData={formattedData}
                setNewData={setNewData}
              />
            )}
          </FieldRow>
        );
      }
      if (field.type === FieldTypes.DRAGABLE_SELECT) {
        return (
          <FieldRow key={field.id}>
            <DragableSelect data={field} field={newData} setter={setNewData} />
          </FieldRow>
        );
      }
      if (field.type === FieldTypes.SELECT) {
        return (
          <FieldRow key={field.id}>
            <Tooltip title={field.tip} arrow placement="right">
              <FormControl
                key={field.id}
                sx={{ width: '100%' }}
                required={field.required}
                disabled={
                  readOnly ||
                  (typeof field.readOnly === 'function'
                    ? field.readOnly(newData)
                    : field.readOnly)
                }
              >
                <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
                <Select
                  labelId={`${field.id}-label`}
                  id={field.id}
                  open={formState?.[field.id] || false}
                  onClose={() =>
                    setFormState({ ...formState, [field.id]: false })
                  }
                  endAdornment={
                    typeof field.endAdornment === 'function'
                      ? field.endAdornment(formattedData, field, setNewData)
                      : field.endAdornment
                  }
                  multiple={field.multiple ?? false}
                  onOpen={() =>
                    setFormState({ ...formState, [field.id]: true })
                  }
                  value={
                    Array.isArray(formattedData?.[field.id])
                      ? formattedData?.[field.id]
                      : field.multiple
                        ? []
                        : (formattedData?.[field.id] ?? field.default ?? '')
                  }
                  label={field.label}
                  onChange={(e) => {
                    setNewData({
                      ...formattedData,
                      [field.id]: e.target.value,
                    });
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {field.multiple
                        ? selected?.map((value) => (
                            <Chip
                              key={
                                typeof field.optionValuer === 'function'
                                  ? field.optionValuer(value)
                                  : value
                              }
                              label={
                                typeof field.optionFormatter === 'function'
                                  ? field.optionFormatter(value)
                                  : value
                              }
                              clickable={typeof field.linker === 'function'}
                              component={
                                typeof field.linker === 'function' ? 'a' : 'div'
                              }
                              href={
                                typeof field.linker === 'function'
                                  ? field.linker(value)
                                  : undefined
                              }
                            />
                          ))
                        : (field.options.find(
                            (option) => option.id === selected
                          )?.label ?? selected)}
                    </Box>
                  )}
                >
                  {field.options
                    ?.sort((a, b) =>
                      field.sort === false ? undefined : a < b ? -1 : 1
                    )
                    ?.filter((option) => option !== null)
                    ?.map((option) =>
                      typeof option === 'object' ? (
                        <MenuItem value={option.id} key={option.id}>
                          {option.label === '' ? (
                            <span>&nbsp;</span>
                          ) : (
                            option.label
                          )}
                        </MenuItem>
                      ) : (
                        <MenuItem value={option} key={option}>
                          {option === '' ? <span>&nbsp;</span> : option}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </Tooltip>
            {field.enableNullCheckbox && (
              <NullCheckbox
                readOnly={readOnly}
                field={field}
                formattedData={formattedData}
                setNewData={setNewData}
              />
            )}
          </FieldRow>
        );
      }
      if (field.type === FieldTypes.DYNAMIC_SELECT) {
        if (
          field.id === 'contacts_agent_commission_schedule_profiles' ||
          field.id === 'contacts_agent_commission_schedule_profiles_sets' ||
          field.id === 'contacts_agent_commission_profiles_names' ||
          field.id === 'parent_relationships'
        )
          return field.render(
            field,
            newData,
            setNewData,
            dynamicSelects[field.table]?.data ?? dynamicSelects[field.table]
          );
        return (
          <FieldRow key={field.id}>
            <DynamicSelect
              field={field}
              readOnly={readOnly}
              formattedData={formattedData}
              setNewData={setNewData}
              // @ts-ignore
              formState={formState}
              // @ts-ignore
              endAdornment={field.endAdornment}
              setFormState={setFormState}
              dynamicSelects={dynamicSelects}
              disabled={formattedData?.[`${field.id}-null`]}
              fullWidth={true}
            />
            {field.enableNullCheckbox && (
              <NullCheckbox
                readOnly={readOnly}
                field={field}
                formattedData={formattedData}
                setNewData={setNewData}
              />
            )}
          </FieldRow>
        );
      }
      if (field.type === FieldTypes.DATE) {
        return (
          <FieldRow key={field.id}>
            <BasicDatePicker
              label={field.label}
              value={
                formattedData?.[field.id]
                  ? new Date(formattedData?.[field.id])
                  : null
              }
              setValue={(e) => {
                setNewData({
                  ...formattedData,
                  [field.id]: e,
                });
              }}
              disabled={
                readOnly ||
                (typeof field.readOnly === 'function'
                  ? field.readOnly(newData)
                  : field.readOnly) ||
                formattedData?.[`${field.id}-null`]
              }
            />
            {field.enableNullCheckbox && (
              <NullCheckbox
                readOnly={readOnly}
                field={field}
                formattedData={formattedData}
                setNewData={setNewData}
              />
            )}
          </FieldRow>
        );
      }
      if (field.type === FieldTypes.FIELD_MATCHER) {
        return (
          <FieldMatcher
            key={field.id}
            value={newData?.[field.id] ?? []}
            setValue={(e) => {
              setNewData({
                ...newData,
                [field.id]: e,
              });
            }}
            fields={field.fields}
          />
        );
      }
      if (field.type === FieldTypes.RATE_SCHEDULE) {
        return (
          <RateScheduleAnnual
            key={field.id}
            value={newData?.[field.id] ?? []}
            setValue={(e) => {
              setNewData({
                ...newData,
                [field.id]: e,
              });
            }}
            fields={field.fields}
          />
        );
      }
      if (field.type === FieldTypes.CODE) {
        return (
          <Box sx={{ mb: 1 }} key={field.id}>
            <CodeMirror
              height="350px"
              value={newData?.[field.id]}
              width="100%"
              extensions={[javascript({ jsx: true })]}
              onChange={(e) => {
                setNewData({
                  ...newData,
                  [field.id]: e,
                });
              }}
            />
          </Box>
        );
      }
      if (field.type === FieldTypes.CUSTOM) {
        return (
          <FieldRow key={field.id}>
            {field.render(
              field,
              newData,
              setNewData,
              dynamicSelects[field.table]?.data ?? dynamicSelects[field.table]
            )}
          </FieldRow>
        );
      }
      return (
        <FieldComponent
          field={field}
          newData={newData}
          formattedData={formattedData}
          setNewData={setNewData}
          readOnly={readOnly}
          navigate={navigate}
          oldData={oldData}
          key={field.id}
        />
      );
    },
    [
      dynamicSelects,
      formState,
      formattedData,
      navigate,
      newData,
      oldData,
      setNewData,
    ]
  );

  if (!newData) return null;

  const fieldsWithValidationIssues = fields.filter(
    (field) =>
      (field.validator instanceof Function &&
        !field.validator(newData?.[field.id] || '')) ||
      (field.required && !newData?.[field.id])
  );

  return (
    <Box sx={{ mt: 0.75, maxWidth: 1200, flexGrow: 1 }}>
      <BasicDialog
        title="Delete record?"
        positiveLabel="Delete"
        open={showDelConfirm}
        onClose={(val) => {
          if (val) {
            onDelete();
          }
          setShowDelConfirm(false);
        }}
        bodyComponent={
          <Alert severity="warning">
            Are you sure you want to delete this record?
            <br />
            {dataDesc.onDeleteText ?? ''}
          </Alert>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!!fields.length &&
          fields.some((field) => field.enableNullCheckbox) && (
            <FieldRow>
              <Typography variant="caption">New value</Typography>
              <Typography variant="caption" sx={{ textAlign: 'right' }}>
                Clear data
              </Typography>
            </FieldRow>
          )}
        {fields.map((field) => {
          if (Array.isArray(field)) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                key={field[0].id}
              >
                {field.map((subField, i) => {
                  if (!hasAccess(subField.access, userRole, role === 'admin')) {
                    return null;
                  }
                  return (
                    <Box
                      sx={
                        i === field.length - 1
                          ? { width: '100%' }
                          : { width: '100%', mr: 1 }
                      }
                      key={subField.id}
                    >
                      {getFieldElement(subField)}
                    </Box>
                  );
                })}
              </Box>
            );
          } else {
            if (!hasAccess(field.access, userRole, role === 'admin')) {
              return null;
            }
            return getFieldElement(field);
          }
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 2,
          mt: 1,
        }}
      >
        <Box>
          {newData.id && !formModeOnly && (
            <Button
              sx={{ ml: 2, color: 'red' }}
              onClick={() => setShowDelConfirm(true)}
              disabled={readOnly}
            >
              Delete
            </Button>
          )}
          {newData.id && !formModeOnly && dataDesc.copyable && (
            <Button
              sx={{ ml: 2 }}
              onClick={() => {
                const fieldsToRemove = [
                  'id',
                  'str_id',
                  'sync_id',
                  'account_id',
                  'uid',
                  'created_at',
                  'created_by',
                  'updated_at',
                  'updated_by',
                  'state',
                  'document_id',
                  'import_id',
                ];
                dataDesc.fields.flat().forEach((field) => {
                  if (
                    dataDesc.table === 'statement_data' &&
                    field.id === 'report_data_id'
                  )
                    return;
                  if (
                    typeof field.readOnly === 'function'
                      ? field.readOnly(newData)
                      : field.readOnly
                  ) {
                    fieldsToRemove.push(field.id);
                  } else if (
                    dataDesc.table === 'report_data' &&
                    [
                      'children_report_data',
                      'commission_profile',
                      'commission_profile_id',
                      'document',
                      'effective_date',
                      'history',
                      'internal_id',
                      'reconciliation_data',
                      'parent_data',
                      'statement_data',
                    ].includes(field.id)
                  ) {
                    fieldsToRemove.push(field.id);
                  }
                });
                const newNewData = clone(newData);
                fieldsToRemove.forEach((field) => {
                  delete newNewData[field];
                });
                Object.keys(newNewData).forEach((field) => {
                  if (!dataDesc.fields.flat().find((f) => f.id === field)) {
                    delete newNewData[field];
                  }
                });

                const nameFields = [
                  'name',
                  'company_name',
                  'product_name',
                  'full_name',
                  'first_name',
                ];
                for (const field of nameFields) {
                  if (newNewData[field]) {
                    newNewData[field] = `Copy of ${newNewData[field]}`;
                    break;
                  }
                }
                setSearchParams((prev) => {
                  prev.delete('id');
                  return prev;
                });
                setNewData(newNewData);
              }}
              disabled={readOnly}
            >
              Create copy
            </Button>
          )}
        </Box>
        <Box>
          {!formModeOnly && (
            <Button sx={{ mr: 2 }} onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Tooltip
            title={
              fieldsWithValidationIssues.length > 0
                ? `Missing or invalid fields: ${fieldsWithValidationIssues
                    .map((field) => `"${field.label}"`)
                    .join(', ')}`
                : ''
            }
            arrow
          >
            <span>
              <Button
                variant="contained"
                onClick={async () => {
                  setLoading(true);
                  const res = await onSave();
                  setLoading(false);
                  if (res?.error) {
                    console.log('res', res);
                    openSnackbar(
                      <Alert severity="error">
                        Error encountered: {res?.error}
                      </Alert>
                    );
                  }
                }}
                disabled={
                  readOnly ||
                  fieldsWithValidationIssues.length > 0 ||
                  !validateData(newData) ||
                  loading
                }
              >
                {newData.id ||
                newData.str_id ||
                newData.key ||
                dataDesc.saveOnly
                  ? 'Save'
                  : 'Add'}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
      {userRole === Roles.ACCOUNT_ADMIN &&
        !embed &&
        Object.keys(oldData).length > 0 && (
          <Box
            sx={{
              fontSize: 10,
              pb: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Divider />
            <Typography variant="caption" sx={{ textAlign: 'center' }}>
              Debug info
            </Typography>
            <Typography variant="caption">
              Id:{' '}
              <Link
                component={RouterLink}
                to={`${location.pathname}?id=${oldData.str_id}`}
              >
                {oldData.str_id}
              </Link>
              <IconButton
                onClick={(e) => {
                  copy(oldData.str_id);
                }}
              >
                <ContentCopy sx={{ height: 14, width: 14 }} />
              </IconButton>
              {oldData.id}
              <IconButton
                onClick={(e) => {
                  copy(oldData.id);
                }}
              >
                <ContentCopy sx={{ height: 14, width: 14 }} />
              </IconButton>
            </Typography>
            {oldData.document_id && (
              <Typography variant="caption">
                Document:{' '}
                <Link
                  component={RouterLink}
                  to={`/documents?id=${oldData.document_id}`}
                >
                  {oldData.document_id}
                </Link>
                <IconButton
                  onClick={(e) => {
                    copy(oldData.document_id);
                  }}
                >
                  <ContentCopy sx={{ height: 14, width: 14 }} />
                </IconButton>
              </Typography>
            )}
            <Typography variant="caption">
              Created:{' '}
              {`${Formatter.dateTime(oldData.created_at)} by ${oldData.created_by}${oldData.created_proxied_by ? ` (via ${oldData.created_proxied_by})` : ''}`}
            </Typography>
            <Typography variant="caption">
              Updated:{' '}
              {`${Formatter.dateTime(oldData.updated_at)} by ${oldData.updated_by}${oldData.updated_proxied_by ? ` (via ${oldData.updated_proxied_by})` : ''}`}
            </Typography>
            {oldData.state && (
              <Typography variant="caption">State: {oldData.state}</Typography>
            )}
          </Box>
        )}
    </Box>
  );
};

export default memo(DataForm);
