import validator from 'validator';
import { Navigate } from 'react-router-dom';

import DataView from '@/components/DataView';
import API from '@/services/API';
import useSyncedFields from '@/contexts/useSyncedFields';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';

const dataDesc = {
  label: 'Companies',
  table: 'companies',
  editable: true,
  copyable: true,
  bulkAdd: true,
  validateData: (data) => data?.company_name,
  fields: [
    {
      id: 'company_name',
      label: 'Company Name',
      required: true,
    },
    {
      id: 'email',
      label: 'Email',
      validator: (val) => validator.isEmail(val) || val === '',
    },
    {
      id: 'website',
      label: 'Website',
      validator: (val) => validator.isURL(val) || val === '',
    },
    {
      id: 'phone',
      label: 'Phone',
      validator: (val) => validator.isMobilePhone(val, 'en-US') || val === '',
    },
    {
      id: 'address',
      label: 'Address',
    },
    {
      id: 'notes',
      label: 'Notes',
    },
    {
      id: 'type',
      label: 'Type',
      type: 'select',
      multiple: true,
      options: [
        'Carrier',
        'MGA/MGU',
        'General Agency',
        'Sub Agency',
        'Customer',
        'Partner',
      ],
      strToValue: (vals) => vals?.split(',')?.map((v) => v.trim()),
    },
    {
      id: 'divider',
      type: 'divider',
      access: 'admin',
      label: 'Admin',
    },
    {
      id: 'access',
      label: 'Access',
      type: 'select',
      options: ['account', 'global'],
      access: 'admin',
    },
  ],
};

const CompaniesView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const viewSettings = accountSettings?.pages_settings?.companies;

  const viewOnly = viewSettings?.read_only ?? false;

  const { syncedFields, isSyncedField } = useSyncedFields('companies');
  dataDesc.fields.forEach((field) => {
    if (syncedFields?.includes(field.id)) {
      field.readOnly = (data) => {
        return isSyncedField(data, field.id, data.config);
      };
      field.endAdornment = (data, field, setNewData) => (
        <SyncEndAdornment
          syncedFields={syncedFields}
          syncId={data?.sync_id}
          fieldId={field?.id}
          data={data}
          onChange={(newOverrideFields) => {
            setNewData({
              ...data,
              config: {
                ...(data.config || {}),
                overrideFields: newOverrideFields,
              },
            });
          }}
        />
      );
    }
  });

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  return (
    <DataView dataDesc={dataDesc} readOnly={viewOnly} viewOnly={viewOnly} />
  );
};

export default CompaniesView;
