import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';

const SearchableList = ({
  items,
  count = 0,
  page,
  setPage,
  search,
  setSearch,
  onKeyPress,
  sort,
  setSort,
  limit,
  handleItemClick,
  listItemText,
  selectedItem,
  title,
}) => {
  const handleShowMore = () => {
    setPage(page + 1);
  };

  const handleSortChange = (event, newSort) => {
    if (newSort !== null) {
      setPage(0);
      setSort(newSort);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 4, mb: 2, flexDirection: ['column', 'row'] }}
      >
        <Typography
          style={{
            fontWeight: 'medium',
            marginBottom: '10px',
            whiteSpace: 'normal',
            flex: 1,
            marginRight: '10px',
          }}
        >
          {title}
        </Typography>
        <ToggleButtonGroup
          value={sort}
          exclusive
          onChange={handleSortChange}
          sx={{ height: '20px' }}
          color="primary"
        >
          <ToggleButton
            value="asc"
            sx={{
              borderRadius: '12px',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            A-Z
          </ToggleButton>
          <ToggleButton
            value="desc"
            sx={{
              borderRadius: '12px',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            Z-A
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Tooltip title="Press Enter to search" arrow>
        <TextField
          label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={onKeyPress}
          fullWidth
        />
      </Tooltip>
      <Box
        overflow="auto"
        maxHeight="60vh"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <List>
          {items?.map((item, index) => (
            <ListItem
              key={index}
              onClick={() => handleItemClick(item)}
              sx={{
                cursor: 'pointer',
                borderRadius: '0 24px 24px 0',
                px: 0.5,
                py: 0.5,
                backgroundColor:
                  item === selectedItem ? '#2096f322' : 'inherit',
              }}
            >
              <ListItemText
                primary={listItemText(item)}
                sx={{ '& .MuiTypography-body1': { fontSize: '14px' } }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box display="flex" justifyContent="center" width="100%">
        {(page + 1) * limit <= count && (
          <Button onClick={handleShowMore}>Show more</Button>
        )}
      </Box>
    </>
  );
};

export default SearchableList;
