import { DeleteOutline } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';

import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { formatDatetime } from '@/services/DataTransformation/formatter';
import { stringAvatar } from '@/common/tools';

export interface MessageItem {
  user_str_id: string;
  message: string;
  processor_str_id: string;
  account_str_id: string;
  str_id: string;
  created_at: string;
  id: number;
}

export type MessageModel = Partial<MessageItem>;

export interface CommentDrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rowData: any;
  type: 'account' | 'processor';
}

const CommentDrawer = ({ open, setOpen, rowData, type }) => {
  const [notes, setNotes] = useState<string | undefined>();
  const [userMap, setUserMap] = useState({});
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | any
  >();

  const [loading, setLoading] = useState<boolean>(false);

  const [currentComment, setCurrentComment] = useState<MessageModel>();

  const [showList, setShowList] = useState<MessageModel[]>([]);
  const { data: comments = [], refetch } = API.getBasicQuery(
    'comments',
    `id=${rowData.str_id}&entity=${type}`,
    !!rowData.str_id && !!type
  );

  const { data: owners, isLoading } = API.getBasicQuery(
    'users/get_fintary_admins'
  );
  const commentsPoster = API.getMutation('comments', 'POST');
  const commentsDel = API.getMutation('comments', 'DELETE');
  const { openSnackbar } = useOutletContext<any>();

  useEffect(() => {
    if (comments.length) {
      setShowList([...comments]);
    }
  }, [comments]);

  useEffect(() => {
    if (owners && !isLoading) {
      const users = {};
      owners.forEach((o) => {
        users[o.uid] = Formatter.contact(o);
      });
      setUserMap(users);
    } else {
      setUserMap({});
    }
  }, [owners, isLoading]);

  const submit = async () => {
    if (notes && !loading) {
      setLoading(true);
      const item = {
        message: notes,
        type: type,
        account_str_id: type === 'account' ? rowData.str_id : null,
        processor_str_id: type === 'processor' ? rowData.str_id : null,
      };

      const res = await commentsPoster.mutateAsync(item);
      setLoading(false);
      if (res.error) {
        openSnackbar(res.error);
        return;
      }
      setShowList([...showList, res]);
      setNotes('');
    }
  };

  const delComment = async () => {
    if (currentComment) {
      setAnchorEl(undefined);
      const filtered = showList.filter((it) => it.id !== currentComment.id);
      setShowList(filtered);
      try {
        const res = await commentsDel.mutateAsync({
          ids: [currentComment.id],
        });
        if (res.error) {
          openSnackbar(res.error);
          return;
        }
        refetch();
        setCurrentComment(undefined);
      } catch (error) {
        openSnackbar(error);
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      sx={{
        zIndex: 9999,
      }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: '8px',
          height: '100vh',
          width: '460px',
          overflow: 'hidden',
        }}
      >
        <Box className="flex items-center justify-between p-4">
          <Typography variant="h6">Notes</Typography>
        </Box>
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <List>
            {showList.map((item) => (
              <ListItem
                sx={{
                  background: 'rgba(100, 100, 100, .04)',
                  marginBottom: '4px',
                }}
                key={item.str_id}
                onClick={(e) => {
                  setCurrentComment(item);
                  setAnchorEl(e.currentTarget);
                }}
              >
                {item.user_str_id && userMap[item.user_str_id] && (
                  <>
                    <ListItemAvatar>
                      <Avatar
                        {...stringAvatar(userMap[item.user_str_id])}
                      ></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={userMap[item.user_str_id]}
                      secondary={
                        <>
                          <Typography
                            variant="body2"
                            sx={{ color: '#333', whiteSpace: 'pre-wrap' }}
                          >
                            {item.message}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {formatDatetime(item.created_at)}
                          </Typography>
                        </>
                      }
                    />
                  </>
                )}
              </ListItem>
            ))}
          </List>
          <Popover
            id="p"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{ zIndex: 9999, padding: '2px' }}
          >
            <IconButton className="ml-1" onClick={delComment}>
              <DeleteOutline />
            </IconButton>
          </Popover>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'flex-end',
          }}
        >
          <TextField
            id="standard-multiline-static"
            label="Notes"
            multiline
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
          />

          <LoadingButton
            onClick={submit}
            loading={loading}
            disabled={!notes?.trim()}
            variant="contained"
            sx={{ width: '100px' }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CommentDrawer;
