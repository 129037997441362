import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingCircle = ({ size = 40 }) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', my: 2, width: '100%' }}
    >
      <CircularProgress disableShrink size={size} />
    </Box>
  );
};

export default LoadingCircle;
