import { Close as CloseIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { nanoid } from 'nanoid';
import { useState } from 'react';

import { FilterSelect } from '@/common';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import Statements from '@/services/Statements';
import FieldMatcher from '@/components/molecules/FieldMatcher';
import { useAccountStore } from '@/store';

type ContactsMemosAddProps = {
  data: any;
  setter: any;
  field: any;
  dynamicSelects: any;
};

const defaultMemo = {
  approver_name: '',
  description: '',
  recorded_by_user_id: '',
  start_date: null,
  remarks: '',
};

const statements = new Statements('insurance');
const statementFields = Object.values(statements.fields);

const ContactsMemosAdd: React.FC<ContactsMemosAddProps> = ({
  data,
  field,
  setter,
  dynamicSelects,
}) => {
  const { selectedAccount } = useAccountStore();

  const [approvedInputs, setApprovedInputs] = useState<any>({});
  const contactMemos = data?.contact_memos ?? [];
  const { data: _contacts } = API.getBasicQuery(
    'contacts',
    'is_dynamic_select=true'
  );
  const contacts: string[] = [
    ...new Set<string>(
      _contacts?.data?.map((c) =>
        Formatter.contact(c, {
          incl_email: true,
          account_id: selectedAccount?.accountId,
        })
      ) ?? []
    ),
  ];

  const { data: users } = API.getBasicQuery(
    'users/get_account_users',
    'state=active'
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            px: 1,
            pt: 0.5,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {contactMemos.map((memo, index) => (
              <Box
                key={`${memo.id}-${index}`}
                sx={{
                  m: 0.5,
                  p: 1,
                  borderStyle: 'solid',
                  borderColor: 'silver',
                  borderWidth: 1,
                  borderRadius: 4,
                  display: 'inline-block',
                  width: '100%',
                  backgroundColor: '#2196f30a',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  sx={{ ml: 0.5 }}
                >
                  <Typography variant="body2"></Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                    }}
                  >
                    <BasicDatePicker
                      label="Date"
                      value={memo.start_date}
                      setValue={(e) => {
                        setter({
                          ...data,
                          contact_memos: data.contact_memos.map((item) =>
                            item.id === memo.id
                              ? { ...item, start_date: e }
                              : item
                          ),
                        });
                      }}
                      sx={{ my: 0.5, width: 160 }}
                    />
                    <IconButton
                      onClick={() => {
                        setter({
                          ...data,
                          contact_memos: contactMemos.filter(
                            (cm) => cm.id !== memo.id
                          ),
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <TextField
                    label="Description"
                    variant="outlined"
                    value={memo.description}
                    onChange={(e) => {
                      setter({
                        ...data,
                        contact_memos: data.contact_memos.map((item) =>
                          item.id === memo.id
                            ? { ...item, description: e.target.value }
                            : item
                        ),
                      });
                    }}
                    fullWidth
                    multiline
                    sx={{ my: 0.5 }}
                  />
                  <TextField
                    label="Remarks"
                    variant="outlined"
                    value={memo.remarks}
                    onChange={(e) => {
                      setter({
                        ...data,
                        contact_memos: data.contact_memos.map((item) =>
                          item.id === memo.id
                            ? { ...item, remarks: e.target.value }
                            : item
                        ),
                      });
                    }}
                    fullWidth
                    multiline
                    sx={{ my: 0.5 }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Autocomplete
                      options={[
                        ...new Set<string>([
                          ...(contacts ?? []),
                          memo.approver_name,
                          approvedInputs[memo.id] ?? '',
                          '',
                        ]),
                      ]}
                      value={memo.approver_name ?? ''}
                      onChange={(e, val) => {
                        setter({
                          ...data,
                          contact_memos: data.contact_memos.map((item) =>
                            item.id === memo.id
                              ? { ...item, approver_name: val }
                              : item
                          ),
                        });
                      }}
                      getOptionLabel={(o) => o}
                      inputValue={approvedInputs[memo.id]}
                      onInputChange={(event, newInputValue) => {
                        setApprovedInputs({
                          ...approvedInputs,
                          [memo.id]: newInputValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Approved by"
                          placeholder="Select approver"
                        />
                      )}
                      sx={{ mt: 0.5, mr: 0.5, width: '100%' }}
                    />
                    <FilterSelect
                      label="Recorded by"
                      placeholder="Select user"
                      options={[...(users ?? []), ''] ?? ['']}
                      getOptionLabel={(o) =>
                        Formatter.contact(
                          typeof o === 'string' && users
                            ? users?.find((u) => u.uid === o)
                            : o,
                          {
                            incl_email: true,
                            account_id: selectedAccount?.accountId,
                          }
                        )
                      }
                      value={memo.recorded_by_user_id}
                      onChange={(e) => {
                        setter({
                          ...data,
                          contact_memos: data.contact_memos.map((item) =>
                            item.id === memo.id
                              ? { ...item, recorded_by_user_id: e?.data?.uid }
                              : item
                          ),
                        });
                      }}
                      sx={{ mt: 0.5, ml: 0.5, width: '100%' }}
                    />
                  </Box>
                  <Divider sx={{ mt: 1 }} />
                  <Tooltip
                    placement="right"
                    arrow
                    title="Tag commission entries based on criteria"
                  >
                    <Typography variant="caption" sx={{ cursor: 'default' }}>
                      Memo tagging ⓘ
                    </Typography>
                  </Tooltip>
                  <FieldMatcher
                    value={memo?.match_criteria ?? []}
                    setValue={(e) => {
                      setter({
                        ...data,
                        contact_memos: data.contact_memos.map((item) => {
                          return item.id === memo.id
                            ? { ...item, match_criteria: e }
                            : item;
                        }),
                      });
                    }}
                    fields={statementFields}
                    addLabel="Add criteria"
                  />
                </Box>
              </Box>
            ))}
            <FormControl key={field.id} sx={{ m: 0.5, width: 30 }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setter({
                    ...data,
                    contact_memos: [
                      ...(data.contact_memos ?? []),
                      { ...defaultMemo, id: nanoid() },
                    ],
                  });
                }}
              >
                Add
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactsMemosAdd;
