import { Autocomplete, TextField } from '@mui/material';

import API from '@/services/API';

interface AutoCompleteCompaniesProps {
  value: any;
  onChange: (event: any, newValue: string | null) => void;
  inputValue?: string;
  onInputChange?: (event: any, newInputValue: string) => void;
}

const AutoCompleteCompanies = ({
  value,
  onChange,
  inputValue,
  onInputChange,
}: AutoCompleteCompaniesProps) => {
  const { data } = API.getBasicQuery('companies');
  return (
    data?.length && (
      <Autocomplete
        disablePortal
        id="company"
        options={data}
        isOptionEqualToValue={(option, value) =>
          option?.str_id === value?.str_id
        }
        renderInput={(params) => <TextField {...params} label="Company" />}
        getOptionLabel={(option) =>
          `${option.company_name} ${
            option?.company_id ? `(${option?.company_id})` : ''
          }` ?? option
        }
        value={value}
        inputValue={inputValue}
        onChange={onChange}
        onInputChange={onInputChange}
      />
    )
  );
};

export default AutoCompleteCompanies;
