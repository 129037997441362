import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useState } from 'react';
import Validator from 'common/Validator';
import { clone } from 'lodash-es';

import MultiSelect from '@/components/molecules/MultiSelect';

const transformers = [
  {
    id: 'standardizeProduct',
    label: 'Standardize product',
    params: {
      mapping: {
        default: '{}',
        multiline: true,
        validator: Validator.json,
      },
    },
  },
  {
    id: 'standardizeProductType',
    label: 'Standardize product type',
    params: {
      mapping: {
        default: '{}',
        multiline: true,
        validator: Validator.json,
      },
    },
  },
  { id: 'toLowerCase', label: 'Convert to lower case' },
  { id: 'toUpperCase', label: 'Convert to upper case' },
  { id: 'removeDashes', label: 'Remove dashes' },
  { id: 'removeSpaces', label: 'Remove spaces' },
  { id: 'removeCommas', label: 'Remove commas' },
  { id: 'removePeriods', label: 'Remove periods' },
  { id: 'trimZeros', label: 'Trim zeros (leading and trailing)' },
  { id: 'removeLetters', label: 'Remove letters (6+ numbers required)' },
  { id: 'removedashnn', label: 'Remove "-##" and "_##"' },
  { id: 'removespacenn', label: 'Remove " ##"' },
  {
    id: 'removeFromEnd',
    label: 'Remove last n characters',
    params: {
      n: { default: 1, formatter: (val) => (val ? parseFloat(val) : 1) },
    },
  },
  {
    id: 'slice',
    label: 'Extract a section of the string',
    params: {
      indexStart: {
        default: 0,
        formatter: (val) => (val ? parseFloat(val) : 0),
      },
      indexEnd: {
        default: null,
        formatter: (val) => (val ? parseFloat(val) : null),
      },
    },
  },
  { id: 'normalizeCompany', label: 'Normalize company names' },
  { id: 'normalizeSymbols', label: 'Normalize symbols (&)' },
];

const emptyRow = { field: '', transformers: [], params: '' };

const KeyConfig = ({ fieldId, data: rowData, setter, fields }) => {
  const [editingParam, setEditingParam] = useState(null);
  const [validationError, setValidationError] = useState(null);

  const data =
    typeof rowData[fieldId] === 'string'
      ? [clone(emptyRow)]
      : (rowData[fieldId] ?? [clone(emptyRow)]);

  const handleSave = () => {
    const transformer = transformers.find(
      (t) => t.id === editingParam.transformerId
    );
    const param = transformer?.params?.[editingParam.key];

    if (param?.validator) {
      const isValid = param.validator(editingParam.value);
      if (!isValid) {
        setValidationError('Invalid input ');
        return;
      }
    }

    const newData = [...data];
    const datum = newData[editingParam.datumIndex];
    datum.params = datum.params || {};
    datum.params[editingParam.transformerId] = {
      ...datum.params[editingParam.transformerId],
      [editingParam.key]: editingParam.value,
    };
    setter({ ...rowData, [fieldId]: newData });
    setEditingParam(null);
    setValidationError(null);
  };

  return (
    <Box key={fieldId}>
      {data?.map((datum, i) => (
        <Box key={i} sx={{ mb: 1, display: 'flex' }}>
          <FormControl sx={{ mr: 2 }}>
            <InputLabel id={datum.id}>Field</InputLabel>
            <Select
              label="Field"
              labelId={datum.id}
              value={datum.field}
              onChange={(e) => {
                const newdatums = [...data];
                newdatums[i].field = e.target.value;
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              sx={{ minWidth: 180 }}
            >
              {fields?.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {field.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex' }}>
            <MultiSelect
              label="Transformers"
              values={transformers}
              formatter={(transformer, isOpen) => {
                return isOpen ? (
                  <div>
                    {transformer.label}
                    <Box>
                      {Object.entries(transformer.params || {})?.map(
                        ([key, param]) => (
                          <TextField
                            key={key}
                            label={key}
                            defaultValue={param.default}
                            value={datum?.params[transformer.id]?.[key]}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              if (param.multiline) {
                                setEditingParam({
                                  transformerId: transformer.id,
                                  key,
                                  value:
                                    datum?.params[transformer.id]?.[key] ||
                                    param.default,
                                  datumIndex: i,
                                });
                              }
                            }}
                            InputProps={{
                              readOnly: param.multiline,
                            }}
                            onChange={(e) => {
                              const transformers = [...rowData[fieldId]];
                              transformers.map((r) => {
                                if (r.field === datum.field) {
                                  r.params = r.params || {};
                                  r.params[transformer.id] = {
                                    ...r.params[transformer.id],
                                    [key]: param.formatter
                                      ? param.formatter(e.target.value)
                                      : e.target.value,
                                  };
                                }
                              });
                              setter({ ...rowData, [fieldId]: transformers });
                            }}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                            sx={{
                              mr: 2,
                              width: param.multiline ? 360 : 120,
                            }}
                            error={
                              param.validator
                                ? !param.validator(
                                    datum?.params[transformer.id]?.[key]
                                  )
                                : false
                            }
                          />
                        )
                      )}
                    </Box>
                  </div>
                ) : (
                  transformer.label
                );
              }}
              valuer={(transformer) => transformer.id}
              selectedValues={data[i].transformers}
              setSelectedValues={(newValues) => {
                const newdatums = [...data];
                newdatums[i].transformers =
                  typeof newValues === 'string'
                    ? newValues.split(',')
                    : newValues;
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              sx={{ width: 160, mr: 2 }}
            />
          </Box>

          <IconButton
            onClick={() => {
              const newdatums = [...data];
              newdatums.splice(i, 1);
              setter({ ...rowData, [fieldId]: newdatums });
            }}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button
        onClick={() =>
          setter({ ...rowData, [fieldId]: [...data, clone(emptyRow)] })
        }
      >
        Add
      </Button>

      <Dialog
        open={!!editingParam}
        fullWidth
        maxWidth="lg"
        onClose={() => {
          setEditingParam(null);
          setValidationError(null);
        }}
      >
        <DialogTitle>Edit {editingParam?.key}</DialogTitle>
        <DialogContent sx={{ pb: 1 }}>
          <TextField
            fullWidth
            multiline
            minRows={16}
            maxRows={24}
            value={editingParam?.value}
            onChange={(e) => {
              setEditingParam({ ...editingParam, value: e.target.value });
              setValidationError(null);
            }}
            error={!!validationError}
            helperText={validationError}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditingParam(null);
              setValidationError(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default KeyConfig;
