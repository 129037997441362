import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { exportToCsv } from '@/services/helpers';
import { auth } from '@/firebase';

const DownloadsPage = () => {
  const [downloadsMessage, setDownloadsMessage] = useState(
    'Your donwload will begin shortly.'
  );

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const downloadType = params.get('download_type');
    const fileName = params.get('file_name');

    const fetchData = async () => {
      if (!downloadType || !fileName) {
        setDownloadsMessage('Download not available');
      } else {
        const idToken = await auth.currentUser?.getIdToken(true);
        if (idToken) {
          const extraParams = new Map(
            Object.entries({
              download_type: downloadType,
              file_name: fileName,
            })
          );
          await exportToCsv(
            { extraParams },
            {
              idToken,
              endpoint: 'custom_download',
              exportOptions: { fileName: fileName },
            }
          );
        }
      }
    };

    fetchData();
  }, [location]);

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        <Typography variant="h5">{downloadsMessage}</Typography>
      </Box>
    </Box>
  );
};

export default DownloadsPage;
