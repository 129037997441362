import { Navigate } from 'react-router-dom';

import DataView from '@/components//DataView';
import Formatter from '@/services/Formatter';
import DataTransformation from '@/services/DataTransformation';
import { FieldTypes } from '@/types';
import API from '@/services/API';

const Normalizer = DataTransformation;

const dataDesc = {
  label: 'Agent incentive tiers',
  table: 'schedules/agents/incentive_tiers',
  editable: true,
  copyable: true,
  fields: [
    { id: 'name', label: 'Name' },
    {
      id: 'companies',
      label: 'Carriers',
      type: 'dynamic-select',
      table: 'companies',
      field: 'id',
      multiple: true,
      formatter: (val, collectionVals = []) => {
        const _val = typeof val === 'object' ? val?.id : val;
        if (
          _val &&
          Array.isArray(collectionVals) &&
          collectionVals.length > 0
        ) {
          return (
            collectionVals?.find((company) => company.id === _val)
              ?.company_name || 'Not found'
          );
        }
        return val;
      },
      optionFormatter: (option) => option.company_name,
      optionValuer: (option) => option.id,
    },
    [
      {
        id: 'threshold_min',
        label: 'Threshold min',
        normalizer: Normalizer.normalizeCurrency,
        formatter: Formatter.currency,
        type: FieldTypes.CURRENCY,
      },
      {
        id: 'threshold_max',
        label: 'Threshold max',
        normalizer: Normalizer.normalizeCurrency,
        formatter: Formatter.currency,
        type: FieldTypes.CURRENCY,
      },
    ],
    {
      id: 'calculation_basis',
      label: 'Calculation basis',
      type: 'select',
      options: [
        { id: 'annual_premium', label: 'Annual premium' },
        { id: 'target_premium', label: 'Target premium' },
        { id: 'commissions', label: 'Commissions' },
      ],
    },
    {
      id: 'lookback_units',
      label: 'Qualification period',
      type: 'select',
      sort: false,
      options: ['Annually', 'Quarterly', 'Monthly'],
    },
    [
      {
        id: 'start_date',
        label: 'Start date',
        type: 'date',
        formatter: Formatter.date,
      },
      {
        id: 'end_date',
        label: 'End date',
        type: 'date',
        formatter: Formatter.date,
      },
    ],
    { id: 'notes', label: 'Notes' },
    { id: 'divider', type: 'divider' },
    [
      {
        id: 'created_at',
        label: 'Created',
        formatter: (val) => new Date(val).toLocaleString(),
        readOnly: true,
      },
      {
        id: 'updated_at',
        label: 'Updated',
        formatter: (val) => new Date(val).toLocaleString(),
        readOnly: true,
      },
    ],
  ],
};

const IncentivesTiersView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.incentives_schedules;
  const viewOnly = viewSettings?.read_only ?? false;

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  return (
    <DataView
      dataDesc={dataDesc}
      viewOnly={viewOnly}
      readOnly={viewOnly}
      hideExport
    />
  );
};

export default IncentivesTiersView;
