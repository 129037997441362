import { auth, firestore } from '@/firebase';

const Datastore = {};

const checkUser = () => {
  if (!auth?.currentUser) {
    throw new Error('No current user');
  }
};

Datastore.addData = async (collection, data, validation) => {
  checkUser();
  if (validation instanceof Function && !validation(data)) {
    throw new Error('Invalid data');
  }
  // if (!validator.isEmail(contact.email)) {
  //   throw new Error(`Invalid email: ${contact.email}`);
  // }
  const impersonatedUser = JSON.parse(localStorage.getItem('customLoginUser'));
  const user = impersonatedUser ?? auth.currentUser;
  if (data.id === auth.currentUser.uid) {
    data.id = user.uid;
  }
  const res = await firestore.collection(collection).add({
    ...data,
    uid: user.uid,
    state: 'active',
    created: new Date().getTime(),
    updated: new Date().getTime(),
  });
  return res.id;
};

Datastore.addOrUpdateData = async (collection, data, validation) => {
  checkUser();
  if (validation instanceof Function && !validation(data)) {
    throw new Error('Invalid data');
  }
  // If doc key is uid, swap with impersonatedUser.uid
  const impersonatedUser = JSON.parse(localStorage.getItem('customLoginUser'));
  const user = impersonatedUser ?? auth.currentUser;
  if (data.id === auth.currentUser.uid) {
    data.id = user.uid;
  }
  const res = await firestore
    .collection(collection)
    .doc(data.id)
    .set(
      {
        ...data,
        uid: user.uid,
        state: 'active',
        created: new Date().getTime(),
        updated: new Date().getTime(),
      },
      { merge: true }
    );
  return res;
};

Datastore.updateData = async (collection, { id, ...data }) => {
  checkUser();
  if (!id) {
    throw new Error('id required');
  }
  // If doc key is uid, swap with impersonatedUser.uid
  const impersonatedUser = JSON.parse(localStorage.getItem('customLoginUser'));
  const user = impersonatedUser ?? auth.currentUser;
  const docRef = firestore
    .collection(collection)
    .doc(id === auth.currentUser.uid ? user.uid : id);
  const doc = await docRef.get();
  if (!doc.exists) {
    throw new Error(`${id} not found.`);
  }
  await docRef.update({ ...data, updated: new Date().getTime() });
};

export default Datastore;
