import { Box } from '@mui/material';

const FieldRow = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      mb: 1,
      width: '100%',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </Box>
);

export default FieldRow;
