import { useCallback } from 'react';
import {
  useBeforeUnload as _useBeforeUnload,
  unstable_usePrompt as usePrompt,
} from 'react-router-dom';

export default function useBeforeUnloadPage(
  doBlock: boolean = false,
  message?: string
) {
  _useBeforeUnload(
    useCallback(
      (e) => {
        if (doBlock) {
          e.preventDefault();
          return (e.returnValue = '');
        }
      },
      [doBlock]
    )
  );

  usePrompt({
    when: doBlock,
    message: message || 'Discard unsaved changes?',
  });
}
