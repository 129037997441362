import { Alert, Box, LinearProgress, Snackbar } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import API from '@/services/API';

export const DataProcessingTaskProgress = (props: {
  taskId: string;
  onFinish: () => void;
}) => {
  const { taskId, onFinish } = props;

  const isRunning = (task) => {
    return ['pending', 'processing'].includes(task.status);
  };

  const getSeverity = (task: { status: string }) => {
    if (task.status === 'completed') {
      return 'success';
    } else if (task.status === 'error') {
      return 'error';
    } else if (task.status === 'pending') {
      return 'warning';
    }
    return 'info';
  };

  const queryKey = ['data_processing', taskId];
  const { data } = useQuery<{ type; status; total; success; failed }[]>({
    queryKey,
    queryFn: () => API.get('data_processing', `id=${taskId}`) as any,
    enabled: true,
    refetchInterval: (data) => {
      if (data && data?.state?.data && !isRunning(data?.state?.data[0])) {
        return false;
      }
      return 3000;
    },
  });
  const [task] = data || [];
  if (!task) {
    return null;
  }

  const severity = getSeverity(task);
  return (
    <>
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Box>
          <Alert
            severity={severity as any}
            onClose={onFinish}
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                minWidth: '200px',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <span>
                TaskID: {taskId} <br /> TaskType: {task.type} <br />
                TaskStatus: {task?.status} <br />
                {task?.total &&
                  `Progress:
                ${(task?.success ?? 0) + (task?.failed ?? 0)}/${task?.total}${task?.failed ? ` (${task?.failed} failed)` : ''}`}
              </span>
            </Box>
          </Alert>
          {isRunning(task) ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}
        </Box>
      </Snackbar>
    </>
  );
};
