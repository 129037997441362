import { useMemo, useState, createContext } from 'react';

export type UIStateContext = {
  commissions: any[];
  commissionDetails: any[];
  role: any[];
  appDrawerExpanded: any[];
};
export const UIStateContext = createContext<UIStateContext>(
  {} as UIStateContext
);

const UIStateProvider = ({ children }) => {
  const [appDrawerExpanded, setAppDrawerExpanded] = useState({});
  const [commissions, setCommissions] = useState({});
  const [commissionDetails, setCommissionDetails] = useState({});
  const [role, setRole] = useState('default');

  const store = useMemo(
    () => ({
      commissions: [commissions, setCommissions],
      commissionDetails: [commissionDetails, setCommissionDetails],
      role: [role, setRole],
      appDrawerExpanded: [appDrawerExpanded, setAppDrawerExpanded],
    }),
    [commissions, commissionDetails, role, appDrawerExpanded]
  );

  return (
    <UIStateContext.Provider value={store}>{children}</UIStateContext.Provider>
  );
};

export default UIStateProvider;
