import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import API from '@/services/API';

const DataUpdateTools: React.FC = () => {
  const [loading, setloading] = useState(false);

  const runCompensationTypePoster = API.getMutation(
    'admin/post-reconciliation/compensation-type',
    'POST'
  );

  const { openSnackbar } = useOutletContext() as any;

  return (
    <Box>
      <Box>
        <Typography sx={{ m: 0.5 }}>
          Commissions: Compensation type assignment
        </Typography>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={async () => {
            setloading(true);
            const response = await runCompensationTypePoster.mutateAsync({});
            if (response.error) {
              openSnackbar(response.error);
            } else {
              openSnackbar(
                `Compensation type updated for ${response.data} commissions line items.`
              );
            }
            setloading(false);
          }}
          sx={{ mr: 1 }}
        >
          Update
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default DataUpdateTools;
