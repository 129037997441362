import {
  AccountCircleOutlined,
  AccountTreeOutlined,
  AdminPanelSettingsOutlined,
  ArrowForwardIosSharp,
  BookmarksOutlined,
  BusinessOutlined,
  CloudUploadOutlined,
  ConstructionOutlined,
  ContrastRounded,
  CurrencyExchangeOutlined,
  DeveloperBoard,
  DynamicFeedOutlined,
  EventNoteOutlined,
  FilePresent,
  FilterAltOutlined,
  FormatAlignJustifyOutlined,
  FormatListBulletedOutlined,
  GroupsOutlined,
  History,
  SaveAltOutlined,
  InsightsOutlined,
  LeaderboardOutlined,
  ListAlt,
  Logout,
  MarkChatRead,
  Merge,
  MultilineChartOutlined,
  PaymentOutlined,
  PaymentsOutlined,
  PeopleOutlined,
  ReadMore,
  ReceiptLongOutlined,
  ReceiptOutlined,
  RequestQuoteOutlined,
  Route,
  Rule,
  SaveOutlined,
  SellOutlined,
  SettingsOutlined,
  StorageOutlined,
  StyleOutlined,
  TableChartOutlined,
  ViewCarouselOutlined,
  ViewColumnOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AccountIds } from 'common/constants';
import { access } from 'fs';

import BaseModal from '@/components/UploadModal';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { auth } from '@/firebase';
import API from '@/services/API';
import UILabels from '@/services/UILabels';
import { hasAccess } from '@/services/helpers';
import { useAccountStore, useMenuStore, useRoleStore } from '@/store';
import { Roles } from '@/types';

const FintaryAdminOnlyIcon = ({ access }) => {
  const isDesktop = useMediaQuery('(min-width:600px)');
  return Array.isArray(access) &&
    access?.length === 1 &&
    access?.includes(Roles.FINTARY_ADMIN) ? (
    <span
      style={{
        color: 'transparent',
        textShadow: '0 0 0 #e8e8e8',
      }}
    >
      🔒
    </span>
  ) : null;
};

const AppDrawer = ({ openSnackbar, open }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([[]]);
  const { menuOpen, setMenuOpen } = useMenuStore();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const drawerWidth = 200;
  const theme = useTheme();
  const [loginAsUser, setLoginAsUser] = useState<any>();

  const { userRole } = useRoleStore();
  const { selectedAccount } = useAccountStore();
  const mode = selectedAccount?.accountMode;
  const enableCompGridManagement =
    selectedAccount?.accountId === AccountIds.TRANSGLOBAL;

  const { data: accountSettings, isFetched: accountSettingsReady } =
    API.getBasicQuery(`accounts/settings`);

  const { refetch: refetchUser } = API.getBasicQuery('admin/check');

  const {
    role: [role, setRole],
    appDrawerExpanded: [expanded, setExpanded],
  } = useContext(UIStateContext);

  const documentViewSettings = accountSettings?.pages_settings?.add_documents;
  const showAddDocument =
    documentViewSettings?.show_page ??
    (userRole &&
      [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST].includes(userRole));

  useEffect(() => {
    const labels = new UILabels(mode);
    const routeConfig = [
      [
        ...(accountSettings?.pages_settings?.reconciliation?.show_page !== false
          ? [
              {
                label:
                  accountSettings?.pages_settings?.reconciliation?.menu_label ??
                  labels.getLabel('drawer', 'reconciliation'),
                icon: <Rule />,
                target: '/reconciliation',
                tooltip: '🅶 + 🅾',
              },
            ]
          : []),
        ...(accountSettings?.pages_settings?.commissions?.show_page !== false
          ? [
              {
                label:
                  accountSettings?.pages_settings?.commissions?.menu_label ??
                  labels.getLabel('drawer', 'cash'),
                icon: <RequestQuoteOutlined />,
                tooltip: '🅶 + 🅲',
                target: '/commissions',
              },
            ]
          : []),
        ...(accountSettings?.pages_settings?.policies?.show_page !== false
          ? [
              {
                label:
                  accountSettings?.pages_settings?.policies?.menu_label ??
                  labels.getLabel('drawer', 'transactions'),
                icon: <ReceiptOutlined />,
                target: '/policies',
                tooltip: '🅶 + 🅿',
              },
            ]
          : []),
        {
          label: 'Schedules',
          icon: <EventNoteOutlined />,
          target: [
            ...(accountSettings?.pages_settings?.comp_grids_schedules
              ?.show_page !== false
              ? [
                  {
                    label:
                      accountSettings?.pages_settings?.comp_grids_schedules
                        ?.menu_label ?? 'Comp grids',
                    icon: <TableChartOutlined />,
                    target: '/schedules/comp-grids/viewer',
                    access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
                  },
                ]
              : []),
            {
              label: 'Comp profiles',
              icon: <PaymentOutlined />,
              target: '/schedules/comp-profiles',
              access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
            },
            {
              label: 'Comp profile sets',
              icon: <PaymentsOutlined />,
              target: '/schedules/comp-profile-sets',
              access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
            },
            {
              label: 'Carriers',
              icon: <BusinessOutlined />,
              target: '/schedules/carriers',
              access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
            },
            ...(accountSettings?.pages_settings?.incentives_schedules
              ?.show_page !== false
              ? [
                  {
                    label:
                      accountSettings?.pages_settings?.incentives_schedules
                        ?.menu_label ?? 'Incentives',
                    icon: <LeaderboardOutlined />,
                    target: '/schedules/incentive-tiers',
                    access: [Roles.FINTARY_ADMIN],
                  },
                ]
              : []),
          ],
          access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
        },
        {
          label: 'Comp grids',
          icon: <TableChartOutlined />,
          target: '/schedules/comp-grids/viewer',
          access: [Roles.PRODUCER],
        },
        ...(accountSettings?.pages_settings?.insights?.show_page !== false
          ? [
              {
                label:
                  accountSettings?.pages_settings?.insights?.menu_label ??
                  labels.getLabel('drawer', 'dashboard'),
                icon: <InsightsOutlined />,
                target: [
                  ...(accountSettings?.pages_settings?.insights?.show_page !==
                  false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.insights
                              ?.menu_label ?? 'Dashboard',
                          icon: <StorageOutlined />,
                          target: '/insights',
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.businessInsights
                    ?.show_page !== false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.businessInsights
                              ?.menu_label ?? 'Business Insights',
                          icon: <MultilineChartOutlined />,
                          target: '/insights/business-insights',
                          access: [Roles.FINTARY_ADMIN],
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.policiesInsights
                    ?.show_page !== false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.policiesInsights
                              ?.menu_label ?? 'Policies (Life clients)',
                          icon: <LeaderboardOutlined />,
                          target: '/insights/policies-insights',
                          access: [Roles.FINTARY_ADMIN],
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.agentsInsights
                    ?.show_page !== false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.agentsInsights
                              ?.menu_label ?? 'Agents',
                          icon: <PeopleOutlined />,
                          target: '/insights/agents-insights',
                          access: [Roles.FINTARY_ADMIN],
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.agentGroupInsights
                    ?.show_page !== false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.agentGroupInsights
                              ?.menu_label ?? 'Agent Group',
                          icon: <GroupsOutlined />,
                          target: '/insights/agent-group-insights',
                          access: [Roles.FINTARY_ADMIN],
                        },
                      ]
                    : []),
                ],
                access: [Roles.ACCOUNT_ADMIN, Roles.PRODUCER],
              },
            ]
          : []),
        ...(accountSettings?.pages_settings?.views?.show_page !== false
          ? [
              {
                label:
                  accountSettings?.pages_settings?.views?.menu_label ?? 'Views',
                icon: <BookmarksOutlined />,
                target: '/views',
                tooltip: '🅶 + 🆅',
              },
            ]
          : []),
        ...(accountSettings?.pages_settings?.summaries?.show_page !== false ||
        accountSettings?.pages_settings?.reports?.show_page !== false
          ? [
              {
                label: 'Reports',
                icon: <SaveOutlined />,
                target: [
                  ...(accountSettings?.pages_settings?.summaries?.show_page !==
                  false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.summaries
                              ?.menu_label ?? 'Summaries',
                          icon: <ViewCarouselOutlined />,
                          target: '/reports/summary',
                          access: [Roles.ACCOUNT_ADMIN],
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.reports?.show_page !==
                  false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.reports
                              ?.menu_label ?? 'Reports',
                          icon: <StorageOutlined />,
                          target: '/reports',
                        },
                      ]
                    : []),
                ],
                tooltip: '🅶 + 🆁',
                access: [Roles.ACCOUNT_ADMIN, Roles.PRODUCER],
              },
            ]
          : []),
        ...(accountSettings?.pages_settings?.companies?.show_page !== false ||
        accountSettings?.pages_settings?.products?.show_page !== false ||
        accountSettings?.pages_settings?.options?.show_page !== false
          ? [
              {
                label: 'Companies',
                icon: <BusinessOutlined />,
                access: [
                  Roles.ACCOUNT_ADMIN,
                  // TODO (frank.santillan): Undo after supporting config in settings
                  // Roles.PRODUCER,
                  Roles.DATA_SPECIALIST,
                ],
                target: [
                  ...(accountSettings?.pages_settings?.companies?.show_page !==
                  false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.companies
                              ?.menu_label ?? 'Companies',
                          icon: <StorageOutlined />,
                          target: '/companies',
                          access: [
                            Roles.ACCOUNT_ADMIN,
                            Roles.PRODUCER,
                            Roles.DATA_SPECIALIST,
                          ],
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.products?.show_page !==
                  false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.products
                              ?.menu_label ?? 'Products',
                          icon: <SellOutlined />,
                          target: '/companies/products',
                          access: [
                            Roles.ACCOUNT_ADMIN,
                            Roles.PRODUCER,
                            Roles.DATA_SPECIALIST,
                          ],
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.options?.show_page !==
                  false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.options
                              ?.menu_label ?? 'Options',
                          icon: <StyleOutlined />,
                          target: '/companies/products/options',
                          access: [
                            Roles.ACCOUNT_ADMIN,
                            Roles.PRODUCER,
                            Roles.DATA_SPECIALIST,
                          ],
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),
        ...(accountSettings?.pages_settings?.agents?.show_page !== false ||
        accountSettings?.pages_settings?.agents_groups?.show_page !== false ||
        accountSettings?.pages_settings?.agents_production?.show_page !== false
          ? [
              {
                label: 'Agents',
                icon: <PeopleOutlined />,
                target: [
                  ...(accountSettings?.pages_settings?.agents?.show_page !==
                  false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.agents
                              ?.menu_label ?? 'Agents',
                          icon: <StorageOutlined />,
                          target: '/agents/list',
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.agents_groups
                    ?.show_page !== false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.agents_groups
                              ?.menu_label ?? 'Groups',
                          icon: <GroupsOutlined />,
                          target: '/agents/groups',
                        },
                      ]
                    : []),
                  ...(accountSettings?.pages_settings?.agents_production
                    ?.show_page !== false
                    ? [
                        {
                          label:
                            accountSettings?.pages_settings?.agents_production
                              ?.menu_label ?? 'Production',
                          icon: <MultilineChartOutlined />,
                          target: '/agents/production',
                        },
                      ]
                    : []),
                ],
                access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
              },
            ]
          : []),
        ...(accountSettings?.pages_settings?.documents?.show_page !== false
          ? [
              {
                label:
                  accountSettings?.pages_settings?.documents?.menu_label ??
                  labels.getLabel('drawer', 'documents'),
                icon: <FilePresent />,
                target: '/documents',
                tooltip: '🅶 + 🅳',
                access: [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST],
              },
            ]
          : []),
        {
          label: labels.getLabel('drawer', 'settings'),
          icon: <SettingsOutlined />,
          target: '/settings',
          tooltip: '🅶 + 🆂',
          access: [Roles.ACCOUNT_ADMIN, Roles.PRODUCER, Roles.DATA_SPECIALIST],
        },
      ],
      [
        {
          label: 'Admin',
          icon: <AdminPanelSettingsOutlined />,
          target: [
            {
              label: 'Accounts',
              icon: <AccountCircleOutlined />,
              target: '/admin/accounts',
              tooltip: '🅶 + 🅰',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Users',
              icon: <PeopleOutlined />,
              target: '/admin/users',
              tooltip: '🅶 + 🆄',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Companies',
              icon: <BusinessOutlined />,
              target: '/admin/companies',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Documents',
              icon: <FilePresent />,
              target: '/admin/documents?qc=new',
              access: [Roles.FINTARY_ADMIN],
            },
            // {
            //   label: 'Processors',
            //   icon: <DeveloperBoard />,
            //   target: '/admin/processors',
            // },
            {
              label: 'Fields',
              icon: <ListAlt />,
              target: '/admin/fields',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Tools',
              icon: <ConstructionOutlined />,
              target: '/admin/tools',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Calculation',
              icon: <ListAlt />,
              target: '/admin/calculation',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Metrics',
              icon: <InsightsOutlined />,
              target: '/admin/metrics',
              access: [Roles.FINTARY_ADMIN],
            },
          ],
          access: [Roles.FINTARY_ADMIN],
        },
        {
          label: 'Comp grids',
          icon: <TableChartOutlined />,
          target: [
            {
              label: 'Viewer',
              icon: <TableChartOutlined />,
              target: '/schedules/comp-grids/viewer',
              access: enableCompGridManagement
                ? [Roles.ACCOUNT_ADMIN]
                : [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Comp grids',
              icon: <ViewColumnOutlined />,
              target: '/schedules/comp-grids',
              access: enableCompGridManagement
                ? [Roles.ACCOUNT_ADMIN]
                : [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Products',
              icon: <SellOutlined />,
              target: '/schedules/comp-grids/products',
              access: enableCompGridManagement
                ? [Roles.ACCOUNT_ADMIN]
                : [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Levels',
              icon: <LeaderboardOutlined />,
              target: '/schedules/comp-grids/levels',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Criteria',
              icon: <FilterAltOutlined />,
              target: '/schedules/comp-grids/criteria',
              access: enableCompGridManagement
                ? [Roles.ACCOUNT_ADMIN]
                : [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Rates',
              icon: <TableChartOutlined />,
              target: '/schedules/comp-grids/rates',
              access: [Roles.FINTARY_ADMIN],
            },
          ],
          access: enableCompGridManagement
            ? [Roles.ACCOUNT_ADMIN]
            : [Roles.FINTARY_ADMIN],
        },
        {
          label: 'Accounting',
          icon: <CurrencyExchangeOutlined />,
          target: [
            {
              label: 'Transactions',
              icon: <FormatListBulletedOutlined />,
              target: '/accounting/transactions',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Transaction details',
              icon: <FormatAlignJustifyOutlined />,
              target: '/accounting/transaction-details',
              access: [Roles.FINTARY_ADMIN],
            },
          ],
          access: [Roles.FINTARY_ADMIN],
        },
        {
          label: 'Documents',
          icon: <ReceiptLongOutlined />,
          target: [
            {
              label: labels.getLabel('drawer', 'documents'),
              icon: <FilePresent />,
              target: '/documents',
              access: ['admin', 'demo'],
            },
            {
              label: 'Profiles',
              icon: <DynamicFeedOutlined />,
              target: '/documents/profiles',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Extractions',
              icon: <ReadMore />,
              target: '/extractions',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: labels.getLabel('drawer', 'mappings'),
              icon: <Route />,
              target: '/mappings',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Imports',
              icon: <SaveAltOutlined />,
              target: '/imports',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: labels.getLabel('drawer', 'processors'),
              icon: <DeveloperBoard />,
              target: '/processors',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Vertex',
              icon: <ContrastRounded />,
              target: '/vertex',
              access: [Roles.FINTARY_ADMIN],
            },
            {
              label: 'Prompts',
              icon: <MarkChatRead />,
              target: '/prompts',
              access: [Roles.FINTARY_ADMIN],
            },
          ],
          access: [Roles.FINTARY_ADMIN],
        },
        {
          label: 'Reconciliation',
          icon: <Rule />,
          target: [
            {
              label: 'Reconcilers',
              icon: <Merge />,
              target: '/reconciliation/reconcilers',
              access: ['admin', 'demo'],
            },
            {
              label: 'Flows',
              icon: <AccountTreeOutlined />,
              target: '/reconciliation/flows',
              access: ['admin', 'demo'],
            },
            {
              label: 'History',
              icon: <History />,
              target: '/reconciliation/history',
              access: [Roles.FINTARY_ADMIN],
            },
          ],
          access: [Roles.FINTARY_ADMIN],
        },
      ].filter((e) => e?.label),
      [
        auth?.currentUser?.email &&
        !['hello+bernardorobles@fintary.com'].includes(auth?.currentUser?.email)
          ? {
              label: 'Previews',
              icon: <VisibilityOutlined />,
              target: [
                {
                  label: 'Reconciler',
                  icon: <Rule />,
                  target: '/reconciler',
                  access: ['admin', 'demo'],
                },
                // {
                //   label: labels.getLabel('drawer', 'banking'),
                //   icon: <ReceiptLong />,
                //   target: '/transactions',
                //   access: ['admin', 'demo'],
                //   disabled: true,
                // },
              ],
              access: [Roles.FINTARY_ADMIN],
            }
          : {},
      ].filter((e) => e?.label),
    ];

    setMenuItems(routeConfig);
  }, [mode, role, userRole, accountSettings]);

  useEffect(() => {
    setMenuOpen(isDesktop);
  }, [isDesktop]);

  const StyledBox = styled(Box)({
    width: drawerWidth,
    overflow: 'auto',
    overflowX: 'hidden',
  });

  useEffect(() => {
    const user = localStorage.getItem('customLoginUser');
    if (user) {
      setLoginAsUser(JSON.parse(user));
    }

    auth.onAuthStateChanged(async (user) => {
      if (
        user?.email &&
        (user.email.endsWith('@fintary.com') ||
          user.email.endsWith('@fintary.co'))
      ) {
        if (['demo@fintary.com'].includes(user.email)) {
          setRole('demo');
        } else {
          const { data: resJson } = await refetchUser();
          if (resJson && resJson?.data?.isAdmin) {
            setRole('admin');
          }
        }
      }
    });
  }, [setRole]);

  const handleClose = (result) => {
    setShowUploadModal(false);
    if (result) {
      openSnackbar(result);
    }
  };

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme: t }) => ({
    border: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        isDesktop ? <ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} /> : null
      }
      sx={{
        pl: 0.5,
        borderRadius: '0 24px 24px 0',
        '&:hover': { backgroundColor: 'whitesmoke' },
      }}
      {...props}
    />
  ))(({ theme: t }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginTop: 0,
      marginBottom: 0,
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: 0,
  }));

  const filteredMenuItems = menuItems
    .filter((section) => hasAccess(section.access, userRole, role === 'admin'))
    .map((section) =>
      section
        .filter((item) => hasAccess(item?.access, userRole, role === 'admin'))
        .map((item) => ({
          ...item,
          target: Array.isArray(item.target)
            ? item.target.filter((t) =>
                hasAccess(t?.access, userRole, role === 'admin')
              )
            : item.target,
        }))
    )
    .filter((section) => section.length > 0);

  return (
    <>
      <Drawer
        variant={isDesktop ? 'persistent' : 'temporary'}
        open={menuOpen}
        sx={{ width: menuOpen ? drawerWidth : 0 }}
        onClose={isDesktop ? undefined : () => setMenuOpen(false)}
      >
        <Toolbar />
        <StyledBox
          sx={{
            height: `calc(100vh - 114px${loginAsUser ? ' - 100px' : ''})`,
          }}
        >
          {accountSettingsReady &&
            filteredMenuItems.map((section, i) => (
              <div key={i}>
                <Divider />
                <List>
                  {section.map(
                    ({
                      label,
                      icon,
                      target,
                      access,
                      endIcon,
                      disabled,
                      tooltip,
                    }) =>
                      Array.isArray(target) ? (
                        <Accordion expanded={expanded[label]} key={label}>
                          <AccordionSummary
                            onClick={() => {
                              if (!expanded[label]) {
                                navigate(target[0].target);
                              }
                              setExpanded({
                                ...expanded,
                                [label]: !expanded[label],
                              });
                            }}
                            sx={{ pr: 1, minHeight: 40 }}
                          >
                            <ListItem
                              button
                              disabled={disabled}
                              sx={{
                                pl: 1,
                                pr: 0,
                                py: 0.5,
                                '&:hover': { backgroundColor: 'unset' },
                              }}
                            >
                              <ListItemIcon
                                style={{
                                  minWidth: 40,
                                  color: 'black',
                                }}
                              >
                                {icon}
                              </ListItemIcon>
                              <ListItemText primary={label} />
                              <FintaryAdminOnlyIcon access={access} />
                            </ListItem>
                          </AccordionSummary>
                          <AccordionDetails>
                            {target.map((t) => (
                              <Tooltip
                                title={t.tooltip ?? ''}
                                arrow
                                placement="right"
                                key={t.label}
                              >
                                <ListItem
                                  key={t.label}
                                  onClick={() => {
                                    if (!isDesktop) setMenuOpen(false);
                                  }}
                                  component={Link}
                                  to={t.target}
                                  sx={{
                                    ...{ pl: 2.5, py: 0.25, opacity: 0.8 },
                                    ...(location.pathname ===
                                    t.target.split('?')[0]
                                      ? {
                                          backgroundColor: '#2096f322',
                                          borderRadius: '0 24px 24px 0',
                                        }
                                      : {}),
                                    color: 'unset',
                                  }}
                                  disabled={t.disabled}
                                >
                                  <ListItemIcon
                                    style={{
                                      color:
                                        location.pathname ===
                                        t.target.split('?')[0]
                                          ? theme.palette.primary.main
                                          : 'unset',
                                      minWidth: 40,
                                    }}
                                  >
                                    {t.icon}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={t.label}
                                    primaryTypographyProps={
                                      location.pathname === t.target
                                        ? {
                                            color: theme.palette.primary.main,
                                            fontWeight: 600,
                                            fontSize: 15,
                                          }
                                        : { fontSize: 15 }
                                    }
                                  />

                                  <FintaryAdminOnlyIcon access={t.access} />
                                </ListItem>
                              </Tooltip>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <Tooltip
                          title={tooltip ?? ''}
                          arrow
                          placement="right"
                          enterDelay={1000}
                          key={target}
                        >
                          <ListItem
                            key={target}
                            onClick={() => {
                              if (!isDesktop) setMenuOpen(false);
                            }}
                            component={Link}
                            to={target}
                            sx={
                              location.pathname === target
                                ? {
                                    color: 'unset',
                                    backgroundColor: '#2096f322',
                                    borderRadius: '0 24px 24px 0',
                                    px: 1.5,
                                    py: 0.5,
                                  }
                                : {
                                    color: 'unset',
                                    px: 1.5,
                                    py: 0.5,
                                    '&:hover': {
                                      borderRadius: '0 24px 24px 0',
                                    },
                                  }
                            }
                            secondaryAction={
                              access === 'admin' && (
                                <IconButton
                                  edge="end"
                                  disabled
                                  sx={{ opacity: 0.5, mr: -2 }}
                                >
                                  {endIcon ?? <AdminPanelSettingsOutlined />}
                                </IconButton>
                              )
                            }
                            disabled={disabled}
                          >
                            <ListItemIcon
                              style={{
                                color:
                                  location.pathname === target
                                    ? theme.palette.primary.main
                                    : 'unset',
                                minWidth: 40,
                              }}
                            >
                              {icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={label}
                              primaryTypographyProps={
                                location.pathname === target
                                  ? {
                                      color: theme.palette.primary.main,
                                      fontWeight: 600,
                                    }
                                  : {}
                              }
                              sx={
                                location.pathname === target
                                  ? {
                                      color: 'white',
                                      fontWeight: 600,
                                      '&:hover': {
                                        color: 'black',
                                      },
                                    }
                                  : {}
                              }
                            />
                            <FintaryAdminOnlyIcon access={access} />
                          </ListItem>
                        </Tooltip>
                      )
                  )}
                </List>
              </div>
            ))}
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          >
            {loginAsUser && (
              <Box
                sx={{ pt: 1, px: 1, mb: showAddDocument ? 0 : 1 }}
                display="flex"
                flexDirection="column"
              >
                <Typography variant="body2">Logged in as:</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    width: '100%',
                    fontWeight: 'medium',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    mb: 0.5,
                  }}
                >
                  {loginAsUser.email || loginAsUser.delegatorEmail}
                </Typography>
                <Button
                  color="error"
                  startIcon={<Logout />}
                  variant="contained"
                  onClick={() => {
                    localStorage.clear();
                    setLoginAsUser(null);
                    // Force to refresh all active tabs
                    localStorage.setItem('loggedOut', Date.now().toString());
                    window.location.href = '/admin/accounts';
                  }}
                >
                  Logout
                </Button>
              </Box>
            )}
            {showAddDocument && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Tooltip
                  title="You can also email statements to documents@fintary.com for processing"
                  arrow
                >
                  <Button
                    variant="contained"
                    startIcon={<CloudUploadOutlined />}
                    onClick={() => {
                      setShowUploadModal(true);
                    }}
                    sx={{
                      width: 180,
                      mt: 1,
                      mb: 1.5,
                      boxShadow: 0,
                      backgroundColor: '#e5f1fd',
                      color: '#2196f3',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: '#e5f1fd',
                        color: '#2196f3',
                        boxShadow: 0,
                      },
                    }}
                  >
                    Upload data
                  </Button>
                </Tooltip>
                {/* <Divider />
                <List sx={{ background: 'white', py: 0 }}>
                  <Tooltip
                    title="You can also email statements to us statements@fintary.com for processing"
                    arrow
                  >
                    <span>
                      <ListItem
                        button
                        onClick={() => {
                          setShowUploadModal(true);
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 24, mr: 2 }}>
                          <CloudUploadOutlined />
                        </ListItemIcon>
                        {isDesktop && <ListItemText primary="Upload data" />}
                      </ListItem>
                    </span>
                  </Tooltip>
                </List> */}
              </Box>
            )}
          </div>
        </StyledBox>
      </Drawer>
      <BaseModal
        open={showUploadModal}
        handleClose={handleClose}
        openSnackbar={openSnackbar}
      />
    </>
  );
};

AppDrawer.propTypes = {
  openSnackbar: PropTypes.func,
};

export default AppDrawer;
