import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { arrayToMap } from 'common/utils';
import { useState } from 'react';

import DataView from '@/components/DataView';
import LazyLoadDynamicSelect from '@/components/molecules/LazyLoadDynamicSelect';
import CompProfilesAdd from '@/components/schedules/CompProfilesView/CompProfilesAdd';
import CompRuleConfig from '@/components/schedules/CompProfilesView/CompRuleConfig';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';

const CommissionProfileRules = ({ rules, dynamicSelects }) => {
  const [expanded, setExpanded] = useState(false);
  const ruleKeys = [
    { id: 'product_id', label: 'Product(s)' },
    { id: 'product_option_id', label: 'Option(s)' },
    { id: 'issue_age_min', label: 'Issue age min' },
    { id: 'issue_age_max', label: 'Issue age max' },
    { id: 'transaction_type', label: 'Transaction type' },
    { id: 'compensation_type', label: 'Compensation type' },
    { id: 'match_criteria', label: 'Other criteria' },
    { id: 'commission_rate', label: 'Comm rate' },
    { id: 'receivable_rate', label: 'Recv rate' },
    { id: 'payout_rate', label: 'Payout rate' },
    { id: 'keep_rate', label: 'Flat rate' },
    { id: 'plus_rate', label: 'Percentage of received' },
    { id: 'carrier_grid_level', label: 'Carrier grid level' },
    { id: 'carrier_grid_override_rate', label: 'Carrier grid level rate' },
    { id: 'split', label: 'Split pct' },
  ];
  const { data: optionsLookup } = API.getBasicQuery(
    'companies/products/options'
  );

  const optionsMap = optionsLookup?.reduce((acc, option) => {
    acc[option.id] = option;
    return acc;
  }, {});

  return (
    <Box>
      <Button
        endIcon={expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          color: 'text.primary',
          fontWeight: 500,
        }}
      >
        {`${rules.length} rules`}
      </Button>
      {expanded && (
        <Table>
          <TableHead>
            <TableRow>
              {ruleKeys.map((ruleKey) => (
                <TableCell key={ruleKey.id}>{ruleKey.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rules.map((rule, i) => (
              <TableRow key={`rule-${i}`}>
                {ruleKeys.map((ruleKey) => (
                  <TableCell key={ruleKey.id}>
                    {['product_id', 'product_option_id'].includes(ruleKey.id) &&
                    Array.isArray(rule?.[ruleKey.id])
                      ? rule?.[ruleKey.id]?.map((id) => (
                          <Chip
                            label={
                              ruleKey.id === 'product_id'
                                ? dynamicSelects?.find(
                                    (option) => option.id === id
                                  )?.product_name
                                : optionsMap[id]?.name
                            }
                            sx={{ height: 24, m: 0.1 }}
                            key={`rule-${i}::${ruleKey.id}-${id}`}
                          />
                        ))
                      : ruleKey.id === 'match_criteria'
                        ? rule?.[ruleKey.id]?.map((matcher, i) => (
                            <Chip
                              label={Formatter.fieldMatcher(matcher)}
                              sx={{ height: 24, m: 0.1 }}
                              key={`rule-${i}::${ruleKey.id}-${i}`}
                            />
                          ))
                        : rule[ruleKey.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

const CommissionProfileCriteriaRules = ({
  payeeLevelId,
  payerLevelId,
  rules,
  dynamicSelects,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [fetchData, setFetchData] = useState(false);

  const { data: payeeRates } = API.getBasicQuery(
    'comp-grids/rates',
    `comp_grid_level_id=${payeeLevelId ?? ''}&is_dynamic_select=true`,
    fetchData
  );
  const { data: payerRates } = API.getBasicQuery(
    'comp-grids/rates',
    `comp_grid_level_id=${payerLevelId ?? ''}&is_dynamic_select=true`,
    fetchData
  );
  const payeeRatesMap = arrayToMap(
    payeeRates?.data,
    (e) => `${e.comp_grid_level_id}::${e.comp_grid_criterion_id}`
  );
  const payerRatesMap = arrayToMap(
    payerRates?.data,
    (e) => `${e.comp_grid_level_id}::${e.comp_grid_criterion_id}`
  );

  const ruleKeys = [
    { id: 'comp_grid_criteria_id', label: 'Criteria' },
    { id: 'match_criteria', label: 'Other criteria' },
    { id: 'calculation_method', label: 'Calculation method' },
  ];

  return (
    <Box>
      <Button
        onClick={() => {
          setExpanded(!expanded);
          if (!fetchData) setFetchData(true);
        }}
        endIcon={expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
      >
        {`${rules.length} rules`}
      </Button>
      {expanded && (
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              {ruleKeys.map((ruleKey) => (
                <TableCell key={ruleKey.id}>{ruleKey.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rules.map((rule, i) => (
              <TableRow key={`rule-${i}`}>
                {ruleKeys.map((ruleKey) => (
                  <TableCell key={ruleKey.id}>
                    {ruleKey.id === 'match_criteria'
                      ? rule?.[ruleKey.id]?.map((matcher, i) => (
                          <Chip
                            label={Formatter.fieldMatcher(matcher)}
                            sx={{ height: 24, m: 0.1 }}
                            key={`rule-${i}::${ruleKey.id}-${i}`}
                          />
                        ))
                      : ruleKey.id === 'comp_grid_criteria_id'
                        ? rule?.[ruleKey.id]?.map((criterion_id) => (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography variant="body2">
                                {Formatter.getDynamicSelectFormatter(
                                  Formatter.compGridCriterion
                                )(criterion_id, dynamicSelects)}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ whiteSpace: 'nowrap' }}
                              >
                                {rule?.calculation_method === 'compGridLevel'
                                  ? `(@ ${payerRatesMap[`${payerLevelId}::${criterion_id}`]?.rate}% - ${payeeRatesMap[`${payeeLevelId}::${criterion_id}`]?.rate}% = ${+(payerRatesMap[`${payerLevelId}::${criterion_id}`]?.rate ?? 0) - +(payeeRatesMap[`${payeeLevelId}::${criterion_id}`]?.rate ?? 0)}%)`
                                  : rule?.calculation_method === 'payHouseRate'
                                    ? `(@ ${payeeRatesMap[`${payeeLevelId}::${criterion_id}`]?.house_rate}%)`
                                    : rule?.calculation_method ===
                                        'payOverrideUpToTotalRate'
                                      ? `(@ ${payeeRatesMap[`${payeeLevelId}::${criterion_id}`]?.rate}%)`
                                      : ''}
                              </Typography>
                            </Box>
                          ))
                        : rule[ruleKey.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

const CompProfilesView = () => {
  const { userRole } = useRoleStore();
  const { selectedAccount } = useAccountStore();
  const { data: accountSettings } = API.getBasicQuery('accounts/settings');
  const { data: companies } = API.getBasicQuery(
    'companies',
    'type=Carrier&is_dynamic_select=true'
  );

  const isNewCompGrids = accountSettings?.comp_grids_enabled;

  const viewSettings = accountSettings?.pages_settings?.agent_schedules;
  const viewOnly = viewSettings?.read_only ?? false;

  const dataDesc = {
    label: 'Compensation profiles',
    table: 'schedules/comp-profiles',
    editable: userRole === Roles.ACCOUNT_ADMIN,
    copyable: true,
    validateData: (data) => data.name,
    bulkAdd: true,
    fields: [
      { id: 'name', label: 'Name' },
      {
        id: 'company_id',
        label: 'Carrier',
        type: 'dynamic-select',
        table: 'companies',
        field: 'id',
        nullable: true,
        formatter: Formatter.getDynamicSelectFormatter((o) => o?.company_name),
        optionFormatter: (option) => option.company_name,
        optionValuer: (option) => option.id,
      },
      {
        id: 'product_type',
        label: 'Product type',
      },
      {
        id: 'payee_types',
        label: 'Payout recipients',
        type: 'select',
        options: [
          { id: 'agent', label: 'Agent' },
          { id: 'upline', label: "Agents' hierarchy upline" },
        ],
      },
      {
        id: 'hierarchy_processing',
        label: 'Hierarchy processing',
        type: 'select',
        options: [
          { id: 'none', label: 'None (assigned agent only)' },
          { id: 'upline', label: 'Agent and upline' },
          { id: 'all', label: 'Entire hierarchy' },
        ],
      },
      {
        id: 'calculation_basis',
        label: 'Calculation basis',
        type: 'select',
        options: [
          { id: 'annual_premium', label: 'Policy data: Annual premium' },
          { id: 'target_premium', label: 'Policy data: Target premium' },
          {
            id: 'normalized_premium',
            label: 'Commission data: Normalize premium based on split',
          },
          { id: 'commissions', label: 'Commissions' },
          { id: 'commissions_remaining', label: 'Commissions remaining' },
        ],
      },
      {
        id: 'calculation_scope',
        label: 'Calculation scope',
        type: 'select',
        options: [
          { id: 'commission', label: 'Per commission' },
          { id: 'policy', label: 'Per policy' },
        ],
      },
      {
        id: 'payout_timing',
        label: 'Payout timing',
        type: 'select',
        options: [
          { id: 'first_payment_date', label: 'First payment date' },
          { id: 'first_processed_date', label: 'First processing date' },
        ],
      },
      {
        id: 'comp_grid_id',
        label: 'Comp grid',
        type: 'dynamic-select',
        table: 'comp-grids',
        queryParamName: 'company_id',
        queryParamValue: 'company_id',
        formatter: Formatter.getDynamicSelectFormatter(),
        optionFormatter: (option) => option.name,
        optionValuer: (option) => option.id,
      },
      {
        id: 'payer_comp_grid_level_id',
        label: 'Carrier grid level',
        type: 'dynamic-select',
        table: 'comp-grids/levels',
        queryParamName: 'comp_grid_id',
        queryParamValue: 'comp_grid_id',
        formatter: Formatter.getDynamicSelectFormatter(),
        optionFormatter: (option) => option.name,
        optionValuer: (option) => option.id,
      },
      {
        id: 'payee_comp_grid_level_id',
        label: 'House payout grid level',
        type: 'dynamic-select',
        table: 'comp-grids/levels',
        queryParamName: 'comp_grid_id',
        queryParamValue: 'comp_grid_id',
        formatter: Formatter.getDynamicSelectFormatter(),
        optionFormatter: (option) => option.name,
        optionValuer: (option) => option.id,
      },
      {
        id: 'schedules',
        label: 'Comp calculation rules',
        type: 'custom',
        table: isNewCompGrids ? 'comp-grids/criteria' : 'companies/products',
        tableFormatter: (rules, row, dynamicSelects) =>
          isNewCompGrids ? (
            <CommissionProfileCriteriaRules
              payeeLevelId={row.payee_comp_grid_level_id}
              payerLevelId={row.payer_comp_grid_level_id}
              rules={rules}
              dynamicSelects={dynamicSelects}
            />
          ) : (
            <CommissionProfileRules
              rules={rules}
              dynamicSelects={dynamicSelects}
            />
          ),
        render: (field, row, setter) => (
          <CompRuleConfig
            key={'schedules'}
            fieldId="schedules"
            companyId={row.company_id}
            payerLevelId={row.payer_comp_grid_level_id}
            payeeLevelId={row.payee_comp_grid_level_id}
            data={row}
            setter={setter}
            readOnly={userRole === Roles.DATA_SPECIALIST}
          />
        ),
      },
      { id: 'notes', label: 'Notes' },
      {
        id: 'contacts_agent_commission_profiles_names',
        label: 'Agents',
        type: 'custom',
        table: 'contacts',
        tableFormatter: (field, row, dynamicSelects, header) => (
          <LazyLoadDynamicSelect
            data={field}
            header={header}
            formatter={(o) =>
              Formatter.contact(o, { account_id: selectedAccount?.accountId })
            }
          />
        ),
        optionFormatter: (option) => `${option.email}`,
        optionValuer: (option) => option?.id,
        render: (field, row, setter, dynamicSelects) => (
          <CompProfilesAdd
            key="contacts_agent_commission_schedule_profiles"
            table="contacts_agent_commission_schedule_profiles"
            data={row}
            setter={setter}
            field={field}
            dynamicSelects={dynamicSelects}
            readOnly={userRole !== Roles.ACCOUNT_ADMIN}
          />
        ),
      },
      { id: 'divider', type: 'divider' },
      {
        id: 'created_at',
        label: 'Created at',
        condition: (data) => !!data.created_at,
        formatter: Formatter.dateTime,
        readOnly: true,
        visible: ['form'],
      },
      {
        id: 'updated_at',
        label: 'Updated at',
        condition: (data) => !!data.updated_at,
        formatter: Formatter.dateTime,
        readOnly: true,
        visible: ['form'],
      },
    ],
    // queryChips: {},
    filterConfigs: {
      company_id: { type: 'select', label: 'Carrier', options: {} },
    },
  };

  if (Array.isArray(companies) && companies.length > 0) {
    companies.forEach((company, i) => {
      dataDesc.filterConfigs.company_id.options[company.id] = {
        id: company.id,
        label: company.company_name,
        query: { comp_grid_id: company.id },
      };
    });
  }

  return (
    <DataView
      dataDesc={dataDesc}
      hideExport
      readOnly={userRole === Roles.DATA_SPECIALIST}
      viewOnly={viewOnly}
      enablePagination
      headingOffset={112}
    />
  );
};

export default CompProfilesView;
