import { Home as HomeIcon } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';
import { Link } from 'react-router-dom';

import EmptyState from '@/components/EmptyState';
import { ReactComponent as NotFoundIllustration } from '@/illustrations/not-found.svg';

const NotFoundPage = () => {
  return (
    <EmptyState
      image={<NotFoundIllustration />}
      title="Page doesn’t exist."
      description="The page you’re trying to access doesn’t exist."
      button={
        <Fab variant="extended" color="primary" component={Link} to="/">
          <Box clone mr={1}>
            <HomeIcon />
          </Box>
          Home
        </Fab>
      }
    />
  );
};

export default NotFoundPage;
