import * as XLSX from 'xlsx';

class Spreadsheet {
  #data;

  // private
  constructor(data) {
    this.#data = data;
  }

  static loadSpreadsheet = async (file) => {
    const data = XLSX.read(await file.arrayBuffer(), {
      raw: file.type === 'text/csv',
      cellStyles: true,
    });
    return new Spreadsheet(data);
  };

  static indexToCol = (index) => {
    return index < 0
      ? ''
      : this.indexToCol(index / 26 - 1) +
          String.fromCharCode((index % 26) + 65);
  };

  getSheets = () => {
    return this.#data.SheetNames;
  };

  getJson = (sheetName, isRawData = false) => {
    if (sheetName && this.#data.SheetNames.includes(sheetName)) {
      const json = XLSX.utils.sheet_to_json(this.#data.Sheets[sheetName], {
        header: 1,
        raw: false,
        blankrows: false,
      });

      if (!isRawData) {
        let lastRow = json.length - 1;
        for (; lastRow > 0; lastRow -= 1) {
          if (json[lastRow].length > 0) {
            break;
          }
        }
        return json.slice(0, lastRow + 1);
      }
      return XLSX.utils.sheet_to_json(this.#data.Sheets[sheetName]);
    }
    return {};
  };
}

export default Spreadsheet;
