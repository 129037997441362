const DEFAULTS = {
  drawer: {
    dashboard: 'Insights',
    reconciliation: 'Reconciliation',
    cash: 'Cash',
    transactions: 'Transactions',
    banking: 'Banking',
    entities: 'Entities',
    documents: 'Documents',
    settings: 'Settings',
    processors: 'Processors',
    mappings: 'Mappings',
    users: 'Users',
    reconciliationHistory: 'Reconciliations',
  },
  dashboard: {
    title: 'Dashboard',
    sales: 'Sales',
    revenue: 'Revenue',
    cashReceived: 'Cash received',
    cashPaid: 'Cash paid',
    transactionsWithCashReceipt: 'Transactions with cash receipt',
    deductionsChargebacks: 'Deductions & chargebacks',
    cashInflowByPayingEntity: 'Cash inflow by paying entity',
    cashInflowByCustomer: 'Cash inflow by customer',
    revenueByCustomer: 'Revenue by customer',
    aggregatePremiumsByCarrier: 'Aggregate premiums by carrier',
    monthlyCashInflow: 'Monthly cash inflow',
    monthlyRevenue: 'Monthly revenue',
    effectiveCommissionRateByPayingEntity:
      'Effective commission rate by paying entity',
    effectiveCommissionRateByCarrier:
      'Effective commission rate by carrier/MGA',
  },
  reconciliation: {
    title: 'Reconciliation Summary',
    salesId: 'Sales Id',
    entity: 'Carrier/MGA',
    payingEntity: 'Paying entity',
    customerName: 'Customer name',
    productType: 'Product type',
    startDate: 'Start date',
    annualizedRevenue: 'Annualized revenue',
    cashDue: 'Cash due',
    cashPaid: 'Cash paid',
    totalRevenue: 'Total revenue',
    balance: 'Balance',
    reconciliationStatus: 'Reconciled',
    status: 'Status',
  },
  cash: {
    title: 'Cash Details',
    titleSimple: 'Cash',
    transactionId: 'Transaction Id',
    policyId: 'Transaction Id',
    amount: 'Cash amount',
    commissionAmount: 'Cash amount',
    customerName: 'Customer name',
    invoiceDate: 'Invoice date',
    paymentDate: 'Payment date',
    processingDate: 'Processing date',
    repName: 'Rep name',
    repId: 'Rep Id',
    invoiceNumber: 'Invoice number',
    statementNumber: 'Invoice number',
    transactionType: 'Transaction type',
    entity: 'Carrier/MGA',
    payingEntity: 'Paying entity',
    annualizedPremium: 'Premium amount',
    rate: 'Commission rate',
    startDate: 'Start date',
    productType: 'Product type',
    productName: 'Product name',
    fees: 'Fees',
    groupId: 'Group number',
    periodDate: 'Period',
  },
  transactions: {
    title: 'Transaction Details',
    titleSimple: 'Transactions',
    repName: 'Rep name',
    agent: 'Rep name',
    customerName: 'Customer name',
    salesId: 'Sales Id',
    startDate: 'Start date',
    payingEntity: 'Paying entity',
    annualizedRevenue: 'Annualized revenue',
    productName: 'Product name',
    paymentDate: 'Payment date',
    processingDate: 'Processing date',
    transactionType: 'Transaction type',
    commissionsDue: 'Commissions due',
    status: 'Status',
  },
  documents: {
    title: 'Documents',
  },
  mapper: {
    statement: 'Statement',
    report: 'Invoice', // Is this correct? How should we think about these two in generic?
  },
};

const STRINGS = {
  default: DEFAULTS,
  insurance: {
    ...DEFAULTS,
    drawer: {
      ...DEFAULTS.drawer,
      cash: 'Commissions',
      transactions: 'Policies',
      banking: 'Banking',
      entities: 'Companies',
      documents: 'Documents',
    },
    dashboard: {
      ...DEFAULTS.dashboard,
      sales: 'Policies placed in force',
      revenueOpt1: 'Premiums placed in force',
      revenueOpt2: 'Target premiums placed in force',
      revenueIssuePayOpt1: 'Premiums issue pay',
      revenueIssuePayOpt2: 'Target premiums issue pay',
      cashReceived: 'Commissions received',
      cashPaid: 'Commissions paid',
      transactionsWithCashReceipt: 'Policies with commission payments',
      deductionsChargebacks: 'Policies with reversed payments',
      cashInflowByPayingEntity: 'Commissions by paying entity',
      cashInflowByCustomer: 'Commissions by carrier',
      revenueByCustomerOpt1: 'Premiums by carrier',
      revenueByCustomerOpt2: 'Target premiums by carrier',
      aggregatePremiumsByCarrier: 'Aggregate premiums by carrier',
      agentCommissions: 'Commissions by agent',
      monthlyCashInflow: 'Commissions received',
      monthlyCashInflowAcc: 'Commissions received (accumulated)',
      monthlyCashOutflow: 'Commissions paid',
      monthlyCashOutflowAcc: 'Commissions paid (accumulated)',
      monthlyCashNet: 'Commissions P&L',
      monthlyCashNetAcc: 'Commissions P&L (accumulated)',
      monthlyRevenue: 'Policies in force',
      effectiveCommissionRateByPayingEntity:
        'Effective commission rate by paying entity',
      effectiveCommissionRateByCarrier:
        'Effective commission rate by carrier/MGA',
    },
    reconciliation: {
      ...DEFAULTS.reconciliation,
      salesId: 'Policy number',
      entity: 'Carrier/MGA',
      payingEntity: 'Paying entity',
      customerName: 'Customer name',
      productType: 'Product type',
      startDate: 'Effective date',
      annualizedRevenue: 'Premium',
      cashDue: 'Commissions due',
      cashPaid: 'Amount paid',
      totalRevenue: 'Total premiums',
      balance: 'Balance',
      reconciliationStatus: 'Reconciled',
    },
    cash: {
      ...DEFAULTS.cash,
      title: 'Commissions',
      titleSimple: 'Commissions',
      transactionId: 'Policy number',
      policyId: 'Policy number',
      amount: 'Commission amount',
      commissionAmount: 'Commission amount',
      customerName: 'Customer name',
      invoiceDate: 'Statement date',
      paymentDate: 'Payment date', // NOTE: This is confusing, because of evolution of fields and labels, the db field for statement date is paymentDate.
      processingDate: 'Processing date',
      repName: 'Agent name',
      repId: 'Agent Id',
      invoiceNumber: 'Statement number',
      statementNumber: 'Statement number',
      transactionType: 'Transaction type',
      writingCarrierName: 'Carrier/MGA',
      payingEntity: 'Paying entity',
      annualizedPremium: 'Premium amount',
      rate: 'Commission rate',
      startDate: 'Effective date',
      productType: 'Product type',
      premiumType: 'Premium type',
      productName: 'Product name',
      fees: 'Fees',
      groupId: 'Group number',
      periodDate: 'Period',
    },
    transactions: {
      ...DEFAULTS.transactions,
      title: 'Policies',
      titleSimple: 'Policies',
      repName: 'Agent name',
      agent: 'Agent name',
      customerName: 'Customer name',
      salesId: 'Policy number',
      startDate: 'Effective date',
      payingEntity: 'Master company',
      annualizedRevenue: 'Annualized revenue',
      productName: 'Product name',
      paymentDate: 'Payment date',
      processingDate: 'Processing date',
      transactionType: 'Transaction type',
      commissionsDue: 'Commissions due',
      status: 'Status',
    },
    documents: {
      ...DEFAULTS.documents,
    },
    mapper: {
      ...DEFAULTS.mapper,
      statement: 'Commissions statement',
      report: 'Production report',
    },
  },
};

class UILabels {
  #strings;

  constructor(mode) {
    this.#strings = STRINGS[mode || 'default'];
  }

  getLabel = (area, item) => {
    return this.#strings[area][item];
  };
}

export default UILabels;
