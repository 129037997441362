import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LaunchOutlined } from '@mui/icons-material';
import { Box, Chip, IconButton } from '@mui/material';
import CommonFormatter from 'common/Formatter';

import Formatter from '@/services/Formatter';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';

const ImportsView = () => {
  const dataDesc = useMemo(
    () => ({
      label: 'Imports',
      table: 'data_imports',
      fields: {
        summed_total_amount: {
          label: 'Summed total',
          enabled: true,
          formatter: Formatter.currency,
        },
        statement_total_amount: {
          label: 'Statement total',
          enabled: true,
          formatter: Formatter.currency,
        },
        count: {
          label: 'Count',
          enabled: true,
        },
        process_count: {
          label: 'Manual actions',
          enabled: true,
        },
        process_action_records: {
          label: 'Action records',
          enabled: true,
          formatter: (actionMap: { [key: string]: number }) => {
            if (!actionMap) {
              return '';
            }
            const actionRecords = Object.entries(actionMap).map(
              ([key, value]) => {
                return (
                  <Box key={key} sx={{ display: 'flex', gap: 1 }}>
                    <Chip
                      label={`${key}: ${value}`}
                      size="small"
                      sx={{ mb: '4px' }}
                    />
                  </Box>
                );
              }
            );
            return <Box>{actionRecords}</Box>;
          },
        },
        process_duration: {
          label: 'Duration',
          enabled: true,
          formatter: CommonFormatter.duration,
        },
        status: {
          label: 'Status',
          enabled: true,
          formatter: (val) =>
            Formatter.statusChip(val, {
              mapping: {
                Success: 'green',
                Failed: 'red',
              },
            }),
        },
        str_id: {
          label: 'Import ID',
          enabled: true,
          formatter: (val, row) => {
            // link to the documents vieew
            return (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Chip size="small" label={val} />
                  <IconButton
                    component={Link}
                    to={`/${row.type === 'statement' ? 'commissions' : 'policies'}?import_id=${val}`}
                    target="_blank"
                    sx={{
                      opacity: 0.5,
                      '&:hover': { opacity: 1 },
                      color: '#2196f3',
                    }}
                  >
                    <LaunchOutlined />
                  </IconButton>
                </Box>
              </>
            );
          },
        },
        type: {
          label: 'Type',
          enabled: true,
        },
        company: {
          label: 'Company',
          enabled: true,
          formatter: (val) => val?.company_name,
        },
        created_at: {
          label: 'Uploaded at',
          enabled: true,
          formatter: (s) => Formatter.dateTime(s),
          readOnly: true,
        },
      },
    }),
    []
  );

  return (
    <>
      <EnhancedDataView
        dataSpec={dataDesc}
        hideAdd
        hideSelectedCount
        enableMultiSelect={false}
        enableEdit={false}
        hideExport
      />
    </>
  );
};

export default ImportsView;
