import { useState, useEffect } from 'react';
import { useInterval } from 'react-use';

let intervalId: any = null;

const useAutoPolling = (
  apiFunction,
  checkCondition,
  delay,
  shouldPoll,
  limit = 5 * 1000 * 60
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  // limit polling total times
  const [totalPollingTimes, setTotalPollingTimes] = useState(0);
  const [shouldStartPoll, setShouldStartPoll] = useState(false);

  useEffect(() => {
    const _totalTime = totalPollingTimes * delay;
    if (_totalTime >= limit) {
      setShouldStartPoll(true);
      console.log('polling limit reached, stop polling...');
    }
  }, [totalPollingTimes, limit]);

  useEffect(() => {
    // if (!shouldPoll) {
    //   clearInterval(intervalId);
    // }
    setShouldStartPoll(shouldPoll);
  }, [shouldPoll]);

  useInterval(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiFunction();
        setTotalPollingTimes((prev) => prev + 1);
        if (checkCondition(response)) {
          clearInterval(intervalId);
          setData(response);
        }
      } catch (error) {
        console.error('polling error:', error);
        clearInterval(intervalId);
        setShouldStartPoll(false);
      } finally {
        setLoading(false);
      }
    };

    if (shouldStartPoll) {
      if (loading) {
        return;
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
      fetchData();
      console.log(shouldStartPoll ? 'polling start...' : 'polling stop...');
      intervalId = setInterval(fetchData, delay);
      if (!shouldStartPoll) {
        clearInterval(intervalId);
      }
    }
  }, delay);

  return { data, loading };
};

export default useAutoPolling;
