import { DragIndicatorOutlined } from '@mui/icons-material';
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface CustomField {
  label: string;
}

interface CustomSelectOptions {
  [custom_fields_id: string]: {
    fields: Record<string, CustomField>;
  };
}

interface Data {
  customSelectOptions: CustomSelectOptions;
  label: string;
}

interface Field {
  fields?: string[];
  custom_fields_id: string;
}

type DragableSelectProps = {
  data: Data;
  setter: React.Dispatch<React.SetStateAction<Field>>;
  field: Field;
};

type Item = {
  id: string;
  label: string;
  type: string;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};

interface DraggableItemProps {
  item: Item;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

const DraggableItem: React.FC<DraggableItemProps> = React.memo(
  ({ item, index, moveItem }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
      accept: 'item',
      hover(item: DragItem) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        moveItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: 'item',
      item: { id: item.id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <span ref={ref}>
        <Chip
          icon={<DragIndicatorOutlined sx={{ height: 12, width: 12 }} />}
          sx={{
            mr: 0.25,
            mb: 0.25,
            opacity: isDragging ? 0.5 : 1,
            backgroundColor: isDragging ? '#aad4ff' : '#e5e9ea',
          }}
          label={item.label}
        />
      </span>
    );
  }
);

const DragableSelect: React.FC<DragableSelectProps> = ({
  data,
  field,
  setter,
}) => {
  const [items, setItems] = useState(
    field.fields?.map((key) => ({
      id: key,
      label:
        data.customSelectOptions[field.custom_fields_id]?.fields?.[key]
          ?.label || key,
      type: 'item',
    })) || []
  );

  useEffect(() => {
    setter({
      ...field,
      fields: items.map((item) => item.id),
    });
  }, [items]);

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragItem);
    setItems(newItems);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const key = event.target.value as string;
    setItems((prevItems) => {
      const itemExists = prevItems?.some((item) => item.id === key);
      if (itemExists) {
        return prevItems.filter((item) => item.id !== key);
      } else {
        return [
          ...prevItems,
          {
            id: key,
            label:
              data.customSelectOptions[field.custom_fields_id]?.fields?.[key]
                .label || key,
            type: 'item',
          },
        ];
      }
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box>
        <Typography variant="subtitle2">{data.label}</Typography>
        <FormControl>
          <Select
            data-testid="select-comp"
            value="Add"
            onChange={handleSelectChange}
          >
            <MenuItem value="Add">Add</MenuItem>
            {Object.keys(
              data.customSelectOptions[field.custom_fields_id]?.fields || {}
            )?.map((key) => (
              <MenuItem key={key} value={key}>
                {data.customSelectOptions[field.custom_fields_id].fields[key]
                  .label || key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {Array.isArray(items) && items.length > 0 && (
          <Box
            sx={{
              mt: 0.5,
              mb: 1,
              p: 1,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderStyle: 'solid',
              borderColor: 'silver',
              borderWidth: 1,
              borderRadius: 4,
              width: '100%',
              backgroundColor: '#2196f308',
            }}
          >
            {items?.map((item, index) => (
              <DraggableItem
                key={item.id}
                index={index}
                item={item}
                moveItem={moveItem}
              />
            ))}
          </Box>
        )}
      </Box>
    </DndProvider>
  );
};

export default DragableSelect;
